import { useContext } from 'react';
import { AuthContext } from "./Context/AuthContext";
import { Modal, Button } from 'react-bootstrap';

const AfterLoginPopup = ({ onClose, show }) => {
    const { bannerDetails } = useContext(AuthContext);

    return (
        <Modal show={show} onHide={onClose} size="lg" aria-labelledby="after-login-popup" centered>
            <Modal.Header closeButton>
                {bannerDetails?.FRONT_WELCOME_TEXT_BANNER &&
                    <Modal.Title dangerouslySetInnerHTML={{ __html: bannerDetails.FRONT_WELCOME_TEXT_BANNER }}></Modal.Title>
                }
            </Modal.Header>
            <Modal.Body style={{padding : "0px"}}>
                {!bannerDetails?.FRONT_WELCOME_BANNER ? (
                    <>
                        <div style={{ boxShadow: '0 0 5px', padding: '0px' }}>
                            <h2>Dear Client,</h2>
                            <h5 className="mb-1">
                                You are requested to login with our official site <a href="# ">{process.env.REACT_APP_NAME}</a> only. Please check the site name before you login.
                            </h5>
                            <h5 className="mb-1">Thanks for your support.</h5>
                            <h5 className="mb-1">Team {process.env.REACT_APP_NAME}</h5>
                        </div>
                        <div className="mt-5 font-hindi" style={{ boxShadow: '0 0 5px', padding: '0px' }}>
                            <h2>प्रिय ग्राहक,</h2>
                            <h5 className="mb-1">
                                आपसे अनुरोध है कि केवल हमारी आधिकारिक साइट <a href="# ">{process.env.REACT_APP_NAME}</a> से लॉगिन करें। लॉगइन करने से पहले साइट का नाम जरूर देख लें।
                            </h5>
                            <h5 className="mb-1">आपके समर्थन के लिए धन्यवाद।</h5>
                            <h5 className="mb-1">टीम {process.env.REACT_APP_NAME}</h5>
                        </div>
                    </>
                ) : (
                    <img src={`https://dxc247.com/uploads/sites_configuration/${bannerDetails.FRONT_WELCOME_BANNER}`} className="img-fluid" alt="Welcome Banner"/>
                )}
            </Modal.Body>

        </Modal>
    );
};

export default AfterLoginPopup;
