import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const ProtectedRoute = ({  children }) => {

    const navigate = useNavigate();

    const isLoggedIn  = localStorage.getItem('token') !== null;
    useEffect(() => {

        if (!isLoggedIn) {
            return navigate('/login');
        }
        // eslint-disable-next-line
    }, [isLoggedIn]);

    if (!isLoggedIn) {
        return navigate('/login'); // It is important to return null or some kind of a fallback UI here because we don't want to render children if user is not logged in
    }

    return children;
};

export default ProtectedRoute;