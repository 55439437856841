import React, {useContext, useEffect,  useState} from "react";
import axiosFetch from "../../Common_Functions/Constants";
import {useNavigate, useParams} from "react-router-dom";
import {CommonContext} from "../../Components/Context/CommonContext";
import BetTable from "./BetTable";
import Loader from "../../Components/Loader";
import {SportsContext} from "../../Components/Context/SportsContext";

const SportsLayout = ({children, setSportList, sportList, gameId = null}) => {

    const [showTv, setShowTv] = useState(false)



    const {match_id} = useParams();

    const [myBetModel, setMyBetModel] = useState({})
    const [tabActive, setTabActive] = useState('odds')
    const nav = useNavigate();


    const {formatDateTime} = useContext(CommonContext);
    const {showLoader} = useContext(SportsContext);
    const exposure = localStorage.getItem('exposure')
    useEffect(() => {
        const getData = async () => {
            await axiosFetch(`sport_data/${match_id}`, 'get')
                .then((res) => {
                    setSportList(res.data)

                    if(Object.keys(res.data).length === 0){
                        nav('/')
                    }
                })
        }
        getData()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {

        const getBetListData = async () => {
            await axiosFetch(`bet_data/${match_id}`, 'get', setMyBetModel)
        }

        getBetListData();
        // eslint-disable-next-line
    }, [exposure])


    return (
        <>
        <div className="game-page" style={{position: 'relative'}}>

            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <span data-toggle="tab" onClick={() => {
                        setTabActive('odds');
                        setShowTv(false)
                    }}
                       className={`nav-link text-white ${tabActive === 'odds' ? 'active' : ""}`}>Odds</span>
                </li>
                <li className="nav-item">
                    <span data-toggle="tab" onClick={() => {
                        setTabActive('matched');
                        setShowTv(false)
                    }}
                       className={`nav-link text-white ${tabActive === 'matched' ? 'active' : ""}`}>
                        Matched Bet
                    </span>
                </li>
                <li  onClick={() => setShowTv(!showTv)} className="nav-item"><span  className='nav-link text-white'><i
                    className="fas fa-tv"></i></span></li>

            </ul>
            <div className="tab-content">
                <div style={{ position:'relative'}}>
                    {!showTv && gameId !== null && (
                        // eslint-disable-next-line jsx-a11y/iframe-has-title
                        <iframe
                            src={`https://api.codewithabhilash.com/play2.html?eid=${gameId}`}
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            allow="autoplay; encrypted-media"
                            allowFullScreen
                        ></iframe>
                    )}
                </div>


                <div id="oddstab" className={`tab-pane ${tabActive === 'odds' ? 'active' : ""}`}>
                    <div className="match-title">
                <span className="match-name">
                    {sportList?.match_name}
                </span>
                        {sportList.match_date_time && (
                            <span
                                className="float-right">{formatDateTime(new Date(sportList?.match_date_time))}</span>
                        )}

                        <span className="float-right"></span>
                        <input type="hidden" id="match_id" defaultValue={`${sportList?.match_id}`}/>
                        <input type="hidden" id="sport_id" defaultValue={`${sportList?.sport_id}`}/>
                        <input type="hidden" id="market_id" defaultValue={`${sportList?.market_id}`}/>
                    </div>

                    <span className="tvAdd position-relative" data-tv="0"></span>

                    <div id="scoreboard-box">

                        {/*<iframe style="width:100%;min-height: 230px;" scrolling="no" className="example"*/}
                        {/*        src="{{$urlscoreboard}}"></iframe>*/}
                    </div>
                    {children}

                </div>
                <div id="matched-bet" className={`tab-pane ${tabActive === 'matched' ? 'active' : ""}`}>
                    <div className="table-responsive">
                        <BetTable myBetModel={myBetModel}/>
                    </div>
                </div>

            </div>

        </div>
            {showLoader === true &&

                <Loader/>
            }
        </>
    )
}
export default SportsLayout;