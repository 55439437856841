function Teen20 () {


    return (<>
        <div className="last-result-tiele"><span>Rules</span></div>
        <div className="table-responsive rules-table">
            <table className="table table-bordered">
                <thead>
                <tr>
                    <th colSpan="2" className="box-10 text-center">Pair Plus</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="box-7">Pair (Double)</td>
                    <td className="box-3">1 To 1</td>
                </tr>
                </tbody>
            </table>
            <table className="table table-bordered">
                
                <tbody>
                <tr>
                    <td className="box-7">Flush (Color)</td>
                    <td className="box-3">1 To 4</td>
                </tr>
                </tbody>
            </table>
            <table className="table table-bordered">
                
                <tbody>
                <tr>
                    <td className="box-7">Straight (Rown)</td>
                    <td className="box-3">1 To 6</td>
                </tr>
                </tbody>
            </table>
            <table className="table table-bordered">
                
                <tbody>
                <tr>
                    <td className="box-7">Trio (Teen)</td>
                    <td className="box-3">1 To 35</td>
                </tr>
                </tbody>
            </table>
            <table className="table table-bordered">
                
                <tbody>
                <tr>
                    <td className="box-7">Straight Flush (Pakki Rown)</td>
                    <td className="box-3">1 To 45</td>
                </tr>
                </tbody>
            </table>
        </div>
    </>)
}
export default Teen20;