import CasinoLayout from "../Section/Casino/CasinoLayout";
import {useState} from "react";

import {CasinoLastResult} from "../Section/Casino/CasinoLastResult";
const Teen2024 = () => {
    const [sportList, setSportList] = useState({})

    return (
        <CasinoLayout sportList={sportList} setSportList={setSportList}>
            <div className="video-container" style={{minHeight: '0px'}}>
                <div className="video-overlay" style={{top: '-300px'}}>
                    <div className="casino-video-cards">
                        <div className="">
                            <div className="flip-card-container">
                                <div className="flip-card">
                                    <div className="flip-card-inner ">
                                        <div className="flip-card-front"><img src="/img/casino/cards/1.png"/></div>

                                    </div>
                                </div>
                                <div className="flip-card">
                                    <div className="flip-card-inner ">
                                        <div className="flip-card-front"><img src="/img/casino/cards/1.png"/></div>

                                    </div>
                                </div>
                                <div className="flip-card">
                                    <div className="flip-card-inner ">
                                        <div className="flip-card-front"><img src="/img/casino/cards/1.png"/></div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-1">
                            <div className="flip-card-container">
                                <div className="flip-card">
                                    <div className="flip-card-inner ">
                                        <div className="flip-card-front"><img src="/img/casino/cards/1.png"/></div>

                                    </div>
                                </div>
                                <div className="flip-card">
                                    <div className="flip-card-inner ">
                                        <div className="flip-card-front"><img src="/img/casino/cards/1.png"/></div>

                                    </div>
                                </div>
                                <div className="flip-card">
                                    <div className="flip-card-inner ">
                                        <div className="flip-card-front"><img src="/img/casino/cards/1.png"/></div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="casino-detail teenpatti2024">
                <div className="casino-table">
                    <div className="casino-table-left-box">
                        <div className="casino-table-header">
                            <div className="casino-nation-detail">
                                Player A-20
                                <div>
                                    <img src="/img/card/1.jpg"/>
                                    <img src="/img/card/2.jpg"/>
                                    <img src="/img/card/3.jpg"/>
                                    <img src="/img/card/4.jpg"/>
                                    <img src="/img/card/5.jpg"/>
                                </div>
                            </div>
                            <div className="casino-odds-box back">Back</div>
                            <div className="casino-odds-box lay">Lay</div>
                        </div>
                        <div className="casino-table-body">
                            <div className="casino-table-row ">
                                <div className="casino-nation-detail">
                                    <div className="casino-nation-name">Player A-20</div>
                                </div>
                                <div className="casino-odds-box back suspended-box"><span
                                    className="casino-odds">0</span></div>
                                <div className="casino-odds-box lay suspended-box"><span
                                    className="casino-odds">0</span></div>
                            </div>
                            <div className="casino-table-row">
                                <div className="casino-nation-detail">
                                    <div className="casino-nation-name">Session 9</div>
                                </div>
                                <div className="casino-odds-box back suspended-box"><span
                                    className="casino-odds">0</span></div>
                                <div className="casino-odds-box lay suspended-box"><span
                                    className="casino-odds">0</span></div>
                            </div>
                            <div className="casino-table-row">
                                <div className="casino-nation-detail">
                                    <div className="casino-nation-name">Consecutive Cards 20</div>
                                </div>
                                <div className="casino-odds-box back suspended-box"><span
                                    className="casino-odds">0</span></div>
                                <div className="casino-odds-box lay suspended-box"><span
                                    className="casino-odds">0</span></div>
                            </div>
                            <div className="casino-table-row">
                                <div className="casino-nation-detail">
                                    <div className="casino-nation-name">Pair 20</div>
                                </div>
                                <div className="casino-odds-box back suspended-box"><span
                                    className="casino-odds">0</span></div>
                                <div className="casino-odds-box lay suspended-box"><span
                                    className="casino-odds">0</span></div>
                            </div>
                            <div className="casino-table-row">
                                <div className="casino-nation-detail">
                                    <div className="casino-nation-name">Straight 20</div>
                                </div>
                                <div className="casino-odds-box back suspended-box"><span
                                    className="casino-odds">0</span></div>
                                <div className="casino-odds-box lay suspended-box"><span
                                    className="casino-odds">0</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="casino-table-box-divider"></div>
                    <div className="casino-table-right-box">
                        <div className="casino-table-header">
                            <div className="casino-nation-detail">
                                Player B-24
                                <div>
                                    <img src="/img/card/8.jpg"/>
                                    <img src="/img/card/9.jpg"/>
                                    <img src="/img/card/10.jpg"/>
                                    <img src="/img/card/11.jpg"/>
                                    <img src="/img/card/12.jpg"/>
                                    <img src="/img/card/13.jpg"/>
                                </div>
                            </div>
                            <div className="casino-odds-box back">Back</div>
                            <div className="casino-odds-box lay">Lay</div>
                        </div>
                        <div className="casino-table-body">
                            <div className="casino-table-row ">
                                <div className="casino-nation-detail">
                                    <div className="casino-nation-name">Player B-24</div>
                                </div>
                                <div className="casino-odds-box back suspended-box"><span
                                    className="casino-odds">0</span></div>
                                <div className="casino-odds-box lay suspended-box"><span
                                    className="casino-odds">0</span></div>
                            </div>
                            <div className="casino-table-row">
                                <div className="casino-nation-detail">
                                    <div className="casino-nation-name">Session 32</div>
                                </div>
                                <div className="casino-odds-box back suspended-box"><span
                                    className="casino-odds">0</span></div>
                                <div className="casino-odds-box lay suspended-box"><span
                                    className="casino-odds">0</span></div>
                            </div>
                            <div className="casino-table-row">
                                <div className="casino-nation-detail">
                                    <div className="casino-nation-name">Consecutive Cards 24</div>
                                </div>
                                <div className="casino-odds-box back suspended-box"><span
                                    className="casino-odds">0</span></div>
                                <div className="casino-odds-box lay suspended-box"><span
                                    className="casino-odds">0</span></div>
                            </div>
                            <div className="casino-table-row">
                                <div className="casino-nation-detail">
                                    <div className="casino-nation-name">Pair 24</div>
                                </div>
                                <div className="casino-odds-box back suspended-box"><span
                                    className="casino-odds">0</span></div>
                                <div className="casino-odds-box lay suspended-box"><span
                                    className="casino-odds">0</span></div>
                            </div>
                            <div className="casino-table-row">
                                <div className="casino-nation-detail">
                                    <div className="casino-nation-name">Straight 24</div>
                                </div>
                                <div className="casino-odds-box back suspended-box"><span
                                    className="casino-odds">0</span></div>
                                <div className="casino-odds-box lay suspended-box"><span
                                    className="casino-odds">0</span></div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <marquee scrollamount="3" className="casino-remark m-b-10">Welcome</marquee>
            <div className="casino-last-result-title">
                <span>Last Result</span>
                {/*<span><a href={`/frontend/reports/user-casino-game-result?gameName=teen32`}></a></span>*/}
            </div>


        </CasinoLayout>
    );

};

export default Teen2024;
