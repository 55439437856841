import React from 'react';
import Alert from 'react-bootstrap/Alert';

const AlertBootstrap = (props) => (
    <div style={{minHeight: "70px"}}>
        <Alert variant={props.style} style={{minHeight: "70px"}} onClose={props.closeAlert} dismissible>
            <span>{props.message}</span>
        </Alert>
    </div>
);

export default AlertBootstrap;