import CasinoLayout from "../Section/Casino/CasinoLayout";


import {useState} from "react";
const Kbc = () => {

    const [sportList, setSportList] = useState({})

    return (
        <CasinoLayout sportList={sportList} setSportList={setSportList}>
            <div className="video-container" style={{minHeight: "0px"}}>
                <div className="video-overlay" style={{top: "-400px"}}>
                    <div className="casino-video-cards">
                        <div>

                        </div>
                    </div>
                </div>
            </div>


            <div className="kbc">
                <div className="casino-detail">
                    <div className="casino-table">
                        <div className="row row5 kbc-btns">
                            <div className="col-12 col-md-4">
                                <div className="casino-odd-box-container">
                                    <div className="casino-nation-name"><b>[Q1] Red Black</b></div>
                                    <div className="btn-group">
                                        <input type="radio" className="btn-check" id="redBlack-1" name="redBlackBtn"
                                               value="1"/>
                                        <label className="form-check-label btn suspended-box"
                                               htmlFor="redBlack-1">
                                            <img src="/img/casino/cards/heart.png"/>
                                            <img src="/img/casino/cards/diamond.png"/>
                                        </label>

                                        <input type="radio" className="btn-check" id="redBlack-2" name="redBlackBtn"
                                               value="2"/>
                                        <label className="form-check-label btn suspended-box"
                                               htmlFor="redBlack-2">
                                            <img src="/img/casino/cards/spade.png"/>
                                            <img src="/img/casino/cards/club.png"/>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="casino-odd-box-container">
                                    <div className="casino-nation-name"><b>[Q2] Odd Even</b></div>
                                    <div className="btn-group">
                                        <input type="radio" className="btn-check" id="oddEven-1" name="oddEvenBtn"
                                               value="1"/>
                                        <label className="form-check-label btn suspended-box"
                                               htmlFor="oddEven-1">Odd</label>

                                        <input type="radio" className="btn-check" id="oddEven-2" name="oddEvenBtn"
                                               value="2"/>
                                        <label className="form-check-label btn suspended-box"
                                               htmlFor="oddEven-2">Even</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="casino-odd-box-container">
                                    <div className="casino-nation-name"><b>[Q3] 7 Up-7 Down</b></div>
                                    <div className="btn-group">
                                        <input type="radio" className="btn-check" id="upDown-1" name="7UpDownBtn"
                                               value="1"/>
                                        <label className="form-check-label btn suspended-box"
                                               htmlFor="upDown-1">Up</label>

                                        <input type="radio" className="btn-check" id="upDown-2" name="7UpDownBtn"
                                               value="2"/>
                                        <label className="form-check-label btn suspended-box"
                                               htmlFor="upDown-2">Down</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row row5 kbc-btns kbcothers mt-xl-3">
                            <div className="col-12 col-md-4">
                                <div className="casino-odd-box-container">
                                    <div className="casino-nation-name"><b>[Q4] 3 Card Judgement</b></div>
                                    <div className="btn-group">
                                        <input type="radio" className="btn-check" id="cardj-1" name="3cardj" value="1"/>
                                        <label className="form-check-label btn suspended-box"
                                               htmlFor="cardj-1">A23</label>

                                        <input type="radio" className="btn-check" id="cardj-2" name="3cardj" value="2"/>
                                        <label className="form-check-label btn suspended-box"
                                               htmlFor="cardj-2">456</label>

                                        <input type="radio" className="btn-check" id="cardj-3" name="3cardj" value="3"/>
                                        <label className="form-check-label btn suspended-box"
                                               htmlFor="cardj-3">8910</label>

                                        <input type="radio" className="btn-check" id="cardj-4" name="3cardj" value="4"/>
                                        <label className="form-check-label btn suspended-box"
                                               htmlFor="cardj-4">JQK</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="casino-odd-box-container">
                                    <div className="casino-nation-name"><b>[Q5] Suits</b></div>
                                    <div className="btn-group">
                                        <input type="radio" className="btn-check" id="suits-1" name="suit" value="1"/>
                                        <label className="form-check-label btn {!! suspended-box" htmlFor="suits-1">
                                            <img src="/img/casino/cards/spade.png"/>
                                        </label>

                                        <input type="radio" className="btn-check" id="suits-2" name="suit" value="2"/>
                                        <label className="form-check-label btn suspended-box" htmlFor="suits-2">
                                            <img src="/img/casino/cards/heart.png"/>
                                        </label>

                                        <input type="radio" className="btn-check" id="suits-3" name="suit" value="3"/>
                                        <label className="form-check-label btn suspended-box" htmlFor="suits-3">
                                            <img src="/img/casino/cards/club.png"/>
                                        </label>

                                        <input type="radio" className="btn-check" id="suits-4" name="suit" value="4"/>
                                        <label className="form-check-label btn suspended-box" htmlFor="suits-4">
                                            <img src="/img/casino/cards/diamond.png"/>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <marquee data-v-ec4526dc="" scrollamount="3"
                             className="casino-remark m-b-10">Welcome</marquee>
                    <div className="casino-last-result-title">
                        <span>Last Result</span>
                        
                    </div>
                    
                </div>
            </div>


        </CasinoLayout>
    );

};


export default Kbc;
