import React from 'react';
import ReactDOM from 'react-dom/client';
import Login from './Login';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/dist/css/bootstrap.min.css';
import {isMobile} from 'react-device-detect';

import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import MobileHome from "./Mobile/MobileHome";
import DesktopHome from "./Desktop/Home";
import ProtectedRoute from "./ProtectedRoute";
import {AuthProvider} from "./Components/Providers/AuthProvider";
import Tennis from "./Mobile/SportsData/Tennis";
import DesktopTennis from "./Desktop/SportsData/Tennis";
import Cricket from "./Mobile/SportsData/MobileCricket";
import DesktopCricket from "./Desktop/SportsData/Cricket";
import Soccer from "./Mobile/SportsData/Soccer";
import DesktopSoccer from "./Desktop/SportsData/Soccer";
import {CommonProvider} from "./Components/Providers/CommonProvider";
import {SportsProvider} from "./Components/Providers/SportsProvider";
import AccountStatement from "./Mobile/AccountStatement";
import DesktopAccountStatement from "./Desktop/AccountStatement";
import ProfitLossReport from "./Mobile/ProfitLossReport";
import DesktopProfitLossReport from "./Desktop/ProfitLossReport";
import BetHistories from "./Mobile/BetHistories";
import DesktopBetHistories from "./Desktop/BetHistories";
import {Rules} from "./Mobile/Rules";
import CasinoResultReport from "./Mobile/CasinoResultReport";
import DesktopCasinoResultReport from "./Desktop/CasinoResultReport";
import CasinoMain from "./Mobile/Section/Casino/CasinoMain";
import CasinoMainDesktop from "./Desktop/Section/Casino/CasinoMain";
import {CasinoProvider} from "./Components/Providers/CasinoProvider";
import AllCasinos from "./Mobile/AllCasinos";
import DesktopAllCasinos from "./Desktop/AllCasinos";
import HomeTabPages from "./Desktop/HomeTabPages";
import FantasyGames from "./Mobile/FantasyGames";
import DesktopFantasyGames from "./Desktop/FantasyGames";


const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
    <React.StrictMode>

        <CommonProvider>
            <SportsProvider>
        <CasinoProvider>
                <Router>
                    <AuthProvider>
                        <Routes>

                            <Route path="/login" element={<Login/>}/>
                            <Route path="/our/:which_casino" element={<ProtectedRoute>{isMobile ? <AllCasinos/> : <DesktopAllCasinos/>}</ProtectedRoute>} />
                            <Route path="/" element={<ProtectedRoute>{isMobile ? <MobileHome/> : <DesktopHome/>}</ProtectedRoute>}/>
                            <Route path="/tennis/:match_id" element={<ProtectedRoute>{isMobile ? <Tennis/> : <DesktopTennis/>}</ProtectedRoute>}/>
                            <Route path="/game-list/:match" element={<ProtectedRoute><HomeTabPages/></ProtectedRoute>}/>
                            <Route path="/fantasy" element={<ProtectedRoute>{isMobile ? <FantasyGames/> : <DesktopFantasyGames/> }</ProtectedRoute>}/>

                            <Route path="/account-statement" element={<ProtectedRoute>{isMobile ?   <AccountStatement/> : <DesktopAccountStatement/> }</ProtectedRoute>}/>
                            <Route path="/profit-loss" element={<ProtectedRoute>{isMobile ?   <ProfitLossReport/> : <DesktopProfitLossReport/> }</ProtectedRoute>}/>
                            <Route path="/bet-history" element={<ProtectedRoute>{isMobile ?  <BetHistories/> : <DesktopBetHistories/>}</ProtectedRoute>}/>
                            <Route path="/rules" element={<ProtectedRoute><Rules/></ProtectedRoute>}/>
                            <Route path="/casino-game-results" element={<ProtectedRoute>{isMobile ?  <CasinoResultReport/> : <DesktopCasinoResultReport/>}</ProtectedRoute>}/>
                            <Route path="/casino/:match_id" element={<ProtectedRoute>{isMobile  ? <CasinoMain/> : <CasinoMainDesktop />}</ProtectedRoute>}/>


                            <Route path="/soccer/:match_id" element={<ProtectedRoute>{isMobile ? <Soccer/> : <DesktopSoccer/>}</ProtectedRoute>}/>
                            <Route path="/cricket/:match_id" element={<ProtectedRoute>{isMobile ? <Cricket/> : <DesktopCricket/>}</ProtectedRoute>}/>
                        </Routes>
                    </AuthProvider>
                </Router>
        </CasinoProvider>
            </SportsProvider>
        </CommonProvider>

    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


