export const Dtl20 = ({lastResults, openPopup}) => {
    return (
        <div className="last-result-container text-right mt-1">
            {Object.entries(lastResults).map(([key, result]) => {
                const mid = result.mid;
                const className = result.win === '1' ? 'ball-runs last-result m-l-5 playera' : (result.win === '21' ? 'ball-runs last-result m-l-5 playerb' : "ball-runs last-result m-l-5 playerc");

                return (
                    <span onClick={() => openPopup(mid)}
                          key={mid}
                          className={className}
                          title={`Round ID: ${mid}`}
                    >
                                    {result.win === '1' ? 'D' : (result.win === '21' ?  'T' : 'L')}
                                </span>
                );
            })}
        </div>
    );
}
