const MatchedBetTable = ({mybetModel, type}) => {


    return (
        <div className="table-responsive m-u-table">
            <div className="tbodyScroll">
                {type !== 'lottcard' ? (
                    <table className="table coupon-table table-bordered table-stripted">
                        <thead>
                        <tr>
                            <th>Runner</th>
                            {(type === 'superover' || type === 'cricketv3' || type === 'trap' || type === 'poker' || type === 'card32eu' || type === 'queen' || type === 'card32') && (
                                <th>Bet Type</th>
                            )}
                            <th>Odds</th>
                            <th>Stack</th>
                        </tr>
                        </thead>
                        <tbody>
                        {mybetModel.length > 0 ? (
                            mybetModel.map((data, key) => {
                                const rowClass = (data.extra === 'LAY' || data.extra === 'NO') ? 'lay' : 'back';
                                return (
                                    <tr key={key} className={rowClass}>
                                        {data.bet_type === '3CARDJ' ? (
                                            <td>{data.type} {data.team_name}</td>
                                        ) : (
                                            <td>{data.team_name}</td>
                                        )}
                                        {(type === 'superover' || type === 'cricketv3' || type === 'trap' || type === 'poker' || type === 'card32eu' || type === 'queen' || type === 'card32') && (
                                            <td>{data.extra}</td>
                                        )}
                                        <td>{data.bet_odds}</td>
                                        <td>{data.bet_amount}</td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                {(type === 'superover' || type === 'cricketv3' || type === 'trap' || type === 'poker' || type === 'card32eu') ? (
                                    <th colSpan="4">No Records Found</th>
                                ) : (
                                    <th colSpan="3">No Records Found</th>
                                )}
                            </tr>
                        )}
                        </tbody>
                    </table>
                ) : (
                    <div className="my-bets lottery-bets">
                        {mybetModel.map((data, key) => (
                            <div key={key} className="lottery-my-bet-box">
                                <div>{`${data.type.charAt(0).toUpperCase() + data.type.slice(1).toLowerCase()} - ${data.team_name === 10 ? 0 : data.team_name}`}</div>
                                <div>{data.bet_amount}</div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default MatchedBetTable;
