import React, {useState, useEffect, useRef, useContext} from 'react';
import CasinoLayout from "../Section/Casino/CasinoLayout";
import axiosFetch, {
    getExBySingleTeamNameCasino,

    getPlayerCardAccordingToNumberOfPlayers, resetBetFields
} from "../../Common_Functions/Constants";
import {useParams} from "react-router-dom";
import {SportsContext} from "../../Components/Context/SportsContext";

import Notify from "../../js/Notify";
import {CasinoLastResult} from "../Section/Casino/CasinoLastResult";

const Teen9 = () => {
    const profitData = useRef(0);
    const profit = useRef(0);
    const loss = useRef(0);
    const ruleImage = '/img/rules/teen20.jpg'
    const ruleDescription = '';

    const [roundId, setRoundId] = useState('');
    const roundIdSaved = useRef(null);
    const remark = useRef('Welcome');
    const teamname = useRef('');
    const [submitButtonDisable, setSubmitButtonDisable] = useState(false);

    const [lastResult, setLastResult] = useState({});

    const stakeValue = useRef(0);
    const [odds, setOdds] = useState(0);
    const [backOrLay, setbackOrLay] = useState('back');
    const [sportList, setSportList] = useState({});
    const {match_id} = useParams();
    const {betType, setBetType, setPopupDisplayForDesktop} = useContext(SportsContext);
    const [hideLoading, setHideLoading] = useState(true);

    const teamNames = useRef(["Player A", "Player B"]);
    const [data, setData] = useState([]);

    const [playerAmounts, setPlayerAmounts] = useState({
        "Tiger Winner": '',
        "Tiger Pair": '',
        "Tiger Flush": '',
        "Tiger Straight": '',
        "Tiger Trio": '',
        "Tiger Straight Flush": '',
        "Dragon Winner": '',
        "Dragon Pair": '',
        "Dragon Flush": '',
        "Dragon Straight": '',
        "Dragon Trio": '',
        "Dragon Straight Flush": '',
        "Lion Winner": '',
        "Lion Pair": '',
        "Lion Flush": '',
        "Lion Straight": '',
        "Lion Trio": '',
        "Lion Straight Flush": '',
    });


    const [playerStatuses, setPlayerStatuses] = useState({
        "Tiger Winner": 'suspended-box',
        "Tiger Pair": 'suspended-box',
        "Tiger Flush": 'suspended-box',
        "Tiger Straight": 'suspended-box',
        "Tiger Trio": 'suspended-box',
        "Tiger Straight Flush": 'suspended-box',
        "Dragon Winner": 'suspended-box',
        "Dragon Pair": 'suspended-box',
        "Dragon Flush": 'suspended-box',
        "Dragon Straight": 'suspended-box',
        "Dragon Trio": 'suspended-box',
        "Dragon Straight Flush": 'suspended-box',
        "Lion Winner": 'suspended-box',
        "Lion Pair": 'suspended-box',
        "Lion Flush": 'suspended-box',
        "Lion Straight": 'suspended-box',
        "Lion Trio": 'suspended-box',
        "Lion Straight Flush": 'suspended-box',
    });

    const [oddsData, setOddsData] = useState({
        "Winner": {Tiger: '', Lion: '', Dragon: ''},
        "Pair": {Tiger: '', Lion: '', Dragon: ''},
        "Flush": {Tiger: '', Lion: '', Dragon: ''},
        "Straight": {Tiger: '', Lion: '', Dragon: ''},
        "Trio": {Tiger: '', Lion: '', Dragon: ''},
        "Straight Flush": {Tiger: '', Lion: '', Dragon: ''}
    });


    const exposure = localStorage.getItem('exposure');
    const sportLength = Object.keys(data).length;


    useEffect(() => {
        const getPlayerNameSuffix = (playerName) => {
            if (playerName.includes('Winner')) {
                return 'WINNER'; // Default to Winner if 'Winner' is not in playerName
            } else if (playerName.includes('Tiger')) {
                return 'OTHER_T'; // If it contains Tiger, use 'other_t'
            } else if (playerName.includes('Dragon')) {
                return 'OTHER_D'; // If it contains Dragon, use 'other_d'
            } else if (playerName.includes('Lion')) {
                return 'OTHER_L'; // If it contains Lion, use 'other_l'
            }
            return 'Winner'; // Fallback to Winner
        };
        if (data?.sub && sportList?.id) {
            const keys = Object.keys(playerAmounts); // Get the keys (player names) from playerAmounts

            keys.forEach((playerName) => {
                getExBySingleTeamNameCasino(sportList.id, data.mid, playerName, match_id, getPlayerNameSuffix(playerName))
                    .then(res => {
                        // Update playerAmounts based on the response
                        setPlayerAmounts(prev => ({
                            ...prev,
                            [playerName]: res.data // Assuming the amount is in the response
                        }));
                    })
                    .catch(error => {
                        console.error(`Error fetching data for ${playerName}:`, error);
                    });
            });
        }
    }, [exposure, sportLength, roundId]);
    const placeBet = () => {

        setHideLoading(false)
        if (playerStatuses[teamname.current] === 'suspended-box') {
            Notify(`Bet Not Confirmed. Reason: Game Suspended`, null, null, 'danger');
            setPopupDisplayForDesktop(false);
            setHideLoading(true)
            return;
        }

        if (roundIdSaved.current !== roundId) {
            Notify("Bet Not Confirm Reason Game Change", null, null, 'danger')
            setPopupDisplayForDesktop(false)
            setHideLoading(true)
            return;
        }


        setSubmitButtonDisable(true)

        const postdata = {

            "sportId": sportList.id,
            "matchId": roundId,
            "isback": backOrLay === 'back' ? 1 : 0,
            "placeName": teamname.current,
            "placeName2": null,
            "odds": odds,
            "oddsk": 0,
            "profit": parseFloat(profit.current).toFixed(2),
            "loss": parseFloat(loss.current).toFixed(2),
            "betType": betType,
            "bet_side": backOrLay.toUpperCase(),
            "betAmount": parseFloat(stakeValue.current),
            "type": "TEEN9",
            "matchType": "teen9",

        }

        axiosFetch('casino/store', 'post', null, postdata)
            .then((res) => {
                if (res.data.status === true) {

                    resetBetFields(profitData, stakeValue)
                    Notify("Bet Placed Successfully", null, null, 'success')
                } else {

                    resetBetFields(profitData, stakeValue)
                    Notify(res.data.msg, null, null, 'danger')
                }
                setHideLoading(true)
                setSubmitButtonDisable(false)
                setPopupDisplayForDesktop(false)
            })


    }
    useEffect(() => {


        if (data?.sub) {
            Object.keys(playerStatuses).forEach((team, index) => {

                updatePlayerStats(data.sub[index], team);
            });

            data.sub.forEach(item => {
                const {nat, b} = item; // Destructure nat and b from item
                const [team, ...betTypeArr] = nat.split(' '); // Split nat into team and bet type
                const betType = betTypeArr.join(' '); // Rejoin the remaining parts as the bet type

                // Check if betType exists in oddsData
                if (oddsData[betType]) {
                    setOddsData(prevOdds => ({
                        ...prevOdds,
                        [betType]: {
                            ...prevOdds[betType],
                            [team]: b // Update the b value for the specific team
                        }
                    }));
                }
            });


        }

    }, [data]);

    const updatePlayerStats = (playerData, playerName) => {
        if (!playerData) return;

        let playerStatus = playerData.gstatus === "SUSPENDED" ? "suspended-box" : '';
        setPlayerStatuses(prev => ({...prev, [playerName]: playerStatus}));
    };

    const openPopup = (isBakOrLay, teamnam, oddvalue, winnerName, playerName) => {


        if (playerName === 'Tiger' && winnerName !== 'Winner') {
            setBetType('OTHER_T');
        } else if (playerName === 'Dragon' && winnerName !== 'Winner') {
            setBetType('OTHER_D');
        } else if (playerName === 'Lion' && winnerName !== 'Winner') {
            setBetType('OTHER_L');
        }
        else{
            setBetType('WINNER');
        }


        if (parseFloat(oddvalue) > 0) {
            roundIdSaved.current = roundId
            setbackOrLay(isBakOrLay)
            setPopupDisplayForDesktop(true);
            teamname.current = teamnam
            setOdds(oddvalue)
        } else {
            Notify("Odds Should Not Be Zero", null, null, 'danger')

        }


    }

    const casinoBetDataNew = (new_odds) => {
        stakeValue.current = new_odds
        if (backOrLay === 'back') {


            loss.current = stakeValue.current;


            profit.current = profitData.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)

        } else {

            profit.current = profitData.current = stakeValue.current;


            loss.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)
        }


    }

    const renderDynamicRows = () => {

        return Object.keys(oddsData).map(status => (

            <tr key={status}>
                <td className="box-4"><b>{status}</b></td>
                <td className={`box-2 back text-center ${playerStatuses[`Tiger ${status}`]}`}
                    onClick={() => openPopup('back', "Tiger " + status, oddsData[status]?.Tiger, status, 'Tiger')}>

                    <span className="odds d-block"><b>{oddsData[status]?.Tiger}</b></span>
                    <span className="d-block">
<div className={parseFloat(playerAmounts[`Tiger ${status}`]) > 0 ? 'text-success' : 'text-danger'}>   {playerAmounts[`Tiger ${status}`]}</div>
                    </span>

                </td>
                <td className={`box-2 back text-center ${playerStatuses[`Lion ${status}`]}`}
                    onClick={() => openPopup('back', "Lion " + status, oddsData[status]?.Lion, status, 'Lion')}>
                    <span className="odds d-block"><b>{oddsData[status]?.Lion}</b></span>
                    <span className="d-block">
<div
    className={parseFloat(playerAmounts[`Lion ${status}`]) > 0 ? 'text-success' : 'text-danger'}>   {playerAmounts[`Lion ${status}`]}</div>
                    </span>
                </td>
                <td className={`box-2 back text-center ${playerStatuses[`Dragon ${status}`]}`}
                    onClick={() => openPopup('back', "Dragon " + status, oddsData[status]?.Dragon, status, 'Dragon')}>
                    <span className="odds d-block"><b>{oddsData[status]?.Dragon}</b></span>
                    <span className="d-block">
<div
    className={parseFloat(playerAmounts[`Dragon ${status}`]) > 0 ? 'text-success' : 'text-danger'}>   {playerAmounts[`Dragon ${status}`]}</div>
                    </span>
                </td>
            </tr>
        ));
    };

    return (
        <CasinoLayout hideLoading={hideLoading} isBack={backOrLay} teamname={teamname} handleStakeChange={casinoBetDataNew} odds={odds}
                      stakeValue={stakeValue} setOdds={setOdds} placeBet={placeBet}
                      submitButtonDisable={submitButtonDisable} data={data} roundId={roundId} setRoundId={setRoundId}
                      sportList={sportList}
                      setSportList={setSportList} setData={setData} setLastResult={setLastResult}>


            <div className="video-container" style={{minHeight: '0px'}}>
                <div className="video-overlay" style={{top: '-300px'}}>
                    <div className="videoCards">
                        <div>
                            <h3 className="text-white">Tiger</h3>
                            <div>
                                {getPlayerCardAccordingToNumberOfPlayers(data, 1, 3).map((img, key) => (
                                    <img key={key} src={img} alt={`Tiger Card ${key}`}/>
                                ))}
                            </div>
                        </div>
                        <div>
                            <h3 className="text-white">Lion</h3>
                            <div>
                                {getPlayerCardAccordingToNumberOfPlayers(data, 2, 3).map((img, key) => (
                                    <img key={key} src={img} alt={`Lion Card ${key}`}/>
                                ))}
                            </div>
                        </div>
                        <div>
                            <h3 className="text-white">Dragon</h3>
                            <div>
                                {getPlayerCardAccordingToNumberOfPlayers(data, 3, 3).map((img, key) => (
                                    <img key={key} src={img} alt={`Dragon Card ${key}`}/>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="table-responsive mb-1">
                <table className="table table-bordered mb-0">
                    <thead>
                    <tr>
                        <th className="box-4"></th>
                        <th className="box-2 back text-center"><b>Tiger</b></th>
                        <th className="box-2 back text-center"><b>Lion</b></th>
                        <th className="box-2 back text-center"><b>Dragon</b></th>
                    </tr>
                    </thead>
                    <tbody>
                    {renderDynamicRows()}
                    </tbody>
                </table>
                <marquee scrollamount="3" className="casino-remark m-b-10">{remark.current}</marquee>
                <div className="casino-last-result-title">
                    <span>Last Result</span>
                </div>
                <div className="last-result-container">
                    <CasinoLastResult sportList={sportList} lastResults={lastResult} data={data}/>
                </div>
            </div>

            
        </CasinoLayout>
    );
};

export default Teen9;
