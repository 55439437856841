import CasinoLayout from "../Section/Casino/CasinoLayout";
import {useContext, useEffect, useRef, useState} from "react";

import {CasinoLastResult} from "../Section/Casino/CasinoLastResult";

import axiosFetch, {

    getExByColor,

    getExByTeamNameForCasino, resetBetFields,

} from "../../Common_Functions/Constants";
import {useParams} from "react-router-dom";
import {SportsContext} from "../../Components/Context/SportsContext";
import {BetPlacPopupSection} from "../../Components/BetPlacPopupSection";

import Notify from "../../js/Notify";
import {CasinoContext} from "../../Components/Context/CasinoContext";

const Card32 = () => {
    const {updateCardsForCard32Casino} = useContext(CasinoContext)

    const defaultValues = {odds: {back: 0, lay: 0}, status: 'suspended-box', amounts: 0, cards: [], card_number: null}

    const [totalPlayers, setTotalPlayers] = useState(
        Array.from({length: 4}, (_, index) => (`Player ${index + 8}`)).reduce((accumulator, currentValue) => {
            return {...accumulator, [currentValue]: defaultValues}
        }, {})
    );

    const ruleImage = '/img/rules/card32.jpg'
    const ruleDescription = '';
    const [roundId, setRoundId] = useState('')


    const roundIdSaved = useRef(null);

    const [submitButtonDisable, setSubmitButtonDisable] = useState(false)


    const stakeValue = useRef(0);
    const [odds, setOdds] = useState(0)

    const [backOrLay, setbackOrLay] = useState('back')
    const [sportList, setSportList] = useState({})
    const {match_id} = useParams();
    const {
        setBetType,
        setPopupDisplay,

    } = useContext(SportsContext)
    const [hideLoading, setHideLoading] = useState(true)


    const teamNames = useRef([])

    const [data, setData] = useState([]);


    const remark = useRef('Welcome');
    const [lastResult, setLastResult] = useState({});
    const teamname = useRef('');
    const loss = useRef(0);
    const profit = useRef(0);
    const profitData = useRef(0);
    const calculateTeamname = async () => {
        const updatedPlayers = {...totalPlayers}; // Create a copy of the current state

        // Create an array of promises
        const promises = Object.keys(updatedPlayers).map(async (key) => {
            const d = await getExByTeamNameForCasino(sportList.id, roundId, key, match_id, 'ODDS');

            updatedPlayers[key] = {
                ...updatedPlayers[key],
                amounts: d.data || 0 // Update the amounts
            };
        });

        // Wait for all promises to resolve
        await Promise.all(promises);

        // Now that all promises are resolved, update the state
        setTotalPlayers(updatedPlayers); // Update the state with the new player data
    };


    useEffect(() => {


        updateCardsForCard32Casino(data, totalPlayers, setTotalPlayers)

        remark.current = data?.remark || 'Welcome';

        setBetType('ODDS')

        const updateOdds = () => {
            setTotalPlayers((prevState) => {
                // Create a new state object based on the previous state
                const updatedState = {...prevState};


                data.sub.forEach(item => {
                    const playerName = item.nat; // Adjust as needed to match your data structure
                    if (updatedState[playerName]) {
                        updatedState[playerName] = {
                            ...updatedState[playerName],
                            odds: {back: item?.b || 0, lay: item?.l || 0}, // Assuming `item.odds` holds the new odds value

                            status: item.gstatus === 'OPEN' ? '' : 'suspended-box', // Update based on suspend status

                        };
                    }
                });

                return updatedState; // Return the updated state
            });
        };


        if (data?.sub) {
            updateOdds()

        }


    }, [data]);

    const exposure = localStorage.getItem('exposure');
    const sportLength = Object.keys(data).length;


    useEffect(() => {


        if (data?.sub && sportList?.id) {


            calculateTeamname();


        }
    }, [exposure, sportLength, roundId]);


    const openPopup = (isBakOrLay, teamnam, oddvalue) => {


        if (parseFloat(oddvalue) > 0) {
            roundIdSaved.current = roundId
            setbackOrLay(isBakOrLay)
            setPopupDisplay(true);
            teamname.current = teamnam
            setOdds(oddvalue)
        } else {
            Notify("Odds Value Change Bet Not Confirm", null, null, 'danger')

        }


    }
    const casinoBetDataNew = (event, new_odds) => {
        stakeValue.current = event.target.value
        if (backOrLay === 'back') {


            loss.current = stakeValue.current;


            profit.current = profitData.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)

        } else {

            profit.current = profitData.current = stakeValue.current;


            loss.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)
        }


    }


    const placeBet = () => {

        setHideLoading(false)
        if (totalPlayers[teamname.current].status === 'suspended-box') {
            Notify(`Bet Not Confirmed. Reason: Game Suspended`, null, null, 'danger');
            setPopupDisplay(false);
            setHideLoading(true);

            return;
        }

        if (roundIdSaved.current !== roundId) {
            Notify("Bet Not Confirm Reason Game Change", null, null, 'danger')
            setPopupDisplay(false)
            return;
        }


        setSubmitButtonDisable(true)

        const postdata = {

            "sportId": sportList.id,
            "matchId": roundId,
            "isback": backOrLay === 'back' ? 1 : 0,
            "placeName": teamname.current,
            "placeName2": null,
            "odds": odds,
            "oddsk": 0,
            "profit": parseFloat(profit.current).toFixed(2),
            "loss": parseFloat(loss.current).toFixed(2),
            "betType": "ODDS",
            "bet_side": backOrLay.toUpperCase(),
            "betAmount": parseFloat(stakeValue.current),
            "type": "CARD32",
            "matchType": "card32",

        }

        axiosFetch('casino/store', 'post', null, postdata)
            .then((res) => {
                if (res.data.status === true) {

                    resetBetFields(profitData, stakeValue)
                    Notify("Bet Placed Successfully", null, null, 'success')
                    calculateTeamname();
                } else {

                    resetBetFields(profitData, stakeValue)
                    Notify(res.data.msg, null, null, 'danger')
                }
                setHideLoading(true)
                setSubmitButtonDisable(false)
                setPopupDisplay(false)
            })


    }

    return (
        <CasinoLayout ruleImage={ruleImage}
                      ruleDescription={ruleDescription} data={data} roundId={roundId} setRoundId={setRoundId} sportList={sportList}
                      setSportList={setSportList} setData={setData} setLastResult={setLastResult}>
            <div className="video-container" style={{minHeight: '0px'}}>
                <div className="video-overlay" style={{top: '230px'}}>
                    {Object.entries(totalPlayers).map(([key,value]) => (
                    <div key={key}>
                        {value.card_number !== null && (
                        <p className="mb-0">
                            <b>
                                <span className="text-white">{key} : {value.card_number} </span>
                                <span className="text-warning"></span>
                            </b>
                        </p>
                        )}
                        <div>

                            {value?.cards.map((v, l) => (
                                <img src={v} key={l}/>
                            ))}

                        </div>
                    </div>
                    ))}

                </div>
            </div>

            <div className="casino-container casino-32A">
                <div className="table-responsive mb-1">
                    <table className="table table-bordered mb-0">
                        <thead>
                        <tr>
                            <th className="box-6 min-max"></th>
                            <th className="box-2 text-center back">BACK</th>
                            <th className="box-2 text-center lay">LAY</th>
                        </tr>
                        </thead>
                        <tbody>
                        {Object.entries(totalPlayers).map(([key, value]) => {


                            return (
                                <tr key={key}>
                                    <td className="box-6">
                                    <b>{key}</b>
                                        <p className="m-b-0">{getExByColor(value.amounts)}</p>
                                    </td>
                                    <td className={`box-2 back text-center ${value.status}`}
                                        onClick={() => openPopup('back', key, value.odds.back)}>
                                        <span className="odds d-block"><b>{value.odds.back}</b></span>
                                        <span></span>
                                    </td>
                                    <td className={`box-2 lay text-center ${value.status}`}
                                        onClick={() => openPopup('lay', key, value.odds.lay)}>
                                        <span className="odds d-block"><b>{value.odds.lay}</b></span>
                                        <span></span>
                                    </td>
                                </tr>
                            )
                        })}

                        </tbody>
                    </table>
                </div>

                <marquee scrollamount="3" className="casino-remark m-b-10">{remark.current}</marquee>
                <div className="casino-last-result-title">
                    <span>Last Result</span>
                </div>
                <div className="last-result-container text-right mt-1">
                    <CasinoLastResult sportList={sportList} lastResults={lastResult} data={data}/>
                </div>
            </div>
            <BetPlacPopupSection placeBet={placeBet} profitValue={profitData.current} profit={profit} loss={loss}
                                 teamnames={teamNames} handleStakeChange={casinoBetDataNew} hideLoading={hideLoading}
                                 teamname={teamname} backOrLay={backOrLay} stakeValue={stakeValue} odds={odds}
                                 profitData={profitData}
                                 setOdds={setOdds} submitButtonDisable={submitButtonDisable}/>

        </CasinoLayout>
    );

};


export default Card32;
