import Header from "./Section/Header";
import {useEffect} from "react";

export const Rules = () => {

    useEffect(() => {

            const accordionHeaders = document.querySelectorAll('.card-header');

            accordionHeaders.forEach(header => {
                header.addEventListener('click', function () {
                    const content = this.nextElementSibling; // Get the associated content

                    // First, close all other open accordion items
                    accordionHeaders.forEach(otherHeader => {
                        const otherContent = otherHeader.nextElementSibling; // Get the associated content of the other headers
                        if (otherContent !== content && otherContent.classList.contains('show')) {
                            // Close the other content
                            otherContent.classList.remove('show');
                            otherContent.classList.add('collapse');
                            otherHeader.querySelector('button').setAttribute('aria-expanded', 'false'); // Update aria attribute for the closed header
                        }
                    });

                    // Now toggle the clicked accordion item
                    if (content.classList.contains('collapse')) {

                        content.classList.add('show'); // Show the content
                        this.querySelector('button').setAttribute('aria-expanded', 'true'); // Update aria attribute for the opened header
                    } else {
                        content.classList.remove('show');
                        content.classList.add('collapse'); // Hide the content
                        this.querySelector('button').setAttribute('aria-expanded', 'false'); // Update aria attribute for the closed header
                    }
                });
            });


    }, []);
    return (
        <>
            <Header/>
            <div className="report-container">

                <div className="card rules-container">
                    <div className="card-header">
                        <h4 className="mb-0">Rules</h4>
                    </div>
                    <div className="card-body container-fluid container-fluid-5">
                        <div className="row row5 mt-2">
                            <div className="col-12">
                                <div role="tablist">
                                    <div id="accordion">
                                        <div className="card">
                                            <div id="heading0" data-toggle="collapse" data-target="#collapse0"
                                                 aria-expanded="true" aria-controls="collapse0" className="card-header">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link">
                                                        Football Fancy
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapse0" aria-labelledby="heading0" data-parent="#accordion"
                                                 className="collapse show">
                                                <div className="card-body">
                                                    <table className="table table-bordered">
                                                        <tbody>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Total Goals, Team Total Goals goals.scored in 90 minutes or in extra-time will count.Goals scored in penalty shootouts do not count.</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Corners - Only corners taken in 90 minutes count.</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Penalties Missed/Converted - Penalties taken in 90 minutes, extra-time and penalty shootouts all count. If a penalty has to be re-taken the previous disallowed penalty(ies) do not count.</span>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div id="heading1" data-toggle="collapse" data-target="#collapse1"
                                                 aria-controls="collapse1" className="card-header">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link">
                                                        Motor Sport
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapse1" aria-labelledby="heading1" data-parent="#accordion"
                                                 className="collapse">
                                                <div className="card-body">
                                                    <table className="table table-bordered">
                                                        <tbody>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Total Goals, Team Total Goals goals.scored in 90 minutes or in extra-time will count.Goals scored in penalty shootouts do not count.</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Corners - Only corners taken in 90 minutes count.</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Penalties Missed/Converted - Penalties taken in 90 minutes, extra-time and penalty shootouts all count. If a penalty has to be re-taken the previous disallowed penalty(ies) do not count.</span>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div id="heading2" data-toggle="collapse" data-target="#collapse2"
                                                 aria-controls="collapse2" className="card-header">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link">
                                                        Mixed Martial Arts
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapse2" aria-labelledby="heading2" data-parent="#accordion"
                                                 className="collapse">
                                                <div className="card-body">
                                                    <table className="table table-bordered">
                                                        <tbody>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Total Goals, Team Total Goals goals.scored in 90 minutes or in extra-time will count.Goals scored in penalty shootouts do not count.</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Corners - Only corners taken in 90 minutes count.</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Penalties Missed/Converted - Penalties taken in 90 minutes, extra-time and penalty shootouts all count. If a penalty has to be re-taken the previous disallowed penalty(ies) do not count.</span>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div id="heading3" data-toggle="collapse" data-target="#collapse3"
                                                 aria-controls="collapse3" className="card-header">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link">
                                                        Big Bash League
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapse3" aria-labelledby="heading3" data-parent="#accordion"
                                                 className="collapse">
                                                <div className="card-body">
                                                    <table className="table table-bordered">
                                                        <tbody>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Total Goals, Team Total Goals goals.scored in 90 minutes or in extra-time will count.Goals scored in penalty shootouts do not count.</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Corners - Only corners taken in 90 minutes count.</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Penalties Missed/Converted - Penalties taken in 90 minutes, extra-time and penalty shootouts all count. If a penalty has to be re-taken the previous disallowed penalty(ies) do not count.</span>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div id="heading4" data-toggle="collapse" data-target="#collapse4"
                                                 aria-controls="collapse4" className="card-header">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link">
                                                        Lunch Favourite
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapse4" aria-labelledby="heading4" data-parent="#accordion"
                                                 className="collapse">
                                                <div className="card-body">
                                                    <table className="table table-bordered">
                                                        <tbody>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Total Goals, Team Total Goals goals.scored in 90 minutes or in extra-time will count.Goals scored in penalty shootouts do not count.</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Corners - Only corners taken in 90 minutes count.</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Penalties Missed/Converted - Penalties taken in 90 minutes, extra-time and penalty shootouts all count. If a penalty has to be re-taken the previous disallowed penalty(ies) do not count.</span>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div id="heading5" data-toggle="collapse" data-target="#collapse5"
                                                 aria-controls="collapse5" className="card-header">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link">
                                                        Bookmaker
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapse5" aria-labelledby="heading5" data-parent="#accordion"
                                                 className="collapse">
                                                <div className="card-body">
                                                    <table className="table table-bordered">
                                                        <tbody>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Total Goals, Team Total Goals goals.scored in 90 minutes or in extra-time will count.Goals scored in penalty shootouts do not count.</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Corners - Only corners taken in 90 minutes count.</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Penalties Missed/Converted - Penalties taken in 90 minutes, extra-time and penalty shootouts all count. If a penalty has to be re-taken the previous disallowed penalty(ies) do not count.</span>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div id="heading6" data-toggle="collapse" data-target="#collapse6"
                                                 aria-controls="collapse6" className="card-header">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link">
                                                        Teenpatti
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapse6" aria-labelledby="heading6" data-parent="#accordion"
                                                 className="collapse">
                                                <div className="card-body">
                                                    <table className="table table-bordered">
                                                        <tbody>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Total Goals, Team Total Goals goals.scored in 90 minutes or in extra-time will count.Goals scored in penalty shootouts do not count.</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Corners - Only corners taken in 90 minutes count.</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Penalties Missed/Converted - Penalties taken in 90 minutes, extra-time and penalty shootouts all count. If a penalty has to be re-taken the previous disallowed penalty(ies) do not count.</span>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div id="heading7" data-toggle="collapse" data-target="#collapse7"
                                                 aria-controls="collapse7" className="card-header">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link">
                                                        CricketCasino
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapse7" aria-labelledby="heading7" data-parent="#accordion"
                                                 className="collapse">
                                                <div className="card-body">
                                                    <table className="table table-bordered">
                                                        <tbody>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Total Goals, Team Total Goals goals.scored in 90 minutes or in extra-time will count.Goals scored in penalty shootouts do not count.</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Corners - Only corners taken in 90 minutes count.</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Penalties Missed/Converted - Penalties taken in 90 minutes, extra-time and penalty shootouts all count. If a penalty has to be re-taken the previous disallowed penalty(ies) do not count.</span>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div id="heading8" data-toggle="collapse" data-target="#collapse8"
                                                 aria-controls="collapse8" className="card-header">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link">
                                                        Politics
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapse8" aria-labelledby="heading8" data-parent="#accordion"
                                                 className="collapse">
                                                <div className="card-body">
                                                    <table className="table table-bordered">
                                                        <tbody>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Total Goals, Team Total Goals goals.scored in 90 minutes or in extra-time will count.Goals scored in penalty shootouts do not count.</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Corners - Only corners taken in 90 minutes count.</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Penalties Missed/Converted - Penalties taken in 90 minutes, extra-time and penalty shootouts all count. If a penalty has to be re-taken the previous disallowed penalty(ies) do not count.</span>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div id="heading9" data-toggle="collapse" data-target="#collapse9"
                                                 aria-controls="collapse9" className="card-header">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link">
                                                        Fancy Market 1
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapse9" aria-labelledby="heading9" data-parent="#accordion"
                                                 className="collapse">
                                                <div className="card-body">
                                                    <table className="table table-bordered">
                                                        <tbody>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Total Goals, Team Total Goals goals.scored in 90 minutes or in extra-time will count.Goals scored in penalty shootouts do not count.</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Corners - Only corners taken in 90 minutes count.</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Penalties Missed/Converted - Penalties taken in 90 minutes, extra-time and penalty shootouts all count. If a penalty has to be re-taken the previous disallowed penalty(ies) do not count.</span>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div id="heading10" data-toggle="collapse" data-target="#collapse10"
                                                 aria-controls="collapse10" className="card-header">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link">
                                                        IPL
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapse10" aria-labelledby="heading10" data-parent="#accordion"
                                                 className="collapse">
                                                <div className="card-body">
                                                    <table className="table table-bordered">
                                                        <tbody>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Total Goals, Team Total Goals goals.scored in 90 minutes or in extra-time will count.Goals scored in penalty shootouts do not count.</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Corners - Only corners taken in 90 minutes count.</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Penalties Missed/Converted - Penalties taken in 90 minutes, extra-time and penalty shootouts all count. If a penalty has to be re-taken the previous disallowed penalty(ies) do not count.</span>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div id="heading11" data-toggle="collapse" data-target="#collapse11"
                                                 aria-controls="collapse11" className="card-header">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link">
                                                        Kabaddi
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapse11" aria-labelledby="heading11" data-parent="#accordion"
                                                 className="collapse">
                                                <div className="card-body">
                                                    <table className="table table-bordered">
                                                        <tbody>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Total Goals, Team Total Goals goals.scored in 90 minutes or in extra-time will count.Goals scored in penalty shootouts do not count.</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Corners - Only corners taken in 90 minutes count.</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Penalties Missed/Converted - Penalties taken in 90 minutes, extra-time and penalty shootouts all count. If a penalty has to be re-taken the previous disallowed penalty(ies) do not count.</span>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div id="heading12" data-toggle="collapse" data-target="#collapse12"
                                                 aria-controls="collapse12" className="card-header">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link">
                                                        World Cup
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapse12" aria-labelledby="heading12" data-parent="#accordion"
                                                 className="collapse">
                                                <div className="card-body">
                                                    <table className="table table-bordered">
                                                        <tbody>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Total Goals, Team Total Goals goals.scored in 90 minutes or in extra-time will count.Goals scored in penalty shootouts do not count.</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Corners - Only corners taken in 90 minutes count.</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Penalties Missed/Converted - Penalties taken in 90 minutes, extra-time and penalty shootouts all count. If a penalty has to be re-taken the previous disallowed penalty(ies) do not count.</span>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div id="heading13" data-toggle="collapse" data-target="#collapse13"
                                                 aria-controls="collapse13" className="card-header">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link">
                                                        Binary
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapse13" aria-labelledby="heading13" data-parent="#accordion"
                                                 className="collapse">
                                                <div className="card-body">
                                                    <table className="table table-bordered">
                                                        <tbody>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Total Goals, Team Total Goals goals.scored in 90 minutes or in extra-time will count.Goals scored in penalty shootouts do not count.</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Corners - Only corners taken in 90 minutes count.</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Penalties Missed/Converted - Penalties taken in 90 minutes, extra-time and penalty shootouts all count. If a penalty has to be re-taken the previous disallowed penalty(ies) do not count.</span>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div id="heading14" data-toggle="collapse" data-target="#collapse14"
                                                 aria-controls="collapse14" className="card-header">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link">
                                                        Fancy
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapse14" aria-labelledby="heading14" data-parent="#accordion"
                                                 className="collapse">
                                                <div className="card-body">
                                                    <table className="table table-bordered">
                                                        <tbody>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Total Goals, Team Total Goals goals.scored in 90 minutes or in extra-time will count.Goals scored in penalty shootouts do not count.</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Corners - Only corners taken in 90 minutes count.</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Penalties Missed/Converted - Penalties taken in 90 minutes, extra-time and penalty shootouts all count. If a penalty has to be re-taken the previous disallowed penalty(ies) do not count.</span>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div id="heading15" data-toggle="collapse" data-target="#collapse15"
                                                 aria-controls="collapse15" className="card-header">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link">
                                                        Match
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapse15" aria-labelledby="heading15" data-parent="#accordion"
                                                 className="collapse">
                                                <div className="card-body">
                                                    <table className="table table-bordered">
                                                        <tbody>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Total Goals, Team Total Goals goals.scored in 90 minutes or in extra-time will count.Goals scored in penalty shootouts do not count.</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Corners - Only corners taken in 90 minutes count.</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Penalties Missed/Converted - Penalties taken in 90 minutes, extra-time and penalty shootouts all count. If a penalty has to be re-taken the previous disallowed penalty(ies) do not count.</span>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div id="heading16" data-toggle="collapse" data-target="#collapse16"
                                                 aria-controls="collapse16" className="card-header">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link">
                                                        Khado
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapse16" aria-labelledby="heading16" data-parent="#accordion"
                                                 className="collapse">
                                                <div className="card-body">
                                                    <table className="table table-bordered">
                                                        <tbody>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Total Goals, Team Total Goals goals.scored in 90 minutes or in extra-time will count.Goals scored in penalty shootouts do not count.</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Corners - Only corners taken in 90 minutes count.</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Penalties Missed/Converted - Penalties taken in 90 minutes, extra-time and penalty shootouts all count. If a penalty has to be re-taken the previous disallowed penalty(ies) do not count.</span>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div id="heading17" data-toggle="collapse" data-target="#collapse17"
                                                 aria-controls="collapse17" className="card-header">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link">
                                                        Election
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapse17" aria-labelledby="heading17" data-parent="#accordion"
                                                 className="collapse">
                                                <div className="card-body">
                                                    <table className="table table-bordered">
                                                        <tbody>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Total Goals, Team Total Goals goals.scored in 90 minutes or in extra-time will count.Goals scored in penalty shootouts do not count.</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Corners - Only corners taken in 90 minutes count.</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-danger">Tournament Penalties Missed/Converted - Penalties taken in 90 minutes, extra-time and penalty shootouts all count. If a penalty has to be re-taken the previous disallowed penalty(ies) do not count.</span>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}