import CasinoLayout from "../Section/Casino/CasinoLayout";
import {useContext, useEffect, useRef, useState} from "react";

import {CasinoLastResult} from "../Section/Casino/CasinoLastResult";
import axiosFetch, {
    cardMap, getExByColor,
    resetBetFields
} from "../../Common_Functions/Constants";
import {SportsContext} from "../../Components/Context/SportsContext";
import {BetPlacPopupSection} from "../../Components/BetPlacPopupSection";
import Notify from "../../js/Notify";
import {CasinoContext} from "../../Components/Context/CasinoContext";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AndarBaharVideoCards from "../../Components/AndarBaharVideoCards";

const Ab20 = () => {
    const [roundId, setRoundId] = useState('')
    const hideRules = false;

    const {getAndarBaharCalculation} = useContext(CasinoContext)


    const roundIdSaved = useRef(null);

    const [submitButtonDisable, setSubmitButtonDisable] = useState(false)


    const stakeValue = useRef(0);
    const [odds, setOdds] = useState(0)

    const [backOrLay, setbackOrLay] = useState('back')
    const [sportList, setSportList] = useState({})

    const defaultCards = Array.from({length: 13}, (_, array) => {
        array = 0;
        return "/img/card/" + array + ".jpg";

    })

    const andarBaharCards = defaultCards

    const [enableOnclick, setEnableOnclick] = useState(false)

    const [totalPlayers, setTotalPlayers] = useState([
        {
            Andar: {
                cards: andarBaharCards, status: '', amounts: Object.fromEntries(Array.from({length: 13}, (_, index) => {
                    return ["Andar " + cardMap(index), ''];
                }))

            }
        },
        {
            Bahar: {
                cards: andarBaharCards, status: '', amounts: Object.fromEntries(Array.from({length: 13}, (_, index) => {
                    return ["Bahar " + cardMap(index), ''];
                }))
            }
        }

    ])


    const {
        setBetType,
        betType,
        setPopupDisplay,

    } = useContext(SportsContext)
    const [hideLoading, setHideLoading] = useState(true)


    const [data, setData] = useState([]);

    const remark = useRef('Welcome');
    const [lastResult, setLastResult] = useState({});
    const teamname = useRef('');
    const loss = useRef(0);
    const profit = useRef(0);
    const profitData = useRef(0);


    useEffect(() => {


        if (data?.sub) {


        }

        if (data.card) {

            remark.current = data.remark || 'Welcome';
            processData(data)
        }



    }, [data]);

    const exposure = localStorage.getItem('exposure');
    const sportLength = Object.keys(data).length;
    const processData = (d_data) => {

        let card_change = false;
        const defaultCardsChange = Array.from({length: 13}, (_, array) => {
            array += 1;
            return "/img/card/" + array + ".jpg";

        })


        const cardSet1 = d_data?.card
        const cardsArray = cardSet1.split(','); // Explode the string into an array

        const allAreOnes = cardsArray.every(value => value === '1' || value === 1); // Check if all values are '1'


        if (allAreOnes) {

            card_change = false;
            if (enableOnclick === false)
                setEnableOnclick(true)

            if (parseInt(data.lt) <= 3) {
                setTotalPlayers((prevState) => {

                    const changeState = [...prevState];

                    changeState.forEach((value, index) => {
                        const akeys = Object.keys(value)[0]
                        changeState[index][akeys] = {
                            ...changeState[index][akeys],
                            status: 'suspended-box'

                        }

                    })


                    return changeState;
                })
            }


        } else {
            card_change = true;

            if (enableOnclick === true) {

                setEnableOnclick(false)

                setTotalPlayers((prevState) => {

                    const changeState = [...prevState];

                    changeState.forEach((value, index) => {
                        const akeys = Object.keys(value)[0]
                        changeState[index][akeys] = {
                            ...changeState[index][akeys],
                            status: ''

                        }

                    })


                    return changeState;
                })
            }
        }

        const img_ar = card_change === true ? defaultCards.slice() : defaultCardsChange.slice(), post_ar = {},
            post_br = {};
        const img_br = card_change === true ? defaultCards.slice() : defaultCardsChange.slice()


        const setCardImages = (img_obj, post_obj, keyPrefix, cardSet, offset = 0) => {


            const cards = cardSet.split(',').filter(Boolean);

            cards.forEach((value, key) => {

                if (offset === 20) {
                    value -= 20;
                }
                if (value === -20) {
                    value = 0;
                }


                let img_src = `/img/card/${value}.jpg`;
                img_obj[key] = img_src;
                post_obj[key] = img_src;
            });
        };

        if (d_data?.aall && d_data?.card) {
            [...Array(13)].forEach((_, i) => {
                img_ar[i] = img_br[i] = process.env.REACT_APP_CARD_PATH + '1.png';
            });

            if (d_data?.ares) setCardImages(img_ar, post_ar, 'Andar', d_data.ares);
            if (d_data?.bres) setCardImages(img_br, post_br, 'Bahar', d_data.bres, 20);
        }

        setTotalPlayers((prevState) => {
            const updatedPlayers = [...prevState];
            updatedPlayers[0]['Andar'].cards = img_ar;
            updatedPlayers[1]['Bahar'].cards = img_br;
            return updatedPlayers;
        });

        if ((Object.keys(post_ar).length || Object.keys(post_br).length) && roundId) {
            const data = {
                round_id: roundId,
                andar: JSON.stringify(post_ar),
                bahar: JSON.stringify(post_br),
            };

            fetch(`https://${window.location.hostname}:3000/saveab20data`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(data),
            })
                .catch((error) => console.error('Error:', error));
        }
    };


// Example usage of asset function to simulate PHP asset path


    useEffect(() => {

        if (data?.sub && sportList?.id) {

            getAndarBaharCalculation(sportList.id, roundId, 'ab20', setTotalPlayers);

        }
    }, [exposure, sportLength, roundId]);


    const openPopup = (isBakOrLay, teamnam, oddvalue, bet) => {
        setBetType(bet)


        if (parseFloat(oddvalue) > 0) {
            roundIdSaved.current = roundId
            setbackOrLay(isBakOrLay)
            setPopupDisplay(true);
            teamname.current = teamnam
            setOdds(oddvalue)
        } else {
            Notify("Odds Value Change Bet Not Confirm", null, null, 'danger')

        }


    }
    const casinoBetDataNew = (event, new_odds) => {
        stakeValue.current = event.target.value
        if (backOrLay === 'back') {


            loss.current = stakeValue.current;


            profit.current = profitData.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)

        } else {

            profit.current = profitData.current = stakeValue.current;


            loss.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)
        }


    }

    const placeBet = () => {

        setHideLoading(false)
        if (totalPlayers[0]['Andar'].status === 'suspended-box') {
            Notify(`Bet Not Confirmed. Reason: Game Suspended`, null, null, 'danger');
            setPopupDisplay(false);
            setHideLoading(true);

            return;
        }

        if (roundIdSaved.current !== roundId) {
            Notify("Bet Not Confirm Reason Game Change", null, null, 'danger')
            setPopupDisplay(false)
            return;
        }


        setSubmitButtonDisable(true)

        const postdata = {

            "sportId": sportList.id,
            "matchId": roundId,
            "isback": backOrLay === 'back' ? 1 : 0,
            "placeName": teamname.current,
            "placeName2": null,
            "odds": odds,
            "oddsk": 0,
            "profit": parseFloat(profit.current).toFixed(2),
            "loss": parseFloat(loss.current).toFixed(2),
            "betType": betType,
            "bet_side": backOrLay.toUpperCase(),
            "betAmount": parseFloat(stakeValue.current),
            "type": "AB20",
            "matchType": "ab20",

        }

        axiosFetch('casino/store', 'post', null, postdata)
            .then((res) => {
                if (res.data.status === true) {

                    resetBetFields(profitData, stakeValue)
                    getAndarBaharCalculation(sportList.id, roundId, 'ab20', setTotalPlayers);
                    Notify("Bet Placed Successfully", null, null, 'success')
                } else {

                    resetBetFields(profitData, stakeValue)
                    Notify(res.data.msg, null, null, 'danger')
                }
                setHideLoading(true)
                setSubmitButtonDisable(false)
                setPopupDisplay(false)
            })


    }

    return (
        <CasinoLayout hideRules={hideRules} data={data} roundId={roundId} setRoundId={setRoundId} sportList={sportList}
                      setSportList={setSportList} setData={setData} setLastResult={setLastResult}>
            <div className="video-container" style={{minHeight: '0px', marginLeft: "20px"}}>
                <div className="video-overlay" style={{top: '230px'}}>
                    <div id="game-cards" style={{width: '105px'}}>
                        <div className="w-50">

                            {data?.card && (
                                <AndarBaharVideoCards cards={data?.card} player="Andar"/>
                            )}
                            {data?.card && (
                                <AndarBaharVideoCards cards={data?.card} player="Bahar"/>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="casino-container andar-bahar">
                {data?.card && totalPlayers.map((value, key) => {
                    const title = Object.keys(value)[0];
                    const status = value[title].status
                    const amounts = value[title].amounts
                    return (
                        <div className={`${title.toLowerCase()}-bx`} key={key}>
                            <div className="ab-title text-center">{title.toUpperCase()}</div>
                            <div className="text-center ab-content w-100 position-relative">
                                <div id="andar_div"
                                     className="owl-carousel owl-theme ab-slider owl-loaded owl-drag w-100 position-relative">
                                    <div className="owl-stage-outer">
                                        <div className="owl-stage"
                                             style={{
                                                 transform: 'translate3d(0px, 0px, 0px)',
                                                 transition: 'all 0s ease 0s'
                                             }}>


                                            {Object.entries(value[title]['cards']).map(([key, value]) => (
                                                <div key={key} className="owl-item active"
                                                     style={{width: '52.166px', marginRight: '10px'}}>
                                                    <div
                                                        onClick={enableOnclick === true ? () => openPopup('back', title + " " + cardMap(parseInt(key)), 2, title.toUpperCase()) : null}>
                                                        <div className={status}>
                                                            <img src={value} alt={title} className="andar-bahar-image"/>
                                                        </div>
                                                        {getExByColor(amounts[title + " " + cardMap(parseInt(key))])}
                                                    </div>
                                                </div>
                                            ))}

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    )
                })}

                <marquee scrollamount="3" className="casino-remark m-b-10">{remark.current}</marquee>
                <div className="casino-last-result-title">
                    <span>Last Result</span>
                </div>
                <div className="last-result-container text-right mt-1">
                    <CasinoLastResult sportList={sportList} lastResults={lastResult} data={data}/>
                </div>
            </div>

            <BetPlacPopupSection placeBet={placeBet} profitValue={profitData.current} profit={profit} loss={loss}
                                 handleStakeChange={casinoBetDataNew} hideLoading={hideLoading}
                                 teamname={teamname} backOrLay={backOrLay} stakeValue={stakeValue} odds={odds}
                                 profitData={profitData}
                                 setOdds={setOdds} submitButtonDisable={submitButtonDisable}/>

        </CasinoLayout>
    );

};


export default Ab20;
