import React, {useContext, useEffect, useRef} from 'react';
import $ from 'jquery';
import Header from "./Section/Header";
import {AuthContext} from "../Components/Context/AuthContext";

const BetHistories = () => {
    const toDate = useRef(new Date().toISOString().split('T')[0]);
    const fromDate = useRef(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]);
    const betSide = useRef('');
    const {ACCESS_TOKEN} = useContext(AuthContext);


    useEffect(() => {
        $('.ABCD').DataTable({
            processing: false,
            serverSide: true,
            ajax: {
                url: process.env.REACT_APP_API_URL + "reports/bet-history-data",
                type: 'post',
                data: {
                    'from_date': fromDate.current.value,
                    'to_date': toDate.current.value,
                    'bet_side': betSide.current.value,

                },
                async: false,
                headers: {
                    'Authorization': `Bearer ${ACCESS_TOKEN}`
                },
            },
            columns: [
                {data: 'game_name', name: 'game_name'},
                {data: 'sport_name', name: 'sport_name'},
                {data: 'team_name', name: 'team_name'},
                {data: 'bet_side', name: 'bet_side'},
                {data: 'bet_odds', name: 'bet_odds'},
                {data: 'bet_amount', name: 'bet_amount'},
                {data: 'created_at', name: 'created_at'},
                {data: 'ip_address', name: 'ip_address'},
            ],
            rowCallback: function (row, data) {
                if (data.bet_side == "LAY" || data.bet_side == "No") {
                    $(row).addClass('lay');
                } else {
                    $(row).addClass('back');
                }
            },
        });
    }, []);


    const handleSubmit = () => {
        // Call your initiateTable or data-fetching function here
        console.log('Form submitted with:', {fromDate, toDate, betSide});
        // Replace with your logic to fetch data
        // setTableData(responseData); // Assuming responseData is the data fetched from an API
    };


    return (
        <><Header/>
            <div className="col-md-10 report-main-content m-t-5" style={{paddingLeft: 0, paddingRight: 0}}>
                <div className="card">
                    <div className="card-header">
                        <h4 className="mb-0">
                            Bet History
                            <span className="export-buttons pull-right"></span>
                        </h4>
                    </div>
                    <div className="card-body container-fluid container-fluid-5 report-container">
                        <div className="row row5" style={{padding: '5px', display: 'block'}}>
                            <div className="row form-horizontal" style={{marginBottom: '25px'}}>
                                <div className="col-6">
                                    <label>From Date</label>
                                    <input
                                        type="date"
                                        name="from_date"
                                        id="from_date"
                                        ref={fromDate}
                                        className="form-control"
                                        placeholder="From Date"

                                        autoComplete="off"
                                    />
                                </div>

                                <div className="col-6">
                                    <label>To Date</label>
                                    <input
                                        type="date"
                                        name="to_date"
                                        id="to_date"
                                        ref={toDate}
                                        className="form-control"
                                        placeholder="To Date"

                                        autoComplete="off"
                                    />
                                </div>

                                <div className="col-12">
                                    <label>Bet Side</label>
                                    <select className="form-control bet_side"
                                            id="bet_side"
                                            ref={betSide}>
                                        <option value="">Select Type</option>
                                        <option value="Back">Back</option>
                                        <option value="Lay">Lay</option>
                                    </select>
                                </div>

                                <div className="col-12" style={{marginTop: '25px'}}>
                                    <button
                                        className="btn btn-primary btn-block btn-sm btn-submit"
                                        onClick={handleSubmit}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>

                            <div className="clearfix"></div>
                            <div className="table-responsive">
                                <table className={`ABCD table table-bordered data-table table-responsive`}>
                                    <thead>
                                    <tr>
                                        <th>Event Type</th>
                                        <th>Event Name</th>
                                        <th>Runner Name</th>
                                        <th>Bet Type</th>
                                        <th>User Rate</th>
                                        <th>Amount</th>
                                        <th>Place Date</th>
                                        <th>IP Address</th>
                                    </tr>
                                    </thead>

                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BetHistories;
