import CasinoLayout from "../Section/Casino/CasinoLayout";
import {useContext, useEffect, useRef, useState} from "react";

import {CasinoLastResult} from "../Section/Casino/CasinoLastResult";

import axiosFetch, {
    cardPointsCount,
    getExByColor,

    getExByTeamNameForCasino, race17calculation, resetBetFields
} from "../../Common_Functions/Constants";
import {useParams} from "react-router-dom";
import {SportsContext} from "../../Components/Context/SportsContext";


import Notify from "../../js/Notify";

const Race17 = () => {
    const [roundId, setRoundId] = useState('')
    const [cardCount, setCardCount] = useState(0)

    const defaultValues = {odds: {back: 0, lay: 0}, status: "suspended-box", amounts: ""}

    const [totalPlayers, setTotalPlayers] = useState({
        "Race to 17": {...defaultValues, subtype: 'race17', subname : "Race to 17"},
        "Big Card (7,8,9) - 1": {...defaultValues, subname: "Big Card (7,8,9) - 1", subtype: 'bigcard'},
        "Zero Card - 1": {...defaultValues, subname: "Zero Card - 1", subtype: 'zerocard'},
        "Any Zero": {...defaultValues, subtype: 'anyzero'},
    })
    const roundIdSaved = useRef(null);

    const desc = `<div class="rules-section">
                        <h6 class="rules-highlight">Main:</h6>
                        <ul class="pl-2 pr-2 list-style">
                            <li>It is played with regular 52 card deck.</li>
                            <li>Value of</li>
                        </ul>
                        <ul class="pl-4 pr-4 list-style">
                            <li>Ace = 1</li>
                            <li>2 = 2</li>
                            <li>3 = 3</li>
                            <li>4 = 4</li>
                            <li>5 = 5</li>
                            <li>6 = 6</li>
                            <li>7 = 7</li>
                            <li>8 = 8</li>
                            <li>9 = 9</li>
                            <li>10 = 0</li>
                            <li>Jack = 0</li>
                            <li>Queen = 0</li>
                            <li>King = 0</li>
                        </ul>
                        <ul class="pl-2 pr-2 list-style">
                            <li>Five(5) card will be pulled from the deck.</li>
                            <li>It is a race to reach 17 or plus.</li>
                            <li>If you bet on 17(Back) , &amp; then,</li>
                            <li>Total of given (5) cards, comes under seventeen(17) you loose.</li>
                            <li>Total of (5) cards comes over sixteen(16) you win.</li>
                        </ul>
                    </div>
 <div class="rules-section">
                        <h6 class="rules-highlight">Fancy :</h6>
                        <h6 class="rules-sub-highlight">Big card(7,8,9)</h6>
                        <ul class="pl-2 pr-2 list-style">
                            <li>here 7,8,9 are named big card.</li>
                            <li>Back/ lay of big card rate is available to bet on every card.</li>
                        </ul>
                        <h6 class="rules-sub-highlight">Zero card(10, jack , queen, king).</h6>
                        <ul class="pl-2 pr-2 list-style">
                            <li>here 10, jack, queen, king is named zero card.</li>
                            <li>Back &amp; lay rate to bet on zero card is available on every card.</li>
                        </ul>
                        <h6 class="rules-sub-highlight">Any zero card.</h6>
                        <ul class="pl-2 pr-2 list-style">
                            <li>Here 10, jack, queen, king, is named zero card, it is bet for having at least one zero card in game( not necessary game will go up to 5 cards). You can bet on this before start of game only.</li>
                        </ul>
                    </div>`


    const [submitButtonDisable, setSubmitButtonDisable] = useState(false)

    const [cards, setCards] = useState([]);

    const stakeValue = useRef(0);
    const [odds, setOdds] = useState(0)

    const [backOrLay, setbackOrLay] = useState('back')
    const [sportList, setSportList] = useState({})
    const {match_id} = useParams();
    const {

        setPopupDisplayForDesktop,

    } = useContext(SportsContext)
    const [hideLoading, setHideLoading] = useState(true)


    const [data, setData] = useState([]);

    const remark = useRef('Welcome');
    const [lastResult, setLastResult] = useState({});
    const teamname = useRef('');
    const loss = useRef(0);
    const profit = useRef(0);
    const profitData = useRef(0);


    const updateAmounts = async () => {

        const promises = Object.entries(totalPlayers).map(([index,value])=> {

            return race17calculation(roundId, value?.subname || index)
        })

        const all_promise = await Promise.all(promises)

        setTotalPlayers((prevState) => {

            const up = {...prevState}

            Object.entries(totalPlayers).forEach(([index,value], i) => {

                up[index].amounts  = Math.round(all_promise[i].data) || ''
            })

            return up;

        })

    }


        const updatePlayers = () => {

        setTotalPlayers(prevState => {
            const updatePlayerss = {...prevState}
            Object.entries(updatePlayerss).forEach(([index, value]) => {
                const datafound = data.sub.find(item => item.subtype === value.subtype)
                if (datafound) {
                    value.status = datafound.gstatus === 'OPEN' ? '' : 'suspended-box';
                    value.odds = {...value.odds, back :datafound.b}
                    value.odds = {...value.odds, lay :datafound.l}
                    if (value.hasOwnProperty('subname')) {
                        value.subname = datafound.nat
                    }
                }
            })
            return updatePlayerss;
        })


    }


    useEffect(() => {

        if (data?.sub) {
            updatePlayers();
        }

        if (data.card) {
            const cardArray = data.card.split(",").map(item => item.trim());
            setCards(cardArray.slice(0, 6));
            setCardCount(cardPointsCount(['10', 'J', 'Q', 'K', '1'], cardArray.slice(0, 6)))

            remark.current = data.remark || 'Welcome';
        }
    }, [data]);

    const exposure = localStorage.getItem('exposure');
    const sportLength = Object.keys(data).length;


    useEffect(() => {

        if (data?.sub && sportList?.id) {
            updateAmounts();
        }
    }, [exposure, sportLength, roundId]);

    const openPopup = (isBakOrLay, teamnam, oddvalue) => {


        if (parseFloat(oddvalue) > 0) {
            roundIdSaved.current = roundId
            setbackOrLay(isBakOrLay)
            setPopupDisplayForDesktop(true);
            teamname.current = teamnam
            setOdds(oddvalue)
        } else {
            Notify("Odds Value Change Bet Not Confirm", null, null, 'danger')

        }


    }
    const casinoBetDataNew = (new_odds) => {
        stakeValue.current = new_odds
        if (backOrLay === 'back') {


            loss.current = stakeValue.current;


            profit.current = profitData.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)

        } else {

            profit.current = profitData.current = stakeValue.current;


            loss.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)
        }


    }

    const renderCards = (cards, player) => (

        cards?.map((card, index) => {
            const imgSrc = card ? `/img/casino/cards/${card}.png` : '/img/casino/cards/1.png';
            return (
                <div className="mt-1" key={index}>
                    <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={imgSrc} alt={`${player} card ${index + 1}`}/>
                            </div>
                        </div>
                    </div>
                </div>
            );
        })

)
    ;

    const placeBet = () => {

        setHideLoading(false)

        const findPlayer = (team) => {
            const found = Object.values(totalPlayers).find(item => item?.subname === team)
            if (found)
                return found.status


            return totalPlayers[team]?.status || 'suspended';


        }

        if (findPlayer(teamname.current) !== '') {
            Notify(`Bet Not Confirmed. Reason: Game Suspended`, null, null, 'danger');
            setPopupDisplayForDesktop(false);
            setHideLoading(true);
            resetBetFields(profitData, stakeValue)

            return;
        }

        if (roundIdSaved.current !== roundId) {
            Notify("Bet Not Confirm Reason Game Change", null, null, 'danger')
            setPopupDisplayForDesktop(false);
            setHideLoading(true);
            resetBetFields(profitData, stakeValue)
            return;
        }


        setSubmitButtonDisable(true)

        const postdata = {

            "sportId": sportList.id,
            "matchId": roundId,
            "isback": backOrLay === 'back' ? 1 : 0,
            "placeName": teamname.current,
            "placeName2": null,
            "odds": odds,
            "oddsk": 0,
            "profit": parseFloat(profit.current).toFixed(2),
            "loss": parseFloat(loss.current).toFixed(2),
            "betType": "ODDS",
            "bet_side": backOrLay.toUpperCase(),
            "betAmount": parseFloat(stakeValue.current),
            "type": match_id.toUpperCase(),
            "matchType": match_id.toLowerCase(),

        }

        axiosFetch('casino/store', 'post', null, postdata)
            .then((res) => {
                if (res.data.status === true) {
                    updateAmounts();

                    resetBetFields(profitData, stakeValue)
                    Notify("Bet Placed Successfully", null, null, 'success')
                } else {

                    resetBetFields(profitData, stakeValue)
                    Notify(res.data.msg, null, null, 'danger')
                }
                setHideLoading(true)
                setSubmitButtonDisable(false)
                setPopupDisplayForDesktop(false)
            })


    }

    return (

        <CasinoLayout raceClass="raceto17" ruleDescription={desc} hideLoading={hideLoading} isBack={backOrLay} teamname={teamname} handleStakeChange={casinoBetDataNew} odds={odds}
                      stakeValue={stakeValue} setOdds={setOdds} placeBet={placeBet}
                      submitButtonDisable={submitButtonDisable} data={data} roundId={roundId} setRoundId={setRoundId}
                      sportList={sportList}
                      setSportList={setSportList} setData={setData} setLastResult={setLastResult}>

            <div className="video-container" style={{minHeight: '0px'}}>
                <div className="video-overlay" style={{top: '-300px'}}>
                    <div className="casino-video-cards">
                        <h5>Total : {cardCount} </h5>


                        {renderCards(cards)}

                    </div>
                </div>
            </div>

            <div className="casino-detail">
                <div className="casino-table">
                    <div className="casino-table-box">
                        {Object.entries(totalPlayers).map(([index, value], i) => (
                            <div className="casino-odd-box-container" key={i}>
                                <div className="casino-nation-name">{value?.subname || index}</div>
                                <div className={`casino-odds-box back ${value.status}`}
                                     onClick={() => openPopup('back', value?.subname || index, value.odds.back)}><span
                                    className="casino-odds">{value.odds.back}</span></div>
                                <div className={`casino-odds-box lay ${value.status}`}
                                     onClick={() => openPopup('lay', value?.subname || index, value.odds.lay)}><span
                                    className="casino-odds">{value.odds.lay}</span></div>
                                <div className="casino-nation-book text-center w-100">

                                    {getExByColor(value.amounts)}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <marquee scrollamount="3" className="casino-remark m-b-10">{remark.current}</marquee>
                <div className="casino-last-result-title">
                    <span>Last Result</span>
                </div>
                <div className="casino-last-results">
                    <CasinoLastResult sportList={sportList} lastResults={lastResult} data={data}/>
                </div>
            </div>
            

        </CasinoLayout>
    );

};


export default Race17;
