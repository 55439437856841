import {React,useState} from 'react'

const Scoreboard =() => {

    const [data] = useState({})

return (


    <div className={`markets cricket_score_board ${Object.keys(data).length > 0 ? '' : 'd-none'}`}>
        <div className="scorecard m-b-5">
            <div className="row">
                <div className="col-md-5 col-sm-5 col-xl-5">
                    <p className="team-1 row">
                        <span className="team-name col-md-3 col-sm-4 col-xl-4 score_team_a">&nbsp;</span>
                        <span className="score col-md-9 col-sm-8 col-xl-8 text-right score_data_team_a">&nbsp;</span>
                    </p>
                    <p className="team-1 team-2 row m-t-20">
                        <span className="team-name col-md-3 score_team_b">&nbsp;</span>
                        <span className="score col-md-9 text-right score_data_team_b">&nbsp;</span>
                    </p>
                </div>
                <div className="col-md-7 col-sm-7 col-xl-7">
                    <div className="row">

                        <div className="col-md-12 col-sm-12 col-xl-12">
                            <div className="text-right">
                                <p className="m-b-0 score_cb">&nbsp;</p>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-xl-12">
                                    <p className="text-right ball-by-ball m-t-10 score_ball">&nbsp;</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)
}
export default Scoreboard;