import React, {useContext, useEffect, useRef, useState} from "react";
import Header from "../Section/Header";
import SportsLayout from "../Section/SportsLayout";
import {getExByTeamNameForCricket, mapSports, showCricketSessionBook} from "../../Common_Functions/Constants";

import {SportsContext} from "../../Components/Context/SportsContext";
import BetPlacePopup from "../../Components/BetPlacePopup";
import Bookmaker from "../Section/AllSports/Bookmaker";
import MatchOdds from "../Section/AllSports/MatchOdds";
import TiedMatch from "../Section/Cricket/TiedMatch";
import FancySession from "../Section/Cricket/FancySession";

import OverByOver from "../Section/Cricket/OverByOver";
import Scoreboard from "../Section/Cricket/Scoreboard";
import Meter from "../Section/Cricket/Meter";
import OddEven from "../Section/Cricket/OddEven";
import Fancy1 from "../Section/Cricket/Fancy1";
import Khado from "../Section/Cricket/Khado";
import {useNavigate, useParams} from "react-router-dom";
import BallByBall from "../Section/Cricket/BallByBall";
import {Buffer} from "buffer";

const MobileCricket = () => {
    const nav = useNavigate();

    const [gameId, setGameId] = useState('')

    const [ar_sectionData, setAr_sectionData] = useState([])
    const {match_id} = useParams();
    const teamNames = useRef({});
    const [maxValue, setMaxValue] = useState([])
    const [minValue, setMinValue] = useState([])
    const defaultTeamName = useRef('')

    const allTeamName = useRef([])
    const teamNameCurrentBets = useRef({})


    const [betOddValue, setBetOddValue] = useState(0)
    const [backOrLay, setbackOrLay] = useState('back')
    const {
        popupDisplay,
        setPopupDisplay,
        betType,
        sports_socket,
        betPlaceStatusCheck,
        setShowLoader,
        betPlaceStatus,
        defaultTeamDatasCalculation
    } = useContext(SportsContext)



    const [sportList, setSportList] = useState({})

    const oddsChange = useRef({});
    const individualBetPlaceFetch = (teamname) => {
        betPlaceStatus.current[teamname] = betPlaceStatusCheck(sportList, ar_sectionData, teamname)
    }
    const ar_lengt = Object.keys(ar_sectionData).length;
    const trackData = useRef({});

    useEffect(() => {
        defaultTeamDatasCalculation(sportList, setOddsTeamData, setBookmakerTeamData, setTiedMatchData)
        //eslint-disable-next-line
    }, [sportList]);


    const callTeamDatas = () => {

        try {
            if (Object.keys(sportList).length > 0) {
                getExByTeamNameForCricket(ar_sectionData, 'ODDS', 'match_odds', sportList.id, oddsTeamData, setOddsTeamData);
                getExByTeamNameForCricket(ar_sectionData, 'BOOKMAKER', 'bookmaker', sportList.id, bookmakerTeamData, setBookmakerTeamData);
                getExByTeamNameForCricket(ar_sectionData, 'TIED_MATCH', 'tied match', sportList.id, tiedMatchData, setTiedMatchData);

            }


        } catch (error) {
            console.error("Error fetching team data:", error);
        }
    };

    const expsoure = localStorage.getItem('exposure')

    useEffect(() => {

        if (ar_lengt > 0) {

            callTeamDatas();
        }

        // eslint-disable-next-line
    }, [ar_lengt, expsoure]);
    useEffect(() => {


        betPlaceStatusCheck(sportList, ar_sectionData)
// eslint-disable-next-line
    }, [ar_lengt]);

    const [oddsTeamData, setOddsTeamData] = useState({})
    const [bookmakerTeamData, setBookmakerTeamData] = useState({})
    const [tiedMatchData, setTiedMatchData] = useState({})


    useEffect(() => {
            let gamename = 'cricket'
            sports_socket.emit('setPurposeFor', 'sports', gamename, '', '', match_id)
            let socket_game = `getSportData${gamename}${match_id}`;
        let emptyCheckTimeout;
            const gameConnect = () => {
                setShowLoader(true)

                sports_socket.on(socket_game, sportData => {
                    const gameSet = [];
                    if (sportData !== null) {
                        sportData = JSON.parse(Buffer.from(sportData).toString('utf8'))



                        if (sportData && sportData.game_detail && sportData.game_detail.length > 0) {
                            setShowLoader(false);

                            for (let i = 0; i < sportData.game_detail.length; i++) {

                                const value = sportData.game_detail[i];


                                if (value.hasOwnProperty('gmid') && gameId === '') {

                                    setGameId(value.gmid)
                                }

                                const gtype = value.mname.toLowerCase();


                                gameSet[gtype] = value;

                                trackData.current = gameSet;


                            }
                            if (Object.values(gameSet).length > 0 && emptyCheckTimeout) {


                                clearTimeout(emptyCheckTimeout);  // Clear the timeout if data is received
                                emptyCheckTimeout = null;
                            }
                            trackData.current = gameSet;

                            setAr_sectionData(gameSet)


                        }

                    }


                })
            }


            gameConnect();
        emptyCheckTimeout = setTimeout(() => {

            if (Object.values(trackData.current).length === 0) {

                nav('/');  // Redirect if gameSet is empty
            }

        }, 3000);

            sports_socket.on('disconnect', function () {

                // Reconnect logic
                const connectInterval = setInterval(() => {

                    gameConnect();
                    clearInterval(connectInterval)
                }, 1000);  // Reattempt connect every second
            });


            return () => {
                sports_socket.off(socket_game);
                setPopupDisplay(false)
                setShowLoader(true);

            };


// eslint-disable-next-line
    }, [match_id])

    useEffect(() => {
        try {
            if (gameId !== '') {

                const tvTrigger = async () => {
                    try {
                        sports_socket.emit('setPurposeFor', 'tv', 'cricket', '', '', match_id)
                        // Handle the successful response here, e.g., console.log(response.data)
                    } catch (error) {
                        // Handle specific error scenarios
                        if (error.response) {
                            // Server responded with a status other than 200 range
                            console.error("Error Response:", error.response.data);
                            console.error("Status:", error.response.status);
                            console.error("Headers:", error.response.headers);
                        } else if (error.request) {
                            // No response received from server
                            console.error("No response received:", error.request);
                        } else {
                            // Something happened in setting up the request
                            console.error("Error:", error.message);
                        }
                    }
                }
                tvTrigger()
            }

        } catch (e) {

        }
        //eslint-disable-next-line
    }, [gameId]);


    return (
        <div>
            <Header/>
            <SportsLayout gameId={gameId} sportList={sportList} data={ar_sectionData} setSportList={setSportList}>
                <Scoreboard/>

                <BetPlacePopup callTeamDatas={callTeamDatas} maxValue={maxValue}
                               minValue={minValue} teamNameCurrentBets={teamNameCurrentBets} showCalculation={true}
                               individualBetPlaceFetch={individualBetPlaceFetch} data={ar_sectionData} betType={betType}
                               sportList={sportList} backOrLay={backOrLay} teamname={defaultTeamName}
                               odds={betOddValue}
                               teamNames={['ODDS', 'BOOKMAKER', 'TIED_MATCH'].includes(betType) ? allTeamName : []}
                               setOdds={setBetOddValue}
                               setPopupDisplay={setPopupDisplay} popupDisplay={popupDisplay}/>

                <MatchOdds setMaxValue={setMaxValue} setMinValue={setMinValue} teamNameCurrentBets={teamNameCurrentBets}
                           allTeamName={allTeamName}
                           oddsTeamData={oddsTeamData} setDefaultTeamName={defaultTeamName}
                           setBetOddValue={setBetOddValue} setbackOrLay={setbackOrLay} teamNames={teamNames}
                           setPopupDisplay={setPopupDisplay} ar_sectionData={ar_sectionData} sportList={sportList}
                           oddsChange={oddsChange}/>

                <Bookmaker setMaxValue={setMaxValue} setMinValue={setMinValue} teamNameCurrentBets={teamNameCurrentBets}
                           allTeamName={allTeamName}
                           setDefaultTeamName={defaultTeamName} bookmakerTeamData={bookmakerTeamData}
                           setBetOddValue={setBetOddValue} setbackOrLay={setbackOrLay}
                           teamNames={teamNames} setPopupDisplay={setPopupDisplay} ar_sectionData={ar_sectionData}
                           sportList={sportList} oddsChange={oddsChange}/>


                <TiedMatch teamNameCurrentBets={teamNameCurrentBets} allTeamName={allTeamName} model={sportList}
                           tiedMatchData={tiedMatchData} gameData={ar_sectionData} teamNameArr={teamNames}

                           setBetOddValue={setBetOddValue} setbackOrLay={setbackOrLay} teamNames={teamNames}
                           setPopupDisplay={setPopupDisplay} setDefaultTeamName={defaultTeamName}
                />
                <FancySession betPlaceStatus={betPlaceStatus} setDefaultTeamName={defaultTeamName}
                              setPopupDisplay={setPopupDisplay} sportList={sportList}
                              oddsChange={oddsChange} teamNames={teamNames} setbackOrLay={setbackOrLay}
                              setBetOddValue={setBetOddValue} data={ar_sectionData}
                              showCricketSessionBook={showCricketSessionBook}
                />
                <OverByOver betPlaceStatus={betPlaceStatus} setDefaultTeamName={defaultTeamName}
                            setPopupDisplay={setPopupDisplay}
                            model={sportList} oddsChange={oddsChange} teamNames={teamNames} setbackOrLay={setbackOrLay}
                            setBetOddValue={setBetOddValue} gameData={ar_sectionData}
                            showCricketSessionBook={showCricketSessionBook}/>
                <BallByBall betPlaceStatus={betPlaceStatus} setDefaultTeamName={defaultTeamName}
                            setPopupDisplay={setPopupDisplay} sportList={sportList}
                            oddsChange={oddsChange} teamNames={teamNames} setbackOrLay={setbackOrLay}
                            setBetOddValue={setBetOddValue} data={ar_sectionData}
                            showCricketSessionBook={showCricketSessionBook}
                />

                <Fancy1 betPlaceStatus={betPlaceStatus} setDefaultTeamName={defaultTeamName}
                        setPopupDisplay={setPopupDisplay} sportList={sportList}
                        oddsChange={oddsChange} teamNames={teamNames} setbackOrLay={setbackOrLay}
                        setBetOddValue={setBetOddValue} data={ar_sectionData}

                />


                <Khado betPlaceStatus={betPlaceStatus} setDefaultTeamName={defaultTeamName}
                       setPopupDisplay={setPopupDisplay} sportList={sportList}
                       oddsChange={oddsChange} teamNames={teamNames} setbackOrLay={setbackOrLay}
                       setBetOddValue={setBetOddValue} data={ar_sectionData}
                       showCricketSessionBook={showCricketSessionBook}
                />

                <OddEven setBetOddValue={setBetOddValue} setbackOrLay={setbackOrLay}
                         setDefaultTeamName={defaultTeamName} model={sportList} gameData={ar_sectionData}
                         setPopupDisplay={setPopupDisplay}/>
                <Meter betPlaceStatus={betPlaceStatus} setDefaultTeamName={defaultTeamName}
                       setPopupDisplay={setPopupDisplay} sportList={sportList}
                       oddsChange={oddsChange} teamNames={teamNames} setbackOrLay={setbackOrLay}
                       setBetOddValue={setBetOddValue} data={ar_sectionData}
                       showCricketSessionBook={showCricketSessionBook}
                />

            </SportsLayout>
        </div>

    );
};
export default MobileCricket;