import React, {useContext, useEffect} from 'react';
import $ from 'jquery';
import {AuthContext} from "./Context/AuthContext";
import {Modal, Table} from "react-bootstrap";

const CurrentBetModal = ({isOpen, onClose, dialogClass = "modal-95w"}) => {
    const {ACCESS_TOKEN} = useContext(AuthContext);
    useEffect(() => {

        if (isOpen === true) {
            $('.data-table-expposure').DataTable({
                processing: true,
                serverSide: true,
                ajax: {
                    url: process.env.REACT_APP_API_URL + "reports/client-list-exposure-popup",
                    type: 'post',
                    async: false,
                    headers: {
                        'Authorization': `Bearer ${ACCESS_TOKEN}`
                    },

                },
                columns: [
                    {data: 'game_name', name: 'game_name'},
                    {data: 'sport_name', name: 'sport_name'},
                    {data: 'team_name', name: 'team_name'},
                    {data: 'bet_side', name: 'bet_side'},
                    {data: 'bet_odds', name: 'bet_odds'},
                    {data: 'bet_amount', name: 'bet_amount'},
                    {data: 'created_at', name: 'created_at'},
                    {data: 'ip_address', name: 'ip_address'},
                ],
                rowCallback: function (row, data) {
                    if (data.bet_side === "LAY" || data.bet_side === "No") {
                        $(row).addClass('lay');
                    } else {
                        $(row).addClass('back');
                    }
                }
            });
        }
    }, [isOpen]);

    return (
        <Modal show={isOpen} onHide={() => onClose(false)} dialogClassName={dialogClass} aria-labelledby="example-custom-modal-styling-title">
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Exposure
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="content_popup report-container" style={{overflowX: 'auto !important', padding: '2px'}}>
                    <div className="popup_form_row">
                        <div id="betHistoryModalbody" style={{padding: '0px'}}>
                            <div className="table-responsive">
                                <Table bordered responsive className="ABCD data-table data-table-expposure">
                                    <thead>
                                    <tr>
                                        <th>Event Type</th>
                                        <th>Event Name</th>
                                        <th>Runner Name</th>
                                        <th>Bet Type</th>
                                        <th>User Rate</th>
                                        <th>Amount</th>
                                        <th>Place Date</th>
                                        <th>IP Address</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default CurrentBetModal;
