import React, {useContext, useState} from 'react';
import {
    chunkArray,

    generateBackAndLayFunction,
    getSize,
    handleShowRules, useFancyHideStatus
} from "../../../Common_Functions/Constants";
import {SportsContext} from "../../../Components/Context/SportsContext";
import FancyBetPopup from "./FancyBetPopup";


const BallByBall = ({
                        data,
                        sportList,
                        betPlaceStatus,
                        oddsChange,
                        setDefaultTeamName,
                        setBetOddValue,
                        showCricketSessionBook,
                        setbackOrLay,
                        teamNames,
                        setPopupDisplay
                    }) => {
    const fancyHideStatus = useFancyHideStatus(sportList, data);
    const {runnerRowDefault, rootClassDefault, setBetType, oddsk, setBetTypeFromArray} = useContext(SportsContext);
    const [fancyData, setFancyData] = useState(null)
    const [closePopup, setClosePopup] = useState(false)


    if (!data || !Array.isArray(data) || data['ball by ball'] === undefined) return null;


    const mainValue = data['ball by ball'];


    const ar_sectionData = mainValue.section;
    teamNames.current['SESSION'] = [];


    return (
        <div className="fancy-market row row5">

            <div className="col-12">
                <div>
                    <div className="market-title">
                        <span>Ball By Ball</span>
                        <span className="m-r-5 game-rules-icon" onClick={() => handleShowRules('Fancyrule')}>
                        <span>
                            <i className="fa fa-info-circle float-right"></i>
                        </span>
                    </span>
                    </div>
                    <div className="row">
                        {chunkArray(ar_sectionData, 2).map((arr, vv) =>(
                        <div className={ar_sectionData.length > 1 ? "col-6" : "col-12"} key={vv}>
                            <div className="table-header">
                                <div className="float-left country-name box-5"></div>
                                <div className="box-1 float-left lay text-center">
                                    <b>No</b>
                                </div>
                                <div className="box-1 float-left back text-center">
                                    <b>Yes</b>
                                </div>
                            </div>

                            <div className="table-body SESSION">
                                {arr.map((oddsArr, key) => {
                                    const teamName = oddsArr.nat.trim();
                                    teamNames.current['SESSION'][teamName] = teamName;

                                    if (fancyHideStatus[oddsArr.sid] || !teamName || teamName.trim() === '') {
                                        return null;
                                    }


                                    let isSuspendedClass = '';
                                    let back = 0, lay = 0, backk = '0', layk = '0';
                                    oddsArr['back'] = [];
                                    oddsArr['lay'] = [];


                                    if (oddsArr.odds && oddsArr.odds.length > 0) {
                                        let no = 0;

                                        oddsArr.odds.forEach(a_value => {
                                            const tno = a_value.tno;
                                            if (a_value.otype === 'back') {

                                                oddsArr['back'][tno] = a_value;
                                                back = a_value.odds;
                                                backk = a_value.size;
                                            } else if (a_value.otype === 'lay') {
                                                oddsArr['lay'][no] = a_value;
                                                lay = a_value.odds;
                                                layk = a_value.size;
                                                no++;
                                            }
                                        });
                                    }

                                    let totalOdds = parseFloat(back) + parseFloat(lay);
                                    if (sportList.match_suspend_fancy === 1 || sportList.match_suspend === 1) {
                                        totalOdds = 0;
                                    }

                                    const gstatus = oddsArr.gstatus ? oddsArr.gstatus.toUpperCase() : '';

                                    if (gstatus === 'SUSPENDED' || gstatus === 'BALL_RUNNING' || gstatus === 'MATCH-SUSPENDED') {
                                        totalOdds = 0;
                                    }

                                    if (totalOdds === 0) {
                                        isSuspendedClass = 'suspended suspend_box';
                                    }


                                    const n_key_lay = key;
                                    const n_key_back = key;


                                    let backFunctionSes = [], layFunctionSes = [];
                                    backFunctionSes = generateBackAndLayFunction(totalOdds, oddsArr, 'back', teamName, 0, n_key_back, 'SESSION', setBetOddValue, setbackOrLay, teamNames, setPopupDisplay, setDefaultTeamName, runnerRowDefault, rootClassDefault, setBetType, oddsk,setBetTypeFromArray, 'ball by ball');

                                    layFunctionSes = generateBackAndLayFunction(totalOdds, oddsArr, 'lay', teamName, 0, n_key_lay, 'SESSION', setBetOddValue, setbackOrLay, teamNames, setPopupDisplay, setDefaultTeamName, runnerRowDefault, rootClassDefault, setBetType, oddsk,setBetTypeFromArray, 'ball by ball');

                                    const betPlaceCheck = betPlaceStatus?.current?.[teamName];
                                    const fancyListValues = Object.values(betPlaceCheck?.fancy_list || []);

                                    const min_value = fancyListValues.length > 0 ? Math.min(...fancyListValues) : null;

                                    const get_fancy_session_value = betPlaceCheck?.fancy_list ? (
                                        <span className="span_fancy_session_value color-font-red">{min_value}</span>
                                    ) : null;

                                    return (
                                        <div key={key} className={`fancy-tripple` }>
                                            <div data-title={gstatus}
                                                 className={`table-row sessionchecksuspended ${isSuspendedClass}`}>
                                                <div className="float-left country-name box-5"
                                                     style={{borderBottom: '0 none'}}>
                                                    <p className="m-b-0">
                                                        {betPlaceCheck?.bet ? (
                                                            <a className="link-session"
                                                               onClick={() => showCricketSessionBook(teamName, sportList.id, setFancyData)}>
                                                                {teamName}
                                                            </a>
                                                        ) : (
                                                            <a>{teamName}</a>
                                                        )}
                                                    </p>
                                                    <p className="m-b-0">{get_fancy_session_value}</p>
                                                </div>
                                                <div className="box-1 lay float-left text-center"
                                                     onClick={layFunctionSes[0]}>
                                                    <span className="odd d-block">{lay > 0 ? lay : '-'}</span>
                                                    <span>{getSize(layk, false)}</span>
                                                </div>
                                                <div className="box-1 back float-left text-center"
                                                     onClick={backFunctionSes[0]}>
                                                    <span className="odd d-block">{back > 0 ? back : '-'}</span>
                                                    <span>{getSize(backk, false)}</span>
                                                </div>
                                                <div className="box-2 float-left text-right min-max"
                                                     style={{borderBottom: "0px none"}}>
                                                    <span className="d-block">Min: <span>100&nbsp;</span></span>
                                                    <span className="d-block">Max: <span>1L&nbsp;</span></span>
                                                </div>

                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        ))}
                    </div>
                </div>
            </div>
            <FancyBetPopup fancyData={fancyData} setFancyData={setFancyData} closePopup={closePopup}
                           setClosePopup={setClosePopup}/>
        </div>
    );
};

export default BallByBall;