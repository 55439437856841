import React, {useContext, useEffect, useState} from "react";
import Header from "./Section/Header";
import $ from 'jquery';
import 'datatables.net-bs4';
import '../css/mobile/datatable.css';
import {AuthContext} from "../Components/Context/AuthContext";
import CommonLayout from "./Section/CommonLayout";

const AccountStatement = () => {

    const [selectedFilter, setSelectedFilter] = useState("0");
    const [fromDate, setFromDate] = useState(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]);
    const [toDate, setToDate] = useState(new Date().toISOString().split('T')[0]);
    const {ACCESS_TOKEN} = useContext(AuthContext);



    const initDatatable = () => {
        const columns = [
            {data: 'DT_RowIndex', name: 'DT_RowIndex'},
            {data: 'created_at', name: 'created_at'},
            {data: 'description', name: 'description'},
            {data: 'credit', name: 'credit'},
            {data: 'debit', name: 'debit'},
            {data: 'balance', name: 'balance'},
            {data: 'From-To', name: 'From-To'},

        ];
        const data =
            {
                'from_date':
                    $('#from_date').val(),
                'to_date':
                    $('#to_date').val(),
                'type':
                    $('input[name="fltrselct"]:checked').val(),


            }
        $('#account_statement_list').DataTable({

            pagingType: 'full_numbers',
            lengthMenu: [25, 50, 75, 100],
            pageLength: 25,
            processing: true,
            serverSide: true,
            orderable: false,
            sortable: false,
            // dom: getDom(),
            ajax: {
                url: `${process.env.REACT_APP_API_URL}account_statement`,
                type: 'post',
                data: data,
                async: false,
                headers: {
                    'Authorization': `Bearer ${ACCESS_TOKEN}`
                },
            },
            columns: columns,
            header: 'false',
            // buttons: getButtonsObj($('#account_statement_list')),

            order: false,
            searchDelay: 500,
            "columnDefs": [{
                "targets": 'no-sort',
                "orderable": false
            }]
        });
    }
    useEffect(() => {


        initDatatable()

        //eslint-disable-next-line
    }, []);
    const handleFilterChange = (e) => {
        setSelectedFilter(e.target.value);
    };

    const handleFromDateChange = (e) => {
        setFromDate(e.target.value);
    };

    const handleToDateChange = (e) => {
        setToDate(e.target.value);
    };

    const handleSubmit = () => {
        $('#account_statement_list').DataTable().destroy();

        initDatatable()
    };

    return (

        <CommonLayout>


            <div className="card">
                <div className="card-header">
                    <div className="row row5">
                        <div className="col-6">
                            <h4 className="mb-0">Account Statement</h4>
                        </div>
                        <div className="col-6">
                            <span className="export-buttons pull-right"></span>
                        </div>
                    </div>
                </div>
                <div className="card-body container-fluid container-fluid-5 report-container">
                    <div className="row row5" style={{padding: "5px"}}>
                        <div className="row form-horizontal" style={{marginBottom: "25px", padding: "5px"}}>
                            <div className="col-md-12">
                                <div className="select_account">
                                    <div className="radio">
                                        <input
                                            id="radio-1"
                                            type="radio"
                                            name="fltrselct"
                                            defaultValue="0"
                                            checked={selectedFilter === "0"}
                                            onChange={handleFilterChange}
                                        />
                                        <label htmlFor="radio-1" className="radio-label">All</label>
                                    </div>
                                    <div className="radio">
                                        <input
                                            id="radio-2"
                                            type="radio"
                                            name="fltrselct"
                                            defaultValue="1"
                                            checked={selectedFilter === "1"}
                                            onChange={handleFilterChange}
                                        />
                                        <label htmlFor="radio-2" className="radio-label">Free Chips</label>
                                    </div>
                                    <div className="radio">
                                        <input
                                            id="radio-3"
                                            type="radio"
                                            name="fltrselct"
                                            defaultValue="2"
                                            checked={selectedFilter === "2"}
                                            onChange={handleFilterChange}
                                        />
                                        <label htmlFor="radio-3" className="radio-label">Settlement</label>
                                    </div>
                                    <div className="radio">
                                        <input
                                            id="radio-4"
                                            type="radio"
                                            name="fltrselct"
                                            defaultValue="3"
                                            checked={selectedFilter === "3"}
                                            onChange={handleFilterChange}
                                        />
                                        <label htmlFor="radio-4" className="radio-label">Game Report</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <input
                                    type="date"
                                    name="from_date"
                                    id="from_date"
                                    defaultValue={fromDate}
                                    onChange={handleFromDateChange}
                                    className="form-control"
                                    placeholder="From Date"
                                    autoComplete="off"
                                />
                            </div>
                            <div className="col-3">
                                <input
                                    type="date"
                                    name="to_date"
                                    id="to_date"
                                    defaultValue={toDate}
                                    onChange={handleToDateChange}
                                    className="form-control"
                                    placeholder="To Date"
                                    autoComplete="off"
                                />
                            </div>
                            <div className="col-md-2">

                                <button className="btn btn-success btn-block btn-sm" onClick={handleSubmit}>
                                    Submit
                                </button>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                        <div className="col-12 account-statement-tbl">
                            <div className="table-responsive">
                                <table className="table table-bordered data-table table-responsive"
                                       id="account_statement_list">
                                    <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Date</th>
                                        <th>Description</th>
                                        <th>Credit</th>
                                        <th>Debit</th>
                                        <th>Balance</th>
                                        <th>From-To</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {/* Data rows will be rendered here */}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </CommonLayout>
    );
};

export default AccountStatement;
