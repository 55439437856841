import CasinoLayout from "../Section/Casino/CasinoLayout";
import {useContext, useEffect, useRef, useState} from "react";

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {CasinoLastResult} from "../Section/Casino/CasinoLastResult";

import axiosFetch, {
    getExByColor, getExByTeamNameForCasino,
    resetBetFields
} from "../../Common_Functions/Constants";
import {useParams} from "react-router-dom";
import {SportsContext} from "../../Components/Context/SportsContext";


import Notify from "../../js/Notify";
import {CasinoContext} from "../../Components/Context/CasinoContext";

const Trap = () => {
    const [roundId, setRoundId] = useState('')
    const defaultValues = {odds: {back: 0, lay: 0}, status: '', amounts: ''}

    const [totalPlayers, setTotalPlayers] = useState({
        "Player A": defaultValues,
        "Player B": defaultValues,
        "HighLowPlayer": {"Low": defaultValues, "High": defaultValues},
        "JQK": defaultValues
    })


    const desc = `<div class="rules-section">
                        <ul class="pl-4 pr-4 list-style">
                            <li> Trap is a 52 card deck game.</li>
                            <li>Keeping Ace= 1 point, 2= 2 points, 3= 3 points, 4= 4 points, 5= 5 points, 6= 6 points, 7=7 points, 8= 8 Points, 9= 9 points, 10= 10 points, Jack= 11 points, Queen= 12 points and King= 13 points.</li>
                            <li>Here there are two sides in TRAP. A and B respectively.
                            </li>
                            <li>First card that will open in the game would be from side ‘A’, next card will open in the game from Side ‘B’. Likewise till the end of the game.</li>
                            <li>Any side that crosses a total score of 15 would be the winner of the game. For Example: the total score should be 16 or above.
                            </li>
                            <li>But if at any stage your score is at 13, 14, 15 then you will get into the trap which ideally means you lose.</li>
                            <li>Hence there are only two conditions from which you can decide the winner here either your opponent has to be trapped in the score of 13, 14, 15 or your total score should be above 15.</li>
                        </ul>
                    </div>`
    const {getCardTotalCard32} = useContext(CasinoContext)


    const roundIdSaved = useRef(null);

    const [submitButtonDisable, setSubmitButtonDisable] = useState(false)

    const [cards, setCards] = useState({});

    const stakeValue = useRef(0);
    const [odds, setOdds] = useState(0)

    const [backOrLay, setbackOrLay] = useState('back')
    const [sportList, setSportList] = useState({})
    const {match_id} = useParams();
    const {

        setPopupDisplayForDesktop,

    } = useContext(SportsContext)
    const [hideLoading, setHideLoading] = useState(true)

    const [data, setData] = useState([]);

    const remark = useRef('Welcome');
    const [lastResult, setLastResult] = useState({});
    const teamname = useRef('');
    const loss = useRef(0);
    const profit = useRef(0);
    const profitData = useRef(0);


    useEffect(() => {


        if (data?.sub) {

            setTotalPlayers((prevState) => {

                const prev = {...prevState}

                Object.entries(prev).map(([index, value]) => {
                    const itemfound = data.sub.find(item => item.nat === index)

                    const highfound = data.sub.slice(2, 15).find(item => item.gstatus === 'OPEN')

                    if (index === 'HighLowPlayer') {
                        if (highfound) {
                            prev[index].High = {
                                ...prev[index].High,
                                odds: {"back": highfound.odds[0].b},
                                status: highfound.gstatus === 'OPEN' ? '' : 'suspended-box',
                            }

                            prev[index].Low = {
                                ...prev[index].Low,
                                odds: {"back": highfound.odds[1].b},
                                status: highfound.gstatus === 'OPEN' ? '' : 'suspended-box',
                            }
                        } else {
                            prev[index].High = {
                                ...prev[index].High,
                                status: 'suspended-box',
                            }
                            prev[index].Low = {
                                ...prev[index].Low,
                                status: 'suspended-box',
                            }
                        }
                    }

                    const jqfound = data.sub.slice(16, 29).find(item => item.gstatus === 'OPEN')

                    if (index === 'JQK') {
                        if (jqfound) {
                            prev[index] = {
                                ...prev[index],
                                odds: {"back": jqfound.odds[0].b, "lay": jqfound.odds[0].l},
                                status: highfound.gstatus === 'OPEN' ? '' : 'suspended-box',
                            }
                        } else {
                            prev[index] = {
                                ...prev[index],

                                status: 'suspended-box',
                            }
                        }
                    }


                    if (itemfound) {
                        prev[index] = {
                            ...prev[index],
                            odds: {"back": itemfound.b, "lay": itemfound.l},
                            status: itemfound.gstatus === 'OPEN' ? '' : 'suspended-box',
                        }

                    }
                })


                return prev
            })


        }
        let cardTotA = 0, cardTotB = 0;
        setCards({

            playerACount: cardTotA,
            playerBCount: cardTotB,
        });
        if (data.card) {
            const cardArray = data.card.split(",").map(item => item.trim());
            cardTotA = getCardTotalCard32([cardArray[0], cardArray[2], cardArray[4]].filter(item => item !== '1'), 0);
            cardTotB = getCardTotalCard32([cardArray[1], cardArray[3], cardArray[5]].filter(item => item !== '1'), 0);
            setCards({
                playerA: [cardArray[0], cardArray[2], cardArray[4]].filter(item => item !== '1'),
                playerB: [cardArray[1], cardArray[3], cardArray[5]].filter(item => item !== '1'),
                playerACount: cardTotA,
                playerBCount: cardTotB,
            });
            remark.current = data.remark || 'Welcome';
        }

    }, [data]);

    const exposure = localStorage.getItem('exposure');
    const sportLength = Object.keys(data).length;

    const updateAmounts = async () => {
        const result = [getExByTeamNameForCasino(sportList.id, roundId, 'Player A', match_id, 'ODDS'),
            getExByTeamNameForCasino(sportList.id, roundId, 'Player B', match_id, 'ODDS')]

        const promises = await Promise.all(result)
            setTotalPlayers((prevState) => {
            const updatedPlayers = Object.entries(prevState).map(([playerKey, playerValue], i) => {
                // Check if i is 0 or 1 to update "Player A" or "Player B"
                if (i === 0 || i === 1) {
                    return [
                        playerKey,
                        {
                            ...playerValue,
                            amounts: promises[i]?.data || '' // Use promises[i] for both players
                        }
                    ];
                }
                // For other players, return the existing playerValue
                return [playerKey, playerValue];
            });

            return Object.fromEntries(updatedPlayers);
        });



    }

    useEffect(() => {

        if (data?.sub && sportList?.id) {
            updateAmounts()


        }
    }, [exposure, sportLength, roundId]);


    const openPopup = (isBakOrLay, teamnam, oddvalue) => {


        if (parseFloat(oddvalue) > 0) {
            roundIdSaved.current = roundId
            setbackOrLay(isBakOrLay)
            setPopupDisplayForDesktop(true);
            teamname.current = teamnam
            setOdds(oddvalue)
        } else {
            Notify("Odds Value Change Bet Not Confirm", null, null, 'danger')

        }


    }
    const casinoBetDataNew = (new_odds) => {
        stakeValue.current = new_odds
        if (backOrLay === 'back') {


            loss.current = stakeValue.current;


            profit.current = profitData.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)

        } else {

            profit.current = profitData.current = stakeValue.current;


            loss.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)
        }


    }


    const placeBet = () => {

        setHideLoading(false)
        if (totalPlayers[teamname.current].status !== '') {
            Notify(`Bet Not Confirmed. Reason: Game Suspended`, null, null, 'danger');
            setPopupDisplayForDesktop(false);
            setHideLoading(true);
            resetBetFields(profitData, stakeValue)
            return;
        }

        if (roundIdSaved.current !== roundId) {
            Notify("Bet Not Confirm Reason Game Change", null, null, 'danger')
            setPopupDisplayForDesktop(false)
            setHideLoading(true);
            resetBetFields(profitData, stakeValue)
            return;
        }


        setSubmitButtonDisable(true)

        const postdata = {

            "sportId": sportList.id,
            "matchId": roundId,
            "isback": backOrLay === 'back' ? 1 : 0,
            "placeName": teamname.current,
            "placeName2": null,
            "odds": odds,
            "oddsk": 0,
            "profit": parseFloat(profit.current).toFixed(2),
            "loss": parseFloat(loss.current).toFixed(2),
            "betType": "ODDS",
            "bet_side": backOrLay.toUpperCase(),
            "betAmount": parseFloat(stakeValue.current),
            "type": match_id.toUpperCase(),
            "matchType": match_id.toLowerCase(),

        }

        axiosFetch('casino/store', 'post', null, postdata)
            .then((res) => {
                if (res.data.status === true) {
                    updateAmounts()

                    resetBetFields(profitData, stakeValue)
                    Notify("Bet Placed Successfully", null, null, 'success')
                } else {

                    resetBetFields(profitData, stakeValue)
                    Notify(res.data.msg, null, null, 'danger')
                }
                setHideLoading(true)
                setSubmitButtonDisable(false)
                setPopupDisplayForDesktop(false)
            })


    }

    return (

        <CasinoLayout raceClass="trap" ruleDescription={desc} hideLoading={hideLoading} isBack={backOrLay} teamname={teamname} handleStakeChange={casinoBetDataNew} odds={odds}
                      stakeValue={stakeValue} setOdds={setOdds} placeBet={placeBet}
                      submitButtonDisable={submitButtonDisable} data={data} roundId={roundId} setRoundId={setRoundId}
                      sportList={sportList}
                      setSportList={setSportList} setData={setData} setLastResult={setLastResult}>



            <VideoSlider cards={cards}/>


            <div className="casino-detail">
                <div className="casino-table">
                    <div className="casino-table-box">
                        {Object.entries(totalPlayers).slice(0, 2).map(([index, value]) => (
                            <>
                                <div
                                    className={index === 'Player A' ? `casino-table-left-box` : `casino-table-right-box`}>
                                    <div className="casino-table-body">
                                        <div className="casino-table-row">
                                            <div className="casino-nation-detail">
                                                <div className="casino-nation-name">{index}</div>
                                                <div className="casino-nation-book">{getExByColor(value.amounts)}</div>
                                            </div>
                                            <div className={`casino-odds-box back ${value.status}`}
                                                 onClick={() => openPopup('back', index, value.odds.back)}>
                                                <span className="casino-odds">{value.odds.back}</span>
                                            </div>
                                            <div className={`casino-odds-box lay ${value.status}`}
                                                 onClick={() => openPopup('lay', index, value.odds.lay)}>
                                                <span className="casino-odds">{value.odds.lay}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {index === 'Player A' && (
                                    <div className="casino-table-box-divider"></div>
                                )}
                            </>
                        ))}

                    </div>

                    <div className="casino-table-box mt-3 sevenupbox">
                        <div className="casino-table-left-box">
                            <h4 className="d-md-none mb-2">Player</h4>
                            <div className="seven-up-down-box">
                                {Object.entries(totalPlayers.HighLowPlayer).map(([index, value], i) => (

                                    <div className={i === 0 ? `up-box ${value.status}` : `down-box ${value.status}`}>
                                        <div className="text-end">
                                            <div className="up-down-odds">{value.odds.back}</div>
                                            <span>{index}</span>
                                        </div>
                                    </div>
                                ))}

                                <div className="seven-box">
                                    <img src="https://nd.sprintstaticdata.com/v13/static/front/img/trape-seven.png"
                                         alt="Seven"/>
                                </div>
                            </div>
                        </div>

                        <div className="casino-table-box-divider"></div>
                        <div className="casino-table-right-box">
                            <div className="casino-table-body">
                                <div className="casino-table-row">
                                    <div className="casino-nation-detail">
                                        <div className="casino-nation-name">
                                            <img src="/img/card/11.jpg" alt="Card 11"/>
                                            <img src="/img/card/12.jpg" alt="Card 12"/>
                                            <img src="/img/card/13.jpg" alt="Card 13"/>
                                        </div>
                                        <div className="casino-nation-book"></div>
                                    </div>
                                    <div className={`casino-odds-box back ${totalPlayers.JQK.status}`}>
                                        <span className="casino-odds">{totalPlayers.JQK.odds.back}</span>
                                    </div>
                                    <div className={`casino-odds-box lay ${totalPlayers.JQK.status}`}>
                                        <span className="casino-odds">{totalPlayers.JQK.odds.lay}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <marquee scrollamount="3" className="casino-remark m-b-10">{remark.current}</marquee>
                <div className="casino-last-result-title">
                    <span>Last Result</span>
                </div>
                <div className="casino-last-results">
                    <CasinoLastResult sportList={sportList} lastResults={lastResult} data={data}/>
                </div>
            </div>
            

        </CasinoLayout>
    );

};

export default Trap;

const VideoSlider = ({cards}) => {
    const sliderSettings = {
        vertical: true,
        verticalSwiping: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        speed: 500,
        dots: false, // Set to true if you want dots
    };

    if (!cards || cards?.playerACount <= 0)
        return null;
    return (


        <div className="video-container" style={{minHeight: "0px"}}>
            <div className="video-overlay" style={{top: "-300px"}}>
                <div className="casino-video-cards">
                    <div className="row row5">
                        <div className="col-6">
                            {cards?.playerACount && cards.playerACount > 0 && (
                                <div>
                                    <div className="text-white">
                                        <b>A</b>
                                    </div>
                                    <div className="text-fancy">{cards.playerACount}</div>
                                </div>
                            )}
                            {cards?.playerA && (
                                <div className="v-slider">
                                    <Slider {...sliderSettings}>
                                        {cards?.playerA &&
                                            cards.playerA.map((value, index) => (
                                                <div key={index} className="slick-slide">
                                                    <div>
                                                        <img
                                                            src={`${process.env.REACT_APP_CARD_PATH}${value}.png`}
                                                            style={{width: "100%", display: "inline-block"}}
                                                            alt={`Card ${index}`}
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                    </Slider>
                                </div>
                            )}
                        </div>

                        <div className="col-6">
                            {cards?.playerBCount && cards.playerBCount > 0 && (
                                <div>
                                    <div className="text-white">
                                        <b>B</b>
                                    </div>
                                    <div className="text-fancy">{cards.playerBCount}</div>
                                </div>
                            )}
                            {cards?.playerB && (
                                <div className="v-slider">
                                    <Slider {...sliderSettings}>
                                        {cards?.playerB &&
                                            cards.playerB.map((value, index) => (
                                                <div key={index} className="slick-slide">
                                                    <div>
                                                        <img
                                                            src={`${process.env.REACT_APP_CARD_PATH}${value}.png`}
                                                            style={{width: "100%", display: "inline-block"}}
                                                            alt={`Card ${index}`}
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                    </Slider>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


