import React, {useContext, useEffect, useRef} from 'react';
import axiosFetch, {
    chunkArray,
    generateBackAndLayFunction,
    getSize,
    handleShowRules,

    useFancyHideStatus
} from "../../../Common_Functions/Constants";
import {SportsContext} from "../../../Components/Context/SportsContext";

const OddEven = ({gameData, model, setPopupDisplay, setDefaultTeamName, setBetOddValue, setbackOrLay}) => {
    const fancyHideStatus = useFancyHideStatus(model, gameData);
    const {runnerRowDefault, rootClassDefault, setBetType, oddsk, setBetTypeFromArray} = useContext(SportsContext);
    const betEvenCalculation = useRef([])

    const calculateBetevEn = () => {
        const promises = gameData?.['oddeven']?.section.map((oddsArr, mainKey) => {
            const teamName = `${oddsArr.nat}`;
            return axiosFetch('calculate_bet_odd_even', 'post', null, {
                'sport_id': model.id,
                'match_id': model.match_id,
                'teamname': teamName + " - ODD"
            });
        });

        if (typeof promises !== 'undefined') {
            Promise.all(promises)
                .then((results) => {
                    results.forEach((result, index) => {
                        const teamName = gameData['oddeven']?.section[index].nat;
                        betEvenCalculation.current[teamName] = result.data;
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    useEffect(() => {

        if (Object.keys(model).length > 0) {
            calculateBetevEn();
        }
        // eslint-disable-next-line
    }, [model, localStorage.getItem('exposure')]);


    if (!gameData || !gameData?.['oddeven']) return null;


    return (
        <>
            {[gameData?.['oddeven']].map((mainValue, mainKey) => {
                if (!mainValue.status) return null;

                let remark = "";
                let minValue = model.fancy_min_limit;
                let maxValue = model.fancy_max_limit;

                if (mainValue.min) minValue = mainValue.min;
                if (mainValue.max) maxValue = mainValue.max;

                minValue = getSize(minValue, true);
                maxValue = getSize(maxValue, true);


                const sectionData = mainValue.section || [];

                return (
                    <div className="fancy-market row row5" key={mainKey}>
                        <div className="col-12">
                            <div>
                                <div className="market-title">
                                    <span>{mainValue.mname}</span>
                                    <span className="m-r-5 game-rules-icon"
                                          onClick={() => handleShowRules('Fancyrule')}>
                                <span><i className="fa fa-info-circle float-right"></i></span>
                            </span>
                                </div>
                                <div className="row">
                                    {chunkArray(sectionData, 2).map((arr, valkey) => {

                                        return (
                                            <div className={sectionData.length > 1 ? "col-6" : "col-12"} key={valkey}>

                                                <div className="table-header">
                                                    <div className="box-5 float-left country-name"></div>
                                                    <div className="box-1 float-left back text-center"><b>Odd</b></div>
                                                    <div className="box-1 float-left back text-center"><b>Even</b></div>
                                                    <div className="box-2 float-left"></div>
                                                </div>

                                                <div className="table-body ODDEVEN">
                                                    {arr.map((oddsArr, key) => {
                                                        const teamName = oddsArr.nat.trim();
                                                        if (fancyHideStatus[oddsArr.sid] || !teamName || teamName.trim() === '') {
                                                            return null;
                                                        }


                                                        const teamNameArrs = {};
                                                        if (teamNameArrs[teamName.trim()]) return null;
                                                        teamNameArrs[teamName.trim()] = teamName;

                                                        let isSuspendedClass = '';
                                                        let isSuspended = '';
                                                        let back = 0, lay = 0, backk = 0, layk = 0;
                                                        oddsArr['odd'] = [];
                                                        oddsArr['even'] = [];

                                                        if (oddsArr.odds) {
                                                            oddsArr.odds.forEach(aValue => {

                                                                if (aValue.otype === "back") {
                                                                    oddsArr['odd'].push(aValue);
                                                                    back = aValue.odds;
                                                                    backk = aValue.size;

                                                                }
                                                                if (aValue.otype === "lay") {
                                                                    oddsArr['even'].push(aValue);
                                                                    back = aValue.odds;

                                                                    lay = aValue.odds;
                                                                    layk = aValue.size;

                                                                }
                                                            });
                                                        }


                                                        let total = (parseFloat(back) + parseFloat(lay));
                                                        if (model.match_suspend_fancy === 1 || model.match_suspend === 1) total = 0;

                                                        const runnerRow = 0;
                                                        const teamNames = `${teamName} - Odd`;
                                                        const teamNameLay = `${teamName} - Even`;
                                                        const gstatus = oddsArr.gstatus?.toUpperCase() || "";

                                                        if (["SUSPENDED", "BALL_RUNNING", "MATCH-SUSPENDED"].includes(gstatus)) total = 0;

                                                        if (total === 0) {
                                                        isSuspended = gstatus || 'SUSPENDED';
                                                        isSuspendedClass = 'suspended suspend_box';
                                                        }

                                                        let backFunctionSes = [], layFunctionSes = [];

                                                        const nKey = `${key}`;
                                                        backFunctionSes = generateBackAndLayFunction(total, oddsArr, 'odd', teamNames, runnerRow, nKey, 'ODDEVEN', setBetOddValue, setbackOrLay, [], setPopupDisplay, setDefaultTeamName, runnerRowDefault, rootClassDefault, setBetType, oddsk,setBetTypeFromArray, 'oddeven');


                                                        layFunctionSes = generateBackAndLayFunction(total, oddsArr, 'even', teamNameLay, runnerRow, nKey, 'ODDEVEN', setBetOddValue, setbackOrLay, [], setPopupDisplay, setDefaultTeamName, runnerRowDefault, rootClassDefault, setBetType, oddsk,setBetTypeFromArray, 'oddeven');


                                                        if (back > 0) {
                                                            back = parseFloat(back);
                                                        } else {
                                                            back = lay;
                                                            backFunctionSes = layFunctionSes;
                                                        }

                                                        if (backk > 0) {
                                                            backk = parseFloat(backk);
                                                        } else {
                                                            backk = layk;
                                                        }


                                                        let getFancySessionValue = betEvenCalculation.current?.[teamName] ?? '';


                                                        if (oddsArr.min) minValue = oddsArr.min;
                                                        if (oddsArr.max) maxValue = oddsArr.max;

                                                        minValue = getSize(minValue, true);
                                                        maxValue = getSize(maxValue, true);
                                                        if (oddsArr.rem) remark = oddsArr.rem;


                                                        return (
                                                            <div className={`fancy-tripple ${runnerRow}`} key={key}>
                                                                <div data-title={isSuspended}
                                                                     className={`table-row sessionchecksuspended ${isSuspendedClass}`}>
                                                                    <div className="float-left country-name box-5"
                                                                         style={{borderBottom: '0px none'}}>
                                                                        <p className="m-b-0">
                                                                            <span>{teamName}</span>

                                                                        </p>
                                                                        <p className="m-b-0">
                                                    <span className="span_fancy_session_value color-font-red"
                                                          style={{color: getFancySessionValue === 0 ? 'black' : undefined}}>
                                                        {getFancySessionValue}
                                                    </span>
                                                                        </p>
                                                                        {remark && (
                                                                            <div className="market-row">
                                                                                {/* eslint-disable-next-line jsx-a11y/no-distracting-elements */}
                                                                                <marquee
                                                                                    className="market-remark">{remark}</marquee>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <div className="box-1 back float-left text-center"
                                                                         onClick={backFunctionSes[0]}>
                                                                    <span
                                                                        className="odd d-block BackPrice1">{back}</span>
                                                                        <span>{getSize(backk, false)}</span>
                                                                    </div>
                                                                    <div className="box-1 back float-left text-center"
                                                                         onClick={layFunctionSes[0]}>
                                                                        <span
                                                                            className="odd d-block LayPrice1">{lay}</span>
                                                                        <span>{getSize(parseFloat(layk), false)}</span>
                                                                    </div>
                                                                    <div className="box-2 float-left text-right min-max"
                                                                         style={{borderBottom: "0px none"}}>
                                                                <span
                                                                    className="d-block">Min: <span>{oddsArr.min}&nbsp;</span></span>
                                                                        <span
                                                                            className="d-block">Max: <span>{getSize(oddsArr.max)}&nbsp;</span></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default OddEven;
