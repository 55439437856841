const RulesCard = ({gamename}) => {

    const renderRules = () => {
        switch (gamename) {


            case 'cricketv3' :
                const teams = [
                    {
                        name: "AUS",
                        cards: [
                            {image: "/img/casino/rules/A.jpg", value: "1 Run"},
                            {image: "/img/casino/rules/2.jpg", value: "2 Run"},
                            {image: "/img/casino/rules/3.jpg", value: "3 Run"},
                            {image: "/img/casino/rules/4.jpg", value: "4 Run"},
                            {image: "/img/casino/rules/6.jpg", value: "6 Run"},
                            {image: "/img/casino/rules/10.jpg", value: "0 Run"},
                            {image: "/img/casino/rules/K.jpg", value: "Wicket"},
                        ],
                    },
                    {
                        name: "IND",
                        cards: [
                            {image: "/img/casino/rules/A.jpg", value: "1 Run"},
                            {image: "/img/casino/rules/2.jpg", value: "2 Run"},
                            {image: "/img/casino/rules/3.jpg", value: "3 Run"},
                            {image: "/img/casino/rules/4.jpg", value: "4 Run"},
                            {image: "/img/casino/rules/6.jpg", value: "6 Run"},
                            {image: "/img/casino/rules/10.jpg", value: "0 Run"},
                            {image: "/img/casino/rules/K.jpg", value: "Wicket"},
                        ],
                    },
                ];
                return (
                    <div className="card m-b-10">
                        <div className="card-header">
                            <h6 className="card-title d-inline-block">Rules</h6>
                        </div>
                        <div className="row row5 cc-rules">
                            {teams.map((team) => (
                                <div className="col-6" key={team.name}>
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="card-title">{team.name}</h5>
                                            <div className="row row5 mt-1">
                                                <div className="col-7">Cards</div>
                                                <div className="col-5 text-right">Value</div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            {team.cards.map((card, index) => (
                                                <div className="row row5 mt-1" key={index}>
                                                    <div className="col-7">
                                                        <img src={card.image} alt={`Card ${index + 1}`}/>
                                                        <span className="ml-2">X</span>
                                                        <span className="ml-2">10</span>
                                                    </div>
                                                    <div className="col-5 text-right value">{card.value}</div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="card-footer"></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                );


                case "poker" : {
                    const bonuses = [
                        {
                            title: "Bonus 1 (2 Cards Bonus)",
                            rules: [
                                {description: "Pair (2-10)", value: "1 TO 3"},
                                {description: "A/Q or A/J Off Suited", value: "1 To 5"},
                                {description: "Pair (JQK)", value: "1 To 10"},
                                {description: "A/K Off Suited", value: "1 To 15"},
                                {description: "A/Q or A/J Suited", value: "1 To 20"},
                                {description: "A/K Suited", value: "1 To 25"},
                                {description: "A/A", value: "1 To 30"},
                            ],
                        },
                        {
                            title: "Bonus 2 (7 Cards Bonus)",
                            rules: [
                                {description: "Three of a Kind", value: "1 TO 3"},
                                {description: "Straight", value: "1 To 4"},
                                {description: "Flush", value: "1 To 6"},
                                {description: "Full House", value: "1 To 8"},
                                {description: "Four of a Kind", value: "1 To 30"},
                                {description: "Straight Flush", value: "1 To 50"},
                                {description: "Royal Flush", value: "1 To 100"},
                            ],
                        },
                    ];

                    return (
                        <div className="card m-b-10">
                            <div className="card-header">
                                <h6 className="card-title d-inline-block">Rules</h6>
                            </div>
                            <div className="card-body" style={{padding: '10px'}}>
                                <table className="table table-bordered rules-table">
                                    <tbody>
                                    {bonuses.map((bonus, index) => (
                                        <>
                                            <tr className="text-center">
                                                <th colSpan="2">{bonus.title}</th>
                                            </tr>
                                            {bonus.rules.map((rule, idx) => (
                                                <tr key={idx}>
                                                    <td width="60%">{rule.description}</td>
                                                    <td>{rule.value}</td>
                                                </tr>
                                            ))}
                                        </>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    );
                }

                case 'teen20' :
            case 'vteen20' :
                    return (
                        <div className="card m-b-10">

                            <div className="card-header">
                                <h6 className="card-title d-inline-block">
                                    Rules
                                </h6>
                            </div>
                            <div className="card-body" style={{padding: "10px"}}>
                                <table className="table table-bordered rules-table">
                                    <tbody>
                                    <tr className="text-center">
                                        <th colSpan="2">Pair Plus</th>
                                    </tr>
                                    <tr>
                                        <td width="60%">Pair (Double)</td>
                                        <td>1 To 1</td>
                                    </tr>

                                    <tr>
                                        <td width="60%">Flush (Color)</td>
                                        <td>1 To 4</td>
                                    </tr>

                                    <tr>
                                        <td width="60%">Straight (Rown)</td>
                                        <td>1 To 6</td>
                                    </tr>

                                    <tr>
                                        <td width="60%">Trio (Teen)</td>
                                        <td>1 To 35</td>
                                    </tr>

                                    <tr>
                                        <td width="60%">Straight Flush (Pakki Rown)</td>
                                        <td>1 To 45</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    );


                case 'teen8' :
                    return (
                        <div className="card m-b-10">
                            <div className="card-header">
                                <h6 className="card-title d-inline-block">
                                    Rules
                                </h6>
                            </div>
                            <div className="card-body" style={{padding: "10px"}}>
                                <div className="table-responsive">
                                    <table className="table table-bordered">
                                        <thead>
                                        <tr>
                                            <th colSpan="2" className="text-center">Pair Plus</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>Pair</td>
                                            <td>1 TO 1</td>
                                        </tr>
                                        <tr>
                                            <td>Flush</td>
                                            <td>1 TO 4</td>
                                        </tr>
                                        <tr>
                                            <td>Straight</td>
                                            <td>1 TO 6</td>
                                        </tr>
                                        <tr>
                                            <td>Trio</td>
                                            <td>1 TO 30</td>
                                        </tr>
                                        <tr>
                                            <td>Straight Flush</td>
                                            <td>1 TO 40</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )

            case 'superover' :
                return (
                    <div className="card m-b-10">
                        <div className="cc-rules">
                            <div data-toggle="collapse" data-target=".video-tv" className="card-header">
                                <h6 className="card-title text-center">ENG VS RSA<br/>INNING'S CARD RULES</h6>
                            </div>
                            <div className="card">
                                <div className="card-header">
                                    <div className="row row5 mt-1">
                                        <div className="col-4">Cards</div>
                                        <div className="col-2 text-center">Count</div>
                                        <div className="col-6 text-right">Value</div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    {[
                                        {img: 'A.jpg', count: 5, valueImg: 'ball1.png', label: ''},
                                        {img: '2.jpg', count: 5, valueImg: 'ball2.png', label: ''},
                                        {img: '3.jpg', count: 5, valueImg: 'ball3.png', label: ''},
                                        {img: '4.jpg', count: 5, valueImg: 'ball4.png', label: ''},
                                        {img: '6.jpg', count: 5, valueImg: 'ball6.png', label: ''},
                                        {img: '10.jpg', count: 5, valueImg: 'ball0.png', label: ''},
                                        {img: 'K.jpg', count: 5, valueImg: 'wicket1.png', label: 'WICKET'}
                                    ].map((card, index) => (
                                        <div className="row row5 mt-1" key={index}>
                                            <div className="col-4">
                                                <img src={`/img/casino/rules/${card.img}`} alt={card.img}/>
                                                <span className="ml-2">X</span>
                                            </div>
                                            <div className="col-2 text-center">{card.count}</div>
                                            <div className="col-6 text-right value">
                                                {card.label && <span className="mr-2">{card.label}</span>}
                                                <img
                                                    src={`https://dzm0kbaskt4pv.cloudfront.net/v2/static/img/balls/superover/${card.valueImg}`}
                                                    alt={card.valueImg}/>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'teen20b':
                return (
                    <div className="card m-b-10">
                        <div className="card-header">
                            <h6 className="card-title d-inline-block">Rules</h6>
                        </div>
                        <div className="card-body" style={{padding: '10px'}}>
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th colSpan="2" className="text-center">Pair Plus</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>Pair</td>
                                        <td>1 TO 1</td>
                                    </tr>
                                    <tr>
                                        <td>Flush</td>
                                        <td>1 TO 4</td>
                                    </tr>
                                    <tr>
                                        <td>Straight</td>
                                        <td>1 TO 6</td>
                                    </tr>
                                    <tr>
                                        <td>Trio</td>
                                        <td>1 TO 30</td>
                                    </tr>
                                    <tr>
                                        <td>Straight Flush</td>
                                        <td>1 TO 40</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                );
            case 'teenmuf':
            case 'vteenmuf':
                return (
                    <div className="card m-b-10">
                        <div className="card-header">
                            <h6 className="card-title d-inline-block">Rules</h6>
                        </div>
                        <div className="card-body" style={{padding: '10px'}}>
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th colSpan="2" className="text-center">Top 9</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>Card 9</td>
                                        <td>1 TO 3</td>
                                    </tr>
                                    <tr>
                                        <td>Card 8</td>
                                        <td>1 TO 4</td>
                                    </tr>
                                    <tr>
                                        <td>Card 7</td>
                                        <td>1 TO 5</td>
                                    </tr>
                                    <tr>
                                        <td>Card 6</td>
                                        <td>1 TO 8</td>
                                    </tr>
                                    <tr>
                                        <td>Card 5</td>
                                        <td>1 TO 30</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                );
            case 'teensin':
                return (
                    <div className="card m-b-10">
                        <div className="card-header">
                            <h6 className="card-title d-inline-block">Rules</h6>
                        </div>
                        <div className="card-body" style={{padding: '10px'}}>
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th colSpan="2" className="text-center">Color Plus Rules</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>Straight</td>
                                    <td>1 TO 2</td>
                                </tr>
                                <tr>
                                    <td>Flush</td>
                                    <td>1 TO 5</td>
                                </tr>
                                <tr>
                                    <td>Trio</td>
                                    <td>1 TO 20</td>
                                </tr>
                                <tr>
                                    <td>Straight Flush</td>
                                    <td>1 TO 30</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                );
            case 'patti2':
                return (
                    <div className="card m-b-10">
                        <div className="card-header">
                            <h6 className="card-title d-inline-block">Rules</h6>
                        </div>
                        <div className="card-body" style={{padding: '10px'}}>
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th colSpan="2" className="text-center">Color Plus Rules</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>Three card Sequence</td>
                                    <td>1 TO 3</td>
                                </tr>
                                <tr>
                                    <td>Four card color</td>
                                    <td>1 TO 9</td>
                                </tr>
                                <tr>
                                    <td>Four card Sequence</td>
                                    <td>1 TO 9</td>
                                </tr>
                                <tr>
                                    <td>Three of a kind</td>
                                    <td>1 TO 12</td>
                                </tr>
                                <tr>
                                    <td>Three card pure Sequence</td>
                                    <td>1 TO 15</td>
                                </tr>
                                <tr>
                                    <td>Four card pure Sequence</td>
                                    <td>1 TO 150</td>
                                </tr>
                                <tr>
                                    <td>Four of a kind</td>
                                    <td>1 TO 200</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return renderRules(gamename)

};

export default RulesCard;