import CasinoLayout from "../Section/Casino/CasinoLayout";
import {useContext, useEffect, useRef, useState} from "react";

import {CasinoLastResult} from "../Section/Casino/CasinoLastResult";

import axiosFetch, {
    getExByColor, getExBySingleTeamNameCasino,

    getExByTeamNameForCasino, resetBetFields,

} from "../../Common_Functions/Constants";
import {useParams} from "react-router-dom";
import {SportsContext} from "../../Components/Context/SportsContext";


import Notify from "../../js/Notify";
import {CasinoContext} from "../../Components/Context/CasinoContext";

const Card32eu = () => {
        const [roundId, setRoundId] = useState('')
        const ruleImage = '/img/rules/card32.jpg'
        const ruleDescription = '';
        const {updateCardsForCard32Casino} = useContext(CasinoContext)

        const roundIdSaved = useRef(null);

        const [submitButtonDisable, setSubmitButtonDisable] = useState(false)

        const stakeValue = useRef(0);
        const [odds, setOdds] = useState(0)

        const chunkArray = (array, size) => {
            return array.reduce((acc, _, index) =>
                    index % size === 0 ? [...acc, array.slice(index, index + size)] : acc
                , []);
        };


        const [backOrLay, setbackOrLay] = useState('back')
        const [sportList, setSportList] = useState({})
        let {match_id} = useParams();
        const {
            setBetType,
            betType,
            setPopupDisplayForDesktop,

        } = useContext(SportsContext)
        const [hideLoading, setHideLoading] = useState(true)
        const defaultValues = {odds: {back: 0, lay: 0}, status: 'suspended-box', amounts: '', cards: [], card_number: null}
        const defaultValuesForOthers = {
            odds: {back: 0, lay: 0},
            status: 'suspended-box',
            amounts: '',

        };
        const defaultValuesForODDEVEN = {
            odds: {back: 0, lay: 0},
            status: 'suspended-box',
            amounts: {back: '', lay: ''},

        }
        const [totalPlayers, setTotalPlayers] = useState([
                    {
                        "ODDS": Array.from({length: 4}, (_, index) => (`Player ${index + 8}`)).reduce((accumulator, currentValue) => {
                            return {...accumulator, [currentValue]: defaultValues}
                        }, {})
                    }, {
                        "ODD_EVEN":
                            Array.from({length: 4}, (_, index) => (`Player ${index + 8}`)).reduce((accumulator, currentValue) => {
                                return {...accumulator, [currentValue]: defaultValuesForODDEVEN};


                            }, {})
                    },
                    {
                        "BOOKMAKER":
                            {
                                'Any Three Card Black':
                                defaultValuesForOthers,
                                'Any Three Card Red':
                                defaultValuesForOthers,
                                'Two Black Two Red':
                                defaultValuesForOthers
                            }
                        ,

                    }
                    ,
                    {
                        "TOTAL":
                            {
                                '8 & 9 Total':
                                defaultValuesForOthers, '10 & 11 Total':
                                defaultValuesForOthers
                            }
                    }
                    ,
                    {
                        "SINGLE":
                            Array.from({length: 10}, (_, index) => index).reduce((accumulator, currentValue) => {
                                return {...accumulator, [currentValue]: defaultValuesForOthers}
                            }, {})
                    }
                ]
            )
        ;


        const teamNames = useRef([])

        const [data, setData] = useState([]);


        const remark = useRef('Welcome');
        const [lastResult, setLastResult] = useState({});
        const teamname = useRef('');
        const loss = useRef(0);
        const profit = useRef(0);
        const profitData = useRef(0);

        const getExByTeamName = async () => {
            let promises = []
            // roundId = '114241009171843'
            for (const [key, value] of Object.entries(totalPlayers[0].ODDS)) {
                promises.push(getExByTeamNameForCasino(sportList.id, roundId, key, match_id, 'ODDS'))

            }

            promises.push(getExBySingleTeamNameCasino(sportList.id, roundId, '', match_id, ''))

            const res = await Promise.all(promises);

            setTotalPlayers((prevState) => {
                const exCal = [...prevState]; // Clone the previous state

                // Update ODDS amounts
                Object.entries(exCal[0].ODDS).forEach(([key, value], index) => {
                    exCal[0].ODDS[key] = {
                        ...value,
                        amounts: res?.[index]?.data || '' // Safely access res data or default to 0
                    };
                });

                const extraData = res[4].data;

                // Loop over entries from the sliced array (indices 1 to 4)
                exCal.slice(1, 5).forEach((value1, key1) => {
                    // Correct index offset
                    let kk = key1 + 1;

                    Object.entries(value1).forEach(([key2, value2]) => {


                        if (extraData.length === 0) {
                            // Reset amounts to 0 for each team in value2
                            Object.keys(value2).forEach(t => {
                                exCal[kk][key2][t] = {
                                    ...(exCal[kk][key2][t] || {}), // Ensure the target exists
                                    amounts: typeof exCal[kk][key2][t]?.amounts === 'object' && exCal[kk][key2][t].amounts !== null
                                        ? {back: '', lay: ''} // Reset amounts to 0 if it's an object
                                        : '' // Otherwise set amounts to 0
                                };
                            });

                        }
                        // Ensure 'Player 8' exists in value2 before accessing it
                        extraData.forEach((value3, key3) => {

                            let is_odd_even = false
                            let t = value3.team_name
                            if (t.endsWith(" Odd")) {
                                is_odd_even = 'odd'
                                t = t.split(" Odd")[0]
                            } else if (t.endsWith(" Even")) {
                                is_odd_even = 'even'
                                t = t.split(" Even")[0]
                            } else if (t.startsWith("Single")) {
                                t = t.split("Single ")[1]
                            }

                            if (!is_odd_even) {
                                if (value2[t]) {
                                    exCal[kk][key2] = {
                                        ...(exCal[kk][key2] || {}), // Ensure the target exists
                                        [t]: {
                                            ...value2[t],
                                            amounts: value3.total_amount || ''// Set amounts as needed
                                        }
                                    };
                                }
                            } else {
                                if (value2[t]) {
                                    exCal[kk][key2] = {
                                        ...(exCal[kk][key2] || {}), // Ensure the target exists
                                        [t]: {
                                            ...value2[t],
                                            amounts: is_odd_even === 'odd' ? {
                                                ...value2[t].amounts,
                                                back: value3.total_amount
                                            } : {...value2[t].amounts, lay: value3.total_amount} // Set amounts as needed
                                        }
                                    };
                                }
                            }
                        })
                    });
                });


                return exCal; // Return the updated state
            });

        }
        useEffect(() => {
            const updateOdds = () => {
                setTotalPlayers((prevState) => {
                    // Create a new state object based on the previous state
                    const updatedState = [...prevState];

                    data.sub.forEach((item, index) => {

                        const playerName = item.nat; // Adjust as needed to match your data structure

                        // Loop through the first two elements (ODDS and ODD_EVEN)
                        const oddValue = updatedState[0].ODDS[playerName]; // Access the ODD_EVEN for the player
                        if (oddValue) {
                            updatedState[0].ODDS[playerName] = {
                                ...oddValue,
                                odds: {back: item?.b || 0, lay: item?.l || 0}, // Update odds if needed
                                status: item.gstatus === 'OPEN' ? '' : 'suspended-box', // Update based on suspend status
                            };
                        }


                        if (playerName.endsWith('Odd')) {
                            let playerNames = playerName.split(" Odd");
                            const oddEvenValue = updatedState[1].ODD_EVEN[playerNames[0]]; // Access the ODD_EVEN for the player

                            updatedState[1].ODD_EVEN[playerNames[0]] = {
                                ...oddEvenValue,
                                odds: {back: item?.b || 0, lay: item?.l || 0}, // Update odds if needed
                                status: item.gstatus === 'OPEN' ? '' : 'suspended-box', // Update based on suspend status
                            };
                        }

                        if (playerName.endsWith('Even')) {
                            let playerNames = playerName.split(" Even");
                            const oddEvenValue = updatedState[1].ODD_EVEN[playerNames[0]]; // Access the ODD_EVEN for the player

                            updatedState[1].ODD_EVEN[playerNames[0]] = {
                                ...oddEvenValue,
                                odds: {...oddEvenValue.odds, lay: item?.b || 0}, // Update odds if needed
                                status: item.gstatus === 'OPEN' ? '' : 'suspended-box', // Update based on suspend status
                            };
                        }


                        if ([12, 13, 26].includes(index)) {
                            if (updatedState[2].BOOKMAKER[item.nat]) {
                                const bookmakervalue = updatedState[2].BOOKMAKER[item.nat]

                                updatedState[2].BOOKMAKER[item.nat] = {
                                    ...bookmakervalue,
                                    odds: {back: item?.b, lay: item?.l || 0}, // Update odds if needed
                                    status: item.gstatus === 'OPEN' ? '' : 'suspended-box', // Update based on suspend status
                                };
                            }


                        }

                        if ([24, 25].includes(index)) {
                            if (updatedState[3].TOTAL[item.nat]) {
                                const Totalvalue = updatedState[3].TOTAL[item.nat]

                                updatedState[3].TOTAL[item.nat] = {
                                    ...Totalvalue,
                                    odds: {back: item?.b, lay: item?.l || 0}, // Update odds if needed
                                    status: item.gstatus === 'OPEN' ? '' : 'suspended-box', // Update based on suspend status
                                };
                            }


                        }
                        const start = 14;
                        const end = 23;

                        const rangeArray = Array.from({length: end - start + 1}, (_, i) => start + i);
                        if (rangeArray.includes(index)) {
                            const singleNat = item.nat.split("Single ");

                            if (updatedState[4].SINGLE[singleNat[1]]) {
                                const SINGLEVALUE = updatedState[4].SINGLE[singleNat[1]]

                                updatedState[4].SINGLE[singleNat[1]] = {
                                    ...SINGLEVALUE,
                                    odds: {back: item?.b, lay: item?.l || 0}, // Update odds if needed
                                    status: item.gstatus === 'OPEN' ? '' : 'suspended-box', // Update based on suspend status
                                };
                            }


                        }
                    });


                    return updatedState; // Return the updated state
                });
            };


            if (data?.sub) {
                updateOdds()

            }

            if (data.card) {

                updateCardsForCard32Casino(data, totalPlayers, setTotalPlayers, 0, 'ODDS')
            }
            remark.current = data.remark || 'Welcome';


        }, [data]);

        const exposure = localStorage.getItem('exposure');
        const sportLength = Object.keys(data).length;


        useEffect(() => {
            if (data?.sub && sportList?.id) {


                getExByTeamName();

            }


        }, [exposure, sportLength, roundId]);


        const openPopup = (isBakOrLay, teamnam, oddvalue, betType = 'ODDS') => {
            setBetType(betType)


            if (parseFloat(oddvalue) > 0) {
                roundIdSaved.current = roundId
                setbackOrLay(isBakOrLay)
                setPopupDisplayForDesktop(true);
                teamname.current = teamnam
                setOdds(oddvalue)
            } else {
                Notify("Odds Value Change Bet Not Confirm", null, null, 'danger')

            }


        }
        const casinoBetDataNew = (new_odds) => {
            stakeValue.current = new_odds
            if (backOrLay === 'back') {


                loss.current = stakeValue.current;


                profit.current = profitData.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)

            } else {

                profit.current = profitData.current = stakeValue.current;


                loss.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)
            }


        }


        const placeBet = () => {

            setHideLoading(false)
            const bb = betType.startsWith("ODD_EVEN") === true ? "ODD_EVEN" : betType;
            const t =
                teamname.current.endsWith(" Odd")
                    ? teamname.current.split(" Odd")[0]
                    : teamname.current.endsWith(" Even")
                        ? teamname.current.split(" Even")[0]
                        : teamname.current.startsWith("Single")
                            ? teamname.current.split("Single ")[1]
                            : teamname.current;


            const indexPlayer = totalPlayers.findIndex(item => item[bb])


            if (totalPlayers[indexPlayer][bb][t].status === 'suspended-box') {
                Notify(`Bet Not Confirmed. Reason: Game Suspended`, null, null, 'danger');
                setPopupDisplayForDesktop(false);
                setHideLoading(true);

                return;
            }

            if (roundIdSaved.current !== roundId) {
                Notify("Bet Not Confirm Reason Game Change", null, null, 'danger')
                setPopupDisplayForDesktop(false)
                return;
            }


            setSubmitButtonDisable(true)

            const postdata = {

                "sportId": sportList.id,
                "matchId": roundId,
                "isback": backOrLay === 'back' ? 1 : 0,
                "placeName": teamname.current,
                "placeName2": null,
                "odds": odds,
                "oddsk": 0,
                "profit": parseFloat(profit.current).toFixed(2),
                "loss": parseFloat(loss.current).toFixed(2),
                "betType": betType,
                "bet_side": backOrLay.toUpperCase(),
                "betAmount": parseFloat(stakeValue.current),
                "type": "CARD32EU",
                "matchType": "CARD32EU".toLowerCase(),

            }

            axiosFetch('casino/store', 'post', null, postdata)
                .then((res) => {
                    if (res.data.status === true) {

                        resetBetFields(profitData, stakeValue)
                        getExByTeamName()
                        Notify("Bet Placed Successfully", null, null, 'success')
                    } else {

                        resetBetFields(profitData, stakeValue)
                        Notify(res.data.msg, null, null, 'danger')
                    }
                    setHideLoading(true)
                    setSubmitButtonDisable(false)
                    setPopupDisplayForDesktop(false)
                })


        }

        return (
            <CasinoLayout ruleImage={ruleImage} ruleDescription={ruleDescription} hideLoading={hideLoading}
                          isBack={backOrLay} teamname={teamname} handleStakeChange={casinoBetDataNew} odds={odds}
                          stakeValue={stakeValue} setOdds={setOdds} placeBet={placeBet}
                          submitButtonDisable={submitButtonDisable} data={data} roundId={roundId} setRoundId={setRoundId}
                          sportList={sportList}
                          setSportList={setSportList} setData={setData} setLastResult={setLastResult}>
                <div className="video-container" style={{minHeight: '0px'}}>

                    <div className="video-overlay" style={{top: '-300px'}}>
                        {Object.entries(totalPlayers[0].ODDS).map(([key, value]) => (
                            <div key={key}>
                                {value.card_number !== null && (
                                    <p className="mb-0">
                                        <b>
                                            <span className="text-white">{key} : <span className="text-warning"> {value.card_number}</span> </span>
                                            <span className="text-warning"></span>
                                        </b>
                                    </p>
                                )}
                                <div>

                                    {value?.cards.map((v, l) => (
                                        <img src={v} key={l}/>
                                    ))}

                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="casino-container casino-32A">

                    <div className="table-responsive mb-1">
                        <div className="card-content m-t-0">
                            <div className="row row5">
                                {totalPlayers.slice(0, 4).map((value, index) => (

                                    <div className="table-responsive col-md-6 main-market m-b-10" key={index}>
                                        <div className="live-poker">
                                            <table className="table coupon-table table table-bordered">
                                                <thead>
                                                <tr>
                                                    <th className="w-6">
                                                        <div className="info-block">
                                                            <span
                                                                data-toggle="collapse"
                                                                data-target={`#min-max-info${index + 1}`}
                                                                aria-expanded="false"
                                                                className="info-icon collapsed"
                                                            >
                                                                <i className="fas fa-info-circle m-l-10"></i>
                                                            </span>
                                                            <div
                                                                id={`min-max-info${index + 1}`}
                                                                className="min-max-info collapse"
                                                            >
                        <span className="m-r-5">
                          <b>Min:</b>100
                        </span>
                                                                <span className="m-r-5">
                          <b>Max:</b>100000
                        </span>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    {index <= 2 && (
                                                        <>
                                                            <th className="back w-2">
                                                                {index === 1 ? "Odd" : "BACK"}
                                                            </th>
                                                            <th className={index === 1 ? "back w-2" : "lay w-2"}>
                                                                {index === 1 ? "Even" : "LAY"}
                                                            </th>
                                                        </>
                                                    )}
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {Object.entries(value).flatMap(([key, value]) =>
                                                    Object.entries(value).map(([player, playerValue]) => {
                                                        const type = index === 0
                                                            ? "ODDS"
                                                            : index === 1
                                                                ? `ODD_EVEN_${player.split("Player ")[1]}`
                                                                : "BOOKMAKER";

                                                        return (
                                                            <tr key={player} className={`bet-info`}>
                                                                <td className="w-6">
                                                                    <p className="m-b-0">
                                                                        <b>{player}</b>
                                                                    </p>
                                                                    <div>
                                                                        <div className="ubook">
                                                                            <b className="text-success">
                                                                                {getExByColor(playerValue.amounts)}
                                                                            </b>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td
                                                                    className={`${index <=2 ? 'w-2' : 'w-4'} ${index === 1 ? "back" : "lay"} ${playerValue.status}`}
                                                                    onClick={() =>
                                                                        openPopup(
                                                                            index === 1 ? "back" : "lay",
                                                                            index === 1 ? `${player} Even` : player,
                                                                            playerValue.odds.lay,
                                                                            type
                                                                        )
                                                                    }
                                                                >
                                                                    <button className="back">
                                                                        <span className="odd">{playerValue.odds.back}</span>
                                                                        <div>
                                                                            <div className="ubook text-danger">
                                                                                <b>{getExByColor(playerValue.amounts.back)}</b>
                                                                            </div>
                                                                        </div>
                                                                    </button>
                                                                </td>
                                                                {index <= 2 && (
                                                                    <td
                                                                        className={`w-2 ${index === 1 ? "back" : "lay"} ${playerValue.status}`}
                                                                        onClick={() =>
                                                                            openPopup(
                                                                                index === 1 ? "back" : "lay",
                                                                                index === 1 ? `${player} Odd` : player,
                                                                                playerValue.odds.lay,
                                                                                type
                                                                            )
                                                                        }
                                                                    >
                                                                        <button className={index === 1 ? 'back' : 'lay'}>
                                                                            <span
                                                                                className="odd">{playerValue.odds.lay}</span>
                                                                            <div>
                                                                                <div className="ubook text-danger">
                                                                                    <b>{getExByColor(playerValue.amounts.lay)}</b>
                                                                                </div>
                                                                            </div>
                                                                        </button>
                                                                    </td>
                                                                )}
                                                            </tr>
                                                        );
                                                    })
                                                )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>


                    <div className="table-responsive mb-2 casino-32-worli">
                        <table className="table table-bordered mb-0">
                            <thead>
                            <tr>
                                <th colSpan="5" className="box-10 text-center">
                                    {totalPlayers[4].SINGLE[0].odds.back}

                                </th>
                            </tr>
                            </thead>
                            <tbody>

                            {chunkArray(
                                Object.entries(totalPlayers[4].SINGLE).sort((a, b) => {
                                    // Custom sort to ensure '0' is last
                                    if (a[0] === "0") return 1; // Move '0' to end
                                    if (b[0] === "0") return -1; // Move '0' to end
                                    return parseInt(a[0]) - parseInt(b[0]); // Sort numerically
                                }),
                                5
                            ).map((chunk, chunkIndex) => {

                                return (
                                    <tr key={chunkIndex}>
                                        {chunk.map(([key1, value]) => {

                                            return (
                                                <td key={key1} className={`box-2 back text-center ${totalPlayers[4].SINGLE[0].status}`}
                                                    onClick={() => openPopup('back', "Single " + key1, totalPlayers[4].SINGLE[0].odds.back, 'SINGLE')}>
                                                    <span className="casino-font">{key1}</span>
                                                    <p className="mb-0">{getExByColor(value.amounts)}</p>
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}


                            </tbody>
                        </table>
                    </div>

                    <marquee scrollamount="3" className="casino-remark m-b-10">{remark.current}</marquee>
                    <div className="casino-last-result-title">
                        <span>Last Result</span>
                    </div>
                    <div className="last-result-container text-right mt-1">
                        <CasinoLastResult sportList={sportList} lastResults={lastResult} data={data}/>
                    </div>
                </div>


            </CasinoLayout>
        );

    }
;


export default Card32eu;
