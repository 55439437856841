function FancyBetPopup ({fancyData,setFancyData})
{

   return( <div id="matchSessionBetShow" className={`modal ${fancyData !== null ? "show" : ""} fade`} role="dialog" aria-modal="true" style={{display : fancyData !== null ? "block" : ""}}>
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="popup_form">
                    <div className="title_popup modal-header">
                        <span>Fancy : <span className="fancyTitleName"></span></span>
                        <button type="button" className="close" onClick={() => setFancyData(null)}
                                data-dismiss="modal">
                            <div className="close_new"><i className="fas fa-times-circle"></i></div>
                        </button>
                    </div>
                    <div className="content_popup modal-body" style={{padding: '0px'}}>
                        <div className="popup_form_row">
                            <div className="modal-body" style={{padding: '10px 20px'}}>
                                <div className="row listFancyDataHere">

                                    <div dangerouslySetInnerHTML={{__html: fancyData}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   )
}
export default FancyBetPopup