import React, {useCallback, useContext, useEffect, useRef} from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import {CasinoContext} from "../Context/CasinoContext";

function CasinoReconnectModalPopup({ setShowCasinoReconnectModal, setSocketDisconnectModal, show}) {
    const idleTimeoutRef = useRef(null); // Use ref instead of state
    const IDLE_TIME_LIMIT = 1000000; // 1 minute of inactivity
    const {casino_socket} = useContext(CasinoContext)

    // Function to close the modal and reset the idle timer
    const handleReconnect = () => {
        setShowCasinoReconnectModal(false);
        setSocketDisconnectModal(false);
        casino_socket.connect();
        resetIdleTimer();
    };

    // Function to reset the idle timer
    const resetIdleTimer = useCallback(() => {
        if (idleTimeoutRef.current) {
            clearTimeout(idleTimeoutRef.current);
        }
        // Set a new timer
        idleTimeoutRef.current = setTimeout(() => {
            setSocketDisconnectModal(true);
            setShowCasinoReconnectModal(true);
        }, IDLE_TIME_LIMIT);
    }, [setSocketDisconnectModal, setShowCasinoReconnectModal]);

    // Function to detect activity and reset timer
    const handleActivity = useCallback(() => {
        resetIdleTimer();
    }, [resetIdleTimer]);

    // Set up event listeners for mouse and keyboard activity
    useEffect(() => {
        // Reset the timer when the user interacts with the page
        window.addEventListener('mousemove', handleActivity);
        window.addEventListener('keydown', handleActivity);

        // Set an initial idle timer
        resetIdleTimer();

        // Cleanup event listeners on component unmount
        return () => {
            window.removeEventListener('mousemove', handleActivity);
            window.removeEventListener('keydown', handleActivity);
            if (idleTimeoutRef.current) clearTimeout(idleTimeoutRef.current);
        };
    }, [handleActivity, resetIdleTimer]);

    return (
        <Modal
            show={show}

            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Body className="p-0">
                <div className="disconnected-message">
                    <div className="text-center">
                        <FontAwesomeIcon height="200px" icon={faExclamationTriangle} className="me-2 fw-bold text-warning" />
                        <b>Disconnection due to inactivity</b>
                    </div>
                    <div className="mt-3 text-center">
                        Are you there? You have been disconnected. Please go back to home or start playing again.
                    </div>
                    <div className="disconnected-buttons mt-3 text-center">
                        <Button variant="outline-primary" onClick={handleReconnect}>
                            Reconnect
                        </Button>
                        <Button variant="outline-danger" href="/">
                            Home
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default CasinoReconnectModalPopup;
