import React, {useEffect, useRef} from 'react'
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown'
import '@leenguyen/react-flip-clock-countdown/dist/index.css';
import '../../css/countdown.css';
import {isMobile} from 'react-device-detect';

function TimerComponent({data}) {
    const desktopStyle = {height: "70px", marginRight: "05px", marginTop: "-80px"}
    const mobileStyle = {height: "30px", width: "20px", fontSize: "20px", marginRight: "05px", fontWeight: "bold"}

    const futureTime = useRef(new Date().getTime());
    const length = Object.entries(data).length;

    useEffect(() => {

        if (length > 0 && data.lt > 0) {
            futureTime.current = new Date().getTime() + data.lt * 1000
        }
    }, [length, data.lt]);

    // digitBlockStyle={{backgroundColor: "white", color: "black", height: "70px"}}
    return <FlipClockCountdown
        digitBlockStyle={isMobile ? mobileStyle : desktopStyle}
        showSeparators={false} showLabels={false} className="float-right"
        hideOnComplete={false}

        style={{marginTop: "-75px"}} to={futureTime.current}/>;
}

export default TimerComponent