import {
    getExByColor,
    getSize,
    handleShowRules,
    organiseOdds,
    generateBackAndLayFunction,
} from "../../../Common_Functions/Constants";
import React, {useContext, useEffect, useMemo} from "react";
import {SportsContext} from "../../../Components/Context/SportsContext";


function MatchOdds({
                       ar_sectionData,
                       sportList,
                       allTeamName = {},
                       oddsChange,
                       setBetOddValue,
                       setbackOrLay,
                       teamNames,
                       teamNameCurrentBets = {},
                       setPopupDisplay,
                       setDefaultTeamName,
                       oddsTeamData,
                       setMaxValue = null,
                       setMinValue = null
                   }) {
    const {runnerRowDefault, rootClassDefault, setBetType,setBetTypeFromArray} = useContext(SportsContext);

    // Memoize maxValue and mainValue to avoid recalculating
    const maxValue = useMemo(() => ar_sectionData?.['match_odds']?.['maxb'], [ar_sectionData]);
    const mainValue = useMemo(() => ar_sectionData?.['match_odds'], [ar_sectionData]);



    useEffect(() => {
        if (setMaxValue !== null) {
            setMaxValue((prevState) => {
                return {...prevState, 'ODDS': maxValue}
            })
        }

        if (setMinValue !== null) {
            setMinValue((prevState) => {
                return {...prevState, 'ODDS': ar_sectionData?.['match_odds']?.['min'] ?? 100}
            })
        }
        //eslint-disable-next-line
    }, [maxValue]);


    // Return null if there is no match_odds data
    if (!ar_sectionData?.['match_odds']) return null;
    teamNames.current['ODDS'] = [];
    if (!allTeamName.current['ODDS']) {
        allTeamName.current['ODDS'] = [];


    }
    let debouncers = {};
    const updateOdds = (backlay, odds) => {
        if (oddsChange.current[backlay] !== odds) {
            // Clear the previous setTimeout
            if (debouncers[backlay]) {
                clearTimeout(debouncers[backlay]);
            }

            debouncers[backlay] = setTimeout(() => {
                oddsChange.current[backlay] = odds;
                delete debouncers[backlay]; // clear debouncer after execution

            }, 100);
        }
    }

    return (

        <>

            <div className="market-title">
                MATCH_ODDS
                <p className="float-right mb-0" onClick={() => handleShowRules('Matchrule')}>
                    <i className="fas fa-info-circle"></i>
                </p>
            </div>
            <div className="main-market">
                <div className="table-header">
                    <div className="float-left country-name box-6 min-max">
                        <b>Max: {getSize(maxValue)}</b>
                    </div>
                    <div className="back box-1 float-left text-center"><b>Back</b></div>
                    <div className="lay box-1 float-left text-center"><b>Lay</b></div>
                </div>
                <div className="table-body ODDS">
                    {mainValue?.['section']?.map((oddsArr, key) => {
                        const teamName = oddsArr.nat.trim() || "";
                        teamNames.current['ODDS'][teamName] = teamName;

                        if (!allTeamName.current['ODDS'].includes(teamName))
                            allTeamName.current['ODDS'].push(teamName);

                        // Determine if the odds are suspended
                        let isSuspended = "", isSuspendedClass = "";
                        let tot = mainValue.status === "OPEN" ? 1 : 0;

                        if (sportList.match_suspend_odd === 1 || sportList.match_suspend === 1) {
                            tot = 0;
                        }

                        if (tot === 0) {
                            isSuspended = "SUSPENDED";
                            isSuspendedClass = "suspended";
                        }

                        const runnerRow = `table-row-${sportList.match_id}-${key}`;
                        const teamEx = oddsTeamData?.[teamName];


                        // Organize odds only if they exist
                        if (oddsArr.odds?.length > 0) {
                            oddsArr = organiseOdds(oddsArr);
                        }

                        // Render back odds
                        const backOdds = oddsArr.back.slice(0, 1).map((back, b_key) => {
                            back.odds = tot === 0 ? '-' : back.odds;
                            back.size = tot === 0 ? '' : back.size;

                            const backFunction = generateBackAndLayFunction(
                                tot, oddsArr, 'back', teamName, runnerRow, 0, 'ODDS',
                                setBetOddValue, setbackOrLay, teamNames, setPopupDisplay,
                                setDefaultTeamName, runnerRowDefault, rootClassDefault, setBetType, null
                                , setBetTypeFromArray, 'match_odds'
                            );

                            if(oddsChange.current[`oddsback${key}${b_key}`] !== back.odds){
                                updateOdds(`oddsback${key}${b_key}`, back.odds)

                            }

                            return (
                                <div

                                    className={`box-1 back float-left text-center ${oddsChange.current[`oddsback${key}${b_key}`] !== back.odds && oddsChange.current[`oddsback${key}${b_key}`] !== undefined ? 'color-yellow-bet' : ''}`}
                                    onClick={backFunction[b_key]}
                                    key={b_key}
                                >
                                    <span className="odd d-block">{back.odds || "-"}</span>
                                    <span className="d-block">{getSize(back.size) || ""}</span>
                                </div>
                            );
                        });

                        // Render lay odds
                        const layOdds = oddsArr.lay.slice(0, 1).map((lay, l_key) => {
                            lay.odds = tot === 0 ? '-' : lay.odds;
                            lay.size = tot === 0 ? '' : lay.size;

                            if(oddsChange.current[`oddslay${key}${l_key}`] !== lay.odds){
                                updateOdds(`oddslay${key}${l_key}`, lay.odds)

                            }



                            const layFunction = generateBackAndLayFunction(
                                tot, oddsArr, 'lay', teamName, runnerRow, 0, 'ODDS',
                                setBetOddValue, setbackOrLay, teamNames, setPopupDisplay,
                                setDefaultTeamName, runnerRowDefault, rootClassDefault, setBetType,null
                                , setBetTypeFromArray, 'match_odds'
                            );

                            return (
                                <div

                                    className={`box-1 lay float-left text-center ${oddsChange.current[`oddslay${key}${l_key}`] !== lay.odds && oddsChange.current[`oddslay${key}${l_key}`] !== undefined ? 'color-yellow-bet' : ''}`}
                                    onClick={layFunction[l_key]}
                                    key={l_key}
                                >
                                    <span className="odd d-block">{lay.odds || "-"}</span>
                                    <span className="d-block">{getSize(lay.size) || ""}</span>
                                </div>
                            );
                        });
                        if (!teamNameCurrentBets.current?.['ODDS']) {
                            teamNameCurrentBets.current['ODDS'] = [];
                            teamNameCurrentBets.current['ODDS'][teamName] = ''
                        }
                        teamNameCurrentBets.current['ODDS'][teamName] = teamEx
                        return (
                            <div
                                data-title={isSuspended}
                                className={`table-row ${runnerRow} ${isSuspendedClass}`}
                                key={key}
                            >
                                <div className="float-left country-name box-4">
                                    <span className="team-name"><b>{teamName}</b></span>
                                    <p>
                                        <span className={`teamEx float-left`}>
                                            {getExByColor(teamEx, true)}
                                        </span>
                                    </p>
                                </div>
                                {backOdds}
                                {layOdds}
                                {oddsArr.lay.length === 1 && (
                                    <>
                                        <div className="box-1 lay1 lay-1 float-left hidden-portrait text-center">
                                            <span className="odd d-block">-</span>
                                            <span className="d-block">&nbsp;</span>
                                        </div>
                                        <div className="box-1 lay2 lay-2 float-left hidden-portrait text-center">
                                            <span className="odd d-block">-</span>
                                            <span className="d-block">&nbsp;</span>
                                        </div>
                                    </>
                                )}
                            </div>
                        );
                    })}
                    {mainValue?.rem && (
                        <div className="market-row">
                            <marquee className="market-remark">{mainValue.rem}</marquee>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default MatchOdds;
