import React, {useContext} from 'react';
import {
    handleShowRules,
    getSize,
    organiseOdds,
    generateBackAndLayFunction, getExByColor
} from "../../../Common_Functions/Constants";
import {SportsContext} from "../../../Components/Context/SportsContext";

const TiedMatch = ({
                       gameData,
                       model,
                       tiedMatchData,
                       allTeamName = [],
                       teamNameArr,
                       teamNameCurrentBets = {},
                       setBetOddValue,
                       setbackOrLay,
                       teamNames,
                       setPopupDisplay,
                       setDefaultTeamName
                   }) => {

    const {runnerRowDefault, rootClassDefault, setBetType,setBetTypeFromArray} = useContext(SportsContext);


    if (!gameData || !gameData['tied match']) {
        return null;
    }


    return gameData['tied match'] && (() => {

        let mainValue = gameData['tied match'];
        let mainKey = 0;
        let remark = mainValue['rem'] || '';
        let minValue = mainValue['min'] || model.odd_min_limit;
        let maxValue = mainValue['max'] || model.odd_max_limit;
        minValue = getSize(minValue, true);
        maxValue = getSize(maxValue, true);
        teamNames.current['TIED_MATCH'] = [];

        const arSectionData = mainValue['section'];


        return (
            <div key={mainKey}>
                <div className="market-title">
                    {mainValue['mname']}
                    <p className="float-right mb-0" onClick={() => handleShowRules('Matchrule')}><i
                        className="fas fa-info-circle"></i></p>
                </div>

                <div className="main-market">
                    <div className="table-header">
                        <div className="float-left country-name box-6 min-max">
                            <b>Min: {minValue} Max: {maxValue}</b>
                        </div>
                        <div className="back box-1 float-left text-center"><b>Back</b></div>
                        <div className="lay box-1 float-left text-center"><b>Lay</b></div>
                    </div>
                    <div className="table-body TIED_MATCH">
                        {arSectionData.map((oddsArr, key) => {

                            let teamName = oddsArr['nat']?.trim() || '';

                            teamNames.current['TIED_MATCH'][teamName] = oddsArr['nat'];


                            // const teamEx = getExByTeamNameForCricket(auth().user.id, model.id, teamName, "TIED_MATCH");
                            const runnerRow = `table-row-${model.match_id}-${key}tied`;

                            const tot = (oddsArr['gstatus'] !== 'ACTIVE' || model.match_suspend_odd || model.match_suspend) ? 0 : 1;

                            let isSuspended = '';
                            let isSuspendedClass = '';
                            if (tot === 0) {
                                isSuspended = 'SUSPENDED';
                                isSuspendedClass = 'suspended';
                            }


                            if (oddsArr.odds && oddsArr.odds.length > 0) {
                                oddsArr = organiseOdds(oddsArr)

                            }

                            const n_key = model.match_id + "-" + key + "-" + (oddsArr['back']?.length - 1);


                            const teamEx = tiedMatchData?.[teamName];
                            if (!teamNameCurrentBets.current['TIED_MATCH']) {
                                teamNameCurrentBets.current['TIED_MATCH'] = [];
                                teamNameCurrentBets.current['TIED_MATCH'][teamName] = ''
                            }
                            if (!allTeamName.current['TIED_MATCH']) {
                                allTeamName.current['TIED_MATCH'] = [];


                            }
                            if (!allTeamName.current['TIED_MATCH'].includes(teamName))
                                allTeamName.current['TIED_MATCH'].push(teamName);

                            teamNameCurrentBets.current['TIED_MATCH'][teamName] = teamEx

                            return (

                                <div key={key} data-title={isSuspended}
                                     className={`table-row ${runnerRow} ${isSuspendedClass}`}>
                                    <div className="float-left country-name box-4">
                                        <span className="team-name"><b>{teamName}</b></span>
                                        <p><span className="teamEx float-left">{getExByColor(teamEx, true)}</span></p>
                                    </div>

                                    {/* Back Odds */}
                                    {oddsArr.back !== 'undefined' && oddsArr?.back?.slice(0, 1).reverse().map((back, b_key) => {
                                        const backClass = b_key === 0 ? 'back' : `back${b_key}`;
                                        const value_price = back?.odds ? parseFloat(back.odds) : "-";
                                        const value_size = back?.size ? getSize(parseFloat(back.size), false) : "";
                                        const backFunction = generateBackAndLayFunction(tot, oddsArr, 'back', teamName, runnerRow, n_key, 'TIED_MATCH', setBetOddValue, setbackOrLay, teamNames, setPopupDisplay, setDefaultTeamName, runnerRowDefault, rootClassDefault, setBetType, null, setBetTypeFromArray, 'tied match');
                                        return (
                                            <div key={b_key} className={`box-1 ${backClass} float-left text-center`}
                                                 onClick={backFunction[b_key]}>
                                                <button>
                                                    <span className="odd d-block">{value_price}</span>
                                                    <span className="d-block">{value_size}</span>
                                                </button>
                                            </div>
                                        );
                                    })}

                                    {/* Lay Odds */}
                                    {oddsArr.lay !== 'undefined' && oddsArr?.lay?.slice(0, 1).map((lay, l_key) => {
                                        const layClass = l_key === 0 ? 'lay' : `lay${l_key}`;
                                        const value_price = lay?.odds ? parseFloat(lay.odds) : "-";
                                        const value_size = lay?.size ? getSize(parseFloat(lay.size), false) : "";
                                        const n_key = model.match_id + "-" + key + "-" + (oddsArr['lay']?.length - 1);
                                        const layFunction = generateBackAndLayFunction(tot, oddsArr, 'lay', teamName, runnerRow, n_key, 'TIED_MATCH', setBetOddValue, setbackOrLay, teamNames, setPopupDisplay, setDefaultTeamName, runnerRowDefault, rootClassDefault, setBetType, null, setBetTypeFromArray, 'tied match');
                                        return (
                                            <div key={l_key} className={`box-1 ${layClass} float-left text-center`}
                                                 onClick={layFunction[l_key]}>
                                                <button>
                                                    <span className="odd d-block">{value_price}</span>
                                                    <span className="d-block">{value_size}</span>
                                                </button>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}

                        {remark && (
                            <div className="market-row">
                                {/* eslint-disable-next-line jsx-a11y/no-distracting-elements */}
                                <marquee className="market-remark">{remark}</marquee>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    })();
};

export default TiedMatch;
