import axios from 'axios';
import {useEffect, useState} from "react";


function axiosFetch(url, method, setList = null, data = {}, params = {}) {

    const token = localStorage.getItem('token');
    return axios({
        method: method,
        url: process.env.REACT_APP_API_URL + url,
        headers: {
            'Authorization': `Bearer ${token}`,
        },
        params,
        data: data
    })
        .then(response => {
            if (setList !== null) {
                setList(response.data);
            }
            return response;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
}

export function organiseOdds(oddsArr) {
    let no = 0;
    oddsArr['back'] = [];
    oddsArr['lay'] = [];
    oddsArr.odds.forEach((a_value) => {
        if (a_value.otype && a_value.otype === 'back') {
            const tno = a_value.tno;
            oddsArr['back'][tno] = a_value;

        }
        if (a_value.otype && a_value.otype === 'lay') {

            oddsArr['lay'][no] = a_value;
            no++;
        }
    });
    return oddsArr;
}

export const getSize = (number = "", isConvert = true) => {
    if (number && number !== "-" && number !== "") {
        if (isConvert) {
            if (number >= 100000) {
                const res = number / 100000;
                return res % 1 !== 0 ? res.toFixed(2) + 'L' : Math.floor(res) + 'L';
            } else if (number >= 1000) {
                const res = number / 1000;
                return res % 1 !== 0 ? res.toFixed(2) + 'K' : Math.floor(res) + 'K';
            } else {
                return number;
            }
        }
    }
    return number;
};

export const getExByColor = (amount, returnZero = false) => {

    if (amount >= 0) {
        return <b><span className="teamEx" style={{color: 'green'}}>{amount}</span></b>;
    } else if (amount < 0) {
        return <b> <span className="teamEx" style={{color: 'red'}}>{amount}</span></b>;
    } else {
        return returnZero === false ? null : <b> <span className="teamEx" style={{color: 'red'}}>0</span></b>;
    }
};


export const handleShowRules = (rule) => {
    console.log("Showing rules for", rule);
};
export const mapSports = {3: 'soccer', 1: 'cricket', 2: 'tennis'};
export default axiosFetch;


function getOddValue(teamName, prices, sizes, rootClass, type, isback, betRowNo, setBetOddValue, setbackOrLay, teamNames, setPopupDisplay, defaultTeamName, oddsk = null, rootClassDefault, runnerRowDefault) {

    rootClassDefault.current = betRowNo
    runnerRowDefault.current = rootClass


    if (prices === 0) {
        // ClearAllSelection();
        // Notify("Odds Price zero not Allow", null, null, 'danger');
        return false;
    }
    setPopupDisplay(true);


    if (oddsk !== null) {

        oddsk.current = sizes;
    }
    setBetOddValue(prices)

    defaultTeamName.current = teamName

    setbackOrLay(isback === 1 ? 'back' : 'lay')


}

export function setdecimalPoint(profit) {

    if (profit === '' || isNaN(profit)) {
        return 0;
    }
    return parseFloat(profit).toFixed(2);
}

export const generateBackAndLayFunction = (tot, oddsArr, type, teamName, runnerRow, betrowno, bet_type = 'ODDS', setBetOddValue, setbackOrLay, teamNames, setPopupDisplay, defaultTeamName = null, runnerRowDefault, rootClassDefault, setBetType, oddsk = null, ...params) => {

    let functions = [];


    if (tot > 0 && oddsArr[type]) {


        oddsArr[type].forEach((odds, index) => {

            if (odds.odds && odds.size) {


                if (type === 'odd' || type === 'even') {
                    // console.log(oddsArr)
                }

                const onClickHandler = () => {

                    if (typeof params[0] === 'function' && params[1] !== undefined) {
                        params[0](params[1])
                    }

                    const back = (type === 'back' || type === 'odd' || type === 'even') ? 1 : 0;


                    setBetType(bet_type);


                    getOddValue(teamName, odds.odds, odds.size, runnerRow, bet_type, back, betrowno, setBetOddValue, setbackOrLay, teamNames, setPopupDisplay, defaultTeamName, oddsk, runnerRowDefault, rootClassDefault);
                };


                functions.push(onClickHandler);


            }
        });
    }


    return functions;
};

export const checkBetPlace = async (teamname, id) => {

    const datas = {teamname: teamname, id: id}
    return await axiosFetch('bet_check', 'post', null, datas);


}


export const showCricketSessionBook = async (fancyName, sportID, set = null) => {
    try {
        const datas = "fancyName=" + fancyName + "&sportID=" + sportID + "&userid=" + JSON.parse(localStorage.getItem("userData")).id;
        const response = await axiosFetch('getFancyList?' + datas, 'get');


        if (response.data.status === true) {
            if (set != null)
                set(response.data.htmlData);

        } else {
            alert('Something went wrong');
        }
    } catch (error) {
        console.error('Error fetching fancy list:', error);
        alert('Something went wrong');
    }
};

const checkFancyHide = async (id, sid) => {
    const datas = {id: id, sid: sid};
    return await axiosFetch('fancy_hide_check', 'post', null, datas);

};

export function useFancyHideStatus(sportList, data) {
    const [fancyHideStatus, setFancyHideStatus] = useState({});

    useEffect(() => {
        const fetchHideStatus = async () => {
            if (data && Array.isArray(data['normal']) && data['normal'].section) {
                const section = data['normal'].section;

                const hideStatuses = await Promise.all(section.map(async (oddsArr) => {
                    const status = await checkFancyHide(sportList.id, oddsArr.sid);
                    return {sid: oddsArr.sid, hide: status};
                }));

                const hideStatusMap = hideStatuses.reduce((acc, item) => {
                    acc[item.sid] = item.hide;
                    return acc;
                }, {});

                setFancyHideStatus(hideStatusMap);
            }
        };

        fetchHideStatus();
    }, [sportList.id, data]); // Trigger effect only when `sportList.id` or `data` changes

    return fancyHideStatus;
}

export async function getExByTeamNameForCricket(data, betType, bet_type, id, prevdata = {}, setFunction = null) {
    let promises = [];
    let arr = [];
    arr[betType] = {...prevdata};  // Initialize with prevdata to avoid overwriting

    const storageKey = `exByTeamName_${betType}_${id}`;


    data?.[bet_type]?.section.forEach((oddsArr) => {
        const teamName = oddsArr.nat.trim();


        // Initialize the team name in arr if not present
        if (!arr[betType][teamName]) {
            arr[betType][teamName] = prevdata?.[teamName] ?? 0;
        }

        let promise = axios.post(
            `${process.env.REACT_APP_API_URL}getExByTeamName`,
            {id: id, bet_type: betType, teamname: teamName},
            {headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}}
        );
        promises.push(promise);
    });

    try {
        const responses = await Promise.all(promises);


        responses.forEach(response => {

            arr[betType][response.data.nat.trim()] = response.data.result;  // Adjust keys as per actual response structure
        });


        // Save updated data in local storage if arr has entries
        if (Object.keys(arr[betType]).length > 0) {

            localStorage.setItem(storageKey, JSON.stringify(arr[betType]));
        }

        if (setFunction !== null) {
            return setFunction(arr[betType]);  // Return the updated object after all promises resolve
        }

    } catch (error) {

        return arr[betType];  // Return partial data if there's an error
    }
}


export function getExByTeamNameForCasino(model_id, roundId, player, match_id, betType = 'BOOKMAKER') {

    const data = {id: model_id, round_id: roundId, player: player, match_id: match_id, betType: betType};
    return axiosFetch('getExByTeamNameForCasino', 'post', null, data)

}

export function getExBySingleTeamNameCasino(model_id, roundId, player, match_id, betType = 'BOOKMAKER') {

    const data = {id: model_id, round_id: roundId, player: player, match_id: match_id, betType: betType};
    return axiosFetch('getExBySingleTeamNameCasino', 'post', null, data)

}

export const gameNames = {
    'cricket': 'Cricket',
    'soccer': 'Soccer',
    'tennis': 'Tennis',
    'cmeter1': '1 CARD METER',
    'dum10': 'DUS KA DUM',
    'teen3': 'INSTANT TEENPATTI',
    'race2': 'RACE TO 2ND',
    'aaa2': 'AMAR AKBAR ANTHONY 2',
    'ab3': 'ANDAR BAHAR 50 CARDS',
    'teen1': '1 CARD ONE-DAYS',
    'teen120': '1 CARD 20-20',
    'kbc': 'K.B.C',
    'teen2024': 'TEEN 20 24',
    'notenum': 'NOTE NUMBER',
    'trio': 'TRIO',
    'teen20b': '20-20 TEENPATTI B',
    'teenmuf': 'MUFLIS TEENPATTI',
    'race17': 'RACE TO 17',
    'teensin': '29CARD BACCARAT',
    'patti2': '2 CARDS TEENPATTI',
    'trap': 'THE TRAP',
    'teen': '1 Day Teen Patti',
    'superover': 'Super Over',
    'teen20': '20-20 Teenpatti',
    'teen9': 'Teenpatti Test',
    'teen8': 'Teenpatti Open',
    'teen6': 'TEENPATTI - 2.0',
    'cricketv3': '5 Cricket',
    'poker': 'Poker One Day',
    'poker20': 'Poker 20-20',
    'poker6': '6 player Poker',
    'baccarat': 'Baccarat',
    'baccarat2': 'Baccarat 2',
    'dt20': '20-20 Dragon Tiger',
    'dt6': '1 Day Dragon Tiger',
    'dtl20': '20-20 Dragon Tiger Lion',
    'dt202': '20-20 Dragon Tiger 2',
    'card32': '32 Cards A',
    'card32eu': '32 Cards B',
    'ab20': 'Andar Bahar',
    'abj': 'Andar bahar 2',
    'lucky7': 'Lucky 7-A',
    'lucky7eu': 'Lucky 7-B',
    'lucky7eu2': 'LUCKY 7-C',
    '3cardj': '3card judgement',
    'war': 'Casino War',
    'worli': 'Worli Matka',
    'worli2': 'Instant Worli',
    'aaa': 'Amar Akbar Anthony',
    'btable': 'Bollywood Casino',
    'lottcard': 'LOTTERY',
    'cmatch20': '20-20 Cricket Match',
    'cmeter': 'Casino Meter',
    'queen': 'Queen',
    'race20': 'Race 20-20',
    'vlucky7': 'V-Lucky 7',
    'vtrio': 'V-Trio',
    'vdtl20': 'V-20-20 D T L',
    'vteenmuf': 'V-Muflis Teenpatti',
    'vaaa': 'V-Amar Akbar Anthony',
    'vbtable': 'V-Bollywood Casino',
    'vdt6': 'V-1 Day Dragon Tiger',
    'vteen': 'V-Teenpatti 1-day',
    'vteen20': 'V-20-20 Teenpatti',
};


export function getPlayerCardAccordingToNumberOfPlayers(d_data, playerToGet = 1, totalNoOfPlayers = 2, pickExactCard = []) {
    let playerCards = [];

    if (pickExactCard.length === 0) {
        for (let i = 0; i < totalNoOfPlayers; i++) {
            let cardValue = i * totalNoOfPlayers + playerToGet - 1;
            playerCards.push(cardValue);
        }
    } else {
        playerCards = pickExactCard;
    }

    if (d_data.card && d_data.card.length > 0) {
        const arCard = Array.isArray(d_data.card)
            ? d_data.card
            : typeof d_data.card === 'string'
                ? d_data.card.split(',')
                : [];

        const playerA = arCard.map(path => `/img/casino/cards/${path}.png`);

        return playerCards.map(index => playerA[index]).filter(Boolean); // Return only valid paths
    }

    return [];
}

//TEEN8 CLASSIFY CARD FUNCTION FURTHER IMPLEMENTATION REFER DEVELOPERS
export const classifyCard = (card) => {
    let cardDetails = {
        card_no: '',
        card_for: '',
        card_icon: '',
        card_class: '',
        img_src: '',
    };

    if (card !== "1" && card) {
        let cardType = "";

        // Check for Spade (SS)
        if (card.includes("SS")) {
            cardType = "SS";
            cardDetails = {
                card_no: card.split("SS")[0],
                card_for: "SS",
                card_icon: "}",
                card_class: "card-black",
                img_src: `/img/casino/cards/${card}.png`,
            };
        }

        // Check for Heart (HH)
        if (card.includes("HH")) {
            cardType = "HH";
            cardDetails = {
                card_no: card.split("HH")[0],
                card_for: "HH",
                card_icon: "{",
                card_class: "card-red",
                img_src: `/img/casino/cards/${card}.png`,
            };
        }

        // Check for Club (CC)
        if (card.includes("CC")) {
            cardType = "CC";
            cardDetails = {
                card_no: card.split("CC")[0],
                card_for: "CC",
                card_icon: "]",
                card_class: "card-black",
                img_src: `/img/casino/cards/${card}.png`,
            };
        }

        // Check for Diamond (DD)
        if (card.includes("DD")) {
            cardType = "DD";
            cardDetails = {
                card_no: card.split("DD")[0],
                card_for: "DD",
                card_icon: "[",
                card_class: "card-red",
                img_src: `/img/casino/cards/${card}.png`,
            };
        }

        // Default image if card type isn't matched
        if (!cardType) {
            cardDetails.img_src = `/img/casino/cards/1.png`; // Default card image if no match
        }
    } else {
        // Handle if card is "1" (no card scenario)
        cardDetails.img_src = `/img/casino/cards/1.png`;
    }

    return cardDetails;
};

export const updatePlayerStats = (playerData, setPlayerBack = null, setPlayerLay = null, playerName, setPlayerStatuses, suspende_status = 'suspended') => {
    if (!playerData) return;
    let playerStatus = '';
    if (playerData.gstatus === "SUSPENDED") {
        playerStatus = suspende_status;


    }

    setPlayerStatuses(prev => ({...prev, [playerName]: playerStatus}));

    if (setPlayerBack !== null) {
        if (playerData.b) {
            setPlayerBack(playerData.b);
        } else {
            setPlayerBack(0);
        }
    }
    if (setPlayerLay !== null) {


        if (playerData.l) {
            setPlayerLay(playerData.l);
        } else {
            setPlayerLay(0);
        }
    }
};

export const resetBetFields = (profitdata, stakevalue) => {
    profitdata.current = 0
    stakevalue.current = 0


}

export function cardMap(index, increase = true) {

    if (increase)
        index += 1
    switch (index) {

        case 1:
            index = "A";
            break;

        case 11:
            index = "J";
            break;

        case 12:
            index = "Q";
            break;
        case 13:
            index = "K";
            break;

        default :

            break;

    }


    return index;

}


export function changeCardIndex(index) {


    switch (index) {

        case "A":
            index = 1;
            break;

        case "J":
            index = 11;
            break;

        case "Q":
            index = 12;
            break;
        case "K":
            index = 13;
            break;

        default :

            break;

    }


    return index;

}


export function cardMapInteger(index) {

    if (index.charAt(1) === '0') {
        index = 10;


    } else {
        index = index.charAt(0);
    }

    switch (index) {

        case 'A':
            index = 1;
            break;

        case 'J':
            index = 11;
            break;

        case "Q":
            index = 12;
            break;
        case "K":
            index = 13;
            break;

        default :

            break;

    }


    return index;

}

export const cardGenerate = () => {

    return Array.from({length: 13}, (_, index) => {
        index += 1
        return [index, '/img/card/' + index + ".jpg"]
    })
}


export const getExBySingleTeamLayBackCasino = (sportId, roundId, teamname, betType, type) => {

    const data = {id: sportId, round_id: roundId, teamname: teamname, bet_type: betType, type: type}
    return axiosFetch('getExBySingleTeamLayBackCasino', 'post', null, data);
}

export const cardPointsCount = (exclude = ["K"], cards) => {

    if (!cards && !Array.isArray(cards))
        return 0;

    return cards.reduce((accumulator, value) => {
        // Check if we should exclude this value

        const includevalue = value.charAt(0) + value.charAt(1)
        const newvalue = includevalue !== '10' ? value.charAt(0).toUpperCase() : includevalue
        if (exclude !== null && exclude.includes(newvalue) === true) {

            return accumulator; // Skip this value
        }

        // Parse and add the transformed value to the accumulator
        return accumulator + parseInt(changeCardIndex(includevalue !== '10' ? value.charAt(0).toUpperCase() : includevalue), 10) || 0; // Ensure adding 0 if NaN
    }, 0);

}

export const race17calculation = (round_id, team_name) => {
    const data = {roundId: round_id, team_name: team_name}

    return axiosFetch('calculate-bet-17', 'post', null, data)
}

export const chunkArray = (arr, size, debug = false) => {
    const half = Math.ceil(arr.length / size); // Calculate the midpoint, rounding up
    const chunk1 = arr.slice(0, half);
    const chunk2 = arr.slice(half);
    if (chunk2.length > 0)

        return [chunk1, chunk2];

    else
        return [chunk1];
}

export const dummyDataOdds = (array) => {
    const newArray = [];
    let type = null;

    if (array.length === 1) {


        for (let i = 0; i <= 1; i++) {
            type = array[0].otype;
            newArray.push({
                sid: '',
                psid: 0,
                odds: 0,
                otype: array[0].otype,
                oname: array[0].oname,
                size: 0
            });
        }
    }

    if (type === 'back') {
        return [...newArray, ...array]; // Merge newArray and array
    } else if (type === null) {
        return array; // Return the original array
    } else {
        return [...array, ...newArray]; // Merge array and newArray
    }
};

// Example usage


export const getStakeValue = async (setValue) => {
    const response = await axiosFetch('get_stake_value', 'get');
    setValue(response.data);

    return response.data


};