import CasinoLayout from "../Section/Casino/CasinoLayout";
import {useContext, useEffect, useRef, useState} from "react";

import {CasinoLastResult} from "../Section/Casino/CasinoLastResult";

import axiosFetch, {
    getExByColor,
    getExBySingleTeamNameCasino,

    getExByTeamNameForCasino, resetBetFields
} from "../../Common_Functions/Constants";
import {useParams} from "react-router-dom";
import {SportsContext} from "../../Components/Context/SportsContext";


import Notify from "../../js/Notify";

const Teen20b = () => {
    const [roundId, setRoundId] = useState('')

    const defaultValues = {odds: 0, status: "suspended-box", amounts: ""}
    const playerKeys = ['A', 'B'];

    const [totalPlayers, setTotalPlayers] = useState({

        "Player A": {...defaultValues, type: "WINNER"},
        "3 Baccarat A": {...defaultValues, type: "KHAL"},
        "Total A": {...defaultValues, type: "TOTAL"},

        "Pair Plus A": {...defaultValues, type: "PAIR"},
        "Black A": {...defaultValues, type: "RED_BLACK_A"},

        "Red A": {...defaultValues, type: "RED_BLACK_A"},

        "Player B": {...defaultValues, type: "WINNER"},
        "3 Baccarat B": {...defaultValues, type: "KHAL"},
        "Total B": {...defaultValues, type: "TOTAL"},
        "Pair Plus B": {...defaultValues, type: "PAIR"},
        "Black B": {...defaultValues, type: "RED_BLACK_B"},

        "Red B": {...defaultValues, type: "RED_BLACK_B"},


    })

    const [data, setData] = useState([]);
    const ruleImage = 'https://sitethemedata.com/casino-new-rules-images/teen20b.jpg'

    const roundIdSaved = useRef(null);

    const [submitButtonDisable, setSubmitButtonDisable] = useState(false)

    const [cards, setCards] = useState({});

    const stakeValue = useRef(0);
    const [odds, setOdds] = useState(0)

    const [backOrLay, setbackOrLay] = useState('back')
    const [sportList, setSportList] = useState({})
    const {match_id} = useParams();
    const {
        setBetType,
        betType,
        setPopupDisplayForDesktop,

    } = useContext(SportsContext)
    const [hideLoading, setHideLoading] = useState(true)
    const desc = `<div class="rule-section"><ul class="pl-2 pr-2 list-style">
                            <li>The game is played with a regular 52 cards single deck, between 2 players A and B.</li>
                            <li>Each player will receive 3 cards.</li>
                            <li><b>Rules of regular teenpatti winner</b></li>
                        </ul></div>`


    const remark = useRef('Welcome');
    const [lastResult, setLastResult] = useState({});
    const teamname = useRef('');
    const loss = useRef(0);
    const profit = useRef(0);
    const profitData = useRef(0);

    const updatePlayers = () => {
        setTotalPlayers((prevPlayer) => {

            const updatedPlayers = JSON.parse(JSON.stringify(prevPlayer))


            Object.entries(updatedPlayers).forEach(([index1, value1], i) => {


                const founddata = data.sub.find(item => item.nat === index1)
                if (founddata) {

                    updatedPlayers[index1].odds = founddata.b
                    updatedPlayers[index1].status = founddata.gstatus === 'OPEN' ? "" : 'suspended-box'
                }


            })


            return updatedPlayers
        })


    }

    const updateAmounts = async () => {
        const amountData = await getExBySingleTeamNameCasino(sportList.id, roundId, '', match_id, '')

        const teamAmounts = {}

        if (amountData.data && Array.isArray(amountData.data)) {
            amountData.data.forEach(value => {

                teamAmounts[value.team_name] = value.total_amount;

            })
        }


        setTotalPlayers((prevPlayer) => {

            const updatedPlayers = JSON.parse(JSON.stringify(prevPlayer))

            Object.entries(updatedPlayers).forEach(([index1, value1], i) => {


                if (teamAmounts[index1] !== undefined) {

                    updatedPlayers[index1].amounts = teamAmounts[index1]

                } else {
                    updatedPlayers[index1].amounts = ''
                }


            })


            return updatedPlayers
        })


    }


    useEffect(() => {


        if (data?.sub) {

            updatePlayers()


        }

        if (data.card) {
            const cardArray = data.card.split(",").map(item => item.trim());
            setCards({
                playerA: cardArray.slice(0, 3),
                playerB: cardArray.slice(3, 6),
            });
            remark.current = data.remark || 'Welcome';
        }
    }, [data]);

    const exposure = localStorage.getItem('exposure');
    const sportLength = Object.keys(data).length;


    useEffect(() => {

        if (data?.sub && sportList?.id) {
            updateAmounts()

        }
    }, [exposure, sportLength, roundId]);

    const openPopup = (isBakOrLay, teamnam, oddvalue, bet) => {
        setBetType(bet)


        if (parseFloat(oddvalue) >= 0) {
            roundIdSaved.current = roundId
            setbackOrLay(isBakOrLay)
            setPopupDisplayForDesktop(true);
            teamname.current = teamnam
            setOdds(oddvalue)
        } else {
            Notify("Odds Value Change Bet Not Confirm", null, null, 'danger')

        }


    }
    const casinoBetDataNew = (new_odds) => {
        stakeValue.current = new_odds
        if (backOrLay === 'back') {


            loss.current = stakeValue.current;


            profit.current = profitData.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)

        } else {

            profit.current = profitData.current = stakeValue.current;


            loss.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)
        }


    }

    const renderCards = (cards, player) => (
        <div className="flip-card-container">
            {cards?.map((card, index) => {
                const imgSrc = card ? `/img/casino/cards/${card}.png` : '/img/casino/cards/1.png';
                return (
                    <div className="flip-card" key={index}>
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={imgSrc} alt={`${player} card ${index + 1}`}/>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );

    const placeBet = () => {

        setHideLoading(false)
        if (totalPlayers['Player B'].status !== '') {
            Notify(`Bet Not Confirmed. Reason: Game Suspended`, null, null, 'danger');
            setPopupDisplayForDesktop(false)
            resetBetFields(profitData, stakeValue)
            setHideLoading(true);

            return;
        }

        if (roundIdSaved.current !== roundId) {
            Notify("Bet Not Confirm Reason Game Change", null, null, 'danger')
            setPopupDisplayForDesktop(false)
            resetBetFields(profitData, stakeValue)
            setHideLoading(true);
            return;
        }


        setSubmitButtonDisable(true)

        const postdata = {

            "sportId": sportList.id,
            "matchId": roundId,
            "isback": backOrLay === 'back' ? 1 : 0,
            "placeName": teamname.current,
            "placeName2": null,
            "odds": odds,
            "oddsk": 0,
            "profit": parseFloat(profit.current).toFixed(2),
            "loss": parseFloat(loss.current).toFixed(2),
            "betType": betType,
            "bet_side": backOrLay.toUpperCase(),
            "betAmount": parseFloat(stakeValue.current),
            "type": match_id.toUpperCase(),
            "matchType": match_id.toLowerCase(),

        }

        axiosFetch('casino/store', 'post', null, postdata)
            .then((res) => {
                if (res.data.status === true) {
                    updateAmounts()

                    resetBetFields(profitData, stakeValue)
                    Notify("Bet Placed Successfully", null, null, 'success')
                } else {

                    resetBetFields(profitData, stakeValue)
                    Notify(res.data.msg, null, null, 'danger')
                }
                setHideLoading(true)
                setSubmitButtonDisable(false)
                setPopupDisplayForDesktop(false)
            })


    }

    return (

        <CasinoLayout ruleImage={ruleImage} raceClass="teenpatti20" ruleDescription={desc} hideLoading={hideLoading} isBack={backOrLay}
                      teamname={teamname} handleStakeChange={casinoBetDataNew} odds={odds}
                      stakeValue={stakeValue} setOdds={setOdds} placeBet={placeBet}
                      submitButtonDisable={submitButtonDisable} data={data} roundId={roundId} setRoundId={setRoundId}
                      sportList={sportList}
                      setSportList={setSportList} setData={setData} setLastResult={setLastResult}>

            <div className="video-container" style={{minHeight: '0px'}}>
                <div className="video-overlay" style={{top: '-300px'}}>
                    <div className="casino-video-cards">
                        <div>
                            <h5>Player A</h5>
                            {renderCards(cards.playerA, "Player A")}
                        </div>
                        <div className="mt-1">
                            <h5>Player B</h5>
                            {renderCards(cards.playerB, "Player B")}
                        </div>
                    </div>
                </div>
            </div>

            <div className="casino-detail">
                <div className="casino-table">
                    <div className="casino-table-box">

                        <PlayerTable players={Object.entries(totalPlayers).slice(0, 6)} playerKeys="A"
                                     openPopup={openPopup}/>
                        <PlayerTable players={Object.entries(totalPlayers).slice(6, 12)} playerKeys="B"
                                     table="casino-table-right-box" openPopup={openPopup}/>
                    </div>
                    <div className="teenpatti20-other-oods d-md-flex">

                        <PlayerTable players={Object.entries(totalPlayers).slice(0, 6)} playerKeys="A"
                                     openPopup={openPopup} cards={true}/>
                        <PlayerTable players={Object.entries(totalPlayers).slice(6, 12)} playerKeys="B"
                                     table="casino-table-right-box" openPopup={openPopup} cards={true}/>
                    </div>

                </div>

                <marquee scrollamount="3" className="casino-remark m-b-10">{remark.current}</marquee>
                <div className="casino-last-result-title">
                    <span>Last Result</span>
                </div>
                <div className="casino-last-results">
                    <CasinoLastResult sportList={sportList} lastResults={lastResult} data={data}/>
                </div>
            </div>


        </CasinoLayout>
    );

};

const PlayerTable = ({table = 'casino-table-left-box', players, playerKeys, openPopup, cards = false}) => (

    <>
        {cards === false && (

            <div className={table}>
                <div className="casino-table-header">
                    <div className="casino-nation-detail">{`Player ${playerKeys}`}</div>
                </div>
                <div className="casino-table-body">
                    <div className="casino-table-row">
                        {players.slice(0, 4).map(([index, value1], i3) => (
                            <div className="casino-odds-box" key={i3}>{index}</div>
                        ))}

                    </div>
                    <div className="casino-table-row">
                        {players.slice(0, 4).map(([index1, value2], io) => (
                            <div className={`casino-odds-box back ${value2.status}`} key={io}
                                 onClick={() => openPopup('back', index1, value2.odds, value2.type)}>
                                <span className="casino-odds">{value2.odds}</span>
                                {getExByColor(value2.amounts)}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )}

        {cards === true && (
            <div className={table}>
                {players.slice(4, 6).map(([index3, value3], i5) => (
                        <div className={`casino-odds-box back ${value3.status}`} key={i5}
                             onClick={() => openPopup('back', index3, value3.odds, value3.type)}>
                            <div>
                                {index3.includes("Black") ? (
                                    <>
                                        <img src={process.env.REACT_APP_CARD_PATH + "spade.png"} alt="spade"/>
                                        <img src={process.env.REACT_APP_CARD_PATH + "club.png"} alt="club"/>
                                    </>
                                ) : (
                                    <>
                                        <img src={process.env.REACT_APP_CARD_PATH + "heart.png"} alt="spade"/>
                                        <img src={process.env.REACT_APP_CARD_PATH + "diamond.png"} alt="club"/>
                                    </>
                                )}
                            </div>
                            <div>
                                <span className="casino-odds">{value3.odds}</span>
                                {getExByColor(value3.amounts)}
                            </div>
                        </div>
                    )
                )}

            </div>
        )}

    </>
)

export default Teen20b;
