import CasinoLayout from "../Section/Casino/CasinoLayout";
import {useContext, useEffect, useRef, useState} from "react";

import {CasinoLastResult} from "../Section/Casino/CasinoLastResult";

import axiosFetch, {
    classifyCard,
    getExByColor,
    getExBySingleTeamNameCasino,

    getExByTeamNameForCasino, getPlayerCardAccordingToNumberOfPlayers, resetBetFields, updatePlayerStats,

} from "../../Common_Functions/Constants";
import {useParams} from "react-router-dom";
import {SportsContext} from "../../Components/Context/SportsContext";
import {BetPlacPopupSection} from "../../Components/BetPlacPopupSection";

import Notify from "../../js/Notify";

const Poker6 = () => {
    const [roundId, setRoundId] = useState('')

    const TOTALPLAYERS = {
        "Player 1": '',
        "Player 2": '',
        "Player 3": '',
        "Player 4": '',
        "Player 5": '',
        "Player 6": '',

    };
    const [cards, setCards] = useState([])
    const roundIdSaved = useRef(null);

    const [submitButtonDisable, setSubmitButtonDisable] = useState(false)

    const stakeValue = useRef(0);
    const [odds, setOdds] = useState(0)

    const [backOrLay, setbackOrLay] = useState('back')
    const [sportList, setSportList] = useState({})
    const {match_id} = useParams();
    const {
        setBetType,
        setPopupDisplay,

    } = useContext(SportsContext)
    const [hideLoading, setHideLoading] = useState(true)


    const [data, setData] = useState([]);


    const ruleImage = '/img/rules/poker.jpeg'
    const ruleDescription = '';
    const [playerStatuses, setPlayerStatuses] = useState(() => {
        const initialOdds = {};
        Object.keys(TOTALPLAYERS).forEach(player => {
            initialOdds[player] = 'suspended-box'; // Initialize each player with odds of 0
        });
        return initialOdds;
    });

    const remark = useRef('Welcome');
    const [lastResult, setLastResult] = useState({});
    const teamname = useRef('');
    const loss = useRef(0);
    const profit = useRef(0);
    const profitData = useRef(0);


    useEffect(() => {
        setBetType('BOOKMAKER')

        if (data?.sub) {
            data.sub.slice(0, 6).map((value, key) => {
                const {nat, b} = value;
                setOddsData(prev => ({...prev, [nat]: b}));

                updatePlayerStats(value, null, null, nat, setPlayerStatuses, 'suspended-box');

            })

            setCards(data.card.split(",").map(value => classifyCard(value)));

            console.log(cards)
        }

    }, [data]);

    const exposure = localStorage.getItem('exposure');
    const sportLength = Object.keys(data).length;

    const [playerAmounts, setPlayerAmounts] = useState(TOTALPLAYERS);
    useEffect(() => {

        if (data?.sub && sportList?.id) {

            remark.current = data.remark || 'Welcome';

            Object.keys(TOTALPLAYERS).map(value => {

                getExBySingleTeamNameCasino(sportList.id, data.mid, value, match_id, 'ODDS')
                    .then(res => {
// Update playerAmounts based on the response
                        setPlayerAmounts(prev => ({
                            ...prev,
                            [value]: res.data // Assuming the amount is in the response
                        }));
                    })
            })

        }

    }, [exposure, sportLength, roundId]);


    const [oddsData, setOddsData] = useState(() => {
        const initialOdds = {};
        Object.keys(TOTALPLAYERS).forEach(player => {
            initialOdds[player] = 0; // Initialize each player with odds of 0
        });
        return initialOdds;
    });
    const openPopup = (isBakOrLay, teamnam, oddvalue) => {


        if (parseFloat(oddvalue) > 0) {
            roundIdSaved.current = roundId
            setbackOrLay(isBakOrLay)
            setPopupDisplay(true);
            teamname.current = teamnam
            setOdds(oddvalue)
        } else {
            Notify("Odds Should Not Be Zero", null, null, 'danger')

        }


    }
    const casinoBetDataNew = (event, new_odds) => {
        stakeValue.current = event.target.value
        if (backOrLay === 'back') {


            loss.current = stakeValue.current;


            profit.current = profitData.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)

        } else {

            profit.current = profitData.current = stakeValue.current;


            loss.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)
        }


    }

    const renderCards = () => (


        getPlayerCardAccordingToNumberOfPlayers(data, 1, 2, [12, 13, 14, 15, 16])?.map((card, index) => {
            const imgSrc = card ? `${card}` : '/img/casino/cards/1.png';
            return (
                <img src={imgSrc} key={index}/>

            );
        })

    );

    const placeBet = () => {

        setHideLoading(false)
        if (playerStatuses[teamname.current] === 'suspended-box') {
            Notify(`Bet Not Confirmed. Reason: Game Suspended`, null, null, 'danger');
            setPopupDisplay(false);
            setHideLoading(true);
            resetBetFields(profitData, stakeValue)


            return;
        }

        if (roundIdSaved.current !== roundId) {
            Notify("Bet Not Confirm Reason Game Change", null, null, 'danger')
            setPopupDisplay(false)
            setHideLoading(true);
            resetBetFields(profitData, stakeValue)

            return;
        }


        setSubmitButtonDisable(true)

        const postdata = {

            "sportId": sportList.id,
            "matchId": roundId,
            "isback": backOrLay === 'back' ? 1 : 0,
            "placeName": teamname.current,
            "placeName2": null,
            "odds": odds,
            "oddsk": 0,
            "profit": parseFloat(profit.current).toFixed(2),
            "loss": parseFloat(loss.current).toFixed(2),
            "betType": "ODDS",
            "bet_side": backOrLay.toUpperCase(),
            "betAmount": parseFloat(stakeValue.current),
            "type": "POKER6",
            "matchType": "poker6",

        }

        axiosFetch('casino/store', 'post', null, postdata)
            .then((res) => {
                if (res.data.status === true) {
                    resetBetFields(profitData, stakeValue)
                    
                    Notify("Bet Placed Successfully", null, null, 'success')
                } else {

                    resetBetFields(profitData, stakeValue)
                    Notify(res.data.msg, null, null, 'danger')
                }
                setHideLoading(true)
                setSubmitButtonDisable(false)
                setPopupDisplay(false)
            })


    }


    return (
        <CasinoLayout ruleImage={ruleImage}
                      ruleDescription={ruleDescription} data={data} roundId={roundId} setRoundId={setRoundId} sportList={sportList}
                      setSportList={setSportList} setData={setData} setLastResult={setLastResult}>
            <div className="video-container" style={{minHeight: "0px"}}>
                <div className="video-overlay" style={{top: "230px"}}>
                    <div className="videoCards">
                        <div>
                            <h6 className="text-white">DEALER</h6>
                            <div>
                                {renderCards()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div>
                    <ul className="nav nav-tabs nav-tabs-2 mb-3">
                        <li className="nav-item">
                            <a className="nav-link text-white">Hands</a>
                        </li>
                    </ul>
                    <div id="hands" className="tab-pane">
                        <div className="hands-container">
                            {Object.keys(playerStatuses).map((player, key) =>

                                (
                                    <div key={player} className="player">
                                        <button className={`btn-theme ${playerStatuses[player]}`}
                                                onClick={() => openPopup('back', player, oddsData[player])}>
                                            <div className="color-card"></div>
                                            <p className="pattern-name mb-0 text-left">
                                                <span>{player}</span>
                                                {Array.from({length: Math.ceil(cards.length / 6)}, (_, index) => {
                                                    const a = key + index * 6; // Calculate the starting index for each group of 6
                                                    if (cards[a] && cards[a].card_no) {

                                                        const marginClass = a === 1 ? 'm-l-20' : 'm-l-5';
                                                        return (
                                                            <span key={a} className={`card-icon ${marginClass}`}>
                                                            {cards[a].card_no}
                                                                <span className={cards[a].card_class}>
                                                            {cards[a].card_icon}
                                                            </span>
                                                            </span>
                                                        );
                                                    }
                                                })
                                                }
                                            </p>
                                            <p className="point mb-0 text-left"><b>{oddsData[player]}</b></p>
                                            <p className="mb-0 text-right min-max">

                                            </p>
                                        </button>

                                        <p className="mb-0 pl-1 text-left"><b
                                            style={{color: "black"}}>{getExByColor(playerAmounts[player])}</b>
                                        </p>
                                    </div>
                                ))
                            }

                        </div>
                    </div>
                </div>
                <marquee scrollamount="3" className="casino-remark m-b-10">{remark.current}</marquee>
                <div className="casino-last-result-title">
                    <span>Last Result</span>
                </div>
                <div className="casino-last-results last-result-container">
                    <CasinoLastResult sportList={sportList} lastResults={lastResult} data={data}/>
                </div>
            </div>
            <BetPlacPopupSection placeBet={placeBet}
                                 profitValue={profitData.current} profit={profit} loss={loss}
                                 handleStakeChange={casinoBetDataNew} hideLoading={hideLoading}
                                 teamname={teamname} backOrLay={backOrLay} stakeValue={stakeValue} odds={odds}
                                 profitData={profitData}
                                 setOdds={setOdds} submitButtonDisable={submitButtonDisable}/>

        </CasinoLayout>
    )
        ;

};

export default Poker6;
