import CasinoLayout from "../Section/Casino/CasinoLayout";
import {useContext, useEffect, useRef, useState} from "react";

import {CasinoLastResult} from "../Section/Casino/CasinoLastResult";

import axiosFetch, {
    getExByColor,
    resetBetFields,
    getExByTeamNameForCasino, getPlayerCardAccordingToNumberOfPlayers, updatePlayerStats,

} from "../../Common_Functions/Constants";
import {useParams} from "react-router-dom";
import {SportsContext} from "../../Components/Context/SportsContext";
import {BetPlacPopupSection} from "../../Components/BetPlacPopupSection";

import Notify from "../../js/Notify";

const Poker = () => {
    const [roundId, setRoundId] = useState('')

    const ruleImage = '/img/rules/poker.jpeg'
    const ruleDescription = '';
    const roundIdSaved = useRef(null);

    const [submitButtonDisable, setSubmitButtonDisable] = useState(false)



    const stakeValue = useRef(0);
    const [odds, setOdds] = useState(0)

    const [backOrLay, setbackOrLay] = useState('back')
    const [sportList, setSportList] = useState({})
    const {match_id} = useParams();
    const {
        setBetType,
        setPopupDisplay,

    } = useContext(SportsContext)
    const [hideLoading, setHideLoading] = useState(true)

    
    const teamNames = useRef(["Player A", "Player B"])

    const [data, setData] = useState([]);
    const [playerA, setPlayerA] = useState(0); // Example player A value
const [playerStatuses, setPlayerStatuses] = useState({ "Player A": 'suspended-box', "Player B": 'suspended-box' });
const [playerA_Back, setPlayerA_Back] = useState(0);
    const [playerB_Back, setPlayerB_Back] = useState(0);
    const [playerA_Lay, setPlayerA_Lay] = useState(0);
    const [playerB, setPlayerB] = useState(0); // Example player B value

    const [playerB_Lay, setPlayerB_Lay] = useState(0);
    const remark = useRef('Welcome');
    const [lastResult, setLastResult] = useState({});
    const teamname = useRef('');
    const loss = useRef(0);
    const profit = useRef(0);
    const profitData = useRef(0);


    useEffect(() => {
        setBetType('ODDS')

        if (data?.sub) {
            updatePlayerStats(data.sub[0], setPlayerA_Back, setPlayerA_Lay, "Player A", setPlayerStatuses, 'suspended-box');
            updatePlayerStats(data.sub[1], setPlayerB_Back, setPlayerB_Lay, "Player B", setPlayerStatuses, 'suspended-box');

        }

    }, [data]);

    const exposure = localStorage.getItem('exposure');
    const sportLength = Object.keys(data).length;


    useEffect(() => {

        if (data?.sub && sportList?.id) {
            getExByTeamNameForCasino(sportList.id, data.mid, 'Player A', match_id, 'ODDS').then(res => setPlayerA(res.data))

            getExByTeamNameForCasino(sportList.id, data.mid, 'Player B', match_id, 'ODDS').then(res => setPlayerB(res.data))
        }
    }, [exposure, sportLength, roundId]);


    const openPopup = (isBakOrLay, teamnam, oddvalue) => {


        if (parseFloat(oddvalue) > 0) {
            roundIdSaved.current = roundId
            setbackOrLay(isBakOrLay)
            setPopupDisplay(true);
            teamname.current = teamnam
            setOdds(oddvalue)
        } else {
            Notify("Odds Should Not Be Zero", null, null, 'danger')

        }


    }
    const casinoBetDataNew = (event, new_odds) => {
        stakeValue.current = event.target.value
        if (backOrLay === 'back') {


            loss.current = stakeValue.current;


            profit.current = profitData.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)

        } else {

            profit.current = profitData.current = stakeValue.current;


            loss.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)
        }

        

    }


    const placeBet = () => {

        setHideLoading(false)
            if (playerStatuses[teamname.current] === 'suspended-box') {
                Notify(`Bet Not Confirmed. Reason: Game Suspended`, null, null, 'danger');
                setPopupDisplay(false);
                setHideLoading(true)
                return;
            }

        if (roundIdSaved.current !== roundId) {
            Notify("Bet Not Confirm Reason Game Change", null, null, 'danger')
            setPopupDisplay(false)
            setHideLoading(true)
            return;
        }


        setSubmitButtonDisable(true)

        const postdata = {

            "sportId": sportList.id,
            "matchId": roundId,
            "isback": backOrLay === 'back' ? 1 : 0,
            "placeName": teamname.current,
            "placeName2": null,
            "odds": odds,
            "oddsk": 0,
            "profit": parseFloat(profit.current).toFixed(2),
            "loss": parseFloat(loss.current).toFixed(2),
            "betType": "ODDS",
            "bet_side": backOrLay.toUpperCase(),
            "betAmount": parseFloat(stakeValue.current),
            "type": "POKER",
            "matchType": "poker",
            
        }

        axiosFetch('casino/store', 'post', null, postdata)
            .then((res) => {
                if (res.data.status === true) {

                    resetBetFields(profitData, stakeValue)
                    Notify("Bet Placed Successfully", null, null, 'success')
                }
                else{

                    resetBetFields(profitData, stakeValue)
                    Notify(res.data.msg, null, null, 'danger')
                }
                setHideLoading(true)
                setSubmitButtonDisable(false)
                setPopupDisplay(false)
            })


    }

    return (
        <CasinoLayout ruleImage={ruleImage}
                      ruleDescription={ruleDescription} data={data} roundId={roundId} setRoundId={setRoundId}
                      sportList={sportList}
                      setSportList={setSportList} setData={setData} setLastResult={setLastResult}>
            <div className="video-container" style={{minHeight: '0px'}}>
                <div className="video-overlay" style={{top: '230px'}}>
                    <div id="game-cards">
                        <div className="card-inner">
                            <div className="row">
                                <div className="col-6">
                                    <h3 className="text-white" style={{fontSize: '11px'}}>Player A</h3>
                                    <div>
                                        {getPlayerCardAccordingToNumberOfPlayers(data, 1, 9, [0, 1]).map((card, index) => (
                                            <img key={index} className="borde-img-card" src={card}
                                                 alt={`Player A card ${index}`}/>
                                        ))}
                                    </div>
                                </div>
                                <div className="col-6 text-right">
                                    <h3 className="text-white" style={{fontSize: '11px'}}>Player B</h3>
                                    {getPlayerCardAccordingToNumberOfPlayers(data, 1, 9, [2, 3]).map((card, index) => (
                                        <img key={index} className="borde-img-card" src={card}
                                             alt={`Player B card ${index}`}/>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="card-inner">
                            <h3 className="text-white">Board</h3>
                            <div>
                                {getPlayerCardAccordingToNumberOfPlayers(data, 1, 9, [4, 5, 6, 7, 8]).map((card, index) => (
                                    <img key={index} className="borde-img-card" src={card} alt={`Board card ${index}`}/>
                                ))}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="casino-detail poker-1day">
                <div className="table-responsive mb-1">
                    <table className="table table-bordered mb-0">
                        <thead>
                        <tr>
                            <th className="box-4 min-max">Min:100 Max:300000</th>
                            <th className="box-3 text-center back">BACK</th>
                            <th className="box-3 text-center lay">LAY</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className={`box-4`}>
                                <b>Player A</b>

                                <p className={`mb-0`}>{getExByColor(playerA)}</p>
                            </td>
                            <td className={`box-3 back text-center ${playerStatuses['Player A']}`}
                                onClick={() => openPopup('back', 'Player A', playerA_Back)}>
                                <span className="odds d-block"><b>{playerA_Back}</b></span>
                                <span className="p-2"></span>
                            </td>
                            <td className={`box-3 lay text-center ${playerStatuses['Player A']}`}
                                onClick={() => openPopup('lay', 'Player A', playerA_Lay)}>
                                <span className="odds d-block"><b>{playerA_Lay}</b></span>
                                <span className="p-2"></span>
                            </td>
                        </tr>
                        <tr>
                            <td className={`box-4`}>
                                <b>Player B</b>
                                <p className={`mb-0`}>{getExByColor(playerB)}</p>
                            </td>
                            <td className={`box-3 back text-center ${playerStatuses['Player B']}`}
                                onClick={() => openPopup('back', 'Player B', playerB_Back)}>
                                <span className="odds d-block"><b>{playerB_Back}</b></span>
                                <span className="p-2"></span>
                            </td>
                            <td className={`box-3 lay text-center ${playerStatuses['Player B']}`}
                                onClick={() => openPopup('lay', 'Player B', playerB_Lay)}>
                                <span className="odds d-block"><b>{playerB_Lay}</b></span>
                                <span className="p-2"></span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div className="casino-title">
                    <span className="casino-name">Bonus Bet</span>
                </div>
                <div className="table-responsive mb-1">
                    <table className="table table-bordered mb-0">
                        <thead>
                        <tr>
                            <th className="box-4 min-max">Min:100 Max:300000</th>
                            <th className="box-3 text-center back">BACK</th>
                            <th className="box-3 text-center back">BACK</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr className={playerStatuses['Player A']}>
                            <td className="box-4">
                                <b>Player A</b>
                            </td>
                            <td className="box-3 back text-center">
                                <span className="odds d-block"><b>Player A 2 card Bonus</b></span>
                                <span style={{color: 'black'}}>2 Card Bonus</span>
                            </td>
                            <td className="box-3 back text-center">
                                <span className="odds d-block"><b>Player A 7 card Bonus</b></span>
                                <span style={{color: 'black'}}>7 Card Bonus</span>
                            </td>
                        </tr>
                        <tr className={playerStatuses['Player B']}>
                            <td className="box-4"><b>Player B</b></td>
                            <td className="box-3 back text-center">
                                <span className="odds d-block"><b>Player B 2 card Bonus</b></span>
                                <span style={{color: 'black'}}>2 Card Bonus</span>
                            </td>
                            <td className="box-3 back text-center">
                                <span className="odds d-block"><b>Player B 7 card Bonus</b></span>
                                <span style={{color: 'black'}}>7 Card Bonus</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <marquee scrollamount="3" className="casino-remark m-b-10">{remark.current}</marquee>
                <div className="casino-last-result-title">
                    <span>Last Result</span>
                </div>
                <div className="casino-last-results">
                    <CasinoLastResult sportList={sportList} lastResults={lastResult} data={data}/>
                </div>
            </div>
            <BetPlacPopupSection placeBet={placeBet} profitValue={profitData.current} profit={profit} loss={loss}
                                 teamnames={teamNames} handleStakeChange={casinoBetDataNew} hideLoading={hideLoading}
                                 teamname={teamname} backOrLay={backOrLay} stakeValue={stakeValue} odds={odds}
                                 profitData={profitData}
                                 setOdds={setOdds} submitButtonDisable={submitButtonDisable}/>

            <div className="last-result-tiele"><span>Rules</span></div>
            <div className="table-responsive rules-table">
                <table className="table table-bordered">
                    <thead>
                    <tr>
                        <th colSpan="2" className="box-10 text-center">Bonus 1 (2 Cards Bonus)</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className="box-7">Pair (2-10)</td>
                        <td className="box-3">1 TO 3</td>
                    </tr>
                    </tbody>
                </table>
                <table className="table table-bordered">
                    
                    <tbody>
                    <tr>
                        <td className="box-7">A/Q or A/J Off Suited</td>
                        <td className="box-3">1 To 5</td>
                    </tr>
                    </tbody>
                </table>
                <table className="table table-bordered">
                    
                    <tbody>
                    <tr>
                        <td className="box-7">Pair (JQK)</td>
                        <td className="box-3">1 To 10</td>
                    </tr>
                    </tbody>
                </table>
                <table className="table table-bordered">
                    
                    <tbody>
                    <tr>
                        <td className="box-7">A/K Off Suited</td>
                        <td className="box-3">1 To 15</td>
                    </tr>
                    </tbody>
                </table>
                <table className="table table-bordered">
                    
                    <tbody>
                    <tr>
                        <td className="box-7">A/Q or A/J Suited</td>
                        <td className="box-3">1 To 20</td>
                    </tr>
                    </tbody>
                </table>
                <table className="table table-bordered">
                    
                    <tbody>
                    <tr>
                        <td className="box-7">A/K Suited</td>
                        <td className="box-3">1 To 25</td>
                    </tr>
                    </tbody>
                </table>
                <table className="table table-bordered">
                    
                    <tbody>
                    <tr>
                        <td className="box-7">A/A</td>
                        <td className="box-3">1 To 30</td>
                    </tr>
                    </tbody>
                </table>
                <table className="table table-bordered">
                    <thead>
                    <tr>
                        <th colSpan="2" className="box-10 text-center">Bonus 2 (7 Cards Bonus)</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className="box-7">Three of a Kind</td>
                        <td className="box-3">1 TO 3</td>
                    </tr>
                    </tbody>
                </table>
                <table className="table table-bordered">
                    
                    <tbody>
                    <tr>
                        <td className="box-7">Straight</td>
                        <td className="box-3">1 To 4</td>
                    </tr>
                    </tbody>
                </table>
                <table className="table table-bordered">
                    
                    <tbody>
                    <tr>
                        <td className="box-7">Flush</td>
                        <td className="box-3">1 To 6</td>
                    </tr>
                    </tbody>
                </table>
                <table className="table table-bordered">
                    
                    <tbody>
                    <tr>
                        <td className="box-7">Full House</td>
                        <td className="box-3">1 To 8</td>
                    </tr>
                    </tbody>
                </table>
                <table className="table table-bordered">
                    
                    <tbody>
                    <tr>
                        <td className="box-7">Four of a Kind</td>
                        <td className="box-3">1 To 30</td>
                    </tr>
                    </tbody>
                </table>
                <table className="table table-bordered">
                    
                    <tbody>
                    <tr>
                        <td className="box-7">Straight Flush</td>
                        <td className="box-3">1 To 50</td>
                    </tr>
                    </tbody>
                </table>
                <table className="table table-bordered">
                    
                    <tbody>
                    <tr>
                        <td className="box-7">Royal Flush</td>
                        <td className="box-3">1 To 100</td>
                    </tr>
                    </tbody>
                </table>
            </div>


        </CasinoLayout>
    );

};


export default Poker;
