import Header from "../Section/Header";
import SidebarLayout from "./SidebarLayout";

import Footer from "./Footer";

const CommonLayout = ({children}) => {

       return (
            <>
                <Header/>

                <div className="main">
                    <div className="container-fluid container-fluid-5">
                        <div className="row row5">
                            <SidebarLayout/>
                            <div className="col-md-10 featured-box white-bg">
                                {children}

                        </div>
                    </div>
                    <Footer/>
                </div>
                </div>

            </>
        )
    }
;

export default CommonLayout;
