import React from 'react';
import { useParams } from 'react-router-dom';
import Teen32 from "../../Casinos/Teen32";
import Race20 from "../../Casinos/Race20";
import Superover from "../../Casinos/Superover";
import Queen from "../../Casinos/Queen";
import Cricketv3 from "../../Casinos/Cricketv3";
import Abj from "../../Casinos/Abj";
import Dt202 from "../../Casinos/Dt202";
import Baccarat2 from "../../Casinos/Baccarat2";
import Baccarat from "../../Casinos/Baccarat";
import Lucky7eu from "../../Casinos/Lucky7eu";
import Cmatch20 from "../../Casinos/Cmatch20";
import Cmeter from "../../Casinos/Cmeter";
import War from "../../Casinos/War";
import Dtl20 from "../../Casinos/Dtl20";
import Teen9 from "../../Casinos/Teen9";
import Teen8 from "../../Casinos/Teen8";
import Teen from "../../Casinos/Teen";
import Teen20 from "../../Casinos/Teen20";
import Poker6 from "../../Casinos/Poker6";
import Poker from "../../Casinos/Poker";
import Poker20 from "../../Casinos/Poker20";
import Ab20 from "../../Casinos/Ab20";
import Worli from "../../Casinos/Worli";
import Worli2 from "../../Casinos/Worli2";
import ThreecardJ from "../../Casinos/ThreecardJ";
import Card32 from "../../Casinos/Card32";
import Card32eu from "../../Casinos/Card32eu";
import Aaa from "../../Casinos/Aaa";
import Btable from "../../Casinos/Btable";
    import Dt6 from "../../Casinos/Dt6";
import Lucky7 from "../../Casinos/Lucky7";
import Testlottery from "../../Casinos/Testlottery";
import Cmeter1 from "../../Casinos/Cmeter1";
import Dum10 from "../../Casinos/Dum10";
import Teen3 from "../../Casinos/Teen3";
import Race2 from "../../Casinos/Race2";
import Aaa2 from "../../Casinos/Aaa2";
import Ab3 from "../../Casinos/Ab3";
import Teen1 from "../../Casinos/Teen1";
import Teen120 from "../../Casinos/Teen120";
import Kbc from "../../Casinos/Kbc";
import Teen2024 from "../../Casinos/Teen2024";
import Notenum from "../../Casinos/Notenum";
import Trio from "../../Casinos/Trio";
import Teen20b from "../../Casinos/Teen20b";
import Teenmuf from "../../Casinos/Teenmuf";
import Race17 from "../../Casinos/Race17";
import Teensin from "../../Casinos/Teensin";
import Patti2 from "../../Casinos/Patti2";
import Trap from "../../Casinos/Trap";
import Teen6 from "../../Casinos/Teen6";
import Lucky7eu2 from "../../Casinos/Lucky7eu2";
import Vlucky7 from "../../Casinos/Virtual/Vlucky7";
import Vtrio from "../../Casinos/Virtual/Vtrio";
import Vdtl20 from "../../Casinos/Virtual/Vdtl20";
import Vteenmuf from "../../Casinos/Virtual/Vteenmuf";
import Vaaa from "../../Casinos/Virtual/Vaaa";
import Vbtable from "../../Casinos/Virtual/Vbtable";
import Vdt6 from "../../Casinos/Virtual/Vdt6";
import Vteen from "../../Casinos/Virtual/Vteen";
import Vteen20 from "../../Casinos/Virtual/Vteen20";
import Dt201 from "../../Casinos/Dt201";

const CasinoMain = () => {
    const { match_id } = useParams(); // Get gamename from the URL

    // Function to render different layouts based on the game name
    const renderLayout = (gamename) => {

        switch (gamename.toLowerCase()) {

            case 'superover':
                return <Superover />;
            case 'race20':
                return <Race20 />;
            case 'queen':
                return <Queen />;
            case 'cricketv3':
                return <Cricketv3 />;
            case 'abj':
                return <Abj />;
            case 'dt202':
                return <Dt202 />;
            case 'baccarat2':
                return <Baccarat2 />;
            case 'baccarat':
                return <Baccarat />;
            case 'lucky7eu':
                return <Lucky7eu />;
            case 'cmatch20':
                return <Cmatch20 />;
            case 'cmeter':
                return <Cmeter />;
            case 'war':
                return <War />;
            case 'dtl20':
                return <Dtl20 />;
            case 'teen9':
                return <Teen9 />;
            case 'teen8':
                return <Teen8 />;
            case 'teen':
                return <Teen />;
            case 'teen20':
                return <Teen20 />;
            case 'poker6':
                return <Poker6 />;
            case 'poker':
                return <Poker />;
            case 'poker20':
                return <Poker20 />;
            case 'ab20':
                return <Ab20 />;
            case 'worli':
                return <Worli />;
            case 'worli2':
                return <Worli2 />;
            case '3cardj':
                return <ThreecardJ />;
            case 'card32':
                return <Card32 />;
            case 'card32eu':
                return <Card32eu />;
            case 'aaa':
                return <Aaa />;
            case 'btable':
                return <Btable />;
            case 'dt20':
                return <Dt201 />;
            case 'dt6':
                return <Dt6 />;
            case 'test_lottery':
                return <Testlottery />;
            case 'lucky7':
                return <Lucky7 />;
            case 'cmeter1':
                return <Cmeter1 />;
            case 'dum10':
                return <Dum10 />;
            case 'teen3':
                return <Teen3 />;
            case 'race2':
                return <Race2 />;
            case 'aaa2':
                return <Aaa2 />;
            case 'ab3':
            case 'ab4':
                return <Ab3 />;
            case 'teen1':
                return <Teen1 />;
            case 'teen120':
                return <Teen120 />;
            case 'kbc':
                return <Kbc />;
            case 'teen2024':
                return <Teen2024 />;
            case 'notenum':
                return <Notenum />;
            case 'trio':
                return <Trio />;
            case 'teen20b':
                return <Teen20b />;
            case 'teenmuf':
                return <Teenmuf />;
            case 'race17':
                return <Race17 />;
            case 'teensin':
                return <Teensin />;
            case 'patti2':
                return <Patti2 />;
            case 'trap':
                return <Trap />;
            case 'teen6':
                return <Teen6 />;
            case 'lucky7eu2':
                return <Lucky7eu2 />;
            case 'vlucky7':
                return <Vlucky7 />;
            case 'vtrio':
                return <Vtrio />;
            case 'vdtl20':
                return <Vdtl20 />;
            case 'vteenmuf':
                return <Vteenmuf />;
            case 'vaaa':
                return <Vaaa />;
            case 'vbtable':
                return <Vbtable />;
            case 'vdt6':
                return <Vdt6 />;
            case 'vteen':
                return <Vteen />;
            case 'vteen20':
                return <Vteen20 />;

            // Default case for "teen32"
            default:
                return <Teen32 />;
        }
    };


    return (
        <div className="casino-main">
            {renderLayout(match_id)}
        </div>
    );
};

export default CasinoMain;
