import {Link} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import axiosFetch from "../../Common_Functions/Constants";
import {SportsContext} from "../../Components/Context/SportsContext";

const SidebarLayout = () => {
    const {
        activeLink,
        navLinks,
        setNavLinks
    } = useContext(SportsContext)

    const [allSportShow, setAllSportShow] = useState({racing : true, others : true, allsports : true})

    useEffect(() => {

        const getData = async () => {

            let promises = [];
            [1, 2, 3].forEach((value, index) => {
                promises.push(axiosFetch('get_sport_data_for_sidebar?sport_id=' + value, 'get'))
            })

            const all_promise = await Promise.all(promises);
            const matches = ['Cricket', 'Tennis', 'Football']
            all_promise.forEach((value, index) => {
                setNavLinks((prevState) => {

                    const matchDetails = value.data.map(item => ({
                        match_name: item.match_name,
                        match_id: item.match_id
                    }));


                    return {...prevState, [matches[index]]: {...prevState[matches[index]], list: matchDetails}}
                })

            })
        }



        getData()
    }, []);


    return (
        <div className="sidebar col-md-2">

            <div data-toggle="collapse" onClick={() => setAllSportShow({...allSportShow, racing: !allSportShow.racing})} data-target=".racing_sports" className={`sidebar-title ${allSportShow.racing ? '' : 'collapsed'}`}
                 aria-expanded="true">
                <h5 className="d-inline-block m-b-0">Racing Sports</h5>
            </div>
            <nav className={`racing_sports collapse ${allSportShow.racing ? 'show' : ''}`}>
                <ul>
                    <li className="nav-item"><span className="nav-link"><span>Horse Racing</span></span>
                    </li>
                </ul>
            </nav>

            <div data-toggle="collapse"  onClick={() => setAllSportShow({...allSportShow, others: !allSportShow.others})}  data-target=".casino" className={`sidebar-title ${allSportShow.others ? '' : 'collapsed'}`}
                 aria-expanded="true">
                <h5 className="d-inline-block m-b-0">Others</h5>
            </div>
            <nav className={`casino collapse ${allSportShow.others ? 'show' : ''}`}>
                <ul>
                    <li className="nav-item"><Link to="/our/all_casino"
                                                   className="nav-link"><span
                        className="newlacunch-menu">Our Casino</span></Link>
                    </li>
                    <li className="nav-item"><Link to="/our/our-virtual"
                                                   className="nav-link"><span
                        className="newlacunch-menu">Our Virtual</span></Link>
                    </li>

                    {/*<li className="nav-item"><a href={`${process.env.REACT_APP_MAIN_URL}/slot/game/netent/new_slots`}*/}
                    {/*                            className="nav-link"><span>Slot Game</span></a></li>*/}
                    <li className="nav-item"><Link to={"/fantasy"}
                                                   className="nav-link"><span>Fantasy Game</span></Link></li>


                </ul>
            </nav>

            <div className={`sidebar-title  m-t-5 theme2bg ${allSportShow.allsports ? '' : 'collapsed'}`} aria-controls="events"
                 onClick={() => setAllSportShow({...allSportShow, allsports: !allSportShow.allsports})} aria-expanded="true" role="button">
                <h5 className="text-white d-inline-block m-b-0">All Sports</h5>
            </div>
            <div id="events" className={`mtree-main collapse ${allSportShow.allsports ? 'show' : ''}`}>
                <div className="ps">
                     <nav>

                        {Object.entries(navLinks).map(([index, value], i) => (
                            <ul className="mtree transit bubba" key={i}>
                                <li className="mtree-node  item">
                                    <div className="text-dark sport-item-sidebar"
                                         onClick={() => activeLink(index)} data-sport-id="1"
                                         id="sportContainer-0">
                                        <span><i
                                            className={`${value.active === false ? 'far fa-plus-square' : 'far fa-minus-square'}`}></i></span>
                                        &nbsp;&nbsp;{index}
                                    </div>
                                    <ul className={`mtree-level-1 ${value.active === false ? 'd-none' : ''}`}
                                        id="sportList-0">
                                        {Object.entries(value.list).map(([index1, value1], i1) => {

                                            return (
                                                <li className="mtree-node text-dark" key={i1}>
                                                    <div className="text-dark">
                                                        <span><i className="fas fa-caret-right"></i></span>
                                                        <Link
                                                            to={"/" + value.canonical_name + "/" + value1.match_id}>{value1.match_name}</Link>
                                                    </div>
                                                </li>
                                            )
                                        })}

                                    </ul>
                                </li>
                            </ul>
                        ))}


                    </nav>
                    <div className="ps__rail-x" style={{left: '0px', bottom: '0px'}}>
                        <div className="ps__thumb-x" tabIndex="0"
                             style={{left: '0px', width: '0px'}}></div>
                    </div>
                    <div className="ps__rail-y" style={{top: '0px', right: '0px'}}>
                        <div className="ps__thumb-y" tabIndex="0"
                             style={{top: '0px', height: '0px'}}></div>
                    </div>
                </div>
            </div>

        </div>
    )

}
export default SidebarLayout