import React, {useContext, useEffect, useRef, useState} from "react";

import SportsLayout from "../Section/SportsLayout";
import {getExByTeamNameForCricket, mapSports} from "../../Common_Functions/Constants";
import {SportsContext} from "../../Components/Context/SportsContext";
import MatchOdds from "../Section/AllSports/MatchOdds";
import Bookmaker from "../Section/AllSports/Bookmaker";
import {useNavigate, useParams} from "react-router-dom";
import {Buffer} from "buffer";
import CommonLayout from "../Section/CommonLayout";

const Tennis = () => {
    const nav = useNavigate();

    const defaultTeamName = useRef('')
    const allTeamName = useRef([])
    const teamNameCurrentBets = useRef({})


    const teamNames = useRef({});
    const [betOddValue, setBetOddValue] = useState(0)
    const [backOrLay, setbackOrLay] = useState('back')
    const {
        popupDisplay,
        setPopupDisplay,
        betType,
        sports_socket,
        betPlaceStatusCheck,
        betPlaceStatus,
        setShowLoader,
        showLoader,
        defaultTeamDatasCalculation
    } = useContext(SportsContext)
    const {match_id} = useParams();


    const [sportList, setSportList] = useState({})
    const [ar_sectionData, setAr_sectionData] = useState([])

    const trackData = useRef({});

    const oddsChange = useRef({});

    const [oddsTeamData, setOddsTeamData] = useState({})
    const [bookmakerTeamData, setBookmakerTeamData] = useState({})
    const [placingBets, setPlacingBets] = useState([])

    useEffect(() => {
        // Runs on mount only

        return () => {
            setPopupDisplay(false)
            setShowLoader(true);
        };
    }, []);

    useEffect(() => {
        if (Object.keys(sportList).length > 0) {

            defaultTeamDatasCalculation(sportList, setOddsTeamData, setBookmakerTeamData)
        }

    }, [sportList]);

    const callTeamDatas = (betType = {
        'ODDS': 'match_odds',
        'BOOKMAKER': 'bookmaker',

    }) => {

        try {
            if (Object.keys(sportList).length > 0) {

                Object.entries(betType).forEach(([index, value]) => {

                    switch (index) {
                        case 'ODDS' :

                            getExByTeamNameForCricket(ar_sectionData, index, value, sportList.id, oddsTeamData, setOddsTeamData);
                            break;
                        default  :
                            getExByTeamNameForCricket(ar_sectionData, index, value, sportList.id, bookmakerTeamData, setBookmakerTeamData)

                    }


                })


            }


        } catch (error) {
            console.error("Error fetching team data:", error);
        }
    };
    const arlength = Object.keys(ar_sectionData).length
    const expsoure = localStorage.getItem('exposure')

    useEffect(() => {
        if (Object.keys(sportList).length > 0 && arlength > 0) {
            callTeamDatas();

        }
// eslint-disable-next-line
    }, [sportList, expsoure]);

    useEffect(() => {
        let gamename = 'tennis'
        sports_socket.emit('setPurposeFor', 'sports', gamename, '', '', match_id)
        let socket_game = `getSportData${gamename}${match_id}`;
        let emptyCheckTimeout;

        setShowLoader(true)

            const gameConnect = () => {
                sports_socket.on(socket_game, sportData => {

                    const gameSet = [];

                    if (sportData !== null) {


                        sportData = JSON.parse(Buffer.from(sportData).toString('utf8'))

                        if (sportData && sportData.game_detail && sportData.game_detail.length > 0) {
                            setShowLoader(false);

                            for (let i = 0; i < sportData.game_detail.length; i++) {
                                const value = sportData.game_detail[i];

                                const gtype = value.mname.toLowerCase();


                                gameSet[gtype] = value;
                            }

                            if (Object.values(gameSet).length > 0 && emptyCheckTimeout) {
                                clearTimeout(emptyCheckTimeout);  // Clear the timeout if data is received
                                emptyCheckTimeout = null;
                            }
                            setAr_sectionData(gameSet)
                            trackData.current = gameSet;
                        }


                    }

                })
            }
            gameConnect();

        emptyCheckTimeout = setTimeout(() => {

            if (Object.values(trackData.current).length === 0) {

                nav('/');  // Redirect if gameSet is empty
            }

        }, 3000);


        sports_socket.on('disconnect', function () {
                console.log('Disconnected from server. Attempting to reconnect...');

                // Reconnect logic
                const connectInterval = setInterval(() => {
                    gameConnect();
                    clearInterval(connectInterval)
                }, 1000);  // Reattempt connect every second
            });

            return () => {
                sports_socket.off(socket_game);
                setPopupDisplay(false)
                setShowLoader(false);

            };


// eslint-disable-next-line
    }, [match_id]);


    const individualBetPlaceFetch = (teamname) => {
        betPlaceStatus.current[teamname] = betPlaceStatusCheck(sportList, ar_sectionData, teamname)
    }
    const [maxValue, setMaxValue] = useState([])
    const [minValue, setMinValue] = useState([])
    const currentOddValue = useRef([]);

    return (
        <CommonLayout>
            <SportsLayout callTeamDatas={callTeamDatas}
                          setOddsTeamData={setOddsTeamData} placingBets={placingBets} setPlacingBets={setPlacingBets}
                          currentOddValue={currentOddValue} maxValue={maxValue} minValue={minValue}
                          teamNameCurrentBets={teamNameCurrentBets}
                          showCalculation={true}
                          individualBetPlaceFetch={individualBetPlaceFetch} betType={betType}
                          backOrLay={backOrLay} teamname={defaultTeamName}
                          odds={betOddValue}
                          teamNames={['ODDS', 'BOOKMAKER', 'TIED_MATCH'].includes(betType) ? allTeamName : []}
                          setOdds={setBetOddValue}
                          setPopupDisplay={setPopupDisplay} popupDisplay={popupDisplay} sportList={sportList}
                          data={ar_sectionData} setSportList={setSportList}>

                {!showLoader && (
                    <>
                <MatchOdds placingBets={placingBets} currentOddValue={currentOddValue}
                           setMaxValue={setMaxValue} setMinValue={setMinValue} teamNameCurrentBets={teamNameCurrentBets}
                           allTeamName={allTeamName} oddsTeamData={oddsTeamData} setDefaultTeamName={defaultTeamName}
                           setBetOddValue={setBetOddValue} setbackOrLay={setbackOrLay} teamNames={teamNames}
                           setPopupDisplay={setPopupDisplay} ar_sectionData={ar_sectionData} sportList={sportList}
                           oddsChange={oddsChange}/>
                <Bookmaker placingBets={placingBets} currentOddValue={currentOddValue} setMaxValue={setMaxValue}
                           setMinValue={setMinValue} teamNameCurrentBets={teamNameCurrentBets}
                           allTeamName={allTeamName} setDefaultTeamName={defaultTeamName}
                           bookmakerTeamData={bookmakerTeamData} setBetOddValue={setBetOddValue}
                           setbackOrLay={setbackOrLay}
                           teamNames={teamNames} setPopupDisplay={setPopupDisplay} ar_sectionData={ar_sectionData}
                           sportList={sportList} oddsChange={oddsChange}/>
                    </>
            )}
            </SportsLayout>
        </CommonLayout>

    );
};


export default Tennis;
