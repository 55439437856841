import React, {useContext, useMemo, useCallback, useEffect} from "react";
import {
    getSize,
    organiseOdds,
    getExByColor,
    handleShowRules,
    generateBackAndLayFunction, dummyDataOdds
} from "../../../Common_Functions/Constants";
import {SportsContext} from "../../../Components/Context/SportsContext";
import bookmaker from "../../../Mobile/Section/AllSports/Bookmaker";

function Bookmaker({
                       ar_sectionData,
                       allTeamName = {},
                       sportList,
                       oddsChange,
                       setBetOddValue,
                       setbackOrLay,
                       teamNames,
                       setDefaultTeamName,
                       teamNameCurrentBets = {},
                       setPopupDisplay,
                       bookmakerTeamData,
                       placingBets = [],
                       setMaxValue = null,
                       setMinValue = null
                   }) {
    const {setBetTypeFromArray, runnerRowDefault, rootClassDefault, setBetType} = useContext(SportsContext);

    if (!teamNames.current['BOOKMAKER']) {
        teamNames.current['BOOKMAKER'] = []; // Initialize 'BOOKMAKER' as an empty array
    }
    // Memoize bookmaker data

    const bookmakerData = useMemo(() => ar_sectionData?.bookmaker, [ar_sectionData]);
    const sections = bookmakerData?.section || [];

    useEffect(() => {
        if (setMaxValue !== null && bookmakerData?.max) {
            setMaxValue((prevState) => {
                return {...prevState, 'BOOKMAKER': bookmakerData?.max}
            })
            if (setMinValue !== null) {
                setMinValue((prevState) => {
                    return {...prevState, 'BOOKMAKER': bookmakerData?.min ?? 100}
                })
            }

        }
        // eslint-disable-next-line
    }, [bookmakerData?.max]);


    const debouncers = {};

    const updateOdds = (backlay, odds) => {
        if (oddsChange.current[backlay] !== odds) {
            // Clear the previous setTimeout
            if (debouncers[backlay]) {
                clearTimeout(debouncers[backlay]);
            }

            debouncers[backlay] = setTimeout(() => {
                oddsChange.current[backlay] = odds;
                delete debouncers[backlay]; // clear debouncer after execution
            }, 100);
        }
    }


    // Common function to render odds
    const renderOdds = useCallback(
        (oddsArr, key11, teamName, runnerRow, tot) => {
            return (
                <>
                    {/* Back Odds */}
                    {dummyDataOdds(oddsArr.back)?.reverse().map((back, b_key) => {
                        const reverseKey = dummyDataOdds(oddsArr.back).length - 1 - b_key;


                        const backFunction = generateBackAndLayFunction(
                            tot, oddsArr, 'back', teamName, runnerRow, key11, 'BOOKMAKER', setBetOddValue, setbackOrLay, teamNames, setPopupDisplay, setDefaultTeamName, runnerRowDefault, rootClassDefault, setBetType,
                            null, setBetTypeFromArray, 'bookmaker'
                        );
                        const value_price = tot === 0 ? '-' : back?.odds || '-';
                        const value_size = tot === 0 ? '' : getSize(back?.size || '');

                        if (oddsChange.current[`bookmakerback${key11}${b_key}`] !== value_price) {

                                updateOdds(`bookmakerback${key11}${b_key}`, value_price)


                        }

                        return (
                            <div
                                key={b_key}

                                className={`box-1 back float-left text-center ${oddsChange.current[`bookmakerback${key11}${b_key}`] !== value_price && oddsChange.current[`bookmakerback${key11}${b_key}`] !== '' ? 'color-yellow-bet' : ""}`}
                                onClick={backFunction[reverseKey]}>
                                <span className="odd d-block">{value_price}</span>
                                <span className="d-block">{value_size}</span>
                            </div>
                        );
                    })}

                    {/* Lay Odds */}
                    {dummyDataOdds(oddsArr.lay)?.map((lay, l_key) => {
                        const value_price = tot === 0 ? '-' : lay?.odds || '-';
                        const value_size = tot === 0 ? '' : getSize(lay?.size || '');

                        const layFunction = generateBackAndLayFunction(
                            tot, oddsArr, 'lay', teamName, runnerRow, l_key, 'BOOKMAKER', setBetOddValue, setbackOrLay, teamNames, setPopupDisplay, setDefaultTeamName, runnerRowDefault, rootClassDefault, setBetType, null,
                            setBetTypeFromArray, 'bookmaker'
                        );

                        if (oddsChange.current[`bookmakerlay${key11}${l_key}`] !== value_price) {
                            // Update odds in oddsChange
                            updateOdds(`bookmakerlay${key11}${l_key}`, value_price)

                        }


                        return (
                            <div
                                key={l_key}

                                className={`box-1 lay float-left text-center checkdataval ${oddsChange.current[`bookmakerlay${key11}${l_key}`] !== value_price && oddsChange.current[`bookmakerlay${key11}${l_key}`] !== '' ? 'color-yellow-bet' : ''}`}
                                onClick={layFunction[l_key]}>
                                <span className="odd d-block">{value_price}</span>
                                <span className="d-block">{value_size}</span>
                            </div>
                        );
                    })}
                </>
            );
        },
        [oddsChange, setBetOddValue, setbackOrLay, teamNames, setPopupDisplay, setDefaultTeamName, runnerRowDefault, rootClassDefault, setBetType]
    );

    return (
        <>
            {bookmakerData && (
                <div>
                    <div className="market-title">
                        Bookmaker market
                        <p className="float-right mb-0" onClick={() => handleShowRules('Bookmakerrule')}>
                            <i className="fas fa-info-circle"></i>
                        </p>
                    </div>
                    <div className="main-market">
                        <div className="table-header">
                            <div className="float-left country-name box-4 min-max">
                                <b className="min-max-value">Min: {bookmakerData.min} Max: {getSize(bookmakerData.max)}</b>
                            </div>
                            <div className="box-1 float-left"></div>
                            <div className="box-1 float-left"></div>
                            <div className="back box-1 float-left text-center"><b>Back</b></div>
                            <div className="lay box-1 float-left text-center"><b>Lay</b></div>
                            <div className="box-1 float-left"></div>
                            <div className="box-1 float-left"></div>
                        </div>
                        <div className="table-body BOOKMAKER">
                            {sections.map((oddsArr, key) => {
                                let isSuspended = "", isSuspendedClass = "";
                                let tot = 1;

                                // Check if suspended
                                if (oddsArr.gstatus === 'SUSPENDED' || sportList.match_suspend_bookmaker === 1 || sportList.match_suspend === 1) {
                                    tot = 0;
                                    isSuspended = "SUSPENDED";

                                    isSuspendedClass = 'suspended';
                                }

                                const teamName = oddsArr.nat.trim() || '';
                                teamNames.current['BOOKMAKER'][teamName] = teamName;

                                const teamEx = bookmakerTeamData?.[teamName] ?? 0;
                                if (!allTeamName.current['BOOKMAKER']) {
                                    allTeamName.current['BOOKMAKER'] = [];


                                }
                                if (!allTeamName.current['BOOKMAKER'].includes(teamName))
                                    allTeamName.current['BOOKMAKER'].push(teamName);
                                const runnerRow = `table-row-${sportList.match_id}-${key}`;

                                // Organize odds
                                const organizedOdds = organiseOdds(oddsArr);
                                const n_key = `${sportList.match_id}-${key}`;
                                if (!teamNameCurrentBets.current?.['BOOKMAKER']) {

                                    teamNameCurrentBets.current['BOOKMAKER'] = [];
                                    teamNameCurrentBets.current['BOOKMAKER'][teamName] = ''
                                }

                                teamNameCurrentBets.current['BOOKMAKER'][teamName] = teamEx
                                return (
                                    <div data-title={isSuspended} key={key}
                                         className={`table-row table-row-0-${key} ${isSuspendedClass}`}>
                                        <div className="float-left country-name box-4">
                                            <span className="team-name"><b>{teamName}</b></span>
                                            <p><span className="teamEx float-left">{getExByColor(teamEx, true)}</span>
                                                <span
                                                    className={`float-right ${placingBets['BOOKMAKER']?.[teamName] < 0 ? 'red-color' : 'green-color'} `}>{placingBets['BOOKMAKER']?.[teamName]}</span>
                                            </p>
                                        </div>
                                        {renderOdds(organizedOdds, n_key, teamName, runnerRow, tot)}
                                    </div>
                                );
                            })}

                            {bookmakerData.rem && (
                                <div className="market-row">
                                    <marquee className="market-remark">{bookmakerData.rem}</marquee>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Bookmaker;
