import Header from "./Section/Header";
import {useContext, useEffect, useState} from "react";
import axios from "axios";
import {AuthContext} from "../Components/Context/AuthContext";
import {io} from 'socket.io-client'
import {CommonContext} from "../Components/Context/CommonContext";

import {Link, useNavigate} from "react-router-dom";
import {SportsContext} from "../Components/Context/SportsContext";
import {Buffer} from "buffer";
import SidebarLayout from "./Section/SidebarLayout";
import Footer from "./Section/Footer";

function Home() {

    const navigate = useNavigate();
    const {setShowLoader} = useContext(SportsContext)

    let Back1 = [];
    let Lay1 = [];
    let Back2 = [];
    let Lay2 = [];

    let BackX = [];
    let LayX = [];


    const BackAndLayForSports = (apiData, model) => {


        if (apiData && Object.keys(apiData).length > 0) {

            let detailArray = apiData.detailData || apiData.game_detail;

            if (!Array.isArray(detailArray)) {
                detailArray = [detailArray];
            }


            if (detailArray && detailArray[0] && detailArray[0].mname.toLowerCase() === 'match_odds') {
                const gameData = detailArray[0];
                const firstGame = gameData.section[0];
                const secondGame = gameData.section[1];
                const thirdGame = gameData.section[2] || null;


                const firstGameOddsBack = firstGame.odds.findIndex(o => o.oname === 'back1');
                const firstGameOddsLay = firstGame.odds.findIndex(o => o.oname === 'lay1');
                const secondGameOddsBack = secondGame.odds.findIndex(o => o.oname === 'back2');
                const secondGameOddsLay = secondGame.odds.findIndex(o => o.oname === 'lay1');

                Back1[model.match_id] = firstGame.odds[firstGameOddsBack]?.odds || 0;

                Lay1[model.match_id] = firstGame.odds[firstGameOddsLay]?.odds || 0;
                Back2[model.match_id] = secondGame.odds[secondGameOddsBack]?.odds || 0;
                Lay2[model.match_id] = secondGame.odds[secondGameOddsLay]?.odds || 0;


                if (thirdGame) {
                    const thirdGameOddsBack = thirdGame.odds.findIndex(o => o.oname === 'back2');
                    const thirdGameOddsLay = thirdGame.odds.findIndex(o => o.oname === 'lay1');
                    BackX[model.match_id] = thirdGame.odds[thirdGameOddsBack]?.odds || '-';
                    LayX[model.match_id] = (thirdGame.odds[thirdGameOddsLay]?.odds || '-');
                }
            }


        }


    };

    const [matchesData, setMatchesData] = useState([])

    const [listData, setListData] = useState([]);

    const token = localStorage.getItem('token');
    useEffect(() => {
        let isMounted = true; // Flag to track if component is still mounted

// Define the async function
        const fetchData = async () => {
            try {
                let response = await axios.get(process.env.REACT_APP_API_URL + 'getMatchesDataForSocket', {
                    headers: {Authorization: `Bearer ${token}`}
                });

                setMatchesData(response.data); // Update state only if the component is still mounted
                setShowLoader(false); // Hide loader after data is fetched

            } catch (error) {
                if (isMounted) {
                    console.error("Error fetching data: ", error);
                    setShowLoader(false); // Hide loader if there's an error
                }
            }
        };

// Execute the async function
        if (isMounted) {
            fetchData();
        }

// Cleanup function to run when the component unmounts
        return () => {
            isMounted = false; // Set the flag to false to prevent state updates on unmounted component
        };
    }, [token]); // Add token as a dependency if needed


    useEffect(() => {


        if (matchesData !== null && matchesData.length > 0) {
            const list_socket = io(process.env.REACT_APP_LIST_URL)
            list_socket.emit('setPurposeFor', 'list', 1, '', '', matchesData)

            list_socket.on('getListData', userDatas => {
                if (userDatas !== null) {
                    userDatas = JSON.parse(Buffer.from(userDatas).toString('utf8'))

                    setListData(userDatas)

                }

            });
            return () => {
                list_socket.off('getListData'); // remove getListData event listener
                list_socket.disconnect(); // disconnect the socket
            };
        }
    }, [matchesData]);


    const [activeTab, setActiveTab] = useState('Cricket');


    const [tennisList, setTennisList] = useState([]);
    const [soccerList, setSoccerList] = useState([]);
    const {cricketList, unAuthorizeHandle, casinoList, setCricketList} = useContext(AuthContext);


    const {formatDateTime} = useContext(CommonContext);
    useEffect(() => {

        if (typeof token !== 'undefined') {
            loadCricketList()

        }

    }, []);


    useEffect(() => {

        localStorage.setItem('casinoList', JSON.stringify(casinoList))


    }, [casinoList])


    function loadCricketList() {

        const  cricketRequest = axios.get(process.env.REACT_APP_API_URL + "cricket_list", {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            if (error.code === 'ERR_NETWORK') {

//console.clear();
                unAuthorizeHandle()

                return navigate('/login');
            }

        });

        const soccerRequest = axios.get(process.env.REACT_APP_API_URL + "soccer_list", {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            if (error.code === 'ERR_NETWORK') {

//console.clear();
                unAuthorizeHandle()

                return navigate('/login');
            }

        });

        const tennisRequest = axios.get(process.env.REACT_APP_API_URL + "tennis_list", {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).catch((e) => {

        });

// Now use axios.all to call all the APIs at the same time
        axios.all([soccerRequest, tennisRequest, cricketRequest])
            .then(axios.spread((...responses) => {
// The responses come back in an array

                const soccerResponse = responses[0];
                const tennisResponse = responses[1];
                const cricketResponse = responses[2];

// Check soccer data
                if (typeof soccerResponse.data !== 'undefined') {
                    if (Object.entries(soccerResponse.data).length > 0) {
                        setSoccerList(prevList => [...prevList, ...soccerResponse.data]);
                    }

                }
// Check tennis data
                if (typeof tennisResponse.data !== 'undefined') {
                    if (Object.entries(tennisResponse.data).length > 0) {
                        setTennisList(prevList => [...prevList, ...tennisResponse.data]);
                    }
                }
                if (typeof cricketResponse.data !== 'undefined') {
                    if (Object.entries(cricketResponse.data).length > 0) {
                        setCricketList(cricketResponse.data);
                    }
                }

            }))
            .catch(errors => {
// react on errors.
                console.error(errors);
            });
    }


    return (
        <div>
            <Header/>


            <div className="main">
                <div className="container-fluid container-fluid-5">

                    <div className="row row5">
                        <SidebarLayout/>
                        <div className="col-md-10 featured-box home-page">
                            <div className="latest-event d-xl-flex">


                            </div>

                            <div className="d-block w-100">


                                <div>

                                    <ul role="tablist" id="home-events" className="nav nav-pills">
                                        <li className="nav-item"><span data-toggle="tab"
                                                                       data-option='cricketData'
                                                                       onClick={() => setActiveTab('Cricket')}
                                                                       className={`sport-nav nav-link ${activeTab === 'Cricket' ? 'active' : ""}`}>Cricket</span>
                                        </li>
                                        <li className="nav-item"><span data-toggle="tab"
                                                                       data-option='soccerData'

                                                                       onClick={() => setActiveTab('Football')}
                                                                       className={`sport-nav nav-link ${activeTab === 'Football' ? 'active' : ""}`}>Football</span>
                                        </li>
                                        <li className="nav-item"><span data-toggle="tab"
                                                                       data-option='tennisData'

                                                                       onClick={() => setActiveTab('Tennis')}
                                                                       className={`sport-nav nav-link ${activeTab === 'Tennis' ? 'active' : ""}`}>Tennis</span>
                                        </li>
                                        <li className="nav-item"><span data-toggle="tab"
                                                                       data-option='electionsData'
                                                                       className="sport-nav nav-link">Horse Racing</span>
                                        </li>
                                        <li className="nav-item"><span data-toggle="tab"
                                                                       data-option='nowDataFound'
                                                                       className="sport-nav nav-link">Table Tennis</span>
                                        </li>
                                        <li className="nav-item"><span data-toggle="tab"
                                                                       data-option='nowDataFound'
                                                                       className="sport-nav nav-link">Basketball</span>
                                        </li>
                                        <li className="nav-item"><span data-toggle="tab"
                                                                       data-option='nowDataFound'
                                                                       className="sport-nav nav-link">Volleyball</span>
                                        </li>
                                        <li className="nav-item"><span data-toggle="tab"
                                                                       data-option='electionsData'
                                                                       className="sport-nav nav-link">Snooker</span>
                                        </li>
                                        <li className="nav-item"><span data-toggle="tab"
                                                                       data-option='electionsData'
                                                                       className="sport-nav nav-link">Ice Hockey</span>
                                        </li>
                                        <li className="nav-item"><span data-toggle="tab"
                                                                       data-option='nowDataFound'
                                                                       className="sport-nav nav-link">E Games</span>
                                        </li>
                                        <li className="nav-item"><span data-toggle="tab"
                                                                       data-option='nowDataFound'
                                                                       className="sport-nav nav-link">Futsal</span></li>
                                        <li className="nav-item"><span data-toggle="tab"
                                                                       data-option='electionsData'
                                                                       className="sport-nav nav-link">Handball</span>
                                        </li>
                                        <li className="nav-item"><span data-toggle="tab"
                                                                       data-option='nowDataFound'
                                                                       className="sport-nav nav-link">Kabaddi</span>
                                        </li>

                                    </ul>

                                    <div className="tab-content">
                                        <div className="tab-pane active">
                                            <div className="coupon-card coupon-card-first">
                                                {activeTab === 'Cricket' && (

                                                    <div className="cricketData sportData">
                                                        <table className="table coupon-table">
                                                            <thead>
                                                            <tr>
                                                                <th style={{width: '63%'}}>Game</th>
                                                                <th colSpan="2">1</th>
                                                                <th colSpan="2">X</th>
                                                                <th colSpan="2">2</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {cricketList.map((cricket, index) => {
                                                                if (Object.keys(listData).length > 0) {
                                                                    BackAndLayForSports(listData[cricket.match_id], cricket)

                                                                } else if (Object.keys(JSON.parse(cricket.sport_new_data)).length > 0) {

                                                                    BackAndLayForSports(JSON.parse(cricket.sport_new_data), cricket)
                                                                }

                                                                return (<tr key={index}>
                                                                        <td>
                                                                            <div className="game-name">
                                                                                <Link className="event-name"
                                                                                      to={`/cricket/${cricket.match_id}`}>{cricket.match_name} {formatDateTime(new Date(cricket.match_date_time))}</Link>

                                                                            </div>
                                                                            <div className="game-icons">
                                                                                {cricket && cricket.isPlay !== 0 && (

                                                                                    <span className="game-icon"><span
                                                                                        className="active"></span></span>
                                                                                )}

                                                                                <span className="game-icon"><i
                                                                                    className="fas fa-tv icon-tv"></i></span>

                                                                                <span className="game-icon"><img
                                                                                    src="../img/icons/ic_fancy.png"
                                                                                    className="fancy-icon"/></span>

                                                                                    <span className="game-icon">
                                                                                                                                                                        {cricket && cricket.isBookmaker !== 0 && (

                                                                                                                                                                            <img
                                                                                        src="../img/icons/ic_bm.png"
                                                                                        className="fancy-icon"/>
                                                                                                                        )}

                                                                                    </span>

                                                                            </div>
                                                                        </td>

                                                                        <td>
                                                                            <button
                                                                                className={`back ${Back1?.[cricket.match_id] > 0 ? '' : 'suspended-box'}`}>
<span
    className="odd">{Back1?.[cricket.match_id] ?? '-'}</span>
                                                                            </button>
                                                                        </td>
                                                                        <td>
                                                                            <button
                                                                                className={`lay ${Lay1?.[cricket.match_id] > 0 ? '' : 'suspended-box'}`}>
<span
    className="odd">{Lay1?.[cricket.match_id] ?? '-'}</span>
                                                                            </button>
                                                                        </td>

                                                                        <td>
                                                                            <button
                                                                                className={`back ${BackX?.[cricket.match_id] > 0 ? '' : 'suspended-box'}`}>

<span
    className="odd">{BackX?.[cricket.match_id] ?? '-'}</span>
                                                                            </button>
                                                                        </td>
                                                                        <td>
                                                                            <button
                                                                                className={`lay ${LayX?.[cricket.match_id] > 0 ? '' : 'suspended-box'}`}>
<span
    className="odd">{LayX?.[cricket.match_id] ?? '-'}</span>
                                                                            </button>
                                                                        </td>

                                                                        <td>
                                                                            <button
                                                                                className={`back ${Back2?.[cricket.match_id] > 0 ? '' : 'suspended-box'}`}>
<span
    className="odd">{Back2?.[cricket.match_id] ?? '-'}</span>
                                                                            </button>
                                                                        </td>
                                                                        <td>
                                                                            <button
                                                                                className={`lay ${Lay2?.[cricket.match_id] > 0 ? '' : 'suspended-box'}`}>
<span
    className="odd">{Lay2?.[cricket.match_id] ?? '-'}</span>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                            </tbody>
                                                        </table>
                                                    </div>


                                                )}
                                                {activeTab === 'Tennis' && (
                                                    <div className="tennisData sportData">
                                                        <table className="table coupon-table">
                                                            <thead>
                                                            <tr>
                                                                <th style={{width: '63%'}}>Game</th>
                                                                <th colSpan="2">1</th>
                                                                <th colSpan="2">X</th>
                                                                <th colSpan="2">2</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {tennisList.map((cricket, index) => {
                                                                if (Object.keys(listData).length > 0) {
                                                                    BackAndLayForSports(listData[cricket.match_id], cricket)

                                                                } else if (Object.keys(JSON.parse(cricket.sport_new_data)).length > 0) {

                                                                    BackAndLayForSports(JSON.parse(cricket.sport_new_data), cricket)
                                                                }

                                                                return (<tr key={index}>
                                                                        <td>
                                                                            <div className="game-name">
                                                                                <Link className="event-name"
                                                                                      to={`/tennis/${cricket.match_id}`}>{cricket.match_name} {formatDateTime(new Date(cricket.match_date_time))}</Link>

                                                                            </div>
                                                                            <div className="game-icons">

<span className="game-icon">
{cricket && cricket.isPlay !== 0 && (

    <span
        className="active"></span>
)}
</span>


                                                                                <span className="game-icon"><i
                                                                                    className="fas fa-tv icon-tv"></i></span>

                                                                                <span className="game-icon"><img
                                                                                    src="../img/icons/ic_fancy.png"
                                                                                    className="fancy-icon"/></span>

                                                                                <span className="game-icon">
{cricket && cricket.isBookmaker !== 0 && (

    <img
        src="../img/icons/ic_bm.png"
        className="fancy-icon"/>
)}
</span>


                                                                            </div>
                                                                        </td>

                                                                        <td>
                                                                            <button
                                                                                className={`back ${Back1?.[cricket.match_id] > 0 ? '' : 'suspended-box'}`}>
<span
    className="odd">{Back1?.[cricket.match_id] ?? '-'}</span>
                                                                            </button>
                                                                        </td>
                                                                        <td>
                                                                            <button
                                                                                className={`lay ${Lay1?.[cricket.match_id] > 0 ? '' : 'suspended-box'}`}>
<span
    className="odd">{Lay1?.[cricket.match_id] ?? '-'}</span>
                                                                            </button>
                                                                        </td>

                                                                        <td>
                                                                            <button
                                                                                className={`back ${BackX?.[cricket.match_id] > 0 ? '' : 'suspended-box'}`}>

<span
    className="odd">{BackX?.[cricket.match_id] ?? '-'}</span>
                                                                            </button>
                                                                        </td>
                                                                        <td>
                                                                            <button
                                                                                className={`lay ${LayX?.[cricket.match_id] > 0 ? '' : 'suspended-box'}`}>
<span
    className="odd">{LayX?.[cricket.match_id] ?? '-'}</span>
                                                                            </button>
                                                                        </td>

                                                                        <td>
                                                                            <button
                                                                                className={`back ${Back2?.[cricket.match_id] > 0 ? '' : 'suspended-box'}`}>
<span
    className="odd">{Back2?.[cricket.match_id] ?? '-'}</span>
                                                                            </button>
                                                                        </td>
                                                                        <td>
                                                                            <button
                                                                                className={`lay ${Lay2?.[cricket.match_id] > 0 ? '' : 'suspended-box'}`}>
<span
    className="odd">{Lay2?.[cricket.match_id] ?? '-'}</span>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                            </tbody>
                                                        </table>
                                                        {/*<div className="norecords">No real-time records found</div>*/}
                                                    </div>
                                                )}
                                                {activeTab === 'Football' && (

                                                    <div className="soccerData sportData">
                                                        <table className="table coupon-table">
                                                            <thead>
                                                            <tr>
                                                                <th style={{width: '63%'}}>Game</th>
                                                                <th colSpan="2">1</th>
                                                                <th colSpan="2">X</th>
                                                                <th colSpan="2">2</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {soccerList.map((cricket, index) => {
                                                                if (Object.keys(listData).length > 0) {
                                                                    BackAndLayForSports(listData[cricket.match_id], cricket)

                                                                } else if (Object.keys(JSON.parse(cricket.sport_new_data)).length > 0) {

                                                                    BackAndLayForSports(JSON.parse(cricket.sport_new_data), cricket)
                                                                }

                                                                return (<tr key={index}>
                                                                        <td>
                                                                            <div className="game-name">
                                                                                <Link className="event-name"
                                                                                      to={`/soccer/${cricket.match_id}`}>{cricket.match_name} {formatDateTime(new Date(cricket.match_date_time))}</Link>

                                                                            </div>
                                                                            <div className="game-icons">
                                                                                {cricket && cricket.isPlay !== 0 && (

                                                                                    <span className="game-icon"><span
                                                                                        className="active"></span></span>
                                                                                )}

                                                                                <span className="game-icon"><i
                                                                                    className="fas fa-tv icon-tv"></i></span>

                                                                                <span className="game-icon"><img
                                                                                    src="../img/icons/ic_fancy.png"
                                                                                    className="fancy-icon"/></span>

                                                                                    <span className="game-icon">
                                                                                                                                                                        {cricket && cricket.isBookmaker !== 0 && (

                                                                                                                                                                            <img
                                                                                        src="../img/icons/ic_bm.png"
                                                                                        className="fancy-icon"/>
                                                                                                                                                )}

                                                                                    </span>

                                                                            </div>
                                                                        </td>

                                                                        <td>
                                                                            <button
                                                                                className={`back ${Back1?.[cricket.match_id] > 0 ? '' : 'suspended-box'}`}>
<span
    className="odd">{Back1?.[cricket.match_id] ?? '-'}</span>
                                                                            </button>
                                                                        </td>
                                                                        <td>
                                                                            <button
                                                                                className={`lay ${Lay1?.[cricket.match_id] > 0 ? '' : 'suspended-box'}`}>
<span
    className="odd">{Lay1?.[cricket.match_id] ?? '-'}</span>
                                                                            </button>
                                                                        </td>

                                                                        <td>
                                                                            <button
                                                                                className={`back ${BackX?.[cricket.match_id] > 0 ? '' : 'suspended-box'}`}>

<span
    className="odd">{BackX?.[cricket.match_id] ?? '-'}</span>
                                                                            </button>
                                                                        </td>
                                                                        <td>
                                                                            <button
                                                                                className={`lay ${LayX?.[cricket.match_id] > 0 ? '' : 'suspended-box'}`}>
<span
    className="odd">{LayX?.[cricket.match_id] ?? '-'}</span>
                                                                            </button>
                                                                        </td>

                                                                        <td>
                                                                            <button
                                                                                className={`back ${Back2?.[cricket.match_id] > 0 ? '' : 'suspended-box'}`}>
<span
    className="odd">{Back2?.[cricket.match_id] ?? '-'}</span>
                                                                            </button>
                                                                        </td>
                                                                        <td>
                                                                            <button
                                                                                className={`lay ${Lay2?.[cricket.match_id] > 0 ? '' : 'suspended-box'}`}>
<span
    className="odd">{Lay2?.[cricket.match_id] ?? '-'}</span>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )}


                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="home-products-container">
                                    <div className="row row5">
                                        <div className="col-md-12">
                                            <div className="casino-list mt-2">
                                                {casinoList.map((casino, index) => (

                                                    <div className="casino-list-item position-relative" key={index}>
                                                        <Link to={"casino/" + casino.match_id}
                                                              className="position-relative d-block">
                                                            <div
                                                                className="casino-list-item-banner"
                                                                style={{
                                                                    backgroundImage: `url(${process.env.REACT_APP_MAIN_URL + "/uploads/casino/" + casino.casino_image})`
                                                                }}>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                ))}


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

            <Footer/>
        </div>
    )
}


export default Home;