import React, {useContext, useState} from 'react';
import {
    chunkArray,
    generateBackAndLayFunction,
    getSize,
    handleShowRules, useFancyHideStatus
} from "../../../Common_Functions/Constants";
import {SportsContext} from "../../../Components/Context/SportsContext";
import FancyBetPopup from "./FancyBetPopup";
import {Link} from "react-router-dom";

const OverByOver = ({
                        gameData,
                        model,
                        showCricketSessionBook,
                        setPopupDisplay, betPlaceStatus,
                        setbackOrLay,
                        setBetOddValue,
                        teamNames,
                        setDefaultTeamName
                    }) => {

    const fancyHideStatus = useFancyHideStatus(model, gameData);

    const [closePopup, setClosePopup] = useState(false)
    const {runnerRowDefault, rootClassDefault, setBetType, oddsk, setBetTypeFromArray} = useContext(SportsContext);

    const [fancyData, setFancyData] = useState(null)

    if (!gameData || !gameData['over by over']) return null;


    const mainValue = gameData['over by over'];
    let minValue = model.fancy_min_limit;
    let maxValue = model.fancy_max_limit;

    if (mainValue.min) {
        minValue = mainValue.min;
    }

    if (mainValue.max) {
        maxValue = mainValue.max;
    }

    minValue = getSize(minValue, true);
    maxValue = getSize(maxValue, true);

    const arSectionData = mainValue.section || [];


    return (
        <div className="fancy-market row row5">
            <div className="col-12">
                <div>
                    <div className="market-title">
                        <span>Over By Over</span>
                        <span className="m-r-5 game-rules-icon" onClick={() => handleShowRules('Fancyrule')}>
                    <span><i className="fa fa-info-circle float-right"/></span>
                </span>
                    </div>
                    <div className="row">
                        {chunkArray(arSectionData, 2, true).map((arr, vaee )=>(
                        <div className={arSectionData.length > 1 ? "col-6"  : "col-12"} key={vaee}>
                        <div className="table-header">
                            <div className="box-5 float-left country-name"></div>
                            <div className="box-1 float-left back text-center"><b>Yes</b></div>
                            <div className="box-1 float-left lay text-center"><b>No</b></div>
                            <div className="box-2 float-left"></div>
                        </div>
                        <div className="table-body SESSION">
                            {arr.map((oddsArr, key) => {
                                const teamName = oddsArr.nat.trim();

                                if (fancyHideStatus[oddsArr.sid] || !teamName || teamName.trim() === '') {
                                    return null;
                                }


                                let back = 0, lay = 0, backk = '0', layk = '0';
                                const odds = oddsArr.odds || [];

                                oddsArr['back'] = [];
                                oddsArr['lay'] = [];

                                let no = 0;


                                odds.forEach(aValue => {
                                    const tno = aValue.tno;
                                    if (aValue.otype === "back") {
                                        oddsArr['back'][tno] = aValue;
                                        back = aValue.odds;
                                        backk = aValue.size;
                                    } else if (aValue.otype === "lay") {
                                        oddsArr['lay'][no] = aValue;
                                        lay = aValue.odds;
                                        layk = aValue.size;
                                        no++;
                                    }
                                });

                                let total = (parseFloat(back) + parseFloat(lay));
                                if (model.match_suspend_fancy === 1 || model.match_suspend === 1) {
                                    total = 0;
                                }

                                let isSuspendedClass = '';
                                let isSuspended = '';

                                const gstatus = oddsArr.gstatus ? oddsArr.gstatus.toUpperCase() : "";
                                if (gstatus && ['SUSPENDED', 'BALL_RUNNING', 'MATCH-SUSPENDED'].includes(gstatus)) {
                                    total = 0;
                                }

                                if (total === 0) {
                                    isSuspended = gstatus || 'SUSPENDED';
                                    isSuspendedClass = 'suspended suspend_box';
                                }

                                let backFunctionSes = [], layFunctionSes = [];



                                backFunctionSes = generateBackAndLayFunction(total, oddsArr, 'back', teamName, 0, key, 'SESSION', setBetOddValue, setbackOrLay, teamNames, setPopupDisplay, setDefaultTeamName, runnerRowDefault, rootClassDefault, setBetType, oddsk, setBetTypeFromArray, 'over by over');

                                layFunctionSes = generateBackAndLayFunction(total, oddsArr, 'lay', teamName, 0, key, 'SESSION', setBetOddValue, setbackOrLay, teamNames, setPopupDisplay, setDefaultTeamName, runnerRowDefault, rootClassDefault, setBetType, oddsk, setBetTypeFromArray, 'over by over');

                                back = back > 0 ? parseFloat(back) : "-";
                                backk = backk > 0 ? parseFloat(backk) : "";
                                lay = lay > 0 ? parseFloat(lay) : "-";
                                layk = layk > 0 ? parseFloat(layk) : "";


                                const betPlaceCheck = betPlaceStatus?.current?.[teamName];

                                const fancyListValues = Object.values(betPlaceCheck?.fancy_list || []);

                                const min_value = fancyListValues.length > 0 ? Math.min(...fancyListValues) : null;


                                const get_fancy_session_value = betPlaceCheck?.fancy_list ? (
                                    <span className="span_fancy_session_value color-font-red">{min_value}</span>
                                ) : null;

                                if (oddsArr.min) {
                                    minValue = oddsArr.min;
                                }

                                if (oddsArr.max) {
                                    maxValue = oddsArr.max;
                                }

                                minValue = getSize(minValue, true);
                                maxValue = getSize(maxValue, true);
                                const remark = oddsArr.rem || '';

                                return (
                                    <div className={`fancy-tripple over-by-over-${key}`} key={key}>
                                        <div data-title={isSuspended}
                                             className={`table-row w-100 sessionchecksuspended ${isSuspendedClass}`}>
                                            <div className="float-left country-name box-5"
                                                 style={{borderBottom: '0px none'}}>
                                                <p className="m-b-0">
                                                    {betPlaceCheck?.bet ? (
                                                        <Link className="link-session"
                                                              onClick={() => showCricketSessionBook(teamName, model.id, setFancyData)}>
                                                            {teamName}
                                                        </Link>
                                                    ) : (
                                                        <span>{teamName}</span>
                                                    )}
                                                </p>
                                                <p className="m-b-0">
                                                    {get_fancy_session_value}
                                                </p>
                                                {remark && (
                                                    <div className="market-row">
                                                        {/* eslint-disable-next-line jsx-a11y/no-distracting-elements */}
                                                        <marquee className="market-remark">{remark}</marquee>
                                                    </div>
                                                )}
                                            </div>

                                            <div className="box-1 back float-left text-center"
                                                 onClick={backFunctionSes[0]}>
                                                <span className="odd d-block">{back}</span>
                                                <span>{getSize(backk, false)}</span>
                                            </div>
                                            <div className="box-1 lay float-left text-center"
                                                 onClick={layFunctionSes[0]}>
                                                <span className="odd d-block">{lay}</span>
                                                <span>{getSize(layk, false)}</span>
                                            </div>
                                            <div className="box-2 float-left text-right min-max"
                                                 style={{borderBottom: "0px none"}}>
                                                <span className="d-block">Min: <span>100&nbsp;</span></span>
                                                <span className="d-block">Max: <span>25K&nbsp;</span></span>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <FancyBetPopup fancyData={fancyData} setFancyData={setFancyData} closePopup={closePopup}
                                       setClosePopup={setClosePopup}/>
                    </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OverByOver;
