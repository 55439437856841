import React, {useContext, useEffect, useRef, useState} from 'react';
import {AuthContext} from "../../Components/Context/AuthContext";
import axiosFetch from "../../Common_Functions/Constants";
import {Link, useParams} from "react-router-dom";
import AfterLoginPopup from "../../Components/AfterLoginPopup";
import CurrentBetModal from "../../Components/CurrentBetModal";
import DesktopRulesModal from "../../Components/DesktopRulesModal";
import EditStakeModal from "../../Components/EditStakeModal";

function Header() {
    const dropdownRef = useRef('');  // Ref for the dropdown

    const [currentBetModalOpen, setCurrentBetModalOpen] = useState(false)

    const [userData, setUserData] = useState(null);

    const {match} = useParams();
    const {userBalance, exposure, setIsLoggedIn, clearIntervals, setShowPopupAfterRedirect, showPopupAfterRedirect} = useContext(AuthContext);

    useEffect(() => {

        const handleDocumentClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false); // Close the dropdown
            }
        };

        // Add event listener on mount
        document.addEventListener('click', handleDocumentClick);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    useEffect(() => {

        const storedUserData = localStorage.getItem("userData");

        if (storedUserData !== "undefined") {

            setUserData(JSON.parse(storedUserData));
        }


    }, []);


    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleLogout = () => {

        axiosFetch('logout', 'post')
        setIsLoggedIn(false)
        localStorage.clear();

        clearIntervals('navigate');


    }




    const toggleModal = () => {
        setShowPopupAfterRedirect(!showPopupAfterRedirect)
        setIsLoggedIn(true)

        localStorage.setItem('isLoggedIn', true)

    };

    const [ruleModal, setRuleModal] = useState(false)
    const [stakeModal, setStakeModal] = useState(false)

    const showStakePopup = () => {

        setStakeModal(!stakeModal)
    };

    const handleRuleModalClose = () => {
        setRuleModal(!ruleModal)
    }

    return (
        <>
            <link rel="stylesheet" href="//dxc247.com/build/assets/app-I4Aqxzlf.css"/>
            <link rel="stylesheet" href="/mobile/css/mobile_all.css"/>
            <header className="header">
                <DesktopRulesModal showModal={ruleModal} handleClose={handleRuleModalClose} />
                {showPopupAfterRedirect === true && (
                    <AfterLoginPopup onClose={toggleModal} show={showPopupAfterRedirect}/>
                )}
                <EditStakeModal handleClose={showStakePopup} show={stakeModal}  />
                <div className="container-fluid">
                    <div className="row">
                        <div className="header-top col-md-12">
                            <div className="float-left">
                                <Link to="/" className="logo router-link-exact-active router-link-active">
                                    <img src="../img/logo.png" className="logo-icon"/>
                                </Link>
                            </div>
                            <ul className="float-right">
                            <li className="search float-left">
                                    <input name="game_keyword" placeholder="All Events" autoComplete="off" type="text"
                                           className=""/><span><i
                                    className="fas fa-search-plus"></i></span>
                                </li>
                                <li className="float-left download-apklink">
                                    <div><span onClick={handleRuleModalClose}
                                            className="rules-link m-r-5"><b>Rules</b></span></div>
                                    <div><span ><span><b>Download Apk</b> <i
                                        className="fab fa-android"></i></span></span></div>
                                </li>
                                <li className="ballance float-left">

                                        <div>
                                            <div>
                                                <span>Balance:</span>
                                                <b><span>{userBalance}</span></b>
                                            </div>
                                            <div>
                                            <span onClick={() => setCurrentBetModalOpen(true)}>
                                                <span className="t-underline">Exposure:</span>
                                                <b><span className="t-underline">{exposure}</span></b>
                                            </span>
                                            </div>
                                        </div>



                                </li>
                                <li className="account float-left" onClick={toggleDropdown}>
                                    <span className="text-uppercase">
                                    {userData ? (userData.is_demo === 0 ? userData.username.toUpperCase() : userData.name) : ''}
                                        <i className="fas fa-chevron-down"></i></span>
                                    <ul className={isDropdownOpen ? 'd-block' : ""}>
                                        <li><Link to="/account-statement"
                                                  className="accountMenu">Account Statement</Link></li>
                                        <li><Link to="/profit-loss"
                                                  className="accountMenu">Profit Loss Report</Link></li>
                                        <li>
                                            <Link onClick={() => setCurrentBetModalOpen(true)}
                                                  className="accountMenu">Current Bet</Link>
                                        </li>
                                        <li><Link to="/bet-history"
                                                  className="accountMenu">Bet History</Link></li>
                                        <li><Link to="/casino-game-results"
                                                  className="accountMenu">Casino Report History</Link></li>
                                        <li><Link onClick={showStakePopup}
                                                  className="accountMenu">Set Button Values</Link></li>
                                        {userData && userData.is_demo === 0 && (
                                            <li><Link to="{{route('frontend.auth.change-password',auth()->user())}}"
                                                      className="">Change Password</Link></li>
                                        )}

                                            <hr/>

                                        <li >
                                            <Link
                                                onClick={handleLogout}>
                                                Sign Out
                                            </Link>

                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            {/*<marquee scrollamount="3">{{getConfigurationValue('FRONTEND_USER_ANNOUNCEMENT')}}</marquee>*/}
                        </div>
                        <div className="header-bottom m-t-10 col-md-12">
                            <nav className="navbar navbar-expand-md btco-hover-menu">
                                <button type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
                                        aria-controls="navbarNavDropdown" aria-expanded="false"
                                        aria-label="Toggle navigation" className="navbar-toggler"><span
                                    className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse">
                                    <ul className="list-unstyled navbar-nav">
                                        <li className={`nav-item ${match === undefined ? 'active' : ''}`}>
                                            <Link to="/"
                                                  className="router-link-exact-active router-link-active"><b>Home</b></Link>
                                        </li>
                                        <li className={`nav-item ${match === 'cricket' ? 'active' : ''}`}>
                                            <Link to="/game-list/cricket" className=""><b>Cricket</b></Link>
                                        </li>
                                        <li className={`nav-item ${match === 'tennis' ? 'active' : ''}`}>
                                            <Link to="/game-list/tennis" className=""><b>Tennis</b></Link>
                                        </li>
                                        <li className={`nav-item ${match === 'soccer' ? 'active' : ''}`}>
                                            <Link to="/game-list/soccer" className=""><b>Football</b></Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/game-list/table-tennis" className=""><b>Table Tennis</b></Link>
                                        </li>
                                        <li className={`nav-item ${match === 'baccarat' ? 'active' : ''}`}>
                                            <Link to="/casino/baccarat" className=""><span><b>Baccarat</b></span></Link>
                                        </li>
                                        <li className={`nav-item ${match === 'card32' ? 'active' : ''}`}>
                                            <Link to="/casino/card32" className=""><span><b>32 Cards</b></span></Link>
                                        </li>
                                        <li className={`nav-item ${match === 'teen20' ? 'active' : ''}`}>
                                            <Link to="/casino/teen20" className=""><span><b>Teenpatti</b></span></Link>
                                        </li>
                                        <li className={`nav-item ${match === 'poker' ? 'active' : ''}`}>
                                            <Link to="/casino/poker" className=""><span><b>Poker</b></span></Link>
                                        </li>
                                        <li className={`nav-item ${match === 'lucky7' ? 'active' : ''}`}>
                                            <Link to="/casino/lucky7" className=""><span><b>Lucky 7</b></span></Link>
                                        </li>


                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>


            </header>
            <CurrentBetModal isOpen={currentBetModalOpen} onClose={setCurrentBetModalOpen} dialogClass="modal-xl"/>

        </>
    );
}

export default Header;
