import CasinoLayout from "../Section/Casino/CasinoLayout";
import {useContext, useEffect, useRef, useState} from "react";

import {CasinoLastResult} from "../Section/Casino/CasinoLastResult";

import axiosFetch, {
    cardMap,
    getExByColor, getExBySingleTeamNameCasino,

    getExByTeamNameForCasino, resetBetFields,

} from "../../Common_Functions/Constants";
import {useParams} from "react-router-dom";
import {SportsContext} from "../../Components/Context/SportsContext";


import Notify from "../../js/Notify";

const Aaa2 = () => {
        const [roundId, setRoundId] = useState('')

    const ruleDescription = `  <div class="rules-section">
                                <ul class="pl-4 pr-4 list-style">
                                    <li>If the card is ACE,2,3,4,5, or 6 Amar Wins.</li>
                                    <li>If the card is 7,8,9 or 10 Akbar wins.</li>
                                    <li>If the card is J,Q or K Anthony wins.</li>
                                </ul>
                            </div>
 <div class="rules-section">
                                <p>
                                    <b class="rules-sub-highlight">EVEN</b>
                                    <span class="ml-2">(PAYOUT 2.12)</span>
                                </p>
                                <ul class="pl-4 pr-4 list-style">
                                    <li>If the card is 2 , 4 , 6 , 8 , 10 , Q</li>
                                </ul>
                                <p>
                                    <b class="rules-sub-highlight">ODD</b>
                                    <span class="ml-2">(PAYOUT 1.83)</span>
                                </p>
                                <ul class="pl-4 pr-4 list-style">
                                    <li>If the card is A , 3 , 5 , 7 , 9 , J , K</li>
                                </ul>
                                <p>
                                    <b class="rules-sub-highlight">RED</b>
                                    <span class="ml-2">(PAYOUT 1.97)</span>
                                </p>
                                <ul class="pl-4 pr-4 list-style">
                                    <li>If the card color is DIAMOND or HEART</li>
                                </ul>
                                <p>
                                    <b class="rules-sub-highlight">BLACK</b>
                                    <span class="ml-2">(PAYOUT 1.97)</span>
                                </p>
                                <ul class="pl-4 pr-4 list-style">
                                    <li>If the card color is CLUB or SPADE</li>
                                </ul>
                                <p>
                                    <b class="rules-sub-highlight">UNDER 7</b>
                                    <span class="ml-2">(PAYOUT 2.0)</span>
                                </p>
                                <ul class="pl-4 pr-4 list-style">
                                    <li>If the card is A , 2 , 3 , 4 , 5 , 6</li>
                                </ul>
                                <p>
                                    <b class="rules-sub-highlight">OVER 7</b>
                                    <span class="ml-2">(PAYOUT 2.0)</span>
                                </p>
                                <ul class="pl-4 pr-4 list-style">
                                    <li>If the card is 8 , 9 , 10 , J , Q , K</li>
                                </ul>
                                <p>
                                    <b>Note: </b>
                                    <span>If the card is 7, Bets on under 7 and over 7 will lose 50% of the bet amount.</span>
                                </p>
                                <p>
                                    <b class="rules-sub-highlight">CARDS</b>
                                    <span class="ml-2">(PAYOUT 12.0)</span>
                                </p>
                                <ul class="pl-4 pr-4 list-style">
                                    <li>A , 2 , 3 , 4 , 5 , 6 , 7 , 8 , 9 , 10 , J , Q , K</li>
                                </ul>
                            </div>
`
    const values = {status: "suspended-box", amounts: ""}
        const defaultValues = {odds: {back: 0, lay: 0}, ...values}
        const [totalPlayers, setTotalPlayers] = useState({

            "Amar": {canonical_name: "A. Amar", ...defaultValues},
            "Akbar": {canonical_name: "A. Akbar", ...defaultValues},
            "Anthony": {canonical_name: "A. Anthony", ...defaultValues},
            "Even": {...values, odds: 0, canonical_name: "Even", type: "ODD_EVEN"},
            "Odd": {...values, odds: 0, canonical_name: "Odd", type: "ODD_EVEN"},
            "Red": {...values, odds: 0, canonical_name: "Red", type: "RED_BLACK"},
            "Black": {...values, odds: 0, canonical_name: "Black", type: "RED_BLACK"},

            "UNDER 7": {...values, odds: 0, canonical_name: "Under 7", type: "UNDER_OVER"},


            "OVER 7": {...values, odds: 0, canonical_name: "Over 7", type: "UNDER_OVER"},
            "Cards": (Array.from({length: 13}, (_, index) => {

                index += 1;
                return {
                    imagePath: '/img/card/' + index + ".jpg", // Image path
                    ...values,
                    img_path: "Card " + cardMap(index, false),
                    odds: 0 // Spread default values into each card object
                };
            })),

        })


        const roundIdSaved = useRef(null);

        const [submitButtonDisable, setSubmitButtonDisable] = useState(false)

        const [cards, setCards] = useState({});

        const stakeValue = useRef(0);
        const [odds, setOdds] = useState(0)

        const [backOrLay, setbackOrLay] = useState('back')
        const [sportList, setSportList] = useState({})
        const {match_id} = useParams();
        const {
            setBetType,
            betType,
            setPopupDisplayForDesktop,

        } = useContext(SportsContext)
        const [hideLoading, setHideLoading] = useState(true)


        const teamNames = useRef(["Player A", "Player B"])

        const [data, setData] = useState([]);

        const remark = useRef('Welcome');
        const [lastResult, setLastResult] = useState({});
        const teamname = useRef('');
        const loss = useRef(0);
        const profit = useRef(0);
        const profitData = useRef(0);


        useEffect(() => {


            if (data?.sub) {

                setTotalPlayers((prevState) => {
                    Object.entries(prevState).map(([index, value], i) => {

                        const foundData = data.sub.find(item => item.nat === index || item.nat === value.canonical_name)


                        if (foundData) {
                            prevState[index] = {
                                ...prevState[index],

                                status: foundData.gstatus === 'OPEN' ? '' : 'suspended-box',
                            }
                            if (['Amar', 'Akbar', 'Anthony'].includes(index)) {
                                prevState[index] = {
                                    ...prevState[index],
                                    odds: {back: foundData.b, lay: foundData.l},

                                }
                            } else {
                                prevState[index] = {
                                    ...prevState[index],
                                    odds: foundData.b,

                                }
                            }
                        }

                        const cardFoundData = data.sub.find(item => item.nat === 'Card A')

                        if (cardFoundData) {
                            prevState['Cards'] = Object.entries(prevState['Cards']).map(([index, value], i) => {

                                return {
                                    ...value,
                                    odds: cardFoundData.b,
                                    status: cardFoundData.gstatus === 'OPEN' ? '' : 'suspended-box',
                                };

                            })

                        }
                    })
                    return prevState

                })


            }

            if (data.card) {
                const cardArray = data.card.split(",").map(item => item.trim());
                setCards({
                    playerA: cardArray.slice(0, 3),
                    playerB: cardArray.slice(3, 6),
                });
                remark.current = data.remark || 'Welcome';
            }
        }, [data]);

        const exposure = localStorage.getItem('exposure');
        const sportLength = Object.keys(data).length;


        const updateAmounts = async () => {
            const results = await Promise.all([
                getExByTeamNameForCasino(sportList.id, roundId, 'Amar', match_id, 'ODDS'),
                getExByTeamNameForCasino(sportList.id, roundId, 'Akbar', match_id, 'ODDS'),
                getExByTeamNameForCasino(sportList.id, roundId, 'Anthony', match_id, 'ODDS'),
                getExBySingleTeamNameCasino(sportList.id, roundId, '', match_id, '')
            ]);

            setTotalPlayers((prevState) => {
                const updatedState = {...prevState}; // Create a new state object

                Object.entries(prevState).forEach(([index, value], i) => {
                    if (['Amar', 'Akbar', 'Anthony'].includes(index)) {
                        // For Amar, Akbar, and Anthony
                        updatedState[index] = {
                            ...value,
                            amounts: results[i]?.data || ''
                        };
                    } else if (index === 'Cards') {
                        // For Cards
                        Object.entries(value).forEach(([cardIndex, cardValue]) => {
                            const cardFound = results[3]?.data.find(item => item.team_name === cardValue.img_path);
                            updatedState[index][cardIndex] = {
                                ...cardValue,
                                amounts: cardFound?.total_amount || ''
                            };
                        });
                    } else {
                        // For other teams
                        const dataFound = results[3]?.data.find(item => item.team_name === index);
                        updatedState[index] = {
                            ...value,
                            amounts: dataFound?.total_amount || ''
                        };
                    }
                });

                return updatedState; // Return the new state
            });
        };

        useEffect(() => {

            if (data?.sub && sportList?.id) {
                updateAmounts()
            }
        }, [exposure, sportLength, roundId]);


        const openPopup = (isBakOrLay, teamnam, oddvalue, betType) => {

            setBetType(betType)

            if (parseFloat(oddvalue) > 0) {
                roundIdSaved.current = roundId
                setbackOrLay(isBakOrLay)
                setPopupDisplayForDesktop(true);
                teamname.current = teamnam
                setOdds(oddvalue)
            } else {
                Notify("Odds Value Change Bet Not Confirm", null, null, 'danger')

            }


        }
        const casinoBetDataNew = (new_odds) => {
            stakeValue.current = new_odds
            if (backOrLay === 'back') {


                loss.current = stakeValue.current;


                profit.current = profitData.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)

            } else {

                profit.current = profitData.current = stakeValue.current;


                loss.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)
            }


        }

        const renderCards = (cards, player) => (
            <div className="flip-card-container">
                {cards?.map((card, index) => {
                    const imgSrc = card ? `/img/casino/cards/${card}.png` : '/img/casino/cards/1.png';
                    return (
                        <div className="flip-card" key={index}>
                            <div className="flip-card-inner">
                                <div className="flip-card-front">
                                    <img src={imgSrc} alt={`${player} card ${index + 1}`}/>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );

        const placeBet = () => {

            setHideLoading(false)

            if (totalPlayers['Amar'].status !== '') {
                Notify(`Bet Not Confirmed. Reason: Game Suspended`, null, null, 'danger');
                setPopupDisplayForDesktop(false);
                setHideLoading(true);
                resetBetFields(profitData, stakeValue)


                return;
            }

            if (roundIdSaved.current !== roundId) {
                Notify("Bet Not Confirm Reason Game Change", null, null, 'danger')
                setPopupDisplayForDesktop(false)
                setHideLoading(true);
                resetBetFields(profitData, stakeValue)

                return;
            }

            setSubmitButtonDisable(true)


            const postdata = {

                "sportId": sportList.id,
                "matchId": roundId,
                "isback": backOrLay === 'back' ? 1 : 0,
                "placeName": teamname.current,
                "placeName2": null,
                "odds": odds,
                "oddsk": 0,
                "profit": parseFloat(profit.current).toFixed(2),
                "loss": parseFloat(loss.current).toFixed(2),
                "betType": betType,
                "bet_side": backOrLay.toUpperCase(),
                "betAmount": parseFloat(stakeValue.current),
                "type": match_id.toUpperCase(),
                "matchType": match_id.toLowerCase(),

            }

            axiosFetch('casino/store', 'post', null, postdata)
                .then((res) => {
                    if (res.data.status === true) {
                        updateAmounts()

                        resetBetFields(profitData, stakeValue)
                        Notify("Bet Placed Successfully", null, null, 'success')
                    } else {

                        resetBetFields(profitData, stakeValue)
                        Notify(res.data.msg, null, null, 'danger')
                    }
                    setHideLoading(true)
                    setSubmitButtonDisable(false)
                    setPopupDisplayForDesktop(false)
                })


        }

        return (

            <CasinoLayout ruleDescription={ruleDescription} raceClass="" hideLoading={hideLoading} isBack={backOrLay}
                          teamname={teamname} handleStakeChange={casinoBetDataNew} odds={odds}
                          stakeValue={stakeValue} setOdds={setOdds} placeBet={placeBet}
                          submitButtonDisable={submitButtonDisable} data={data} roundId={roundId} setRoundId={setRoundId}
                          sportList={sportList}
                          setSportList={setSportList} setData={setData} setLastResult={setLastResult}>

                <div className="video-container" style={{minHeight: '0px'}}>
                    <div className="video-overlay" style={{top: '-300px'}}>
                        <div className="casino-video-cards">
                            <div>
                                {renderCards(cards.playerA, "Player A")}
                            </div>

                        </div>
                    </div>
                </div>

                <div className="casino-page-container aaa">
                    <div className="casino-detail">
                        <div className="casino-table">
                            <div className="casino-table-box">
                                {Object.entries(totalPlayers).slice(0, 3).map(([index, value], i) => (
                                    <div className="casino-odd-box-container" key={i}>
                                        <div className="casino-nation-name">
                                            {value.canonical_name}
                                            <div className="casino-nation-book d-md-none"></div>
                                        </div>
                                        <div
                                            className={`casino-odds-box back ${value.status}`}
                                            onClick={() => openPopup('back', index, value.odds.back, 'ODDS')}
                                        >
                                            <span className="casino-odds"><b>{value.odds.back}</b></span>
                                        </div>
                                        <div
                                            className={`casino-odds-box lay ${value.status}`}
                                            onClick={() => openPopup('lay', index, value.odds.lay, 'ODDS')}
                                        >
                                            <span className="casino-odds"><b>{value.odds.lay}</b></span>
                                        </div>
                                        <div className="casino-nation-book text-center d-none d-md-block w-100">
                                            <div className="text-danger">{getExByColor(value.amounts)}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="container-fluid container-fluid-5 mt-2">
                            <div className="row row5">
                                <EvenSection data={Object.entries(totalPlayers).slice(3, 5)} openPopup={openPopup}/>
                                <EvenSection data={Object.entries(totalPlayers).slice(5, 7)} openPopup={openPopup} table="casino-table-center-box"/>
                                <EvenSection data={Object.entries(totalPlayers).slice(7, 9)} openPopup={openPopup} table="casino-table-right-box"/>


                            </div>
                        </div>

                        <div className="casino-table-full-box aaacards mt-3">
                            <h4 className="w-100 text-center mb-2"><b>{totalPlayers['Cards'][0].odds}</b></h4>


                                    {Object.entries(totalPlayers['Cards']).map(([index, value], i) => (
                                        <div className="card-odd-box" key={i}>
                                            <div
                                                className={value.status}
                                                onClick={() => openPopup('back', `Card ${cardMap(i)}`, totalPlayers['Cards'][0].odds, 'CARD')}
                                            >
                                                <img src={value.imagePath} alt={`Card ${i + 1}`}/>
                                            </div>
                                            <div className="casino-nation-book">
                                                <div style={{color: "black"}}>

                                                    {getExByColor(value.amounts)}

                                                </div>
                                            </div>
                                        </div>
                                    ))}

                        </div>

                    </div>
                    <marquee scrollamount="3" className="casino-remark m-b-10">{remark.current}</marquee>
                    <div className="casino-last-result-title">
                        <span>Last Result</span>
                    </div>
                    <div className="casino-last-results">
                        <CasinoLastResult sportList={sportList} lastResults={lastResult} data={data}/>
                    </div>
                </div>


            </CasinoLayout>
        );

    }
;

const EvenSection = ({data, openPopup, table=" casino-table-left-box"}) => {


    return (<div className={`col-4 ${table}`}>
        <div className="light-bg pl-1 pr-1">

            {data.map(([index, value], key) => (
                <div className="mt-1" key={key}>
                    <p className="d-block mb-0 text-center"><b>{value.odds}</b></p>
                    <button className={`btn-theme mt-1 text-uppercase ${value.status}`}
                            onClick={() => openPopup('back', index, value.odds, value.type)}
                    >
                        {index === 'Red' || index === 'Black' ? (
                            index === 'Red' ? (
                                <>
                                    <span className="card-icon"><span className="card-red">{"{"}</span></span>
                                    <span className="card-icon"><span className="card-red">[</span></span>
                                </>
                            ) : (
                                <>
                                    <span className="card-icon"><span className="card-black">{"}"}</span></span>
                                    <span className="card-icon"><span className="card-black">]</span></span>
                                </>
                            )
                        ) : (
                            <span>{index}</span>
                        )}

                    </button>
                    <p className="mt-1 mb-0 text-center">
                        {getExByColor(value.amounts)}
                    </p>
                </div>
            ))}

        </div>
    </div>)

}

export default Aaa2;
