import React, {useContext, useEffect, useRef, useState} from "react";
import SportsLayout from "../Section/SportsLayout";
import {getExByTeamNameForCricket} from "../../Common_Functions/Constants";
import MatchOdds from "../Section/AllSports/MatchOdds";
import {SportsContext} from "../../Components/Context/SportsContext";
import Bookmaker from "../Section/AllSports/Bookmaker";
import {useNavigate, useParams} from "react-router-dom";
import {Buffer} from "buffer";
import CommonLayout from "../Section/CommonLayout";

const Soccer = () => {
    const nav = useNavigate();

    const trackData = useRef({});
    const teamNames = useRef({});
    const [betOddValue, setBetOddValue] = useState(0)
    const [backOrLay, setbackOrLay] = useState('back')
    const {
        popupDisplay,
        setPopupDisplay,
        betType,
        sports_socket,
        betPlaceStatusCheck,
        betPlaceStatus,
        setShowLoader,
        showLoader,
        defaultTeamDatasCalculation
    } = useContext(SportsContext)


    const [sportList, setSportList] = useState({})

    const defaultTeamName = useRef('')
    const allTeamName = useRef([])
    const teamNameCurrentBets = useRef({})
    const {match_id} = useParams();


    const [oddsTeamData, setOddsTeamData] = useState({})
    const [bookmakerTeamData, setBookmakerTeamData] = useState({})

    useEffect(() => {
        if (Object.keys(sportList).length > 0) {

            defaultTeamDatasCalculation(sportList, setOddsTeamData, setBookmakerTeamData)
        }

    }, [sportList]);

    const callTeamDatas = (betType = {
        'ODDS': 'match_odds', 'BOOKMAKER': 'bookmaker',

    }) => {

        try {
            if (Object.keys(sportList).length > 0) {

                Object.entries(betType).forEach(([index, value]) => {

                    switch (index) {
                        case 'ODDS' :

                            getExByTeamNameForCricket(ar_sectionData, index, value, sportList.id, oddsTeamData, setOddsTeamData);
                            break;
                        default  :
                            getExByTeamNameForCricket(ar_sectionData, index, value, sportList.id, bookmakerTeamData, setBookmakerTeamData)

                    }


                })


            }


        } catch (error) {
            console.error("Error fetching team data:", error);
        }
    };

    const [ar_sectionData, setAr_sectionData] = useState([])

    const arlength = Object.keys(ar_sectionData).length

    useEffect(() => {
        if (Object.keys(sportList).length > 0 && arlength > 0) {
            callTeamDatas();
        }
// eslint-disable-next-line
    }, [sportList, arlength, localStorage.getItem('exposure')]);

    const oddsChange = useRef({});


    useEffect(() => {
        let gamename = 'soccer'
        let socket_game = `getSportData${gamename}${match_id}`;

        setShowLoader(true)

        sports_socket.emit('setPurposeFor', 'sports', gamename, '', '', match_id)
        let emptyCheckTimeout;
            const gameConnect = () => {
                sports_socket.on(socket_game, sportData => {

                    if (sportData !== null) {

                        const gameSet = [];
                        sportData = JSON.parse(Buffer.from(sportData).toString('utf8'))

                        if (sportData && sportData.game_detail && sportData.game_detail.length > 0) {

                            setShowLoader(false);

                            for (let i = 0; i < sportData.game_detail.length; i++) {
                                const value = sportData.game_detail[i];

                                const gtype = value.mname.toLowerCase();


                                gameSet[gtype] = value;
                            }
                            setAr_sectionData(gameSet)
                            trackData.current = gameSet;

                            if (Object.values(gameSet).length > 0 && emptyCheckTimeout) {
                                clearTimeout(emptyCheckTimeout);  // Clear the timeout if data is received
                                emptyCheckTimeout = null;
                            }

                        }


                    }
                    const elements = document.getElementsByClassName('checkdataval');

                    for (let i = 0; i < elements.length; i++) {
                        let element = elements[i];
                        // compare data-old and data-new attributes
                        let oldData = element.getAttribute('data-old');
                        let newData = element.getAttribute('data-new');


                        if (oldData !== newData) {
                            // if data has changed, add the 'color-yellow-bet' class
                            element.classList.add('color-yellow-bet');
                        } else {
                            element.classList.remove('color-yellow-bet');
                        }

                    }
                })
            }
            gameConnect();

        emptyCheckTimeout = setTimeout(() => {

            if (Object.values(trackData.current).length === 0) {

                nav('/');  // Redirect if gameSet is empty
            }

        }, 3000);

            sports_socket.on('disconnect', function () {

                // Reconnect logic
                const connectInterval = setInterval(() => {
                    gameConnect();
                    clearInterval(connectInterval)
                }, 1000);  // Reattempt connect every second
            });

            return () => {
                sports_socket.off(socket_game);
                setPopupDisplay(false)
                setShowLoader(false);
            };


// eslint-disable-next-line
    }, [match_id]);


    const individualBetPlaceFetch = (teamname) => {
        let r = betPlaceStatusCheck(sportList, ar_sectionData, teamname)

        betPlaceStatus.current[teamname] = r
    }
    const [maxValue, setMaxValue] = useState([])
    const [minValue, setMinValue] = useState([])
    const currentOddValue = useRef([]);
    const [placingBets, setPlacingBets] = useState([])

    return (

        <CommonLayout>
            <SportsLayout callTeamDatas={callTeamDatas}
                          setOddsTeamData={setOddsTeamData} placingBets={placingBets} setPlacingBets={setPlacingBets}
                          currentOddValue={currentOddValue} maxValue={maxValue} minValue={minValue}
                          teamNameCurrentBets={teamNameCurrentBets}
                          showCalculation={true}
                          individualBetPlaceFetch={individualBetPlaceFetch} betType={betType}
                          backOrLay={backOrLay} teamname={defaultTeamName}
                          odds={betOddValue}
                          teamNames={['ODDS', 'BOOKMAKER', 'TIED_MATCH'].includes(betType) ? allTeamName : []}
                          setOdds={setBetOddValue}
                          setPopupDisplay={setPopupDisplay} popupDisplay={popupDisplay} sportList={sportList}
                          data={ar_sectionData} setSportList={setSportList}>

                {!showLoader && (
                    <>
                        <MatchOdds placingBets={placingBets} currentOddValue={currentOddValue}
                                   setMaxValue={setMaxValue} setMinValue={setMinValue} teamNameCurrentBets={teamNameCurrentBets}
                                   allTeamName={allTeamName} oddsTeamData={oddsTeamData} setDefaultTeamName={defaultTeamName}
                                   setBetOddValue={setBetOddValue} setbackOrLay={setbackOrLay} teamNames={teamNames}
                                   setPopupDisplay={setPopupDisplay} ar_sectionData={ar_sectionData} sportList={sportList}
                                   oddsChange={oddsChange}/>
                        <Bookmaker placingBets={placingBets} currentOddValue={currentOddValue} setMaxValue={setMaxValue}
                                   setMinValue={setMinValue} teamNameCurrentBets={teamNameCurrentBets}
                                   allTeamName={allTeamName} setDefaultTeamName={defaultTeamName}
                                   bookmakerTeamData={bookmakerTeamData} setBetOddValue={setBetOddValue}
                                   setbackOrLay={setbackOrLay}
                                   teamNames={teamNames} setPopupDisplay={setPopupDisplay} ar_sectionData={ar_sectionData}
                                   sportList={sportList} oddsChange={oddsChange}/>
                    </>
                )}
            </SportsLayout>


        </CommonLayout>


    );
};

export default Soccer;
