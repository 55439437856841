import $ from 'jquery'
const Notify = function(text, callback, close_callback, style) {

    var time = '2000';
    var $container = $('#notifications');

    // Create the container if it doesn't exist
    if ($container.length === 0) {
        $('body').append('<div id="notifications" style="cursor: pointer; position: fixed; right: 0px; z-index: 9999; top: 10px; left: 0; margin: 0 auto; max-width: 300px;"></div>');
        $container = $('#notifications');
    }

    var img;
    if (typeof style === 'undefined' || style !== 'danger') {
        img = `<img src="/img/green_tick.png" style="margin-left:-8px" width="25px" height="25px" />`;
    } else {
        img = `<img src="/img/red_cross.png" style="margin-left:-8px" width="25px" height="25px" />`;
    }




    var html = $('<div class="alert alert-light">'+img +  " " + text + '</div>');

    $container.prepend(html)
    html.removeClass('hide').hide().fadeIn('slow')

    function remove_notice() {
        html.stop().fadeOut('slow').remove()
    }

    var timer =  setInterval(remove_notice, time);

    $(html).hover(function(){
        clearInterval(timer);
    }, function(){
        timer = setInterval(remove_notice, time);
    });

    html.on('click', function () {
        clearInterval(timer)
        callback && callback()
        remove_notice()
    });


}

export default Notify;