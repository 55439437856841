import CasinoLayout from "../Section/Casino/CasinoLayout";
import {useContext, useEffect, useRef, useState} from "react";
import {CasinoLastResult} from "../Section/Casino/CasinoLastResult";

import axiosFetch, {
    getExByColor,
    getExBySingleTeamNameCasino,

    getPlayerCardAccordingToNumberOfPlayers, resetBetFields,

} from "../../Common_Functions/Constants";
import {useParams} from "react-router-dom";
import {SportsContext} from "../../Components/Context/SportsContext";


import Notify from "../../js/Notify";

const Baccarat2 = () => {
    const [roundId, setRoundId] = useState('')

    const roundIdSaved = useRef(null);

    const [submitButtonDisable, setSubmitButtonDisable] = useState(false)
    const TOTALPLAYERS = {
        "Perfect Pair": '',
        "Big": '',
        "Small": '',
        "Either Pair": '',
        "Player Pair": '',
        "Player": '',
        "Tie": '',
        "Banker": '',
        "Banker Pair": '',

    };
    const [playerAmounts, setPlayerAmounts] = useState(TOTALPLAYERS);
    const {match_id} = useParams();

    const stakeValue = useRef(0);
    const [odds, setOdds] = useState(0)

    const [backOrLay, setbackOrLay] = useState('back')
    const [sportList, setSportList] = useState({})
    const ruleImage = '/img/rules/baccarat2-rules.jpg'
    const ruleDescription = '';
    const {

        setPopupDisplayForDesktop,

    } = useContext(SportsContext)
    const [hideLoading, setHideLoading] = useState(true)

    const teamNames = useRef(["Player A", "Player B"])

    const [data, setData] = useState([]);


    const [playerStatuses, setPlayerStatuses] = useState(() => {
        const initialOdds = [{status: 'suspended-box', odds: "0", type: "bet-odds"},
            {status: 'suspended-box', odds: "0", type: "bet-odds"},
            {status: 'suspended-box', odds: "0", type: "bet-odds"},
            {status: 'suspended-box', odds: "0", type: "bet-odds"},
            {status: 'suspended-box', odds: "0", type: "player-pair"},
            {
                status: 'suspended-box', odds: "0", type: "player",
                cardImages: ["/img/casino/cards/1.png", "/img/casino/cards/1.png", "/img/casino/cards/1.png"]
            },
            {status: 'suspended-box', odds: "0", type: "tie"},
            {
                status: 'suspended-box', odds: "0", type: "banker",
                cardImages: ["/img/casino/cards/1.png", "/img/casino/cards/1.png", "/img/casino/cards/1.png"]
            },
            {status: 'suspended-box', odds: "0", type: "banker-pair"}];
        return initialOdds.map((odds, index) => {
            const playerNames = Object.keys(TOTALPLAYERS); // Get player names
            return {
                ...odds,
                label: playerNames[index] || ""  // Assign label from TOTALPLAYERS or empty string if index exceeds
            };
        });
    });


    const updateOdds = (d_data) => {
        setPlayerStatuses(prevStatuses => {
            return prevStatuses.map((status, index) => {
                let newOdds = status.odds; // Default to existing odds
                let newStatus = status.status; // Default to existing status
                let cardImages = status.cardImages || '';
                const playerData = d_data.sub[index];

                if (d_data.sub && d_data.sub[index]) {

                    // Update odds if 'b' exists
                    if (playerData.b && playerData.b > 0) {
                        newOdds = playerData.b; // Update odds
                    }

                    // Update status based on game status
                    if (playerData.gstatus === 'OPEN') {
                        newStatus = ''; // Game is open
                    } else {
                        newStatus = 'suspended-box'; // Game is suspended
                    }
                }

                // Specific handling based on labels
                switch (status.label) {
                    case 'Player':
                        if (d_data.sub[0] && d_data.sub[0].b) {
                            newOdds = d_data.sub[0].b; // Update odds for Player

                        }
                        cardImages = getPlayerCardAccordingToNumberOfPlayers(d_data, 1, 1, [4, 2, 0]); // 3 cards for Player


                        break;

                    case 'Banker':
                        if (d_data.sub[1] && d_data.sub[1].b) {
                            newOdds = d_data.sub[1].b; // Update odds for Banker
                        }
                        cardImages = getPlayerCardAccordingToNumberOfPlayers(d_data, 1, 1, [1, 3, 5]); // 3 cards for Player

                        break;

                    case 'Tie':
                        if (d_data.sub[2] && d_data.sub[2].b) {
                            newOdds = d_data.sub[2].b; // Update odds for Tie
                        }
                        break;

                    case 'Player Pair':
                        if (d_data.sub[3] && d_data.sub[3].b) {
                            newOdds = d_data.sub[3].b; // Update odds for Player Pair
                        }
                        break;

                    case 'Banker Pair':
                        if (d_data.sub[4] && d_data.sub[4].b) {
                            newOdds = d_data.sub[4].b; // Update odds for Banker Pair
                        }
                        break;

                    case 'Perfect Pair':
                        if (d_data.sub[5] && d_data.sub[5].b) {
                            newOdds = d_data.sub[5].b; // Update odds for Perfect Pair
                            newStatus = ''; // Update status to open

                        }
                        break;

                    case 'Big':
                        if (d_data.sub[6] && d_data.sub[6].b) {
                            newOdds = d_data.sub[6].b; // Update odds for Big
                        }
                        break;

                    case 'Small':
                        if (d_data.sub[7] && d_data.sub[7].b) {
                            newOdds = d_data.sub[7].b; // Update odds for Small
                        }
                        break;

                    case 'Either Pair':
                        if (d_data.sub[8] && d_data.sub[8].b) {
                            newOdds = d_data.sub[8].b; // Update odds for Either Pair
                        }
                        break;

                    // Add more cases as needed for additional statuses
                    default:
                        break;
                }


                return {
                    ...status,
                    odds: playerData.gstatus === 'OPEN' ? newOdds : status.odds,  // Update odds
                    status: newStatus, // Update status based on game state
                    cardImages: cardImages
                };
            });
        });
    };


    const remark = useRef('Welcome');
    const [lastResult, setLastResult] = useState({});
    const teamname = useRef('');
    const loss = useRef(0);
    const profit = useRef(0);
    const profitData = useRef(0);


    useEffect(() => {

        if (data?.sub) {
            updateOdds(data);

        }


    }, [data]);

    const exposure = localStorage.getItem('exposure');
    const sportLength = Object.keys(data).length;


    useEffect(() => {

        if (data?.sub && sportList?.id) {
            Object.keys(TOTALPLAYERS).slice(5, 8).map(value => {

                getExBySingleTeamNameCasino(sportList.id, data.mid, value, match_id, value === 'Tie' ? 'TIE' : 'ODDS')
                    .then(res => {
// Update playerAmounts based on the response
                        setPlayerAmounts(prev => ({
                            ...prev,
                            [value]: res.data // Assuming the amount is in the response
                        }));
                    })
            })
        }
    }, [exposure, sportLength, roundId]);

    const openPopup = (isBakOrLay, teamnam, oddvalue) => {


        if (parseFloat(oddvalue) > 0) {
            roundIdSaved.current = roundId
            setbackOrLay(isBakOrLay)
            setPopupDisplayForDesktop(true);
            teamname.current = teamnam
            setOdds(oddvalue)
        } else {
            Notify("Odds Value Change Bet Not Confirm", null, null, 'danger')

        }


    }
    const casinoBetDataNew = (new_odds) => {
        stakeValue.current = new_odds

        if (backOrLay === 'back') {


            loss.current = stakeValue.current;


            profit.current = profitData.current = (parseFloat(odds) * stakeValue.current).toFixed(2)


        } else {

            profit.current = profitData.current = stakeValue.current;


            loss.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)
        }


    }

    const placeBet = () => {

        setHideLoading(false)
        const playerStatus = playerStatuses.find(status => status.label === teamname.current);

        if (playerStatus?.status === 'suspended-box') {
            Notify(`Bet Not Confirmed. Reason: Game Suspended`, null, null, 'danger');
            setPopupDisplayForDesktop(false);
            setHideLoading(true);
            return;
        }

        if (roundIdSaved.current !== roundId) {
            Notify("Bet Not Confirm Reason Game Change", null, null, 'danger')
            setPopupDisplayForDesktop(false)
            return;
        }


        setSubmitButtonDisable(true)

        const postdata = {

            "sportId": sportList.id,
            "matchId": roundId,
            "isback": 0,
            "placeName": teamname.current,
            "placeName2": null,
            "odds": odds,
            "oddsk": 0,
            "profit": teamname.current === 'Tie' ? profit.current : parseFloat(stakeValue.current).toFixed(2),
            "loss": parseFloat(loss.current).toFixed(2),
            "betType": teamname.current === 'Tie' ? 'TIE' : "ODDS",
            "bet_side": backOrLay.toUpperCase(),
            "betAmount": parseFloat(stakeValue.current),
            "type": "BACCARAT2",
            "matchType": "baccarat2",

        }

        axiosFetch('casino/store', 'post', null, postdata)
            .then((res) => {
                if (res.data.status === true) {

                    resetBetFields(profitData, stakeValue)
                    Notify("Bet Placed Successfully", null, null, 'success')
                } else {

                    resetBetFields(profitData, stakeValue)
                    Notify(res.data.msg, null, null, 'danger')
                }
                setHideLoading(true)
                setSubmitButtonDisable(false)
                setPopupDisplayForDesktop(false)
            })


    }

    return (
        <CasinoLayout hideLoading={hideLoading} isBack={backOrLay} teamname={teamname}
                      handleStakeChange={casinoBetDataNew} odds={odds}
                      stakeValue={stakeValue} setOdds={setOdds} placeBet={placeBet}
                      submitButtonDisable={submitButtonDisable} data={data} roundId={roundId} setRoundId={setRoundId}
                      sportList={sportList}
                      setSportList={setSportList} setData={setData} setLastResult={setLastResult}>
            <div className="casino-container container-fluid-5 baccarat-container pt-0 baccarat">
                <div className="row row-5">

                    <div className="col-3">
                        <h4 className="text-center mb-2 mt-2">Statistics</h4>
                        <svg width="188" height="160" aria-label="A chart." style={{overflow: 'hidden'}}>
                            <defs id="_ABSTRACT_RENDERER_ID_10"></defs>
                            <rect x="0" y="0" width="188" height="160" stroke="none" strokeWidth="0"
                                  fill="#eeeeee"></rect>
                            <g>
                                <rect x="133" y="3" width="55" height="39" stroke="none" strokeWidth="0" fillOpacity="0"
                                      fill="#ffffff"></rect>
                                <g columnId="Player">
                                    <rect x="133" y="3" width="55" height="9" stroke="none" strokeWidth="0"
                                          fillOpacity="0"
                                          fill="#ffffff"></rect>
                                    <g>
                                        <text textAnchor="start" x="145" y="10.65" fontFamily="Arial" fontSize="9"
                                              stroke="none"
                                              strokeWidth="0" fill="#222222">
                                            Player
                                        </text>
                                    </g>
                                    <circle cx="137.5" cy="7.5" r="4.5" stroke="none" strokeWidth="0"
                                            fill="#086cb8"></circle>
                                </g>
                                <g columnId="Banker">
                                    <rect x="133" y="18" width="55" height="9" stroke="none" strokeWidth="0"
                                          fillOpacity="0"
                                          fill="#ffffff"></rect>
                                    <g>
                                        <text textAnchor="start" x="145" y="25.65" fontFamily="Arial" fontSize="9"
                                              stroke="none"
                                              strokeWidth="0" fill="#222222">
                                            Banker
                                        </text>
                                    </g>
                                    <circle cx="137.5" cy="22.5" r="4.5" stroke="none" strokeWidth="0"
                                            fill="#ae2130"></circle>
                                </g>
                                <g columnId="Tie">
                                    <rect x="133" y="33" width="55" height="9" stroke="none" strokeWidth="0"
                                          fillOpacity="0"
                                          fill="#ffffff"></rect>
                                    <g>
                                        <text textAnchor="start" x="145" y="40.65" fontFamily="Arial" fontSize="9"
                                              stroke="none"
                                              strokeWidth="0" fill="#222222">
                                            Tie
                                        </text>
                                    </g>
                                    <circle cx="137.5" cy="37.5" r="4.5" stroke="none" strokeWidth="0"
                                            fill="#279532"></circle>
                                </g>
                            </g>
                            <g>
                                <path
                                    d="M118,74.3L118,85.7A57,45.6,0,0,1,85.26941961920913,126.9601135924505L85.26941961920913,115.56011359245049A57,45.6,0,0,0,118,74.3"
                                    stroke="#06518a" strokeWidth="1" fill="#06518a"></path>
                                <path
                                    d="M61,74.3L61,85.7L85.26941961920913,126.9601135924505L85.26941961920913,115.56011359245049"
                                    stroke="#06518a" strokeWidth="1" fill="#06518a"></path>
                                <path
                                    d="M61,74.3L61,28.699999999999996A57,45.6,0,0,1,85.26941961920913,115.56011359245049L61,74.3A0,0,0,0,0,61,74.3"
                                    stroke="#086cb8" strokeWidth="1" fill="#086cb8"></path>
                                <text textAnchor="start" x="88.869152902558" y="71.16606054227418" fontFamily="Arial"
                                      fontSize="9"
                                      stroke="none" strokeWidth="0" fill="#ffffff">43%
                                </text>
                            </g>
                            <g>
                                <path
                                    d="M61,74.3L61,85.7L30.45787268619721,47.198646597108095L30.45787268619721,35.798646597108096"
                                    stroke="#1d7026" strokeWidth="1" fill="#1d7026"></path>
                                <path
                                    d="M61,74.3L30.45787268619721,35.798646597108096A57,45.6,0,0,1,61,28.699999999999996L61,74.3A0,0,0,0,0,61,74.3"
                                    stroke="#279532" strokeWidth="1" fill="#279532"></path>
                            </g>
                            <g>
                                <path
                                    d="M85.26941961920913,115.56011359245049L85.26941961920913,126.9601135924505A57,45.6,0,0,1,4,85.7L4,74.3A57,45.6,0,0,0,85.26941961920913,115.56011359245049"
                                    stroke="#831924" strokeWidth="1" fill="#831924"></path>
                                <path
                                    d="M61,74.3L85.26941961920913,115.56011359245049A57,45.6,0,0,1,30.457872686197167,35.79864659710812L61,74.3A0,0,0,0,0,61,74.3"
                                    stroke="#ae2130" strokeWidth="1" fill="#ae2130"></path>
                                <text textAnchor="start" x="19.346337437754016" y="91.13802096485482" fontFamily="Arial"
                                      fontSize="9" stroke="none" strokeWidth="0" fill="#ffffff">48%
                                </text>
                            </g>
                            <g></g>
                        </svg>
                    </div>

                    <div className="col-9">
                        <div className="row row5">
                            {playerStatuses.slice(0, 4).map((option, index) => (
                                <div key={index} className={`col pr-1 pl-2`}>
                                    <div className={`${option.type} ${option.status}`}>
                                        {option.label}
                                        <br/>
                                        {option.odds}:1

                                    </div>
                                    <div className="book" style={{color: 'black'}}></div>
                                </div>
                            ))}
                        </div>
                        <div className="bet-container">
                            {playerStatuses.slice(4).map((option, index) => (
                                <div
                                    key={index}
                                    className={`${option.type} mb-4`}
                                    data-toggle="modal"
                                    data-target="#mobilepopop"
                                >
                                    <div
                                        className={option.status}
                                        onClick={['Player', 'Tie', 'Banker'].includes(option.label) ?
                                            () => openPopup('back', option.label, option.odds) :
                                            null}>
                                        <div>{option.label.toUpperCase()}<br/>
                                            {option.odds}:1
                                        </div>
                                        {option.cardImages && (
                                            <div className="player-card">
                                                {option.cardImages.map((src, idx) => (
                                                    <img key={idx} src={src} alt={`${option.label} Card ${idx + 1}`}/>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                    <div style={{color: 'black'}}>{getExByColor(playerAmounts[option.label])}</div>
                                </div>
                            ))}
                        </div>
                    </div>


                </div>


                <marquee scrollamount="3" className="casino-remark m-b-10">{remark.current}</marquee>
                <div className="casino-last-result-title">
                    <span>Last Result</span>
                </div>
                <div className="casino-last-results last-result-container">
                    <CasinoLastResult sportList={sportList} lastResults={lastResult} data={data}/>
                </div>


            </div>

        </CasinoLayout>
    )
        ;

};


export default Baccarat2;
