import CasinoLayout from "../Section/Casino/CasinoLayout";
import {useContext, useEffect, useRef, useState} from "react";

import {CasinoLastResult} from "../Section/Casino/CasinoLastResult";

import axiosFetch, {
    resetBetFields,
    cardMap, getExByColor, getExBySingleTeamNameCasino,

} from "../../Common_Functions/Constants";
import {useParams} from "react-router-dom";
import {SportsContext} from "../../Components/Context/SportsContext";


import Notify from "../../js/Notify";
import AndarBaharVideoCards from "../../Components/AndarBaharVideoCards";

const Abj = () => {
    const [roundId, setRoundId] = useState('')

    const [totalPlayers, setTotalPlayers] = useState([

        {
            "ODDS": [{

                "SA": {odds: 15, status: 'suspended-box'},
                "1st Bet": {odds: 2, amounts: '', status: 'suspended-box', canonical_name: 'First Bet A'},
                "2nd Bet": {odds: 2, status: 'suspended-box', canonical_name: 'Second bet A'}
            },

                {
                    "SB": {odds: 15, status: 'suspended-box'},
                    "1st Bet": {odds: 2, amounts: '', status: 'suspended-box', canonical_name: 'First Bet B'},
                    "2nd Bet": {odds: 2, status: 'suspended-box', canonical_name: 'Second bet B'}
                }

            ],
        },
        {
            "ODDEVEN": [{
                "Odd": {
                    odds: 0, status: 'suspended-box', amounts: ''

                }
            }, {
                "Even":
                    {
                        odds: 0, status: 'suspended-box', amounts: ''
                    }

            }],
        }, {
            "COLOR": [{
                "Spade": {
                    odds: 0, status: 'suspended-box', amounts: '', img: process.env.REACT_APP_CARD_PATH + "spade.png"

                }
            }, {
                "Club":
                    {
                        odds: 0, status: 'suspended-box', amounts: '', img: process.env.REACT_APP_CARD_PATH + "club.png"
                    }

            },
                {
                    "Heart":
                        {
                            odds: 0,
                            status: 'suspended-box',
                            amounts: '',
                            img: process.env.REACT_APP_CARD_PATH + "heart.png"
                        }

                },
                {
                    "Diamond":
                        {
                            odds: 0,
                            status: 'suspended-box',
                            amounts: '',
                            img: process.env.REACT_APP_CARD_PATH + "diamond.png"
                        }

                }],
        },
        {
            "CARD_SINGLE": Array.from({length: 13}, (_, index) => ({
                img: "/img/card/" + (index + 1) + ".jpg",
                odds: 0,
                status: 'suspended-box',
                amounts: '',
                nat: "Joker " + cardMap(index)
            }))


        },


    ])


    const updatePlayers = (d_data) => {
        const CARDS = d_data.slice(6, 19);
        const ODDEVEN = d_data.slice(23, 25)
        const updatedPlayers = totalPlayers.map(playerCategory => {
            // Iterate through each category (e.g., ODDS, ODDEVEN, etc.)
            return Object.keys(playerCategory).reduce((acc, category) => {

                acc[category] = playerCategory[category].map(player => {
                    // Update odds and status based on data
                    const playerKey = Object.keys(player)[0];

                    if (category === "ODDEVEN") {
                        // Find matching data for ODDEVEN category

                        const foundData = ODDEVEN.find(item => item.nat.includes(playerKey));

                        if (foundData) {
                            player[playerKey].odds = foundData.b; // Update odds for ODDEVEN
                            player[playerKey].status = foundData.gstatus === "OPEN" ? "" : "suspended-box"; // Update status for ODDEVEN
                        }
                    } else if (category === "CARD_SINGLE") {
                        // Find matching data for CARD_SINGLE category
                        acc[category] = playerCategory[category].map(player => {

                            const foundData = CARDS.find(card => card.nat === player.nat);

                            if (foundData) {
                                player.odds = foundData.b; // Update odds for CARD_SINGLE
                                player.status = foundData.gstatus === "OPEN" ? "" : "suspended-box"; // Update status for CARD_SINGLE
                            }

                            return player;
                        });
                    } else {

                        // Handle other categories like ODDS in the same way as before
                        Object.keys(player).forEach(key => {
                            const foundData = d_data.find(item => item.nat.includes(key));

                            if (foundData) {
                                player[key].odds = foundData.b; // Update odds
                                player[key].status = foundData.gstatus === "OPEN" ? "" : "suspended-box"; // Update status
                            }
                        });
                    }

                    return player;
                });
                return acc;
            }, {});
        });
        setTotalPlayers(updatedPlayers);
    }


    const roundIdSaved = useRef(null);

    const [submitButtonDisable, setSubmitButtonDisable] = useState(false)

    const [cards, setCards] = useState([]);

    const stakeValue = useRef(0);
    const [odds, setOdds] = useState(0)

    const [backOrLay, setbackOrLay] = useState('back')
    const [sportList, setSportList] = useState({})
    const {match_id} = useParams();
    const {
        setBetType,
        betType,
        setPopupDisplayForDesktop,

    } = useContext(SportsContext)
    const [hideLoading, setHideLoading] = useState(true)

    const ruleImage = '/img/rules/abj-rules.jpg'
    const ruleDescription = '';
    const teamNames = useRef(["Player A", "Player B"])

    const [data, setData] = useState([]);


    const remark = useRef('Welcome');
    const [lastResult, setLastResult] = useState({});
    const teamname = useRef('');
    const loss = useRef(0);
    const profit = useRef(0);
    const profitData = useRef(0);


    useEffect(() => {


        if (data?.sub) {


            updatePlayers(data?.sub)

        }

        if (data?.card) {
            const cardArray = data.card.split(",").map(item => item.trim())
            setCards(cardArray.slice(0, 3));

            remark.current = data.remark || 'Welcome';
        }

    }, [data]);

    const exposure = localStorage.getItem('exposure');
    const sportLength = Object.keys(data).length;
    const updateAmounts = async () => {
        const amount = await getExBySingleTeamNameCasino(sportList.id, roundId, '', match_id);

        let updatedPlayers = totalPlayers;

        updatedPlayers.map(playerCategory => {
            // Iterate through each category (e.g., ODDS, ODDEVEN, etc.)
            return Object.keys(playerCategory).reduce((acc, category) => {

                acc[category] = playerCategory[category].map((player, key) => {
                    // Update odds and status based on data
                    const playerKey = Object.keys(player)[0];


                    // Find matching data for the current category


                    if (category === 'CARD_SINGLE') {
                        acc[category] = playerCategory[category].map(c => {
                            // Match the card's nat with the team_name in amount data
                            const foundData = amount.data?.find(item => item.team_name === c.nat);


                            c.amounts = foundData?.total_amount || "";  // Update the amounts from foundData


                            return c;  // Return the updated card object
                        });
                    } else if (category === 'ODDEVEN') {
                        acc[category] = playerCategory[category].map((acc, c) => {
                            const keyis = Object.keys(acc)[key]

                            // Match the card's nat with the team_name in amount data
                            const foundData = amount.data?.find(item => item.team_name === keyis);


                            if (acc[keyis]) {
                                acc[keyis].amounts = foundData?.total_amount || "";  // Update the amounts from foundData
                            }


                            return acc;  // Return the updated card object
                        });
                    } else if (category === 'ODDS') {
                        acc[category] = playerCategory[category].map((acc, c) => {
                            const keyis = Object.keys(acc)[key]
                            // Match the card's nat with the team_name in amount data
                            const foundData = amount.data?.find(item => item.team_name === acc[keyis].canonical_name);

                            if (acc[keyis]) {

                                acc[keyis].amounts = foundData?.total_amount || "";  // Update the amounts from foundData
                            }

                            return acc;  // Return the updated card object
                        });
                    } else {
                        const foundData = amount.data?.find(item => item.team_name === playerKey && item.type === category);


                        // Update odds and status based on found data
                        player[playerKey].amounts = foundData?.total_amount; // Update the odds (or total amount in this case)

                    }

                    return player;
                });
                return acc;
            }, {});
        });

        setTotalPlayers(updatedPlayers);

    };


    useEffect(() => {

        if (data?.sub && sportList?.id) {


            updateAmounts();
        }
    }, [exposure, sportLength, roundId]);


    const openPopup = (isBakOrLay, teamnam, oddvalue, type) => {

        setBetType(type)
        if (parseFloat(oddvalue) > 0) {
            roundIdSaved.current = roundId
            setbackOrLay(isBakOrLay)
            setPopupDisplayForDesktop(true);
            teamname.current = teamnam
            setOdds(oddvalue)
        } else {
            Notify("Odds Value Change Bet Not Confirm", null, null, 'danger')

        }


    }
    const casinoBetDataNew = (new_odds) => {
        stakeValue.current = new_odds
        if (backOrLay === 'back') {


            loss.current = stakeValue.current;


            profit.current = profitData.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)

        } else {

            profit.current = profitData.current = stakeValue.current;


            loss.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)
        }


    }


    const placeBet = () => {

        setHideLoading(false)
        setSubmitButtonDisable(true)

        const notify = (status = 'suspended') => {
            if (status === 'suspended') {
                Notify(`Bet Not Confirmed. Reason: Game Suspended`, null, null, 'danger');


            } else {
                Notify("Bet Not Confirm Reason Game Change", null, null, 'danger')

            }
            setPopupDisplayForDesktop(false);
            setHideLoading(true);
            setSubmitButtonDisable(false)

            resetBetFields(profitData, stakeValue)

        }

        if (teamname.current.includes('First Bet') && totalPlayers[0].ODDS[0]['1st Bet'].status === 'suspended-box') {
            notify()

            return;

        } else if (betType !== 'ODDS' && totalPlayers[3].CARD_SINGLE[0].status === 'suspended-box') {
            notify()

            return;

        }

        if (roundIdSaved.current !== roundId) {
            notify('Round Change')

            return;
        }

        const postdata = {

            "sportId": sportList.id,
            "matchId": roundId,
            "isback": backOrLay === 'back' ? 1 : 0,
            "placeName": teamname.current,
            "placeName2": null,
            "odds": odds,
            "oddsk": 0,
            "profit": parseFloat(profit.current).toFixed(2),
            "loss": parseFloat(loss.current).toFixed(2),
            "betType": betType,
            "bet_side": backOrLay.toUpperCase(),
            "betAmount": parseFloat(stakeValue.current),
            "type": match_id.toUpperCase(),
            "matchType": match_id.toLowerCase(),

        }

        axiosFetch('casino/store', 'post', null, postdata)
            .then((res) => {
                if (res.data.status === true) {
                    updateAmounts()


                    resetBetFields(profitData, stakeValue)
                    Notify("Bet Placed Successfully", null, null, 'success')
                } else {

                    resetBetFields(profitData, stakeValue)
                    Notify(res.data.msg, null, null, 'danger')
                }
                setHideLoading(true)
                setSubmitButtonDisable(false)
                setPopupDisplayForDesktop(false)
            })


    }

    return (
        <CasinoLayout ruleImage={ruleImage} ruleDescription={ruleDescription} hideLoading={hideLoading}
                      isBack={backOrLay} teamname={teamname} handleStakeChange={casinoBetDataNew} odds={odds}
                      stakeValue={stakeValue} setOdds={setOdds} placeBet={placeBet}
                      submitButtonDisable={submitButtonDisable} data={data} roundId={roundId} setRoundId={setRoundId}
                      sportList={sportList}
                      setSportList={setSportList} setData={setData} setLastResult={setLastResult}>
            <div className="video-container" style={{minHeight: '0px'}}>
                <div className="video-overlay" style={{top: "-300px"}}>

                    {cards.length > 0 && cards[0] !== '1' && (
                        <div id="game-cards">
                            <div className="row row5 align-items-center">
                                <div className="col-1">
                                    <div className="row row5">
                                        <div className="col-12">
                                            <p className="mb-0 text-white" style={{lineHeight: '44px'}}><b>A</b></p>
                                        </div>
                                    </div>
                                    <div className="row row5">
                                        <div className="col-12">
                                            <p className="mb-0 text-white" style={{lineHeight: '44px'}}><b>B</b></p>
                                        </div>
                                    </div>
                                </div>
                                {cards.length > 0 && (

                                    <>
                                        <div className="col-2">
                                            <img
                                                src={cards.length > 0 ? process.env.REACT_APP_CARD_PATH + cards[0] + ".png" : ""}
                                                className="card-right" alt="Card 1"/>
                                        </div>
                                        <div className="col-9">
                                            {cards.slice(1, 3).reverse().map((value, index) => (
                                                <div className="card-inner" key={index}>
                                                    <div className="row row5">
                                                        <div className="col-2">
                                                            <img
                                                                src={process.env.REACT_APP_CARD_PATH + value + ".png"}/>
                                                        </div>
                                                        <div className="col-8">
                                                            {data.card && (
                                                                <div>
                                                                    <AndarBaharVideoCards hidePlayers={true} styles={{
                                                                        width: "100px",
                                                                        marginLeft: "30px"
                                                                    }}
                                                                                          cards={data?.card}
                                                                                          player={index === 1 ? 'Bahar' : 'Andar'}/>
                                                                </div>
                                                            )}
                                                        </div>


                                                    </div>
                                                </div>
                                            ))}

                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="casino-container ab-2">
                <div className="ab-2-container">
                    {totalPlayers[0]['ODDS'].map((player, index) => {
                        // Iterate over each player's data
                        return (
                            <div key={index} className="bet-a">
                                <div className="a-title">{index === 0 ? "A" : "B"}</div>
                                {/* Display 'SA' or 'SB' */}

                                {Object.entries(player).map(([betType, betData], index1) => {


                                    return (
                                        <div key={index1}
                                             className={index1 === 0 ? `sa` : (index1 === 1 ? 'first-bet' : 'second-bet')}>
                                            <div
                                                onClick={() => betData.canonical_name.includes("First Bet") ? openPopup('back', betData.canonical_name, betData.odds, 'ODDS') : null}
                                                className={`c_cursor ${betData.status}`}>
                                                <div>{betType}</div>
                                                {/* Show 'First Bet A', 'Second Bet A', or just 'SA' */}
                                                <div className="mt-1">{betData.odds}</div>
                                                {/* Display odds */}

                                            </div>
                                            {betData.amounts &&
                                                <div className="text-center">{getExByColor(betData.amounts)}</div>}
                                        </div>
                                    )
                                })}

                                <div className="a-title">{index === 0 ? "A" : "B"}</div>

                            </div>
                        );
                    })}
                </div>

                <div className="row row5 mt-2">
                    <div className="col-6">
                        <div className="ab-2-box">
                            <div className="row row5">
                                {totalPlayers.slice(1, 2).map((mapvalue, mapindex) => {

                                    return mapvalue[Object.keys(mapvalue)[0]].map((value, index) => {

                                        const oddkeys = Object.keys(value)[0];
                                        const betType = (Object.keys(mapvalue)[0]);


                                        return (mapindex === 0 ?
                                                <div className="col-6 text-center" key={index}>

                                                    <div className="bltitle"><b>{oddkeys}</b></div>
                                                    <div
                                                        onClick={() => openPopup('back', oddkeys, value[oddkeys].odds, betType)}
                                                        className={`back mt-1 blbox c_cursor ${value[oddkeys].status}`}>
                                                        <span className="odd">{value[oddkeys].odds}</span>

                                                    </div>
                                                    <div className="mt-1 text-black">
                                                        <span>{getExByColor(value[oddkeys].amounts)}</span>
                                                    </div>

                                                </div>
                                                :
                                                (<div className="col-3 text-center" key={index}>
                                                        <div className="bltitle">
                                                            <img src={value[oddkeys].img}/>
                                                        </div>
                                                        <div
                                                            onClick={() => openPopup('back', oddkeys, value[oddkeys].odds, betType)}
                                                            className={`back mt-1 blbox c_cursor ${value[oddkeys].status}`}>
                                                            <span className="odd">{value[oddkeys].odds}</span>


                                                        </div>
                                                        <div className="mt-1 text-black">
                                                            <span>{getExByColor(value[oddkeys].amounts)}</span>
                                                        </div>

                                                    </div>
                                                )
                                        )
                                    })

                                })}
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="ab-2-box">
                            <div className="row row5">
                                {totalPlayers.slice(2, 3).map((mapvalue, mapindex) => {

                                    return mapvalue[Object.keys(mapvalue)[0]].map((value, index) => {

                                        const oddkeys = Object.keys(value)[0];
                                        const betType = (Object.keys(mapvalue)[0]);


                                        return (<div className="col-3 text-center" key={index}>
                                                <div className="bltitle">
                                                    <img src={value[oddkeys].img}/>
                                                </div>
                                                <div
                                                    onClick={() => openPopup('back', oddkeys, value[oddkeys].odds, betType)}
                                                    className={`back mt-1 blbox c_cursor ${value[oddkeys].status}`}>
                                                    <span className="odd">{value[oddkeys].odds}</span>


                                                </div>
                                                <div className="mt-1 text-black">
                                                    <span>{getExByColor(value[oddkeys].amounts)}</span>
                                                </div>

                                            </div>

                                        )
                                    })

                                })}
                            </div>
                        </div>
                    </div>


                </div>
                <div className="row row5 mt-2">
                    <div className="col-12">
                        <div className="ab-2-box">

                            <div className="row mt-1">
                                <div className="col-12 text-center">

                                    {totalPlayers[3].CARD_SINGLE.map((value, index) => {

                                        return (

                                            <div className="m-r-5 card-image" key={index}>
                                                <div className={`c_cursor ${value.status}`}
                                                     onClick={() => openPopup('back', value.nat, value.odds, 'CARD_SINGLE')}>
                                                    <img src={value.img}/>
                                                </div>
                                                <p>
                                                    <span className="float-left">
                                                        <span className="teamEx">
                                                    {getExByColor(value.amounts)}
                                                            </span>
                                                        </span>
                                                </p>

                                            </div>
                                        )
                                    })}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <marquee scrollamount="3" className="casino-remark m-b-10">{remark.current}</marquee>

                <div className="casino-last-result-title">
                    <span>Last Result</span>
                </div>
                <div className="casino-last-results">

                    <CasinoLastResult sportList={sportList} lastResults={lastResult} data={data}/>
                </div>
            </div>


        </CasinoLayout>
    );

};

const PlayerTable = ({playerName, playerValue, playerBack, openPopup, playerLay, playerStatus}) => (
    <div className="casino-table-left-box">
        <div className="casino-table-header">
            <div className="casino-nation-detail">{playerName}</div>
            <div className="casino-odds-box back">Back</div>
            <div className="casino-odds-box lay">Lay</div>
        </div>
        <div className={`casino-table-body`}>
            <div className={`casino-table-row ${playerStatus}`}>
                <div className="casino-nation-detail">
                    <div className="casino-nation-name">Main</div>
                    <p className="m-b-0">
                        <span className={`font-weight-bold ${playerValue >= 0 ? 'text-success' : 'text-danger'}`}>
                            {playerValue}
                        </span>
                    </p>
                </div>
                <div className="casino-odds-box back">
                    <span className="casino-odds"
                          onClick={() => openPopup('back', playerName, playerBack)}>{playerBack}</span>
                </div>
                <div className="casino-odds-box lay">
                    <span className="casino-odds"
                          onClick={() => openPopup('lay', playerName, playerLay)}>{playerLay}</span>
                </div>
            </div>
        </div>
    </div>
);

export default Abj;
