import CasinoLayout from "../Section/Casino/CasinoLayout";
import {useContext, useEffect, useRef, useState} from "react";

import {CasinoLastResult} from "../Section/Casino/CasinoLastResult";

import axiosFetch, {
    getExByColor, getExBySingleTeamNameCasino,

    getExByTeamNameForCasino, getPlayerCardAccordingToNumberOfPlayers, resetBetFields, updatePlayerStats,

} from "../../Common_Functions/Constants";
import {useParams} from "react-router-dom";
import {SportsContext} from "../../Components/Context/SportsContext";


import Notify from "../../js/Notify";

const Poker20 = () => {
    const [roundId, setRoundId] = useState('')

    const roundIdSaved = useRef(null);

    const [submitButtonDisable, setSubmitButtonDisable] = useState(false)


    const stakeValue = useRef(0);
    const [odds, setOdds] = useState(0)

    const [backOrLay, setbackOrLay] = useState('back')
    const [sportList, setSportList] = useState({})
    const {match_id} = useParams();
    const {
        setBetType,
        setPopupDisplayForDesktop,

    } = useContext(SportsContext)
    const [hideLoading, setHideLoading] = useState(true)


    const [data, setData] = useState([]);

    const handRanks = [
        "Winner",
        "One Pair",
        "Two Pair",
        "Three of a Kind",
        "Straight",
        "Flush",
        "Full House",
        "Four of a Kind",
        "Straight Flush"
    ];

    const [playerStatuses, setPlayerStatuses] = useState(() => {
        const initialStatus = {};
        handRanks.forEach(rank => {
            initialStatus[rank] = 'suspended-box'


        });
        return initialStatus;
    });

    const remark = useRef('Welcome');
    const [lastResult, setLastResult] = useState({});
    const teamname = useRef('');
    const loss = useRef(0);
    const profit = useRef(0);
    const profitData = useRef(0);


    useEffect(() => {
        setBetType('ODDS')

        if (data?.sub) {


            const t2Data = data.sub;


            const playerAdata = t2Data.slice(0, 9);
            const playerBdata = t2Data.slice(9, 18);

            t2Data.forEach((value) => {


                updatePlayerStats(value, null, null, value.nat, setPlayerStatuses, 'suspended-box')

            })


            playerAdata.forEach(value => {


                const {b, nat} = value
                if (oddsData[nat]) {
                    setOddsData(prevOdds => ({
                        ...prevOdds,
                        [nat]: {
                            ...prevOdds[nat],  // Spread the previous data for the specific rank
                            "Player A": b      // Update only the "Player A" field
                        }
                    }));
                }
            })
            playerBdata.forEach(value => {

                const {b, nat} = value
                if (oddsData[nat]) {
                    setOddsData(prevOdds => ({
                        ...prevOdds,
                        [nat]: {
                            ...prevOdds[nat],  // Spread the previous data for the specific rank
                            "Player B": b      // Update only the "Player A" field
                        }
                    }));
                }
            })


            //
            //
            // // Set player statuses at once after looping through data
            // setPlayerStatuses((prev) => ({
            //     ...prev,
            //     ...playerAStats,
            // }));
        }
    }, [data]);

    const exposure = localStorage.getItem('exposure');
    const sportLength = Object.keys(data).length;


    useEffect(() => {

        if (data?.sub && sportList?.id) {
            Object.entries(playerAmounts).map(([value]) => {
                getExBySingleTeamNameCasino(sportList.id, data.mid, value, match_id, 'WINNER')
                    .then(res => {
                        // Update playerAmounts based on the response
                        setPlayerAmounts(prev => ({
                            ...prev,
                            [value]: res.data // Assuming the amount is in the response
                        }));
                    })
            })
        }
    }, [exposure, sportLength, roundId]);


    const openPopup = (isBakOrLay, teamnam, oddvalue) => {


        if (parseFloat(oddvalue) > 0) {
            roundIdSaved.current = roundId
            setbackOrLay(isBakOrLay)
            setPopupDisplayForDesktop(true);
            teamname.current = teamnam
            setOdds(oddvalue)
        } else {
            Notify("Odds Should Not Be Zero", null, null, 'danger')

        }


    }
    const casinoBetDataNew = (new_odds) => {
        stakeValue.current = new_odds
        if (backOrLay === 'back') {


            loss.current = stakeValue.current;


            profit.current = profitData.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)

        } else {

            profit.current = profitData.current = stakeValue.current;


            loss.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)
        }


    }


    const placeBet = () => {

        setHideLoading(false)
        if (playerStatuses[teamname.current] === 'suspended-box') {
            Notify(`Bet Not Confirmed. Reason: Game Suspended`, null, null, 'danger');
            setPopupDisplayForDesktop(false);
            setHideLoading(true)
            return;
        }

        if (roundIdSaved.current !== roundId) {
            Notify("Bet Not Confirm Reason Game Change", null, null, 'danger')
            setPopupDisplayForDesktop(false)
            setHideLoading(true)
            return;
        }


        setSubmitButtonDisable(true)

        const postdata = {

            "sportId": sportList.id,
            "matchId": roundId,
            "isback": 0,
            "placeName": teamname.current,
            "placeName2": null,
            "odds": odds,
            "oddsk": 0,
            "profit": parseFloat(profit.current).toFixed(2),
            "loss": parseFloat(loss.current).toFixed(2),
            "betType": "WINNER",
            "bet_side": backOrLay.toUpperCase(),
            "betAmount": parseFloat(stakeValue.current),
            "type": "POKER20",
            "matchType": "poker20",

        }

        axiosFetch('casino/store', 'post', null, postdata)
            .then((res) => {
                if (res.data.status === true) {

                    resetBetFields(profitData, stakeValue)
                    Notify("Bet Placed Successfully", null, null, 'success')
                } else {

                    resetBetFields(profitData, stakeValue)
                    Notify(res.data.msg, null, null, 'danger')
                }
                setHideLoading(true)
                setSubmitButtonDisable(false)
                setPopupDisplayForDesktop(false)
            })


    }

    const [oddsData, setOddsData] = useState(() => {
        const initialStatus = {};
        handRanks.forEach(rank => {
            initialStatus[rank] = {
                "Player A": 0,
                "Player B": 0
            }


        });
        return initialStatus;
    });

    const [playerAmounts, setPlayerAmounts] = useState({
        "Player A": '',
        "Player B": '',


    });
    const getOdds = (rank, player) => {
        // This function simulates getting the odds. You should replace it with your logic.
        return oddsData[rank][player];
    };
    const renderRow = (rank) => {
        const playerAStatus = playerStatuses[rank];

        return (
            <tr data-title="SUSPENDED" className="bet-info">
                <td className="box-6"><b>{rank}</b></td>
                <td
                    className={`box-2 back teen-section ${playerAStatus}`}
                    onClick={() => rank === 'Winner' ? openPopup('back', "Player A", getOdds(rank, "Player A")) : ''}
                >
                    <button className="back">
                        <span className="odd">{getOdds(rank, "Player A")}</span>
                        {rank === 'Winner' && getExByColor(playerAmounts['Player A'])}



                    </button>

                </td>
                <td
                    className={`box-2 back teen-section ${playerAStatus}`}
                    onClick={() => rank === 'Winner' ? openPopup('back', "Player B", getOdds(rank, "Player B")) : ''}
                >
                    <button className="back">
                        <span className="odd">{getOdds(rank, "Player B")}</span>
                        {rank === 'Winner' && getExByColor(playerAmounts['Player B'])}
                    </button>

                </td>
            </tr>
        );
    };

    return (
        <CasinoLayout hideLoading={hideLoading} isBack={backOrLay} teamname={teamname}
                      handleStakeChange={casinoBetDataNew} odds={odds}
                      stakeValue={stakeValue} setOdds={setOdds} placeBet={placeBet}
                      submitButtonDisable={submitButtonDisable} data={data} roundId={roundId} setRoundId={setRoundId}
                      sportList={sportList}
                      setSportList={setSportList} setData={setData} setLastResult={setLastResult}>
            <div className="video-container" style={{minHeight: '0px'}}>
                <div className="video-overlay" style={{top: '-300px'}}>
                    <div id="game-cards">
                        <div className="card-inner">
                            <div className="row">
                                <div className="col-6">
                                    <h3 className="text-white" style={{fontSize: '11px'}}>Player A</h3>
                                    <div>
                                        {getPlayerCardAccordingToNumberOfPlayers(data, 1, 9, [0, 1]).map((card, index) => (
                                            <img key={index} className="borde-img-card" src={card}
                                                 alt={`Player A card ${index}`}/>
                                        ))}
                                    </div>
                                </div>
                                <div className="col-6 text-right">
                                    <h3 className="text-white" style={{fontSize: '11px'}}>Player B</h3>
                                    {getPlayerCardAccordingToNumberOfPlayers(data, 1, 9, [2, 3]).map((card, index) => (
                                        <img key={index} className="borde-img-card" src={card}
                                             alt={`Player B card ${index}`}/>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="card-inner">
                            <h3 className="text-white">Board</h3>
                            <div>
                                {getPlayerCardAccordingToNumberOfPlayers(data, 1, 9, [4, 5, 6, 7, 8]).map((card, index) => (
                                    <img key={index} className="borde-img-card" src={card} alt={`Board card ${index}`}/>
                                ))}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="card-content m-t-0  ">
                <div className="row" id="poker-table21">
                    <div className="table-responsive col-md-6 m-b-10 main-market p-r-5">
                        <div className="live-poker">
                            <table className="table coupon-table table table-bordered">
                                <thead>
                                <tr>
                                    <th className="box-6"></th>
                                    <th className="box-2 back">Player A</th>
                                    <th className="box-2 back">Player B</th>
                                </tr>
                                </thead>
                                <tbody>
                                {handRanks.slice(0, 5).map(rank => renderRow(rank))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="table-responsive col-md-6 m-b-10 main-market p-r-5">
                        <div className="live-poker">
                            <table className="table coupon-table table table-bordered">
                                <thead>
                                <tr>
                                    <th className="box-6"></th>
                                    <th className="box-2 back">Player A</th>
                                    <th className="box-2 back">Player B</th>
                                </tr>
                                </thead>
                                <tbody>
                                {handRanks.slice(5, 9).map(rank => renderRow(rank))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <marquee scrollamount="3" className="casino-remark m-b-10">{remark.current}</marquee>
                <div className="casino-last-result-title">
                    <span>Last Result</span>
                </div>
                <div className="casino-last-results">
                    <CasinoLastResult sportList={sportList} lastResults={lastResult} data={data}/>
                </div>
            </div>


        </CasinoLayout>
    );

};


export default Poker20;
