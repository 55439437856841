export const Cmatch20 = ({lastResults, openPopup}) => {
    return (
        <>
            {Object.entries(lastResults).map(([key, result]) => {
                const mid = result.mid;
                const className = 'last-result'

                return (
                    <span onClick={() => openPopup(mid)}
                          key={mid}
                          className={className}
                          title={`Round ID: ${mid}`}
                    >

                       <img src={`/img/balls/ball${result.win}.png`} className="balls" alt="balls"/>
                        &nbsp;
                                </span>
                );
            })}
        </>
    );
}

