import CasinoLayout from "../Section/Casino/CasinoLayout";
import {useContext, useEffect, useRef, useState} from "react";

import {CasinoLastResult} from "../Section/Casino/CasinoLastResult";

import axiosFetch, {
    getExByColor,

    getExByTeamNameForCasino, resetBetFields,

} from "../../Common_Functions/Constants";
import {useParams} from "react-router-dom";
import {SportsContext} from "../../Components/Context/SportsContext";


import Notify from "../../js/Notify";
import {CasinoContext} from "../../Components/Context/CasinoContext";

const Queen = () => {
    const [roundId, setRoundId] = useState('')

    const defaultValues = {odds: {back: 0, lay: 0}, status: '', amounts: ''}

    const [totalPlayers, setTotalPlayers] = useState(Object.fromEntries(Array.from({length: 4},
        (_, index) => {
            return ["Total " + index, defaultValues];
        })));


    const roundIdSaved = useRef(null);

    const [submitButtonDisable, setSubmitButtonDisable] = useState(false)

    const [cards, setCards] = useState({});

    const stakeValue = useRef(0);
    const [odds, setOdds] = useState(0)

    const [backOrLay, setbackOrLay] = useState('back')
    const [sportList, setSportList] = useState({})
    const {match_id} = useParams();
    const {
        setBetType,
        setPopupDisplayForDesktop,

    } = useContext(SportsContext)
    const {getCardTotalCard32} = useContext(CasinoContext)
    const [hideLoading, setHideLoading] = useState(true)


    const teamNames = useRef(["Player A", "Player B"])

    const [data, setData] = useState([]);
    const [playerA, setPlayerA] = useState(0); // Example player A value
    const [playerStatuses, setPlayerStatuses] = useState({"Player A": '', "Player B": ''});
    const [playerA_Back, setPlayerA_Back] = useState(0);
    const [playerB_Back, setPlayerB_Back] = useState(0);
    const [playerA_Lay, setPlayerA_Lay] = useState(0);
    const [playerB, setPlayerB] = useState(0); // Example player B value

    const [playerB_Lay, setPlayerB_Lay] = useState(0);
    const remark = useRef('Welcome');
    const [lastResult, setLastResult] = useState({});
    const teamname = useRef('');
    const loss = useRef(0);
    const profit = useRef(0);
    const profitData = useRef(0);


    useEffect(() => {
        setBetType('BOOKMAKER')

        if (data?.sub) {

            setTotalPlayers((prevState) => {

                const updState = {...prevState}
                Object.entries(prevState).map(([index, value]) => {

                    const datafound = data.sub.find(item => item.nat === index);
                    updState[index] = {
                        ...value, // Copy existing properties
                        odds: {
                            ...value.odds, // Copy existing odds structure
                            back: datafound.b || 0,
                            lay: datafound.l || 0
                        },
                        status: datafound.gstatus === 'OPEN' ? '' : 'suspended-box'
                    };

                })


                return updState


            })

        }

        if (data.card) {
            let total0count = '',
                total1count = '',
                total2count = '',
                total3count = ''
            const cardArray = data.card.split(",").map(item => item.trim());
            const totalA = cardArray.map((card, i) => {
                if (i % 4 === 0) {

                    return card !== '1' ? card : null;
                }
            })

            const ctotalA = totalA.filter(item => item !== null && item !== undefined)

            if (ctotalA.length > 0) {
                total0count = getCardTotalCard32(ctotalA, 0)
            }


            const totalB = cardArray.map((card, i) => {
                if (i % 4 === 1) {
                    if (card !== '1') {
                        total1count = renderCards(cardArray, card)
                    }
                    return card !== '1' ? card : null;
                }
            })

            const totalC = cardArray.map((card, i) => {
                if (i % 4 === 2) {
                    if (card !== '1') {
                        total2count = renderCards(cardArray, card)
                    }
                    return card !== '1' ? card : null;
                }
            })

            const totalD = cardArray.map((card, i) => {
                if (i % 4 === 3) {
                    if (card !== '1') {
                        total3count = renderCards(cardArray, card)
                    }
                    return card !== '1' ? card : null;
                }
            })

            const ctotalB = totalB.filter(item => item !== null && item !== undefined)

            if (ctotalB.length > 0) {
                total1count = getCardTotalCard32(ctotalB, 1)
            }


            const ctotalC = totalC.filter(item => item !== null && item !== undefined)

            if (ctotalC.length > 0) {
                total2count = getCardTotalCard32(ctotalC, 2)
            }
            const ctotalD = totalD.filter(item => item !== null && item !== undefined)

            if (ctotalD.length > 0) {
                total3count = getCardTotalCard32(ctotalD, 3)
            }


            setCards({
                total0: totalA,
                total0count: total0count,
                total1: totalB,
                total1count: total1count,
                total2: totalC,
                total2count: total2count,
                total3: totalD,
                total3count: total3count,
            });
            remark.current = data.remark || 'Welcome';
        }
    }, [data]);

    const exposure = localStorage.getItem('exposure');
    const sportLength = Object.keys(data).length;

    const updateAmounts = async () => {

        const result = [getExByTeamNameForCasino(sportList.id, roundId, 'Total 0', match_id, 'ODDS'),

            getExByTeamNameForCasino(sportList.id, roundId, 'Total 1', match_id, 'ODDS'),
            getExByTeamNameForCasino(sportList.id, roundId, 'Total 2', match_id, 'ODDS'),
            getExByTeamNameForCasino(sportList.id, roundId, 'Total 3', match_id, 'ODDS')]

        const promise = await Promise.all(result)

        setTotalPlayers((prevState) => {
            const pr = {...prevState}
            pr['Total 0'] = {
                ...pr['Total 0'],
                amounts: promise[0]?.data || ''

            }
            pr['Total 1'] = {
                ...pr['Total 1'],
                amounts: promise[1]?.data || ''

            }
            pr['Total 2'] = {
                ...pr['Total 2'],
                amounts: promise[2]?.data || ''

            }
            pr['Total 3'] = {
                ...pr['Total 3'],
                amounts: promise[3]?.data || ''

            }


            return pr;
        })


    }

    useEffect(() => {

        if (data?.sub && sportList?.id) {
            updateAmounts()
        }
    }, [exposure, sportLength, roundId]);


    const openPopup = (isBakOrLay, teamnam, oddvalue) => {


        if (parseFloat(oddvalue) > 0) {
            roundIdSaved.current = roundId
            setbackOrLay(isBakOrLay)
            setPopupDisplayForDesktop(true);
            teamname.current = teamnam
            setOdds(oddvalue)
        } else {
            Notify("Odds Value Change Bet Not Confirm", null, null, 'danger')

        }


    }
    const casinoBetDataNew = (new_odds) => {
        stakeValue.current = new_odds
        if (backOrLay === 'back') {


            loss.current = stakeValue.current;


            profit.current = profitData.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)

        } else {

            profit.current = profitData.current = stakeValue.current;


            loss.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)
        }


    }

    const renderCards = (cards, player) => {

        return cards?.map((card, index) => {
            if (card !== '1' && card) {
                const imgSrc = `/img/casino/cards/${card}.png`;
                return (
                    <img src={imgSrc} alt={`${player} card ${index + 1}`} key={index}/>

                );
            }

        })

    };


    const placeBet = () => {

        setHideLoading(false)
        if (playerStatuses[teamname.current] === 'suspended') {
            Notify(`Bet Not Confirmed. Reason: Game Suspended`, null, null, 'danger');
            setPopupDisplayForDesktop(false);
            setHideLoading(true);
            resetBetFields(profitData, stakeValue)
            return;
        }

        if (roundIdSaved.current !== roundId) {
            Notify("Bet Not Confirm Reason Game Change", null, null, 'danger')
            setPopupDisplayForDesktop(false)
            setHideLoading(true);

            resetBetFields(profitData, stakeValue)
            return;
        }


        setSubmitButtonDisable(true)

        const postdata = {

            "sportId": sportList.id,
            "matchId": roundId,
            "isback": backOrLay === 'back' ? 1 : 0,
            "placeName": teamname.current,
            "placeName2": null,
            "odds": odds,
            "oddsk": 0,
            "profit": parseFloat(profit.current).toFixed(2),
            "loss": parseFloat(loss.current).toFixed(2),
            "betType": "ODDS",
            "bet_side": backOrLay.toUpperCase(),
            "betAmount": parseFloat(stakeValue.current),
            "type": match_id.toUpperCase(),
            "matchType": match_id.toLowerCase(),

        }

        axiosFetch('casino/store', 'post', null, postdata)
            .then((res) => {
                if (res.data.status === true) {
                    updateAmounts();

                    resetBetFields(profitData, stakeValue)
                    Notify("Bet Placed Successfully", null, null, 'success')
                } else {

                    resetBetFields(profitData, stakeValue)

                    Notify(res.data.msg, null, null, 'danger')
                }
                setHideLoading(true)
                setSubmitButtonDisable(false)
                setPopupDisplayForDesktop(false)
            })


    }

    return (
        <CasinoLayout hideRules={true} raceClass="new-casino queen" hideLoading={hideLoading} isBack={backOrLay} teamname={teamname} handleStakeChange={casinoBetDataNew} odds={odds}
                      stakeValue={stakeValue} setOdds={setOdds} placeBet={placeBet}
                      submitButtonDisable={submitButtonDisable} data={data} roundId={roundId} setRoundId={setRoundId}
                      sportList={sportList}
                      setSportList={setSportList} setData={setData} setLastResult={setLastResult}>

            <div className="video-container" style={{minHeight: '0px'}}>
                <div className="video-overlay" style={{top: '-180px'}}>
                    <div className="videoCards">
                        <div>

                            {cards && cards.total0count && cards?.total0count !== '' && (
                                <>
                                    <h5 className="text-white text-uppercase">Total 0 : {cards?.total0count}</h5>
                                    {renderCards(cards.total0, "total0")}
                                </>
                            )}
                        </div>
                        <div className="mt-1">
                            {cards && cards.total1count && cards?.total1count !== '' && (
                                <>

                                    <h5>Total 1 : {cards?.total1count}</h5>
                                    {renderCards(cards.total1, "total1")}
                                </>
                            )}
                        </div>
                        <div className="mt-1">
                            {cards && cards.total2count && cards?.total2count !== '' && (

                                <>
                                    <h5>Total 2 : {cards?.total2count}</h5>
                                    {renderCards(cards.total2, "total2")}
                                </>
                            )}

                        </div>
                        <div className="mt-1">
                            {cards && cards.total3count && cards?.total3count !== '' && (

                                <>
                                    <h5>Total 3 : {cards?.total3count}</h5>
                                    {renderCards(cards.total3, "total3")}
                                </>
                            )}

                        </div>
                    </div>
                </div>
            </div>

            <div className="casino-detail">
                <div className="card-content redqueen m-t-10">
                    <div className="casino-odds-box-wrapper">
                        {Object.entries(totalPlayers).map(([index, value], i) => (
                            <div className="casino-odds-box-container casino-odds-box-container-double" key={i}>
                                <div className="casino-odds-box-bhav">
                                    <b>{index}</b>
                                </div>
                                <div className={`casino-odds-box ${value.status}`}>
                                    <div className="back-border"
                                         onClick={() => openPopup('back', index, value.odds.back)}>
                                        <span className="casino-odds-box-odd">{value.odds.back}</span>

                                    </div>

                                    <div className="lay-border" onClick={() => openPopup('lay', index, value.odds.lay)}>
                                        <span className="casino-odds-box-odd">{value.odds.lay}</span>

                                    </div>
                                </div>
                                {getExByColor(value.amounts)}

                            </div>
                        ))}

                    </div>
                </div>
                <marquee scrollamount="3" className="casino-remark m-b-10">{remark.current}</marquee>
                <div className="casino-last-result-title">
                    <span>Last Result</span>
                </div>
                <div className="last-result-container">
                    <CasinoLastResult sportList={sportList} lastResults={lastResult} data={data}/>
                </div>
            </div>
            

        </CasinoLayout>
    );

};

const PlayerTable = ({playerName, playerValue, playerBack, openPopup, playerLay, playerStatus}) => (
    <div className="casino-table-left-box">
        <div className="casino-table-header">
            <div className="casino-nation-detail">{playerName}</div>
            <div className="casino-odds-box back">Back</div>
            <div className="casino-odds-box lay">Lay</div>
        </div>
        <div className={`casino-table-body`}>
            <div className={`casino-table-row ${playerStatus}`}>
                <div className="casino-nation-detail">
                    <div className="casino-nation-name">Main</div>
                    <p className="m-b-0">
                        <span className={`font-weight-bold ${playerValue >= 0 ? 'text-success' : 'text-danger'}`}>
                            {playerValue}
                        </span>
                    </p>
                </div>
                <div className="casino-odds-box back">
                    <span className="casino-odds"
                          onClick={() => openPopup('back', playerName, playerBack)}>{playerBack}</span>
                </div>
                <div className="casino-odds-box lay">
                    <span className="casino-odds"
                          onClick={() => openPopup('lay', playerName, playerLay)}>{playerLay}</span>
                </div>
            </div>
        </div>
    </div>
);

export default Queen;
