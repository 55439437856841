import Header from "./Section/Header";

const FantasyGames = () => {

    return (
        <>
            <Header/>
            <div className="center-main-container list-page own-casino-page">
                <div className="center-container">
                    <div className="container-fluid container-fluid-5">
                        <div className="row row5">
                            <div className="col-md-7">
                                <div className="fantasy-banners row row5">
                                    <div className="col-6 col-md-3 mb-2 fantasy_div_img" data-game="diam11">
                                        <img src="https://sitethemedata.com/casino_icons/other/diam11.png"
                                             className="img-fluid pointer" />
                                    </div>
                                    <div className="col-6 col-md-3 mb-2 fantasy_div_img" data-game="playerbattle">
                                        <img src="https://sitethemedata.com/casino_icons/other/playerbattle.jpeg"
                                             className="img-fluid pointer" />
                                    </div>
                                    <div className="col-6 col-md-3 mb-2 fantasy_div_img" data-game="snakes_and_ladders">
                                        <img src="https://sitethemedata.com/casino_icons/other/snakes-and-ladders.jpg"
                                             className="img-fluid pointer" />
                                    </div>
                                    <div className="col-6 col-md-3 mb-2 fantasy_div_img" data-game="rummy">
                                        <img src="https://sitethemedata.com/casino_icons/other/rummy.jpg"
                                             className="img-fluid pointer" />
                                    </div>
                                    <div className="col-6 col-md-3 mb-2 fantasy_div_img" data-game="ludoclub">
                                        <img src="https://sitethemedata.com/casino_icons/other/ludoclub.jpg"
                                             className="img-fluid pointer" />
                                    </div>
                                    <div className="col-6 col-md-3 mb-2 fantasy_div_img" data-game="ludo_lands">
                                        <img src="https://sitethemedata.com/casino_icons/other/ludo-lands.jpg"
                                             className="img-fluid pointer" />
                                    </div>
                                    <div className="col-6 col-md-3 mb-2 fantasy_div_img" data-game="poptheball">
                                        <img src="https://sitethemedata.com/casino_icons/other/poptheball.jpg"
                                             className="img-fluid pointer" />
                                    </div>
                                    <div className="col-6 col-md-3 mb-2 fantasy_div_img" data-game="jetx">
                                        <img src="https://sitethemedata.com/casino_icons/other/ssg/xgames/jetx.jpg"
                                             className="img-fluid pointer" />
                                    </div>
                                    <div className="col-6 col-md-3 mb-2 fantasy_div_img" data-game="cappadocia">
                                        <img
                                            src="https://sitethemedata.com/casino_icons/other/ssg/xgames/cappadocia.jpg"
                                            className="img-fluid pointer" />
                                    </div>
                                    <div className="col-6 col-md-3 mb-2 fantasy_div_img" data-game="spinx">
                                        <img src="https://sitethemedata.com/casino_icons/other/ssg/xgames/spinx.jpg"
                                             className="img-fluid pointer" />
                                    </div>
                                    <div className="col-6 col-md-3 mb-2 fantasy_div_img" data-game="jetx3">
                                        <img src="https://sitethemedata.com/casino_icons/other/ssg/xgames/jetx3.jpg"
                                             className="img-fluid pointer" />
                                    </div>
                                    <div className="col-6 col-md-3 mb-2 fantasy_div_img" data-game="cricketx">
                                        <img src="https://sitethemedata.com/casino_icons/other/ssg/xgames/cricketx.jpg"
                                             className="img-fluid pointer" />
                                    </div>
                                    <div className="col-6 col-md-3 mb-2 fantasy_div_img" data-game="vipkeno">
                                        <img src="https://sitethemedata.com/casino_icons/other/ssg/xgames/vipkeno.jpg"
                                             className="img-fluid pointer" />
                                    </div>
                                    <div className="col-6 col-md-3 mb-2 fantasy_div_img" data-game="classickeno">
                                        <img
                                            src="https://sitethemedata.com/casino_icons/other/ssg/xgames/ClassicKeno.jpg"
                                            className="img-fluid pointer" />
                                    </div>
                                    <div className="col-6 col-md-3 mb-2 fantasy_div_img" data-game="russiankeno">
                                        <img
                                            src="https://sitethemedata.com/casino_icons/other/ssg/xgames/RussianKeno.jpg"
                                            className="img-fluid pointer" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
)

}

export default FantasyGames;