import React, {useContext, useMemo, useCallback, useEffect} from "react";
import {
    getSize,
    organiseOdds,
    getExByColor,
    handleShowRules,
    generateBackAndLayFunction
} from "../../../Common_Functions/Constants";
import { SportsContext } from "../../../Components/Context/SportsContext";

function Bookmaker({
                       ar_sectionData,
                       allTeamName = {},
                       sportList,
                       oddsChange,
                       setBetOddValue,
                       setbackOrLay,
                       teamNames,
                       setDefaultTeamName,
                       teamNameCurrentBets = {},
                       setPopupDisplay,
                       bookmakerTeamData,
                       setMaxValue = null,
                       setMinValue = null
                   }) {
    const { runnerRowDefault, rootClassDefault, setBetType, setBetTypeFromArray } = useContext(SportsContext);

    if (!teamNames.current['BOOKMAKER']) {
        teamNames.current['BOOKMAKER'] =[]; // Initialize 'BOOKMAKER' as an empty array
    }
    // Memoize bookmaker data

    const bookmakerData = useMemo(() => ar_sectionData?.bookmaker, [ar_sectionData]);
    const sections = bookmakerData?.section || [];
    useEffect(() => {
        if (setMaxValue !== null && bookmakerData?.max) {
            setMaxValue((prevState) => {
                return {...prevState, 'BOOKMAKER': bookmakerData?.max}
            })
            if (setMinValue !== null) {
                setMinValue((prevState) => {
                    return {...prevState, 'BOOKMAKER': bookmakerData?.min ?? 100}
                })
            }

        }
        // eslint-disable-next-line
    }, [bookmakerData?.max]);
    // Common function to render odds
    const renderOdds = useCallback(
        (oddsArr, key, teamName, runnerRow, tot) => {
            const backFunction = generateBackAndLayFunction(
                tot, oddsArr, 'back', teamName, runnerRow, key, 'BOOKMAKER', setBetOddValue, setbackOrLay, teamNames, setPopupDisplay, setDefaultTeamName, runnerRowDefault, rootClassDefault, setBetType,
                null, setBetTypeFromArray, 'bookmaker'
            );
            const layFunction = generateBackAndLayFunction(
                tot, oddsArr, 'lay', teamName, runnerRow, key, 'BOOKMAKER', setBetOddValue, setbackOrLay, teamNames, setPopupDisplay, setDefaultTeamName, runnerRowDefault, rootClassDefault, setBetType
                , setBetTypeFromArray, 'bookmaker'
            );

            return (
                <>
                    {/* Back Odds */}
                    {oddsArr.back?.slice(0, 1).map((back, b_key) => {
                        const value_price = tot === 0 ? '-' : back?.odds || '-';
                        const value_size = tot === 0 ? '' : getSize(back?.size || '');

                        if(oddsChange.current[`bookmakerback${key}${b_key}`] !== value_price){
                            setTimeout(() => {
                                oddsChange.current[`bookmakerback${key}${b_key}`] = value_price;
                            }, 70);
                        }



                        return (
                            <div
                                key={b_key}

                                className={`box-1 back float-left text-center ${oddsChange.current[`bookmakerback${key}${b_key}`] !== value_price && oddsChange.current[`bookmakerback${key}${b_key}`] !== '' ? 'color-yellow-bet' : ""}`}
                                onClick={backFunction[b_key]}>
                                <span className="odd d-block">{value_price}</span>
                                <span className="d-block">{value_size}</span>
                            </div>
                        );
                    })}

                    {/* Lay Odds */}
                    {oddsArr.lay?.slice(0, 1).map((lay, l_key) => {
                        const value_price = tot === 0 ? '-' : lay?.odds || '-';
                        const value_size = tot === 0 ? '' : getSize(lay?.size || '');

                        if(oddsChange.current[`bookmakerlay${key}${l_key}`] !== value_price){
                            setTimeout(() => {
                                oddsChange.current[`bookmakerlay${key}${l_key}`] = value_price;
                            }, 70);
                        }


                        return (
                            <div
                                key={l_key}

                                className={`box-1 lay float-left text-center ${oddsChange.current[`bookmakerlay${key}${l_key}`] !== value_price && oddsChange.current[`bookmakerlay${key}${l_key}`] !== ''  ? 'color-yellow-bet' : ''}`}
                                onClick={layFunction[l_key]}>
                                <span className="odd d-block">{value_price}</span>
                                <span className="d-block">{value_size}</span>
                            </div>
                        );
                    })}
                </>
            );
        },
        //eslint-disable-next-line
        [oddsChange, setBetOddValue, setbackOrLay, teamNames, setPopupDisplay, setDefaultTeamName, runnerRowDefault, rootClassDefault, setBetType]
    );

    return (
        <>
            {bookmakerData && (
                <div>
                    <div className="market-title">
                        Bookmaker market
                        <p className="float-right mb-0" onClick={() => handleShowRules('Bookmakerrule')}>
                            <i className="fas fa-info-circle"></i>
                        </p>
                    </div>
                    <div className="main-market">
                        <div className="table-header">
                            <div className="float-left country-name box-6 min-max">
                                <b>Min: {bookmakerData.min} Max: {getSize(bookmakerData.max)}</b>
                            </div>
                            <div className="back box-1 float-left text-center"><b>Back</b></div>
                            <div className="lay box-1 float-left text-center"><b>Lay</b></div>
                        </div>
                        <div className="table-body BOOKMAKER">
                            {sections.map((oddsArr, key) => {
                                let isSuspended = "", isSuspendedClass = "";
                                let tot = 1;

                                // Check if suspended
                                if (oddsArr.gstatus === 'SUSPENDED' || sportList.match_suspend_bookmaker === 1 || sportList.match_suspend === 1) {
                                    tot = 0;
                                    isSuspended = "SUSPENDED";

                                    isSuspendedClass = 'suspended';
                                }

                                const teamName = oddsArr.nat || '';
                                teamNames.current['BOOKMAKER'][teamName] = teamName;
                                const teamEx = bookmakerTeamData?.[teamName];
                                if(!allTeamName.current['BOOKMAKER']){
                                    allTeamName.current['BOOKMAKER'] = [];


                                }
                                if(!allTeamName.current['BOOKMAKER'].includes(teamName))
                                allTeamName.current['BOOKMAKER'].push(teamName);
                                const runnerRow = `table-row-${sportList.match_id}-${key}`;

                                // Organize odds
                                const organizedOdds = organiseOdds(oddsArr);
                                const n_key = `${sportList.match_id}-${key}-2`;
                                if(!teamNameCurrentBets.current?.['BOOKMAKER']){

                                    teamNameCurrentBets.current['BOOKMAKER'] = [];
                                    teamNameCurrentBets.current['BOOKMAKER'][teamName] = ''
                                }

                                teamNameCurrentBets.current['BOOKMAKER'][teamName] = teamEx
                                return (
                                    <div data-title={isSuspended} key={key} className={`table-row table-row-0-${key} ${isSuspendedClass}`}>
                                        <div className="float-left country-name box-4">
                                            <span className="team-name"><b>{teamName}</b></span>
                                            <p><span className="teamEx float-left">{getExByColor(teamEx, true)}</span></p>
                                        </div>
                                        {renderOdds(organizedOdds, n_key, teamName, runnerRow, tot)}
                                    </div>
                                );
                            })}

                            {bookmakerData.rem && (
                                <div className="market-row">
                                    <marquee className="market-remark">{bookmakerData.rem}</marquee>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Bookmaker;
