import React, {useContext, useEffect, useState} from "react";
import  {getStakeValue} from "../Common_Functions/Constants";
import {SportsContext} from "./Context/SportsContext";
import {Modal, Button, Form} from 'react-bootstrap';
import StakeModal from "./EditStakeModal";


export const BetPlacPopupSection = ({
                                        showCalculation = false,
                                        stakeValue,
                                        profitData,
                                        setProfitValue = null,
                                        hideLoading,
                                        backOrLay,
                                        teamname,
                                        setOdds,
                                        handleStakeChange,
                                        odds,
                                        teamNameCurrentBets = {},
                                        loss,
                                        profit,
                                        profitValue = null,
                                        placeBet,
                                        submitButtonDisable,
                                        teamnames = {},
                                        clearCards = false,
                                        showBetType = ''
                                    }) => {


    const {popupDisplay, setPopupDisplay, betType} = useContext(SportsContext)


    const [stakeValues, setStakeValues] = useState({});
    useEffect(() => {


        getStakeValue(setStakeValues);
    }, [stakeValues]);

    const [stakeshowModal, setStakeShowModal] = useState(false);




    const renderTeamName = () => {


        return (
            showCalculation &&
            teamnames && teamnames.current?.[betType] &&
            teamnames.current[betType] &&
            teamnames.current[betType].length > 0 && (
                <div className="teamExAdd">
                    {teamnames.current[betType].map((value, index) => {

                        const isBackOrLayCurrentTeam =
                            (backOrLay === "back" && value !== teamname.current) ||
                            (backOrLay === "lay" && value === teamname.current);

// Calculate loss based on teamNameCurrentBets
                        const currentBetValue = parseFloat(teamNameCurrentBets.current?.[betType]?.[value]) || 0;
                        let calculatedLoss = loss.current > 0
                            ? currentBetValue > 0
                                ? Math.abs(parseFloat(loss.current) - Math.abs(currentBetValue))
                                : -(parseFloat(loss.current) + Math.abs(currentBetValue))
                            : 0;



                        if(calculatedLoss > 0){
                            calculatedLoss = -calculatedLoss
                        }

// Calculate profit based on teamNameCurrentBets
                        const calculatedProfit = profit.current > 0
                            ? currentBetValue > 0
                                ? (Math.abs(parseFloat(profit.current)) + Math.abs(currentBetValue))
                                : Math.abs(parseFloat(profit.current)) - Math.abs(currentBetValue)
                            : 0;

// Determine class based on whether it's a loss or profit
                        const textClass = isBackOrLayCurrentTeam
                            ? (calculatedLoss >= 0 ? "text-success" : "text-danger")
                            : (calculatedProfit >= 0 ? "text-success" : "text-danger");
                        return (
                            <div key={index}>
                                {value}
                                <div className={`pull-right ${textClass}`}>
                                    {isBackOrLayCurrentTeam
                                        ? calculatedLoss
                                        : calculatedProfit}
                                </div>
                                <br/>
                            </div>
                        )
                    })}
                </div>
            )
        );
    };



    return (<div id="betStakeModelTxt">

        <StakeModal
            show={stakeshowModal}
            handleClose={() => setStakeShowModal(false)}

        />


        <Modal
            show={popupDisplay}
            onHide={() => {
                setPopupDisplay(false);
                stakeValue.current = 0;
                profitData.current = 0;
                loss.current = 0;
                profit.current = 0;
                if (typeof setProfitValue === 'function') {
                    setProfitValue(0)
                }

                if (typeof clearCards === 'function' && clearCards !== false) {
                    clearCards();
                }
            }}
            dialogClassName="modal-md betPopup"
            aria-labelledby="betStakeModalLabel"
        >
            <Modal.Header closeButton>
                <Modal.Title>Place Bet</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {hideLoading ? null : <div className="loader1 loaderBet"></div>}
                <div id="placeBetSilp">
                    <div className={`place-bet placeBetSilp pt-2 pb-2 ${backOrLay}`}>
                        <div className="container-fluid container-fluid-5">
                            <div className="row row5 align-items-end">
                                <div id="ShowRunnderName"
                                     className="col-5 text-bold">{showBetType + " " + teamname?.current}</div>
                                <div className="col-7 text-right">
                                    <div className="float-right">
                                        <Button
                                            className="stakeactionminus"
                                            onClick={() => {
                                                const newOdds = (parseFloat(odds) - 0.01).toFixed(2);
                                                setOdds(newOdds);
                                                handleStakeChange({target: {value: stakeValue?.current}}, newOdds);
                                            }}
                                        >
                                            <span className="fa fa-minus"></span>
                                        </Button>
                                        <input
                                            readOnly
                                            type="text"
                                            name="odds"
                                            value={odds || ''}
                                            id="ShowBetPrice"
                                            className="stakeinput amountint"
                                            style={{width: '45px'}}
                                        />
                                        <Button
                                            className="stakeactionplus"
                                            onClick={() => {
                                                const newOdds = (parseFloat(odds) + 0.01).toFixed(2);
                                                setOdds(newOdds);
                                                handleStakeChange({target: {value: stakeValue?.current}}, newOdds);
                                            }}
                                        >
                                            <span className="fa fa-plus"></span>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="row row5 mt-2">
                                <div className="col-4">
                                    <Form.Control
                                        type="text"
                                        id="stakeValue"
                                        value={stakeValue?.current}
                                        onChange={handleStakeChange}
                                        placeholder="00"
                                        className="stakeinput w-100"
                                    />
                                </div>
                                <div className="col-4">
                                    <Button
                                        onClick={placeBet}
                                        disabled={submitButtonDisable}
                                        className="btn btn-primary btn-block"
                                    >
                                        Submit
                                    </Button>

                                </div>
                                {profitValue !== null && (<div className="col-4 text-center pt-1">
                                    <span id="profitData">{profitValue}</span>
                                </div>)}
                            </div>
                            <div className="row row5 mt-2">
                                {Object.values(stakeValues).map((item, id) => (<div className="col-4" key={id}>
                                    <button
                                        key={item.val}
                                        className="btn btn-secondary btn-block mb-2"
                                        value={item.val}
                                        onClick={() => handleStakeChange({target: {value: item.val}})}
                                    >
                                        {item.label}
                                    </button>
                                </div>))}
                            </div>
                            {renderTeamName()}
                            <div className="mt-3 d-flex justify-content-between align-items-center">
                                <Button
                                    type="button"
                                    className="btn btn-info"
                                    onClick={() => setStakeShowModal(true)}
                                >
                                    Edit
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    </div>)
}