import React, {useEffect, useState, useRef, useContext} from 'react';
import {Modal, Button, Form, Table} from 'react-bootstrap';
import axiosFetch, {getStakeValue} from "../Common_Functions/Constants";
import Notify from "../js/Notify";
import {SportsContext} from "./Context/SportsContext";

const StakeModal = ({show, handleClose}) => {
    // State for form data retrieval and disabling the save button

    const [stakeDisable, setStakeDisable] = useState(false);

    const {setStakeValues, stakeValues} = useContext(SportsContext)

    // Refs for each form input
    const stakeRefs = useRef({});


    useEffect(() => {


        getStakeValue(setStakeValues);

        if (Object.values(stakeValues).length > 0) {
            Object.entries(stakeValues).forEach(([i, stake], index) => {
                if (!stakeRefs.current[index]) {
                    stakeRefs.current[index] = {label: React.createRef(), val: React.createRef()};
                }

            });
        }
    }, [Object.values(stakeValues).length]);



    // Save function
    const saveStakeNew = async (event) => {
        event.preventDefault();
        setStakeDisable(true);


        // Retrieve values from refs

        const formData = {};
        Object.entries(stakeRefs.current).forEach(([index, refs], i) => {

            formData[`lable${parseInt(i) + 1}`] = refs.label.current.value;
            formData[`val${parseInt(i) + 1}`] = refs.val.current.value;
        });


        try {
            const result = await axiosFetch('saveStake', 'post', null, formData);

            if (result.data.status === true) {
                handleClose(false);
                getStakeValue(setStakeValues);
                setStakeDisable(false);
                Notify("Stake Values Updated Successfully", null, null, 'success');
            } else {
                Notify("Update Error", null, null, 'danger');
            }
        } catch (error) {
            Notify("Something Went Wrong", null, null, 'danger');
        }
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Edit Stake</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Table bordered>
                        <thead>
                        <tr>
                            <th>Sr.No</th>
                            <th>Stake Label</th>
                            <th>Stake Value</th>
                        </tr>
                        </thead>
                        <tbody>
                        {Object.values(stakeValues).map((stake, i) => (
                            <tr key={i}>
                                <th>{`Button ${i + 1}`}</th>
                                <td>
                                    <Form.Control
                                        type="number"
                                        defaultValue={stake.label}
                                        ref={el => stakeRefs.current[i].label.current = el}
                                    />
                                </td>
                                <td>
                                    <Form.Control
                                        type="number"
                                        defaultValue={stake.val}
                                        ref={el => stakeRefs.current[i].val.current = el}
                                    />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    <div className="text-right mb-3">
                        <Button variant="success" disabled={stakeDisable} onClick={saveStakeNew}>
                            Update
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default StakeModal;
