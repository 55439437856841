import CasinoLayout from "../Section/Casino/CasinoLayout";
import {useContext, useEffect, useRef, useState} from "react";

import {CasinoLastResult} from "../Section/Casino/CasinoLastResult";

import axiosFetch, {
    getExByColor,

    getExByTeamNameForCasino, resetBetFields
} from "../../Common_Functions/Constants";
import {useParams} from "react-router-dom";
import {SportsContext} from "../../Components/Context/SportsContext";
import {BetPlacPopupSection} from "../../Components/BetPlacPopupSection";

import Notify from "../../js/Notify";

const Superover = () => {
    const [roundId, setRoundId] = useState('')
    const [min, setMin] = useState('')
    const [max, setMax] = useState('')
    const allTeamName = useRef({"BOOKMAKER" : ["ENG", 'RSA']})
    const teamNameCurrentBets = useRef({})


    const roundIdSaved = useRef(null);

    const [submitButtonDisable, setSubmitButtonDisable] = useState(false)

    const [cards, setCards] = useState([]);

    const stakeValue = useRef(0);
    const [odds, setOdds] = useState(0)

    const [backOrLay, setbackOrLay] = useState('back')
    const [sportList, setSportList] = useState({})
    const {match_id} = useParams();
    const {
        setBetType,
        setPopupDisplay,

    } = useContext(SportsContext)
    const [hideLoading, setHideLoading] = useState(true)


    const teamNames = useRef(["ENG", "RSA"])

    const ruleImage = '/img/rules/superover.jpg'
    const ruleDescription = '';
    const [data, setData] = useState([]);
    const [playerA, setPlayerA] = useState(0); // Example player A value
    const [playerStatuses, setPlayerStatuses] = useState({"ENG": '', "RSA": ''});
    const [playerA_Back, setPlayerA_Back] = useState(0);
    const [playerB_Back, setPlayerB_Back] = useState(0);
    const [playerA_Lay, setPlayerA_Lay] = useState(0);
    const [playerB, setPlayerB] = useState(0); // Example player B value
    const [playerASize, setPlayerASize] = useState({back : "", lay : ""}); // Example player B value
    const [playerBSize, setPlayerBSize] = useState({back : "", lay : ""}); // Example player B value

    const [playerB_Lay, setPlayerB_Lay] = useState(0);
    const remark = useRef('Welcome');
    const [lastResult, setLastResult] = useState({});
    const teamname = useRef('');
    const loss = useRef(0);
    const profit = useRef(0);
    const profitData = useRef(0);


    useEffect(() => {
        setBetType('BOOKMAKER')

        if (data?.t2) {

            updatePlayerStats(data.t2?.[0]?.['section']?.[0], setPlayerA_Back, setPlayerA_Lay, "ENG",setPlayerASize);
            updatePlayerStats(data.t2?.[0]?.['section']?.[1], setPlayerB_Back, setPlayerB_Lay, "RSA", setPlayerBSize);

        }


        if (data) {

            let ar_card_img = {
                'K': process.env.PUBLIC_URL + "/img/casino/rules/ball/wicket1.png",
                'A': process.env.PUBLIC_URL + "/img/casino/rules/ball/ball1.png",
                '2': process.env.PUBLIC_URL + "/img/casino/rules/ball/ball2.png",
                '3': process.env.PUBLIC_URL + "/img/casino/rules/ball/ball3.png",
                '4': process.env.PUBLIC_URL + "/img/casino/rules/ball/ball4.png",
                '6': process.env.PUBLIC_URL + "/img/casino/rules/ball/ball6.png",
                '10': process.env.PUBLIC_URL + "/img/casino/rules/ball/ball0.png"
            };

            if (data?.t1?.card) {
                const ar_explode = ["CC", "HH", "DD", "SS"];

                // Split the card string and map to images
                const result_cards = data.t1.card.split('|').map((item) => {
                    // Remove any "CC", "HH", "DD", "SS" from the item
                    ar_explode.forEach((str) => {
                        item = item.replace(str, "");
                    });

                    // Return the image path if the item exists in ar_card_img, else null
                    return item !== "1" ? ar_card_img[item] || null : null;
                });


                setCards(result_cards); // Assuming setCards is a state setter function
            }

            remark.current = data.remark || 'Welcome';

        }



    }, [data]);

    const exposure = localStorage.getItem('exposure');
    const sportLength = Object.keys(data).length;
    const updateAmount =  async () => {
        await getExByTeamNameForCasino(sportList.id, data?.t1?.gmid, 'ENG', match_id).then(res => setPlayerA(res.data))

        await getExByTeamNameForCasino(sportList.id, data?.t1?.gmid, 'RSA', match_id).then(res => setPlayerB(res.data))
    }
    useEffect(() => {

        if (data?.t2 && sportList?.id) {
            updateAmount()
        }
    }, [exposure, sportLength, roundId]);

    const updatePlayerStats = (playerData, setPlayerBack, setPlayerLay, playerName, setPlayerSize) => {

        if (!playerData) return;
        let playerStatus = '';

        if (playerData.gstatus !== "ACTIVE") {
            playerStatus = "suspended";

        }

        if(playerName === 'ENG'){
            setMin(data?.t2[0].min)
            setMax(data?.t2[0].max)
        }

        const england_back = playerData['odds'].find(item => item.otype === 'back').odds

        const england_lay = playerData['odds'].find(item => item.otype === 'lay').odds
        setPlayerSize({back : playerData['odds'].find(item => item.otype === 'back').size, lay: playerData['odds'].find(item => item.otype === 'lay').size})


        setPlayerStatuses(prev => ({...prev, [playerName]: playerStatus}));

        if (england_back) {
            setPlayerBack(england_back);

        } else {
            setPlayerBack(0);
        }
        if (england_lay) {


            setPlayerLay(england_lay);


        } else {
            setPlayerLay(0);
        }
    };
    const openPopup = (isBakOrLay, teamnam, oddvalue) => {

        loss.current = ''
        profit.current = ''

        if (parseFloat(oddvalue) > 0) {
            roundIdSaved.current = roundId
            setbackOrLay(isBakOrLay)
            setPopupDisplay(true);
            teamname.current = teamnam
            setOdds(oddvalue)
        } else {
            Notify("Odds Value Change Bet Not Confirm", null, null, 'danger')

        }


    }
    const casinoBetDataNew = (event, new_odds) => {
        stakeValue.current = event.target.value
        if (backOrLay === 'back') {
            loss.current = stakeValue.current;
            profit.current = profitData.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)

        } else {
            profit.current = profitData.current = stakeValue.current;
            loss.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)
        }


    }

    const renderCards = () => (
        cards?.map((card, index) => {
            if (card === null)
                return null;
            return (
                <div key={index}>
                    <img style={{width: "100px"}} key={index} src={card} alt={`card-${index}`}/>
                </div>
            );
        })
    );


    const placeBet = () => {

        setHideLoading(false)
        if (playerStatuses[teamname.current] === 'suspended') {
            Notify(`Bet Not Confirmed. Reason: Game Suspended`, null, null, 'danger');
            setPopupDisplay(false);
            setHideLoading(true);

            return;
        }

        if (roundIdSaved.current !== roundId) {
            Notify("Bet Not Confirm Reason Game Change", null, null, 'danger')
            setPopupDisplay(false)
            return;
        }


        setSubmitButtonDisable(true)

        const postdata = {
            "sportId": sportList.id,
            "matchId": roundId,
            "isback": backOrLay === 'back' ? 1 : 0,
            "placeName": teamname.current,
            "placeName2": teamNames.current.find(name => name !== teamname.current),
            "odds": odds,
            "oddsk": 0,
            "profit": parseFloat(profit.current).toFixed(2),
            "loss": parseFloat(loss.current).toFixed(2),
            "betType": "BOOKMAKER",
            "bet_side": backOrLay.toUpperCase(),
            "betAmount": parseFloat(stakeValue.current),
            "type": match_id.toUpperCase(),
            "matchType": match_id.toLowerCase(),

        }

        axiosFetch('casino/store', 'post', null, postdata)
            .then((res) => {
                if (res.data.status === true) {
                    updateAmount()
                    resetBetFields(profitData, stakeValue)
                    Notify("Bet Placed Successfully", null, null, 'success')
                } else {

                    resetBetFields(profitData, stakeValue)
                    Notify(res.data.msg, null, null, 'danger')
                }
                setHideLoading(true)
                setSubmitButtonDisable(false)
                setPopupDisplay(false)
            })


    }

    return (
        <CasinoLayout ruleImage={ruleImage}
                      ruleDescription={ruleDescription} data={data} roundId={roundId} setRoundId={setRoundId}
                      sportList={sportList}
                      setSportList={setSportList} setData={setData} setLastResult={setLastResult}>
            <div className="casino-video" style={{minHeight: '0px'}}>
                <div className="video-overlay" style={{top: '-200px'}}>


                    {renderCards()}


                </div>
            </div>

            <div className="casino-detail">
                <div className="markets">
                    <div className="bookmaker-market">
                        <div className="market-title mt-1">
                            Bookmaker Market

                        </div>
                        <div>
                            <div className="table-header">
                                <div className="float-left country-name box-7 min-max"><b>Min:{min} Max:{max}</b></div>

                                <div className="back box-1 float-left text-center"><b>BACK</b></div>
                                <div className="lay box-1 float-left text-center"><b>LAY</b></div>
                            </div>
                            <div className="table-body">
                                {["ENG", "RSA"].map((team, index) => (
                                    <PlayerTable
                                        key={index}  // Assuming team has a unique name
                                        team={team}
                                        backEvent={() => openPopup('back', team, team === 'ENG' ? playerA_Back : playerB_Back)}
                                        Ex={team === 'ENG' ? playerA : playerB}
                                        layEvent={() => openPopup('lay', team, team === 'ENG' ? playerA_Lay : playerB_Lay)}
                                        size={team === 'ENG' ? playerASize : playerBSize}
                                        isSuspended={playerStatuses[team]}
                                        playerBack={team === 'ENG' ? playerA_Back : playerB_Back}
                                        playerLay={team === 'ENG' ? playerA_Lay : playerB_Lay}
                                        teamNameCurrentBets={teamNameCurrentBets}
                                    />
                                ))}
                            </div>
                        </div>
                        <div className="table-remark text-right remark"></div>
                    </div>
                </div>

                <marquee scrollamount="3" className="casino-remark m-b-10">{remark.current}</marquee>
                <div className="casino-last-result-title">
                    <span>Last Result</span>
                </div>
                <div className="casino-last-results">
                    <CasinoLastResult sportList={sportList} lastResults={lastResult} data={data}/>
                </div>
            </div>
            <BetPlacPopupSection teamNameCurrentBets={teamNameCurrentBets} showCalculation={true} placeBet={placeBet} profitValue={profitData.current} profit={profit} loss={loss}
                                 teamnames={allTeamName}  handleStakeChange={casinoBetDataNew} hideLoading={hideLoading}
                                 teamname={teamname} backOrLay={backOrLay} stakeValue={stakeValue} odds={odds}
                                 profitData={profitData}
                                 setOdds={setOdds} submitButtonDisable={submitButtonDisable}/>

            <div className="card m-b-10">
                <div className="cc-rules">
                    <div data-toggle="collapse" data-target=".video-tv" className="card-header">
                        <h6 className="card-title text-center mb-0">ENG VS RSA<br/>INNING'S CARD RULES</h6>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <div className="row row5 mt-1">
                                <div className="col-4">Cards</div>
                                <div className="col-2 text-center">Count</div>
                                <div className="col-6 text-right">Value</div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row row5 mt-1">
                                <div className="col-4"><img
                                    src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/img/cards/cricketv/A.jpg"/>
                                    <span className="ml-2">X</span></div>
                                <div className="col-2 text-center">5</div>
                                <div className="col-6 text-right value"><img
                                    src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/img/balls/superover/ball1.png"/>
                                </div>
                            </div>
                            <div className="row row5 mt-1">
                                <div className="col-4"><img
                                    src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/img/cards/cricketv/2.jpg"/>
                                    <span className="ml-2">X</span></div>
                                <div className="col-2 text-center">5</div>
                                <div className="col-6 text-right value"><img
                                    src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/img/balls/superover/ball2.png"/>
                                </div>
                            </div>
                            <div className="row row5 mt-1">
                                <div className="col-4"><img
                                    src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/img/cards/cricketv/3.jpg"/>
                                    <span className="ml-2">X</span></div>
                                <div className="col-2 text-center">5</div>
                                <div className="col-6 text-right value"><img
                                    src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/img/balls/superover/ball3.png"/>
                                </div>
                            </div>
                            <div className="row row5 mt-1">
                                <div className="col-4"><img
                                    src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/img/cards/cricketv/4.jpg"/>
                                    <span className="ml-2">X</span></div>
                                <div className="col-2 text-center">5</div>
                                <div className="col-6 text-right value"><img
                                    src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/img/balls/superover/ball4.png"/>
                                </div>
                            </div>
                            <div className="row row5 mt-1">
                                <div className="col-4"><img
                                    src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/img/cards/cricketv/6.jpg"/>
                                    <span className="ml-2">X</span></div>
                                <div className="col-2 text-center">5</div>
                                <div className="col-6 text-right value"><img
                                    src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/img/balls/superover/ball6.png"/>
                                </div>
                            </div>
                            <div className="row row5 mt-1">
                                <div className="col-4"><img
                                    src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/img/cards/cricketv/10.jpg"/>
                                    <span className="ml-2">X</span></div>
                                <div className="col-2 text-center">5</div>
                                <div className="col-6 text-right value"><img
                                    src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/img/balls/superover/ball0.png"/>
                                </div>
                            </div>
                            <div className="row row5 mt-1">
                                <div className="col-4"><img
                                    src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/img/cards/cricketv/K.jpg"/>
                                    <span className="ml-2">X</span></div>
                                <div className="col-2 text-center">5</div>
                                <div className="col-6 text-right value"><span className="mr-2"> WICKET</span> <img
                                    src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/img/balls/superover/wicket1.png"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </CasinoLayout>
    );

};

const PlayerTable = ({team, backEvent, layEvent, Ex, isSuspended, playerBack, playerLay, size, teamNameCurrentBets}) =>
{
    if(!teamNameCurrentBets.current?.['BOOKMAKER']){

        teamNameCurrentBets.current['BOOKMAKER'] = [];
        teamNameCurrentBets.current['BOOKMAKER'][team] = ''
    }

    teamNameCurrentBets.current['BOOKMAKER'][team] = Ex
    return (
    <div data-title="suspended" className={`table-row ${isSuspended ? isSuspended : ''}`}>
        <div className="float-left country-name box-7">
            <span className="team-name"><b>{team
            }</b></span>
            <p>
                <span className="float-left">{getExByColor(Ex)}</span>
            </p>
        </div>


        <div className="box-1 back float-left back lock text-center" onClick={backEvent}>
            <span className="odd d-block">{playerBack}</span>
            <span className="d-block">{size.back}</span>
        </div>
        <div className="box-1 lay float-left text-center" onClick={layEvent}>
            <span className="odd d-block">{playerLay}</span>
            <span className="d-block">{size.lay}</span>
        </div>


    </div>
)}

export default Superover;
