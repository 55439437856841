import CasinoLayout from "../Section/Casino/CasinoLayout";
import {useContext, useEffect, useRef, useState} from "react";

import {CasinoLastResult} from "../Section/Casino/CasinoLastResult";

import axiosFetch, {
    cardMap, getExByColor, resetBetFields
} from "../../Common_Functions/Constants";
import {useParams} from "react-router-dom";
import {SportsContext} from "../../Components/Context/SportsContext";


import Notify from "../../js/Notify";
import {CasinoContext} from "../../Components/Context/CasinoContext";

const Dt202 = () => {
    const {fetchDataDragonTiger} = useContext(CasinoContext)

    const [roundId, setRoundId] = useState('')
    const [TOTALPLAYERS, setTotalPlayers] = useState(
        [
            {
                Dragon: {odds: 0, status: 'suspended-box', 'amounts': ''},
                Tie: {odds: 0, status: 'suspended-box', 'amounts': ''},
                Tiger: {odds: 0, status: 'suspended-box', 'amounts': ''},
                Pair: {odds: 0, status: 'suspended-box', 'amounts': ''},

            },
            {
                Dragon: {
                    Even: {odds: 0, status: 'suspended-box', 'amounts': ''},
                    Odd: {odds: 0, status: 'suspended-box', 'amounts': ''},
                    Black: {odds: 0, status: 'suspended-box', 'amounts': ''},
                    Red: {odds: 0, status: 'suspended-box', 'amounts': ''},
                    Card: {
                        odds: '',
                        status: 'suspended-box',
                        'amounts': Array.from({length: 13}, (_, i) => '')
                    },
                }
            },
            {
                Tiger: {
                    Even: {odds: 0, status: 'suspended-box', 'amounts': ''},
                    Odd: {odds: 0, status: 'suspended-box', 'amounts': ''},
                    Black: {odds: 0, status: 'suspended-box', 'amounts': ''},
                    Red: {odds: 0, status: 'suspended-box', 'amounts': ''},
                    Card: {
                        odds: '',
                        status: 'suspended-box',
                        'amounts': Array.from({length: 13}, (_, i) => '')
                    },
                }
            }
        ]);

    const updateOdds = (data) => {

        data = data.sub;

        setTotalPlayers((prevState) => {
            return prevState.map((section, index) => {
                // Update Dragon section in the first object of TOTALPLAYERS
                if (index === 0) {
                    return {
                        ...section,
                        Dragon: {
                            ...section.Dragon,
                            odds: data.find((d) => d.nat === "Dragon")?.b || 0,
                            status: data.find((d) => d.nat === "Dragon")?.gstatus === 'OPEN' ? '' : 'suspended-box',

                        },
                        Tie: {
                            ...section.Tie,
                            odds: data.find((d) => d.nat === "Tie")?.b || 0,
                            status: data.find((d) => d.nat === "Tie")?.gstatus === 'OPEN' ? '' : 'suspended-box',
                        },
                        Tiger: {
                            ...section.Tiger,
                            odds: data.find((d) => d.nat === "Tiger")?.b || 0,
                            status: data.find((d) => d.nat === "Tiger")?.gstatus === 'OPEN' ? '' : 'suspended-box',
                        },
                        Pair: {
                            ...section.Pair,
                            odds: data.find((d) => d.nat === "Pair")?.b || 0,
                            status: data.find((d) => d.nat === "Pair")?.gstatus === 'OPEN' ? '' : 'suspended-box',
                        }
                    };
                }

                // Update Dragon section in the second object
                if (index === 1 && section.Dragon) {


                    return {
                        ...section,
                        Dragon: {
                            ...section.Dragon,
                            Even: data.find((d) => d.nat === "Dragon Even")
                                ? {
                                    ...section.Dragon.Even,
                                    odds: data.find((d) => d.nat === "Dragon Even").b,
                                    status: data.find((d) => d.nat === "Dragon Even").gstatus === 'OPEN' ? '' : 'suspended-box',

                                }
                                : section.Dragon.Even,
                            Odd: data.find((d) => d.nat === "Dragon Odd")
                                ? {
                                    ...section.Dragon.Odd,
                                    odds: data.find((d) => d.nat === "Dragon Odd").b,
                                    status: data.find((d) => d.nat === "Dragon Odd").gstatus === 'OPEN' ? '' : 'suspended-box',

                                }
                                : section.Dragon.Odd,
                            Black: data.find((d) => d.nat === "Dragon Black")
                                ? {
                                    ...section.Dragon.Black,
                                    odds: data.find((d) => d.nat === "Dragon Black").b,
                                    status: data.find((d) => d.nat === "Dragon Black").gstatus === 'OPEN' ? '' : 'suspended-box',

                                }
                                : section.Dragon.Black,
                            Red: data.find((d) => d.nat === "Dragon Red")
                                ? {
                                    ...section.Dragon.Red,
                                    odds: data.find((d) => d.nat === "Dragon Red").b,
                                    status: data.find((d) => d.nat === "Dragon Red").gstatus === 'OPEN' ? '' : 'suspended-box',

                                }
                                : section.Dragon.Red,
                            Card: data.find((d) => d.nat.startsWith("Dragon Card"))
                                ? {
                                    ...section.Dragon.Card,

                                    odds: data.find((d) => d.nat.startsWith("Dragon Card")).b,
                                    status: data.find((d) => d.nat.startsWith("Dragon Card")).gstatus === 'OPEN' ? '' : 'suspended-box',

                                }
                                : section.Dragon.Card
                        }
                    };
                }

                // Update Tiger section in the third object
                if (index === 2 && section.Tiger) {
                    return {
                        ...section,
                        Tiger: {
                            ...section.Tiger,
                            Even: data.find((d) => d.nat === "Tiger Even")
                                ? {
                                    ...section.Tiger.Even,

                                    odds: data.find((d) => d.nat === "Tiger Even").b,
                                    status: data.find((d) => d.nat === "Tiger Even").gstatus === 'OPEN' ? '' : 'suspended-box',

                                }
                                : section.Tiger.Even,
                            Odd: data.find((d) => d.nat === "Tiger Odd")
                                ? {
                                    ...section.Tiger.Odd,

                                    odds: data.find((d) => d.nat === "Tiger Odd").b,
                                    status: data.find((d) => d.nat === "Tiger Odd").gstatus === 'OPEN' ? '' : 'suspended-box',

                                }
                                : section.Tiger.Odd,
                            Black: data.find((d) => d.nat === "Tiger Black")
                                ? {
                                    ...section.Tiger.Black,

                                    odds: data.find((d) => d.nat === "Tiger Black").b,
                                    status: data.find((d) => d.nat === "Tiger Black").gstatus === 'OPEN' ? '' : 'suspended-box',

                                }
                                : section.Tiger.Black,
                            Red: data.find((d) => d.nat === "Tiger Red")
                                ? {
                                    ...section.Tiger.Red,

                                    odds: data.find((d) => d.nat === "Tiger Red").b,
                                    status: data.find((d) => d.nat === "Tiger Red").gstatus === 'OPEN' ? '' : 'suspended-box',

                                }
                                : section.Tiger.Red,
                            Card: data.find((d) => d.nat.startsWith("Tiger Card"))
                                ? {
                                    ...section.Tiger.Card,

                                    odds: data.find((d) => d.nat.startsWith("Tiger Card")).b,
                                    status: data.find((d) => d.nat.startsWith("Tiger Card")).gstatus === 'OPEN' ? '' : 'suspended-box',

                                }
                                : section.Tiger.Card
                        }
                    };
                }


                // No changes for sections that don't match
                return section;
            });
        });
    };
    const roundIdSaved = useRef(null);

    const [submitButtonDisable, setSubmitButtonDisable] = useState(false)

    const [cards, setCards] = useState({});

    const stakeValue = useRef(0);
    const [odds, setOdds] = useState(0)

    const [backOrLay, setbackOrLay] = useState('back')
    const [sportList, setSportList] = useState({})
    const {match_id} = useParams();
    const {
        setBetType,
        betType,
        setPopupDisplayForDesktop,

    } = useContext(SportsContext)
    const [hideLoading, setHideLoading] = useState(true)
    const ruleImage = '/img/rules/dt20.jpg'
    const ruleDescription = '';

    const teamNames = useRef(["Player A", "Player B"])

    const [data, setData] = useState([]);
    const remark = useRef('Welcome');
    const [lastResult, setLastResult] = useState({});
    const teamname = useRef('');
    const loss = useRef(0);
    const profit = useRef(0);
    const profitData = useRef(0);


    useEffect(() => {

        if (data?.sub) {
            updateOdds(data)

        }

        if (data.card) {
            const cardArray = data.card.split(",").map(item => item.trim());
            setCards({
                playerA: cardArray.slice(0, 3),

            });
            remark.current = data.remark || 'Welcome';
        }
    }, [data]);


    const exposure = localStorage.getItem('exposure')
    const sportLength = Object.keys(data).length;


    useEffect(() => {

        if (sportLength > 0) {
            // Call fetchData without any parameter or with 'cards' as needed
            fetchDataDragonTiger(data, sportList,match_id, roundId,TOTALPLAYERS, setTotalPlayers,betType,'all')

        }
    }, [exposure, sportLength, roundId]);

    const openPopup = (isBakOrLay, teamnam, oddvalue, betType) => {
        setBetType(betType)


        if (parseFloat(oddvalue) > 0) {
            roundIdSaved.current = roundId
            setbackOrLay(isBakOrLay)
            setPopupDisplayForDesktop(true);
            teamname.current = teamnam
            setOdds(oddvalue)
        } else {
            Notify("Odds Value Change Bet Not Confirm", null, null, 'danger')

        }


    }
    const casinoBetDataNew = (new_odds) => {
        stakeValue.current = new_odds
        if (backOrLay === 'back') {


            loss.current = stakeValue.current;


            profit.current = profitData.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)

        } else {

            profit.current = profitData.current = stakeValue.current;


            loss.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)
        }
    }

    const renderCards = (cards, player) => (
        <div className="flip-card-container">
            {cards?.map((card, index) => {
                const imgSrc = card ? `/img/casino/cards/${card}.png` : '/img/casino/cards/1.png';
                return (
                    <div className="flip-card" key={index}>
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={imgSrc} alt={`${player} card ${index + 1}`}/>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );

    const placeBet = () => {

        setHideLoading(false)

        if (TOTALPLAYERS[0].Dragon?.status === 'suspended-box') {
            Notify(`Bet Not Confirmed. Reason: Game Suspended`, null, null, 'danger');
            setPopupDisplayForDesktop(false);
            profitData.current = 0
            stakeValue.current = 0
            setHideLoading(true);

            return;
        }

        if (roundIdSaved.current !== roundId) {
            Notify("Bet Not Confirm Reason Game Change", null, null, 'danger')
            setPopupDisplayForDesktop(false)
            profitData.current = 0
            stakeValue.current = 0
            return;
        }


        setSubmitButtonDisable(true)

        const postdata = {

            "sportId": sportList.id,
            "matchId": roundId,
            "isback": 0,
            "placeName": teamname.current,
            "placeName2": null,
            "odds": odds,
            "oddsk": 0,
            "profit": parseFloat(profit.current).toFixed(2),
            "loss": parseFloat(loss.current).toFixed(2),
            "betType": betType,
            "bet_side": backOrLay.toUpperCase(),
            "betAmount": parseFloat(stakeValue.current),
            "type": "DT202",
            "matchType": "dt202",

        }

        axiosFetch('casino/store', 'post', null, postdata)
            .then((res) => {
                if (res.data?.status === true) {

                    resetBetFields(profitData, stakeValue)
                    Notify("Bet Placed Successfully", null, null, 'success')

                    fetchDataDragonTiger(data, sportList,match_id, roundId,TOTALPLAYERS, setTotalPlayers,betType,'all')

                } else {

                    resetBetFields(profitData, stakeValue)
                    Notify(res.data.msg, null, null, 'danger')
                }
                setHideLoading(true)
                setSubmitButtonDisable(false)
                setPopupDisplayForDesktop(false)


            })
    }

    return (
        <CasinoLayout ruleImage={ruleImage} ruleDescription={ruleDescription} hideLoading={hideLoading}
                      isBack={backOrLay} teamname={teamname} handleStakeChange={casinoBetDataNew} odds={odds}
                      stakeValue={stakeValue} setOdds={setOdds} placeBet={placeBet}
                      submitButtonDisable={submitButtonDisable} data={data} roundId={roundId} setRoundId={setRoundId}
                      sportList={sportList}
                      setSportList={setSportList} setData={setData} setLastResult={setLastResult}>
            <div className="video-container" style={{minHeight: '0px'}}>
                <div className="video-overlay" style={{top: '-300px'}}>
                    <div className="casino-video-cards">
                        <div>

                            {renderCards(cards.playerA, "Player A")}
                        </div>

                    </div>
                </div>
            </div>


            <div className="dt-container">
                <div className="d-t-box m-b-10 m-t-10">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                {Object.keys(TOTALPLAYERS[0]).map(player => (
                                    player !== 'Pair' && (
                                        <div className={`col-md-${player === 'Tie' ? '2' : '5'}`} key={player}>
                                            <p className="d-block mb-0 text-center">
                                                <b>{TOTALPLAYERS[0][player]?.odds}</b>
                                            </p>
                                            <button
                                                className={`btn-theme mt-1 ${TOTALPLAYERS[0][player]?.status}`}
                                                onClick={() => openPopup('back', player, TOTALPLAYERS[0][player]?.odds, player === 'Tie' ? 'TIE' : 'ODDS')}>
                                                {player}
                                            </button>
                                            <p className="mt-1 mb-0 text-center text-danger">
                                                <b>
                                                    {getExByColor(TOTALPLAYERS[0][player].amounts)}
                                                </b>
                                            </p>

                                        </div>
                                    )
                                ))}
                            </div>
                        </div>
                        <div className="col-md-3 pair-block  text-center">

                            <b>{TOTALPLAYERS[0].Pair?.odds}</b>
                            <button
                                className={`btn-theme mt-1 ${TOTALPLAYERS[0].Pair?.status}`}
                                onClick={() => openPopup('back', "Pair", TOTALPLAYERS[0].Pair?.odds, "PAIR")}>
                                Pair
                            </button>

                            {getExByColor(TOTALPLAYERS[0].Pair.amounts)}

                        </div>
                    </div>

                </div>
                <div className="row row-5">


                    {TOTALPLAYERS.slice(1).map((playerObj, index) => {
                        // Extract the player name and details
                        const playerName = Object.keys(playerObj)[0];
                        const playerDetails = playerObj[playerName];
                        // Variables similar to Blade template
                        const evenD = playerDetails.Even?.odds;
                        const oddD = playerDetails.Odd?.odds;
                        const redD = playerDetails.Red?.odds;
                        const blackD = playerDetails.Black?.odds;
                        const evenDStatus = playerDetails.Even?.status;
                        const oddDStatus = playerDetails.Odd?.status;
                        const redDStatus = playerDetails.Red?.status;
                        const blackDStatus = playerDetails.Black?.status;


                        return (


                            <div className="col-md-6" key={index}>

                                <div className="d-t-box m-b-10">
                                    <div className="row">
                                        <div className="col-12">
                                            <h4 className="m-b-0 text-center text-uppercase">{playerName}</h4>
                                        </div>
                                        <div className="col-md-6 text-center">
                                            <b>{evenD}</b>
                                            <button className={`btn-theme mt-1 ${evenDStatus}`}
                                                    onClick={() => openPopup('back',
                                                        playerName + " Even", evenD, playerName.toUpperCase() + "_ODD_EVEN")}>Even
                                            </button>
                                            {getExByColor(playerDetails.Even.amounts)}

                                        </div>
                                        <div className="col-6">
                                            <p className="d-block mb-0 text-center"><b>{oddD}</b></p>
                                            <button className={`btn-theme mt-1 ${oddDStatus}`}
                                                    onClick={() => openPopup('back',
                                                        playerName + " Odd", oddD, playerName.toUpperCase() + "_ODD_EVEN")}>Odd
                                            </button>
                                            <p className="mt-1 mb-0 text-center text-danger"><b>
                                                {getExByColor(playerDetails.Odd.amounts)}
                                            </b></p>
                                        </div>
                                        <div className="col-6">
                                            <p className="d-block mb-0 text-center"><b>{redD}</b></p>
                                            <button className={`btn-theme mt-1 ${redDStatus}`}
                                                    onClick={() => openPopup('back',
                                                        playerName + " Red", redD, playerName.toUpperCase() + "_RED_BLACK")}>
                                                <div className="color-card"></div>
                                                <span className="card-icon"><span
                                                    className="card-red">[</span></span>
                                                <span className="card-icon"><span
                                                    className="card-red">&#123;</span></span>
                                            </button>
                                            <p className="mt-1 mb-0 text-center text-danger"><b>
                                                {getExByColor(playerDetails.Red.amounts)}
                                            </b></p>
                                        </div>
                                        <div className="col-6">
                                            <p className="d-block mb-0 text-center"><b>{blackD}</b></p>
                                            <button className={`btn-theme mt-1 ${blackDStatus}`}
                                                    onClick={() => openPopup('back',
                                                        playerName + " Black", blackD, playerName.toUpperCase() + "_RED_BLACK")}>
                                                <div className="color-card"></div>
                                                <span className="card-icon"><span
                                                    className="card-black">]</span></span>
                                                <span className="card-icon"><span
                                                    className="card-black">{"}"}</span></span>
                                            </button>
                                            <p className="mt-1 mb-0 text-center text-danger"><b>
                                                {getExByColor(playerDetails.Black.amounts)}
                                            </b></p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        )
                            ;
                    })}
                </div>

                <dir className="row row-5">

                    {TOTALPLAYERS.slice(1).map((playerObj, index) => {
                        // Extract the player name and details
                        const playerName = Object.keys(playerObj)[0];
                        const playerDetails = playerObj[playerName];
                        // Variables similar to Blade template

                        const card_odds = playerDetails.Card?.odds;

                        const card_oddsStatus = playerDetails.Card?.status;

                        return (
                            <div className="col-md-6">


                                <div className="d-t-box m-b-10">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <p className="mb-0 text-center"><b>{playerName + " " + card_odds}</b>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="card-dt col-md-12 text-center m-t-10">

                                        {Array.from({length: 13}, (_, cardIndex) => {

                                            return (
                                                <div className=" m-r-5 card-image d-inline-block" key={cardIndex}>
                                                    <div className={card_oddsStatus}
                                                         onClick={() => openPopup('back', playerName + " Card " + cardMap(cardIndex), card_odds, playerName.toUpperCase() + "_SINGLE")}>
                                                        <img src={`/img/card/${cardIndex + 1}.jpg`}
                                                             alt={`Card ${cardIndex + 1}`}/>
                                                    </div>
                                                    <br/>
                                                    <div className="ubook text-center m-t-5 text-danger">
                                                        <b>
                                                            {getExByColor(playerDetails.Card.amounts[cardIndex])}
                                                        </b>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </div>
                                    <div className="row row5">
                                        <div className="col-12">
                                            <p className="mt-1 mb-0 text-right min-max">
                                                {/*Min: {minLimit} Max: {maxLimit}*/}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                </dir>

                <marquee scrollamount="3" className="casino-remark m-b-10">{remark.current}</marquee>
                <div className="casino-last-result-title">
                    <span>Last Result</span>
                </div>
                <div className="casino-last-results">
                    <CasinoLastResult sportList={sportList} lastResults={lastResult} data={data}/>
                </div>
            </div>


        </CasinoLayout>
    );

};


export default Dt202;
