import CasinoLayout from "../../Section/Casino/CasinoLayout";
import {useContext, useEffect, useRef, useState} from "react";

import {CasinoLastResult} from "../../Section/Casino/CasinoLastResult";

import axiosFetch, {
    getExByColor, resetBetFields
} from "../../../Common_Functions/Constants";
import {useParams} from "react-router-dom";
import {SportsContext} from "../../../Components/Context/SportsContext";
import {BetPlacPopupSection} from "../../../Components/BetPlacPopupSection";

import Notify from "../../../js/Notify";
import {CasinoContext} from "../../../Components/Context/CasinoContext";

const Vdt6 = () => {
    const {fetchDataDragonTigerDt6} = useContext(CasinoContext)

    const [roundId, setRoundId] = useState('')
    const [TOTALPLAYERS, setTotalPlayers] = useState({
        Dragon: {
            odds: {back: 0, lay: 0},
            status: 'suspended',
            amounts: '',
            Even: {odds: 0, status: 'suspended', amounts: ''},
            Odd: {odds: 0, status: 'suspended', amounts: ''},
            Black: {odds: 0, status: 'suspended', amounts: ''},
            Red: {odds: 0, status: 'suspended', amounts: ''},
            Spade: {odds: 0, status: 'suspended', amounts: ''},
            Heart: {odds: 0, status: 'suspended', amounts: ''},
            Club: {odds: 0, status: 'suspended', amounts: ''},
            Diamond: {odds: 0, status: 'suspended', amounts: ''}
        },
        Tiger: {
            odds: {back: 0, lay: 0},
            status: 'suspended',
            amounts: '',
            Even: {odds: 0, status: 'suspended', amounts: ''},
            Odd: {odds: 0, status: 'suspended', amounts: ''},
            Black: {odds: 0, status: 'suspended', amounts: ''},
            Red: {odds: 0, status: 'suspended', amounts: ''},
            Spade: {odds: 0, status: 'suspended', amounts: ''},
            Heart: {odds: 0, status: 'suspended', amounts: ''},
            Club: {odds: 0, status: 'suspended', amounts: ''},
            Diamond: {odds: 0, status: 'suspended', amounts: ''}
        },
        Pair: {
            odds: {back: 0, lay: 0},
            status: 'suspended',
            amounts: ''
        }
    });


    const updateOdds = (data) => {

        data = data.sub;


        setTotalPlayers((prevState) => {
            let newState = prevState;

            data.forEach(updates => {
                let {nat, b, gstatus, l} = updates;
                if (nat.includes(" ")) {
                    let [option, subOption] = nat.split(" ");
                    newState[option][subOption] = {
                        ...newState[option][subOption],
                        odds: b,
                        status: gstatus === 'OPEN' ? '' : 'suspended'
                    }
                } else {
                    newState[nat] = {
                        ...newState[nat],
                        odds: {
                            ...newState[nat].odds, // Preserve the existing odds
                            back: b,
                            lay: l
                        },
                        status: gstatus === 'OPEN' ? '' : 'suspended'
                    }
                }
            })

            return newState;
        })

    };
    const roundIdSaved = useRef(null);

    const [submitButtonDisable, setSubmitButtonDisable] = useState(false)

    const [cards, setCards] = useState(['1', '1']);

    const stakeValue = useRef(0);
    const [odds, setOdds] = useState(0)

    const [backOrLay, setbackOrLay] = useState('back')
    const [sportList, setSportList] = useState({})
    const {match_id} = useParams();
    const {
        setBetType,
        betType,
        setPopupDisplay,

    } = useContext(SportsContext)
    const [hideLoading, setHideLoading] = useState(true)
    const ruleImage = '/img/rules/dt20.jpg'
    const ruleDescription = '';
    const exposure = localStorage.getItem('exposure')
    const teamNames = useRef(["Player A", "Player B"])

    const [data, setData] = useState([]);
    const remark = useRef('Welcome');
    const [lastResult, setLastResult] = useState({});
    const teamname = useRef('');
    const loss = useRef(0);
    const profit = useRef(0);
    const profitData = useRef(0);


    useEffect(() => {

        if (data?.sub) {
            updateOdds(data)

        }

        if (data.card) {
            const cardArray = data.card.split(",").map(item => item.trim());
            setCards([cardArray[0],cardArray[1]]);

            remark.current = data.remark || 'Welcome';
        }
    }, [data]);


    const sportLength = Object.keys(data).length;


    useEffect(() => {

        if (sportLength > 0) {

            fetchDataDragonTigerDt6(data, sportList,match_id, roundId,TOTALPLAYERS, setTotalPlayers,betType,'all')

        }
    }, [exposure, sportLength, roundId]);

    const openPopup = (isBakOrLay, teamnam, oddvalue, betType) => {
        setBetType(betType)



        if (parseFloat(oddvalue) > 0) {
            roundIdSaved.current = roundId
            setbackOrLay(isBakOrLay)
            setPopupDisplay(true);
            teamname.current = teamnam
            setOdds(oddvalue)
        } else {
            Notify("Odds Value Change Bet Not Confirm", null, null, 'danger')

        }


    }
    const casinoBetDataNew = (event, new_odds) => {
        stakeValue.current = event.target.value
        if (backOrLay === 'back') {


            loss.current = stakeValue.current;


            profit.current = profitData.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)

        } else {

            profit.current = profitData.current = stakeValue.current;


            loss.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)
        }
    }

    const renderCards = () => (
        <div className="casino-video-cards">
            {Object.entries({'a' : 'DRAGON', 'b': 'TIGER'}).map(([index, value]) => (
                <div className={`vcasinocards-player${index}`}>
                    <h5 className="text-center">{value}</h5>
                    <div className="flip-card-container">
                        <div className="flip-card">
                            <div className="flip-card-inner ">
                                <div className="flip-card-front"><img src={process.env.REACT_APP_CARD_PATH +  cards[0] +".png"}/>
                                </div>
                                <div className="flip-card-back"><img src={process.env.REACT_APP_CARD_PATH +  cards[0]+".png"} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );

    const placeBet = () => {

        setHideLoading(false)
        let explodeTeamname;
        let isSuspended = false;

        if (teamname.current.includes(" ")) {
            explodeTeamname = teamname.current.split(" ");
            isSuspended = TOTALPLAYERS[explodeTeamname[0]][explodeTeamname[1]].status === 'suspended';
        }
        else {
            explodeTeamname = teamname.current;
            isSuspended = TOTALPLAYERS[explodeTeamname].status === 'suspended';

        }


        if (isSuspended === true) {
            Notify(`Bet Not Confirmed. Reason: Game Suspended`, null, null, 'danger');
            setPopupDisplay(false);
            profitData.current = 0
            stakeValue.current = 0
            setHideLoading(true);

            return;
        }

        if (roundIdSaved.current !== roundId) {
            Notify("Bet Not Confirm Reason Game Change", null, null, 'danger')
            setPopupDisplay(false)
            profitData.current = 0
            stakeValue.current = 0
            return;
        }


        setSubmitButtonDisable(true)

        const postdata = {

            "sportId": sportList.id,
            "matchId": roundId,
            "isback": betType === 'ODDS' ? 1 : 0,
            "placeName": teamname.current,
            "placeName2": null,
            "odds": odds,
            "oddsk": 0,
            "profit": parseFloat(profit.current).toFixed(2),
            "loss": parseFloat(loss.current).toFixed(2),
            "betType": betType,
            "bet_side": backOrLay.toUpperCase(),
            "betAmount": parseFloat(stakeValue.current),
            "type": "DT6",
            "matchType": "dt6",

        }

        axiosFetch('casino/store', 'post', null, postdata)
            .then((res) => {
                if (res.data?.status === true) {

                    resetBetFields(profitData, stakeValue)
                    Notify("Bet Placed Successfully", null, null, 'success')

                    fetchDataDragonTigerDt6(data, sportList, match_id, roundId, TOTALPLAYERS, setTotalPlayers, betType, 'ODDS')


                } else {

                    resetBetFields(profitData, stakeValue)
                    Notify(res.data.msg, null, null, 'danger')
                }
                setHideLoading(true)
                setSubmitButtonDisable(false)
                setPopupDisplay(false)


            })
    }
    const dragon = TOTALPLAYERS.Dragon;

    const tiger = TOTALPLAYERS.Tiger;

    const pair = TOTALPLAYERS.Pair;


    return (
        <CasinoLayout virtualVideoCards={renderCards} raceClass="teenpatti1day new-casino race vcasino"  ruleImage={ruleImage}
                      ruleDescription={ruleDescription} data={data} roundId={roundId} setRoundId={setRoundId}
                      sportList={sportList}
                      setSportList={setSportList} setData={setData} setLastResult={setLastResult}>


            <div className="casino-detail">
                <div className="casino-container dragon-tiger-1day">
                    <div className="table-responsive dt-odds">
                        <table className="table table-bordered mb-0">
                            <thead>
                            <tr>
                                <th className="box-4 min-max"></th>
                                <th className="box-3 text-center back">BACK</th>
                                <th className="box-3 text-center lay">LAY</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr className={dragon.status}>
                                <td className="box-4">
                                    <b>Dragon</b>
                                    <p className="mb-0">{getExByColor(dragon.amounts)}</p>
                                </td>
                                <td className="box-3 back text-center"
                                    onClick={() => openPopup('back', 'Dragon', dragon.odds.back, 'ODDS')}>
                                    <span className="odds d-block"><b>{dragon.odds.back}</b></span>

                                </td>
                                <td className="box-3 lay text-center"
                                    onClick={() => openPopup('lay', 'Dragon', dragon.odds.lay, 'ODDS')}>
                                    <span className="odds d-block"><b>{dragon.odds.lay}</b></span>

                                </td>
                            </tr>
                            <tr className={tiger.status}>
                                <td className="box-4">
                                    <b>Tiger</b>
                                    <p className="mb-0">{getExByColor(tiger.amounts)}</p>
                                </td>
                                <td className="box-3 back text-center"
                                    onClick={() => openPopup('back', 'Tiger', tiger.odds.back, 'ODDS')}>
                                    <span className="odds d-block"><b>{tiger.odds.back}</b></span>

                                </td>
                                <td className="box-3 lay text-center"
                                    onClick={() => openPopup('lay', 'Tiger', tiger.odds.lay, 'ODDS')}>
                                    <span className="odds d-block"><b>{tiger.odds.lay}</b></span>

                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="light-bg container-fluid mt-2">
                        <div className="row row5">
                            <div className="col-12">
                                <p className="d-block mb-0 text-center">
                                    <b>{pair.odds.back}</b></p>
                                <button className={`btn-theme mt-1 ${pair.status}`} onClick={() => openPopup('back', 'Pair', pair.odds.back, 'PAIR')}>Pair
                                </button>
                                <p className="mt-1 mb-0 text-center">
                                    {getExByColor(pair.amounts)}
                                </p>
                            </div>
                        </div>
                        <div className="row row5">
                            <div className="col-12">
                                <p className="mt-1 mb-0 text-right min-max">
                                    {/*Min: {minLimit} Max: {maxLimit}*/}
                                </p>
                            </div>
                        </div>
                    </div>
                    {[['Even', 'Odd'], ['Red', 'Black']].map((category, index) => (
                        <div className="table-responsive mt-2" key={index}>
                            <table className="table table-bordered mb-0">
                                <thead>
                                <tr>
                                    <th className="box-4 min-max"></th>
                                    {category.map((cat, catIndex) => (
                                        <th className="box-3 text-center back" key={catIndex}>{cat}

                                            {cat === 'Red' &&
                                                (<><span className="card-icon">
                            <span className="card-red">[</span>
                        </span>
                                                        <span className="card-icon">
                                                <span className="card-red">{"{"} </span>
                                        </span></>
                                                )}

                                            {cat === 'Black' &&
                                                (<> <span className="card-icon">
                                                <span className="card-black">]</span>
                                                </span>
                                                        <span className="card-icon">
                                                <span className="card-black">{"}"}</span>
                                        </span>
                                                    </>
                                                )}

                                        </th>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>
                                {['Dragon', 'Tiger'].map((player) => (
                                    <tr key={player}>
                                        <td className="box-4"><b>{player}</b></td>
                                        {category.map((cat) => (
                                            <td key={cat}
                                                className={`box-3 back text-center ${TOTALPLAYERS[player][cat].status}`}
                                                onClick={() => openPopup('back', `${player} ${cat}`, TOTALPLAYERS[player][cat].odds,   ['Even', 'Odd'].includes(cat) ?  `${player[0]}_EVEN_ODD` : `${player[0]}_RED_BLACK`)}>
                                                <span
                                                    className="odds d-block"><b>{TOTALPLAYERS[player][cat].odds}</b></span>
                                                {getExByColor(TOTALPLAYERS[player][cat].amounts)}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    ))}
                    <div className="table-responsive mt-2">
                        <table className="table table-bordered mb-0">
                            <thead>
                            <tr>
                                <th className="box-2 min-max"></th>
                                {['Spade', 'Heart', 'Club', 'Diamond'].map((suit, index) => (
                                    <th className="box-2 text-center back" key={index}>
                        <span className="card-icon">
                            <span
                                className={`card-${suit.toLowerCase()}`}>
  {
      suit === 'Spade' ? '}'
          : suit === 'Heart' ? <span className="card-red">{'{'}</span>
              : suit === 'Club' ? ']'
                  : <span className="card-red"> {'['}</span>
  }
</span>
                        </span>
                                    </th>
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            {['Dragon', 'Tiger'].map((player) => (
                                <tr key={player}>
                                    <td className="box-2"><b>{player}</b></td>
                                    {['Spade', 'Heart', 'Club', 'Diamond'].map((suit) => (
                                        <td key={suit}
                                            className={`box-2 back text-center ${TOTALPLAYERS[player][suit].status}`}
                                            onClick={() => openPopup('back',  player +" " + suit,   TOTALPLAYERS[player][suit].odds, player === 'Dragon' ? 'D_COLOR' : 'T_COLOR')}>
                                            <span
                                                className="odds d-block"><b>{TOTALPLAYERS[player][suit].odds}</b></span>
                                            {getExByColor(TOTALPLAYERS[player][suit].amounts)}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>

                </div>

                <marquee scrollamount="3" className="casino-remark m-b-10">{remark.current}</marquee>
                <div className="casino-last-result-title">
                    <span>Last Result</span>
                </div>
                <div className="casino-last-results">
                    <CasinoLastResult sportList={sportList} lastResults={lastResult} data={data}/>
                </div>
            </div>
            <BetPlacPopupSection placeBet={placeBet} profitValue={profitData.current} profit={profit} loss={loss}
                                 teamnames={teamNames} handleStakeChange={casinoBetDataNew} hideLoading={hideLoading}
                                 teamname={teamname} backOrLay={backOrLay} stakeValue={stakeValue} odds={odds}
                                 profitData={profitData}
                                 setOdds={setOdds} submitButtonDisable={submitButtonDisable}/>

        </CasinoLayout>
    );

};


export default Vdt6;
