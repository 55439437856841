import CasinoLayout from "../Section/Casino/CasinoLayout";
import {useContext, useEffect, useRef, useState} from "react";

import {CasinoLastResult} from "../Section/Casino/CasinoLastResult";

import axiosFetch, {
    getExByColor, getExBySingleTeamNameCasino,

 getPlayerCardAccordingToNumberOfPlayers, resetBetFields
} from "../../Common_Functions/Constants";
import {useParams} from "react-router-dom";
import {SportsContext} from "../../Components/Context/SportsContext";
import {BetPlacPopupSection} from "../../Components/BetPlacPopupSection";

import Notify from "../../js/Notify";


const War = () => {
    const [roundId, setRoundId] = useState('')
    const ruleImage = '/img/rules/war-rules.jpg'
    const ruleDescription = '';

    const roundIdSaved = useRef(null);

    const [submitButtonDisable, setSubmitButtonDisable] = useState(false)

    const [totalPlayers, setTotalPlayers] = useState(

        Object.fromEntries(Array.from({length:6}, (_, index) => {
            const key = "Winner " +(index + 1)


            return [key,  {"odds" : '', "img" : process.env.REACT_APP_CARD_PATH +"1.png", "status" : '', "amounts" : ""}]
        }))

    )


    const stakeValue = useRef(0);
    const [odds, setOdds] = useState(0)

    const [backOrLay, setbackOrLay] = useState('back')
    const [sportList, setSportList] = useState({})
    const {match_id} = useParams();
    const {

        setPopupDisplay,

    } = useContext(SportsContext)
    const [hideLoading, setHideLoading] = useState(true)



    const teamNames = useRef(["Player A", "Player B"])

    const [data, setData] = useState([]);

    const remark = useRef('Welcome');
    const [lastResult, setLastResult] = useState({});
    const teamname = useRef('');
    const loss = useRef(0);
    const profit = useRef(0);
    const profitData = useRef(0);


    useEffect(() => {

        if (data?.sub) {

            const cardssAre = getPlayerCardAccordingToNumberOfPlayers(data, 1, 2, Array.from({length : 6}, (_,index) => { return index }));

            const playerCards = Object.fromEntries(
                cardssAre.map((card, index) => [`Winner ${index + 1}`, card])
            );


            data.sub.map((value, key) => {

                setTotalPlayers((prevState) => {
                    const prev = {...prevState}
                    const keys = Object.keys(prev)


                    const itemfound = keys.find(item => item === value.nat)

                    if(itemfound){

                        prev[itemfound].odds  = value.b;
                        prev[itemfound].status  = value.gstatus === 'OPEN' ? '' : 'suspendedtd';
                        prev[itemfound].img = playerCards[itemfound]
                    }

                    return  prev


                })
            })

        }

        if (data.card) {

            remark.current = data.remark || 'Welcome';
        }
        // console.log(data)
    }, [data]);

    const exposure = localStorage.getItem('exposure');
    const sportLength = Object.keys(data).length;
    const updateAmount = async () => {

        const amountData = await getExBySingleTeamNameCasino(sportList.id, roundId, '', match_id)


        setTotalPlayers((prevState) => {
            const prev = {...prevState};
            const keys = Object.keys(prev);

            keys.forEach((key) => {
                if (amountData.data.some((value) => value.team_name === key)) {
                    const value = amountData.data.find((item) => item.team_name === key);
                    prev[key].amounts =value.total_amount;

                }
                else{
                    prev[key].amounts ='';
                }
            });

            return prev;
        });



    }

    useEffect(() => {

        if (data?.sub && sportList?.id) {



            updateAmount()



        }
    }, [exposure, sportLength, roundId]);




    const openPopup = (isBakOrLay, teamnam, oddvalue) => {


        if (parseFloat(oddvalue) > 0) {
            roundIdSaved.current = roundId
            setbackOrLay(isBakOrLay)
            setPopupDisplay(true);
            teamname.current = teamnam
            setOdds(oddvalue)
        } else {
            Notify("Odds Value Change Bet Not Confirm", null, null, 'danger')

        }


    }
    const casinoBetDataNew = (event, new_odds) => {
        stakeValue.current = event.target.value
        if (backOrLay === 'back') {


            loss.current = stakeValue.current;


            profit.current = profitData.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)

        } else {

            profit.current = profitData.current = stakeValue.current;


            loss.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)
        }


    }

    const renderCards = () => {
        if(!data){
            return null;
        }
        const dealer = getPlayerCardAccordingToNumberOfPlayers(data, 1, 2, Array.from({length: 7}, (_, index) => {
            return index
        }))[6]

        if(!dealer)
            return null;
        return (
            <div className="flip-card-container">


                <div className="flip-card">
                    <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img src={dealer}/>
                        </div>
                    </div>
                </div>


            </div>
        )
    }

    const placeBet = () => {
        setSubmitButtonDisable(true)

        setHideLoading(false)

        const notify = (status = 'suspended') => {
            if (status === 'suspended') {
                Notify(`Bet Not Confirmed. Reason: Game Suspended`, null, null, 'danger');


            } else {
                Notify("Bet Not Confirm Reason Game Change", null, null, 'danger')

            }
            setPopupDisplay(false);
            setHideLoading(true);
            setSubmitButtonDisable(false)

            resetBetFields(profitData, stakeValue)

        }
        if (totalPlayers[teamname.current].status !== '') {
            notify()

            return;
        }

        if (roundIdSaved.current !== roundId) {
            notify('change')
            return;
        }


        setSubmitButtonDisable(true)

        const postdata = {

            "sportId": sportList.id,
            "matchId": roundId,
            "isback": backOrLay === 'back' ? 1 : 0,
            "placeName": teamname.current,
            "placeName2": null,
            "odds": odds,
            "oddsk": 0,
            "profit": parseFloat(profit.current).toFixed(2),
            "loss": parseFloat(loss.current).toFixed(2),
            "betType": "WINNER",
            "bet_side": backOrLay.toUpperCase(),
            "betAmount": parseFloat(stakeValue.current),
            "type": match_id.toUpperCase(),
            "matchType": match_id.toLowerCase(),

        }

        axiosFetch('casino/store', 'post', null, postdata)
            .then((res) => {
                if (res.data.status === true) {
                    updateAmount()

                    resetBetFields(profitData, stakeValue)
                    Notify("Bet Placed Successfully", null, null, 'success')
                } else {

                    resetBetFields(profitData, stakeValue)
                    Notify(res.data.msg, null, null, 'danger')
                }
                setHideLoading(true)
                setSubmitButtonDisable(false)
                setPopupDisplay(false)
            })


    }

    return (
        <CasinoLayout ruleImage={ruleImage}
                      ruleDescription={ruleDescription} data={data} roundId={roundId} setRoundId={setRoundId} sportList={sportList}
                      setSportList={setSportList} setData={setData} setLastResult={setLastResult}>
            <div className="video-container" style={{minHeight: '0px'}}>
                <div className="video-overlay" style={{top: '230px'}}>
                    <div className="casino-video-cards">
                        <div>

                            {renderCards()}
                        </div>

                    </div>
                </div>
            </div>

            <div className="casino-detail">
                <div className="casino-container table-casino war">

                    <div className="table-card-container">
                        <div className="tab1 mb-2">
                            <div className="row row5">
                                <div className="col-12">
                                    <table className="table table-bordered mb-0">
                                        <tbody>
                                        {Object.entries(data).length > 0 && Object.entries(totalPlayers).map(([index,value], i)=> (
                                        <tr key={i}>
                                            <td className="box-2">
                                                <a className="nav-link table-card">&nbsp;
                                                    <img className="img-responsive" src={value.img} /></a>
                                            </td>
                                            <td className="box-4">
                                                <b>{index}</b>

                                            </td>
                                            <td className={`box-6 back text-center ${value.status}`} onClick={() => openPopup('back', index, value.odds)}>
                                                <span className="odds d-block"><b>{value.odds}</b></span>
                                                <span style={{color: "black"}}>{ getExByColor(value.amounts) }</span>
                                            </td>
                                        </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <marquee scrollamount="3" className="casino-remark m-b-10">{remark.current}</marquee>
                <div className="casino-last-result-title">
                    <span>Last Result</span>
                </div>
                <div className="casino-last-results">
                    <CasinoLastResult sportList={sportList} lastResults={lastResult} data={data}/>
                </div>
            </div>
            <BetPlacPopupSection placeBet={placeBet} profitValue={profitData.current} profit={profit} loss={loss}
                                 teamnames={teamNames} handleStakeChange={casinoBetDataNew} hideLoading={hideLoading}
                                 teamname={teamname} backOrLay={backOrLay} stakeValue={stakeValue} odds={odds}
                                 profitData={profitData}
                                 setOdds={setOdds} submitButtonDisable={submitButtonDisable}/>

        </CasinoLayout>
    );

};

const PlayerTable = ({playerName, playerValue, playerBack, openPopup, playerLay, playerStatus}) => (
    <div className="casino-table-left-box">
        <div className="casino-table-header">
            <div className="casino-nation-detail">{playerName}</div>
            <div className="casino-odds-box back">Back</div>
            <div className="casino-odds-box lay">Lay</div>
        </div>
        <div className={`casino-table-body`}>
            <div className={`casino-table-row ${playerStatus}`}>
                <div className="casino-nation-detail">
                    <div className="casino-nation-name">Main</div>
                    <p className="m-b-0">
                        <span className={`font-weight-bold ${playerValue >= 0 ? 'text-success' : 'text-danger'}`}>
                            {playerValue}
                        </span>
                    </p>
                </div>
                <div className="casino-odds-box back">
                    <span className="casino-odds"
                          onClick={() => openPopup('back', playerName, playerBack)}>{playerBack}</span>
                </div>
                <div className="casino-odds-box lay">
                    <span className="casino-odds"
                          onClick={() => openPopup('lay', playerName, playerLay)}>{playerLay}</span>
                </div>
            </div>
        </div>
    </div>
);

export default War;
