import CasinoLayout from "../Section/Casino/CasinoLayout";
import {useContext, useEffect, useRef, useState} from "react";

import {CasinoLastResult} from "../Section/Casino/CasinoLastResult";

import axiosFetch, {

    resetBetFields,

} from "../../Common_Functions/Constants";
import {useParams} from "react-router-dom";
import {SportsContext} from "../../Components/Context/SportsContext";
import {BetPlacPopupSection} from "../../Components/BetPlacPopupSection";

import Notify from "../../js/Notify";

const Worli = () => {
    const [roundId, setRoundId] = useState('')
    const defaultValues = {"status": '', "odds": ''};
    const [events, setEvents] = useState('')

    const [totalPlayers, setTotalPlayers] = useState({

        SINGLE: {
            "name": Array.from({length: 10}, (_, index) => {
                return index + " Single"
            }),
            ...defaultValues
        },

        LINE: {
            "name": ["Line 1 Single", "Line 2 Single"],
            ...defaultValues
        },
        EVEN_ODD: {
            "name": ["Odd", "Even"],
            ...defaultValues
        },


    })


    const roundIdSaved = useRef(null);

    const [submitButtonDisable, setSubmitButtonDisable] = useState(false)

    const [cards, setCards] = useState({});

    const stakeValue = useRef(0);
    const [odds, setOdds] = useState(0)

    const [backOrLay, setbackOrLay] = useState('back')
    const [sportList, setSportList] = useState({})
    const {match_id} = useParams();
    const {

        setPopupDisplay,

    } = useContext(SportsContext)
    const [hideLoading, setHideLoading] = useState(true)


    const teamNames = useRef(["Player A", "Player B"])

    const [data, setData] = useState([]);

    const remark = useRef('Welcome');
    const [lastResult, setLastResult] = useState({});
    const teamname = useRef('');
    const loss = useRef(0);
    const profit = useRef(0);
    const profitData = useRef(0);


    useEffect(() => {

        if (data?.sub) {
            setTotalPlayers((prevState) => {
                const prevPlayer ={...prevState}

                const updatedPlayers = Object.entries(prevPlayer).map(([index,value], i )=> {
                    const v = {...value};
                    v.status =  data.sub[0].gstatus === 'OPEN' ? '' : 'suspended-box'
                    return [index, v];
                })
                return Object.fromEntries(updatedPlayers);


            })

        }

        if (data.card) {
            const cardArray = data.card.split(",").map(item => item.trim());
            setCards({
                playerA: cardArray.slice(0, 3),

            });
            remark.current = data.remark || 'Welcome';
        }
    }, [data]);



    const openPopup = (isBakOrLay, teamnam, oddvalue, event) => {

        event.classList.remove('back');
        event.classList.add('bg-success');

        setEvents(event)
        if (parseFloat(oddvalue) > 0) {
            roundIdSaved.current = roundId
            setbackOrLay(isBakOrLay)
            setPopupDisplay(true);
            teamname.current = teamnam
            setOdds(oddvalue)
        } else {
            Notify("Odds Value Change Bet Not Confirm", null, null, 'danger')
        }
    }
    const casinoBetDataNew = (event, new_odds) => {
        stakeValue.current = event.target.value
        if (backOrLay === 'back') {


            loss.current = stakeValue.current;


            profit.current = profitData.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)

        } else {

            profit.current = profitData.current = stakeValue.current;


            loss.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)
        }


    }

    const renderCards = (cards, player) => (
        <div className="flip-card-container">
            {cards?.map((card, index) => {
                const imgSrc = card ? `/img/casino/cards/${card}.png` : '/img/casino/cards/1.png';
                return (
                    <div className="flip-card" key={index}>
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={imgSrc} alt={`${player} card ${index + 1}`}/>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );

    const placeBet = () => {
        setHideLoading(false)

        const notify = (status = 'suspended') => {
            if (status === 'suspended') {
                Notify(`Bet Not Confirmed. Reason: Game Suspended`, null, null, 'danger');


            } else {
                Notify("Bet Not Confirm Reason Game Change", null, null, 'danger')

            }
            setPopupDisplay(false);
            setHideLoading(true);
            setSubmitButtonDisable(false)

            resetBetFields(profitData, stakeValue)

        }


        if (totalPlayers['SINGLE'].status !== '') {
            notify()
            return;
        }


        if (roundIdSaved.current !== roundId) {
            notify('change')
            return;
        }


        setSubmitButtonDisable(true)

        const postdata = {

            "sportId": sportList.id,
            "matchId": roundId,
            "isback": backOrLay === 'back' ? 1 : 0,
            "placeName": teamname.current,
            "placeName2": null,
            "odds": odds,
            "oddsk": 0,
            "profit": parseFloat(profit.current).toFixed(2),
            "loss": parseFloat(loss.current).toFixed(2),
            "betType": "SINGLE",
            "bet_side": backOrLay.toUpperCase(),
            "betAmount": parseFloat(stakeValue.current),
            "type": match_id.toUpperCase(),
            "matchType": match_id.toLowerCase(),

        }

        axiosFetch('casino/store', 'post', null, postdata)
            .then((res) => {
                if (res.data.status === true) {
                    events.classList.remove('bg-success');
                    events.classList.add('back');

                    resetBetFields(profitData, stakeValue)
                    Notify("Bet Placed Successfully", null, null, 'success')
                } else {

                    resetBetFields(profitData, stakeValue)
                    Notify(res.data.msg, null, null, 'danger')
                }
                setHideLoading(true)
                setSubmitButtonDisable(false)
                setPopupDisplay(false)
            })
    }

    return (
        <CasinoLayout hideRules={true} data={data} roundId={roundId} setRoundId={setRoundId} sportList={sportList}
                      setSportList={setSportList} setData={setData} setLastResult={setLastResult}>
            <div className="video-container" style={{minHeight: '0px'}}>
                <div className="video-overlay" style={{top: '230px'}}>
                    <div className="casino-video-cards">
                        <div>

                            {renderCards(cards.playerA, "Player A")}
                        </div>

                    </div>
                </div>
            </div>

            <div className="table-responsive">
                <table className={`table table-bordered mb-0 worli-single-data ${totalPlayers['SINGLE'].status}`}>
                    <thead>
                    <tr>
                        <th colSpan="5" className="box-10 text-center">9.5</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        {totalPlayers['SINGLE'].name.slice(1, 6).map((value, index) => (
                            <td key={index} className="text-center back" onClick={event => openPopup('back', value, 9.5, event.currentTarget)} ><span
                                className="casino-font">{index + 1}</span></td>
                        ))}
                    </tr>
                    <tr>
                        {totalPlayers['SINGLE'].name.slice(6, 11).map((value, index) => (
                            <td key={index} className="back text-center" onClick={event => openPopup('back', value, 9.5, event.currentTarget)}><span
                                className="casino-font">{index + 6}</span></td>
                        ))}
                        {/* You can directly use the first element if necessary */}
                        <td className="back text-center" onClick={event => openPopup('back', "0 Single", 9.5, event.currentTarget)}><span
                            className="casino-font">0</span></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className="table-responsive mt-2 mb-1">
                <table className={`table table-bordered mb-0 worli-single-data ${totalPlayers['SINGLE'].status}`}>
                    <thead>
                    <tr>
                        <th colSpan="4" className="box-10 text-center">9.5</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className="back text-center line1">
                            <span className="odds"><b>Line 1</b></span>
                            <p className="mb-0">1|2|3|4|5</p>
                        </td>
                        <td className="back text-center oddclick">
                            <span className="odds"><b>Odd</b></span>
                            <p className="mb-0">1|3|5|7|9</p>
                        </td>
                        <td className="back text-center evenClick">
                            <span className="odds"><b>Even</b></span>
                            <p className="mb-0">2|4|6|8|0</p> {/* Even */}
                        </td>
                        <td className="back text-center line2">
                            <span className="odds"><b>Line 2</b></span>
                            <p className="mb-0">6|7|8|9|0</p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <marquee scrollamount="3" className="casino-remark m-b-10">{remark.current}</marquee>
            <div className="casino-last-result-title">
                <span>Last Result</span>
            </div>
            <div className="casino-last-results">
                <CasinoLastResult sportList={sportList} lastResults={lastResult} data={data}/>
            </div>

            <BetPlacPopupSection placeBet={placeBet} profitValue={profitData.current} profit={profit} loss={loss}
                                 teamnames={teamNames} handleStakeChange={casinoBetDataNew} hideLoading={hideLoading}
                                 teamname={teamname} backOrLay={backOrLay} stakeValue={stakeValue} odds={odds}
                                 profitData={profitData}
                                 setOdds={setOdds} submitButtonDisable={submitButtonDisable} />

        </CasinoLayout>
    );

};

export default Worli;
