import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const cardImages = [
    "https://dxc247.com/all/img/casino/cards/7DD.png",
    "https://dxc247.com/all/img/casino/cards/QCC.png",
    "https://dxc247.com/all/img/casino/cards/10SS.png",
    "https://dxc247.com/all/img/casino/cards/ACC.png",
    "https://dxc247.com/all/img/casino/cards/6SS.png",
    "https://dxc247.com/all/img/casino/cards/QSS.png",
    "https://dxc247.com/all/img/casino/cards/2DD.png",
    "https://dxc247.com/all/img/casino/cards/8CC.png",
    "https://dxc247.com/all/img/casino/cards/6HH.png",
    "https://dxc247.com/all/img/casino/cards/KCC.png",
    "https://dxc247.com/all/img/casino/cards/6CC.png",
    "https://dxc247.com/all/img/casino/cards/JHH.png",
    "https://dxc247.com/all/img/casino/cards/8SS.png"
];

export const Ab3 = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    };

    return (
        <div className="slick-cards-results">
            <Slider {...settings}>
                df
                {cardImages.map((image, index) => (
                    <div key={index} style={{ outline: "none", width: "63px" }} data-index={index}>
                        <img style={{ width: "100%", display: "inline-block" }} src={image} alt={`Card ${index}`} />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

