export const Baccarat = ({lastResults, openPopup}) => {
    return (
        <>
            {Object.entries(lastResults).map(([key, result]) => {
                const mid = result.mid;
                const className = result.win === '1'
                    ? 'ball-runs last-result m-l-5 playera'
                    : result.win === '2'
                        ? 'ball-runs last-result m-l-5 playerb'
                        : 'ball-runs last-result m-l-5 playerc';

                const backgroundColor = result.win === '1'
                    ? '#086cb8'
                    : result.win === '2'
                        ? '#ae2130'
                        : '#279532';

                return (
                    <span
                        onClick={() => openPopup(mid)}
                        key={mid}
                        className={className}
                        style={{ color: '#fff', backgroundColor: backgroundColor }}
                        title={`Round ID: ${mid}`}
                    >
        {result.win === '1' ? 'P' : result.win === '2' ? 'B' : 'T'}
    </span>
                );
            })}
        </>
    );
}
