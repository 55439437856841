import CasinoLayout from "../Section/Casino/CasinoLayout";
import {useContext, useEffect, useRef, useState} from "react";

import {CasinoLastResult} from "../Section/Casino/CasinoLastResult";

import axiosFetch, {

    getExByTeamNameForCasino, resetBetFields,

} from "../../Common_Functions/Constants";
import {useParams} from "react-router-dom";
import {SportsContext} from "../../Components/Context/SportsContext";


import Notify from "../../js/Notify";

const Cmatch20 = () => {
    const [roundId, setRoundId] = useState('')


    const rImage = '/img/rules/cmatch20-rules.jpg'
    const defaultValues = {odds: {back: 0, lay: 0}, status: '', amounts: ""};


    const [totalBalls, setTotalBalls] = useState(Object.fromEntries(Array.from({length: 9}, (_, index) => {

        const runis = "Run " + (index + 2)
        return [runis, defaultValues]

    })))


    const [scores, setScores] = useState([])


    const roundIdSaved = useRef(null);

    const [submitButtonDisable, setSubmitButtonDisable] = useState(false)

    const [cards, setCards] = useState({});

    const stakeValue = useRef(0);
    const [odds, setOdds] = useState(0)

    const [backOrLay, setbackOrLay] = useState('back')
    const [sportList, setSportList] = useState({})
    const {match_id} = useParams();
    const {

        setPopupDisplayForDesktop,

    } = useContext(SportsContext)
    const [hideLoading, setHideLoading] = useState(true)


    const teamNames = useRef(["Player A", "Player B"])

    const [data, setData] = useState([]);

    const remark = useRef('Welcome');
    const [lastResult, setLastResult] = useState({});
    const teamname = useRef('');
    const loss = useRef(0);
    const profit = useRef(0);
    const profitData = useRef(0);


    const updateScoreAndBalls = () => {

        setScores((prevState) => {
            // Assuming d_data['score'] equivalent is passed as `scoreString` in JavaScript
            const scores = data.score.split(",");
            const [C2, C3, C4, C5, C6, C7] = scores.slice(1, 7);

            // Assuming you want to update these values in your previous state
            const updateScores = {...prevState, C2, C3, C4, C5, C6, C7};

            return updateScores;

        })


        setTotalBalls((prevState) => {

            const updatedBalls = JSON.parse(JSON.stringify(prevState));

            Object.entries(updatedBalls).forEach(([key, value]) => {

                const datafound = data.sub.find(item => item.nat === key)

                if (datafound) {

                    updatedBalls[key].odds.back = datafound.b;
                    updatedBalls[key].odds.lay = datafound.l;
                  updatedBalls[key].status = datafound.gstatus === 'OPEN' ? "" : 'suspended-box';

                }

            })

            return updatedBalls

        })


    }


    useEffect(() => {


        if (data?.sub) {

            updateScoreAndBalls()
        }

        if (data.card) {
            const cardArray = data.card.split(",").map(item => item.trim());
            setCards({
                playerA: cardArray.slice(0, 3),

            });
            remark.current = data.remark || 'Welcome';
        }
    }, [data]);

    const exposure = localStorage.getItem('exposure');
    const sportLength = Object.keys(data).length;


    useEffect(() => {

        if (data?.sub && sportList?.id) {

        }
    }, [exposure, sportLength, roundId]);


    const openPopup = (isBakOrLay, teamnam, oddvalue) => {


        if (parseFloat(oddvalue) > 0) {
            roundIdSaved.current = roundId
            setbackOrLay(isBakOrLay)
            setPopupDisplayForDesktop(true);
            teamname.current = teamnam
            setOdds(oddvalue)
        } else {
            Notify("Odds Value Change Bet Not Confirm", null, null, 'danger')

        }


    }
    const casinoBetDataNew = (new_odds) => {
        stakeValue.current = new_odds
        if (backOrLay === 'back') {


            loss.current = stakeValue.current;


            profit.current = profitData.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)

        } else {

            profit.current = profitData.current = stakeValue.current;


            loss.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)
        }


    }

    const renderCards = (cards, player) => (
        <div className="flip-card-container">
            {cards?.map((card, index) => {
                const imgSrc = card ? `/img/casino/cards/${card}.png` : '/img/casino/cards/1.png';
                return (
                    <div className="flip-card" key={index}>
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={imgSrc} alt={`${player} card ${index + 1}`}/>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );

    const placeBet = () => {

        setHideLoading(false)
        if (totalBalls['Run 2'].status !== '') {
            Notify(`Bet Not Confirmed. Reason: Game Suspended`, null, null, 'danger');
            setPopupDisplayForDesktop(false)
            resetBetFields(profitData, stakeValue)
            setHideLoading(true);

            return;
        }

        if (roundIdSaved.current !== roundId) {
            Notify("Bet Not Confirm Reason Game Change", null, null, 'danger')
            setPopupDisplayForDesktop(false)
            resetBetFields(profitData, stakeValue)
            setHideLoading(true);
            return;
        }


        setSubmitButtonDisable(true)

        const postdata = {

            "sportId": sportList.id,
            "matchId": roundId,
            "isback": backOrLay === 'back' ? 1 : 0,
            "placeName": teamname.current,
            "placeName2": null,
            "odds": odds,
            "oddsk": 0,
            "profit": parseFloat(profit.current).toFixed(2),
            "loss": parseFloat(loss.current).toFixed(2),
            "betType": "ODDS",
            "bet_side": backOrLay.toUpperCase(),
            "betAmount": parseFloat(stakeValue.current),
            "type": match_id.toUpperCase(),
            "matchType": match_id.toLowerCase(),

        }

        axiosFetch('casino/store', 'post', null, postdata)
            .then((res) => {
                if (res.data.status === true) {

                    resetBetFields(profitData, stakeValue)
                    Notify("Bet Placed Successfully", null, null, 'success')
                } else {

                    resetBetFields(profitData, stakeValue)
                    Notify(res.data.msg, null, null, 'danger')
                }
                setHideLoading(true)
                setSubmitButtonDisable(false)
                setPopupDisplayForDesktop(false)
            })


    }

    return (

        <CasinoLayout raceClass="teen20-container cc-20" hideLoading={hideLoading} isBack={backOrLay} teamname={teamname} handleStakeChange={casinoBetDataNew} odds={odds}
                      stakeValue={stakeValue} setOdds={setOdds} placeBet={placeBet}
                      submitButtonDisable={submitButtonDisable} data={data} roundId={roundId} setRoundId={setRoundId}
                      sportList={sportList}
                      setSportList={setSportList} setData={setData} setLastResult={setLastResult}>


            <div className="video-container" style={{minHeight: '0px'}}>
                <div className="video-overlay" style={{top: '-300px'}}>
                    <div className="casino-video-cards">
                        <div>

                            {renderCards(cards.playerA, "Player A")}
                        </div>

                    </div>
                </div>
            </div>

            <div className="casino-detail">

                <div className="row row5">
                    {Array.from({length: 9}, (_, index) => (
                        <div className="col-6" key={index}>
                            <div className={`score-box btn-theme ${totalBalls['Run ' + (index + 2)].status}`}>
                                <img src={`/img/balls/ball${index + 2}.png`}
                                     className="img-fluid ball-image" alt="ball"/>
                                <img src="/img/balls/score-bat-icon.png" className="score-img"
                                     alt="score bat icon"/>
                                <div className="team-score">
                                    <div>
                                        <span>Team A</span> <span
                                        className="ml-1">{scores?.['C2']}/{scores?.['C3']}</span> <span
                                        className="ml-1">{scores?.['C4']} Over</span>
                                    </div>
                                    <div>
                                        <span>Team B</span> <span
                                        className="ml-1">{scores?.['C5']}/{scores?.['C6']}</span> <span
                                        className="ml-1"> {scores?.['C7']} Over</span>
                                    </div>
                                </div>

                                <div className="back backbox">
                                    <span className="odds d-block"
                                          onClick={() => openPopup('back', 'Run ' + (index + 2), totalBalls['Run ' + (index + 2)].odds.back)}>{totalBalls['Run ' + (index + 2)].odds.back}</span>
                                </div>
                                <div className="lay laybox">
                                    <span className="odds d-block"
                                          onClick={() => openPopup('lay', 'Run ' + (index + 2), totalBalls['Run ' + (index + 2)].odds.lay)}>{totalBalls['Run ' + (index + 2)].odds.lay}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>


            <marquee scrollamount="3" className="casino-remark m-b-10">{remark.current}</marquee>
                <div className="casino-last-result-title">
                    <span>Last Result</span>
                </div>
                <div className="casino-last-results">
                    <CasinoLastResult sportList={sportList} lastResults={lastResult} data={data}/>
                </div>
            </div>


        </CasinoLayout>
    );

};


export default Cmatch20;
