import React, {useContext, useEffect, useState} from 'react';
import Header from "../../Section/Header";
import {useParams} from "react-router-dom";
import axiosFetch from "../../../Common_Functions/Constants";
import {Buffer} from 'buffer';
import CasinoRules from "../../../Components/Casino/CasinoRules";
import CasinoReconnectModalPopup from "../../../Components/Casino/CasinoReconnectModalPopup";
import {CasinoContext} from "../../../Components/Context/CasinoContext";
import SidebarLayout from "../SidebarLayout";
import DesktopCasinoVideo from "../../../Components/Casino/DesktopCasinoVideo";
import RightSideBarCasino from "../RightSideBarCasino";
import Footer from "../Footer";

const CasinoLayout = ({
                          teamname,

                          hideLoading,
                          isBack,
                          handleStakeChange, odds, stakeValue,
                          setOdds,
                          submitButtonDisable = null,
                          placeBet = null,
                          setSubmitButton = null,
                          virtualVideoCards = false,
                          setData = null,
                          data = {},

                          setLastResult = null,
                          sportList = {},
                          setRoundId = null,
                          roundId = 0,
                          setSportList = null,
                          children,
                          ruleImage = null,
                          ruleDescription = null,
                          hideRules = false,
                          raceClass = 'teenpatti1day'
                      }) => {


        const {casino_socket} = useContext(CasinoContext)
        const [socketDisconnectModal, setSocketDisconnectModal] = useState(false)
        const [showCasinoReconnectModal, setShowCasinoReconnectModal] = useState(false)

        const {match_id} = useParams()

        const length = Object.keys(sportList).length


        const [mybetModel, setMybetModel] = useState([])
        const [showRules, setShowRules] = useState(false);

        useEffect(() => {
            axiosFetch('matched-bet-details/' + sportList.id, 'get', setMybetModel)
            // eslint-disable-next-line
        }, [length, localStorage.getItem('exposure')]);


        const handleShowRules = () => setShowRules(true);
        const handleCloseRules = () => setShowRules(false);


        useEffect(() => {
            const getData = async () => {
                await axiosFetch(`casino_game/${match_id}`, 'get')
                    .then((res) => setSportList(res.data))
            }
            if (setSportList !== null) {
                getData()
            }
        }, [length]);


        useEffect(() => {

            let socket_game = `getUserData${match_id}`;
            casino_socket.emit('setPurposeFor', 'casinos', match_id, '')

            const gameConnect = () => {


                casino_socket.on(socket_game, sportData => {

                    if (sportData !== null) {

                        let fetchedData = JSON.parse(Buffer.from(sportData).toString('utf8'))
                        const parsedData = fetchedData[0]; // Assuming this is the data you want to work with.
                        const processedData = {

                            data: {},
                            lastResults: {},
                        };

                        if (parsedData && Object.keys(parsedData).length > 0) {

                            if (parsedData.hasOwnProperty('t1')) {

                                processedData.data.t1 = parsedData.t1;
                            }
                            if (parsedData.hasOwnProperty('t2')) {
                                processedData.data.t2 = parsedData.t2;
                            } else if (parsedData && parsedData.hasOwnProperty('sub')) {
                                processedData.data = parsedData; // Copy the entire data if 'sub' exists.
                            }

                        }
                        if (fetchedData[1] && fetchedData[1].hasOwnProperty('res')) {

                            processedData.lastResults = fetchedData[1].res.res || fetchedData[1].res;

                        }
                        if (Object.keys(processedData.data).length > 0 && setRoundId !== null) {

                            setRoundId(processedData.data?.mid || processedData?.data?.t1?.gmid)


                        }
                        if (setData !== null) {
                            setData(processedData.data)
                        }


                        if (setLastResult !== null) {
                            setLastResult(processedData.lastResults)
                        }

                    }
                })

            }


            if (Object.keys(sportList).length > 0 && setData !== null) {


                gameConnect();
            }

            if (socketDisconnectModal === true) {

                casino_socket.disconnect();
            }


            casino_socket.on('disconnect', function () {

                // Reconnect logic
                const connectInterval = setInterval(() => {
                    gameConnect();
                    clearInterval(connectInterval)
                }, 1000);  // Reattempt connect every second
            });

            if (length > 0) {
                return () => {

                    console.log('disconnecting ')

                    casino_socket.off(socket_game)
                }
            }

            // eslint-disable-next-line
        }, [length, match_id, showCasinoReconnectModal])


        return (
            <>
                <Header/>

                <div className="main">
                    <div className="container-fluid container-fluid-5">
                        <div className="row row5">
                            <SidebarLayout/>
                            <div className="col-md-10 featured-box white-bg">
                                <div className="row row5 andarbahar -container andarbahar ">
                                    <div className="col-md-9 casino-container coupon-card featured-box-detail ab-2">
                                        <div className={`coupon-card ${raceClass}`}>
                                            <div className="game-heading d-flex justify-content-between">
                                                <span className="card-header-title">{sportList?.match_name}
                                                    {!hideRules && (

                                                        <small
                                                            role="button" className="teenpatti-rules"
                                                            onClick={handleShowRules} tabIndex="0"><u>Rules</u></small>
                                                    )}

                                                </span>
                                                <span className="float-right">Round ID:<span
                                                    className="roundID">{roundId}</span></span>
                                            </div>

                                            <div className={`${virtualVideoCards === false ? 'video-container' : 'video-box-container'}`}>
                                                <DesktopCasinoVideo virtualVideoCards={virtualVideoCards}
                                                                    showCasinoReconnectModal={showCasinoReconnectModal}
                                                                    gamename={match_id}
                                                                    data={data}/>
                                            </div>
                                            {children}

                                        </div>
                                    </div>
                                    <RightSideBarCasino setSubmitButton={setSubmitButton} sportList={sportList} myBetModel={mybetModel}
                                                        hideLoading={hideLoading} isBack={isBack}
                                                        handleStakeChange={handleStakeChange}
                                                        stakeValue={stakeValue?.current} odds={odds} teamname={teamname}
                                                        setOdds={setOdds} submitButtonDisable={submitButtonDisable}
                                                        placeBet={placeBet} gamename={sportList?.match_name}/>
                                </div>
                            </div>

                            {/* Rules Modal */}
                            <CasinoRules image={ruleImage} description={ruleDescription} showRules={showRules}
                                         handleCloseRules={handleCloseRules}/>
                            <CasinoReconnectModalPopup setSocketDisconnectModal={setSocketDisconnectModal}
                                                       setShowCasinoReconnectModal={setShowCasinoReconnectModal}
                                                       show={showCasinoReconnectModal}/>

                        </div>
                    </div>
                    <Footer/>
                </div>

            </>
        )
    }
;

export default CasinoLayout;
