import CasinoLayout from "../Section/Casino/CasinoLayout";
import {useContext, useEffect, useRef, useState} from "react";

import {CasinoLastResult} from "../Section/Casino/CasinoLastResult";

import axiosFetch, {
    cardPointsCount,
    getExByColor,
    getExByTeamNameForCasino, resetBetFields
} from "../../Common_Functions/Constants";
import {useParams} from "react-router-dom";
import {SportsContext} from "../../Components/Context/SportsContext";


import Notify from "../../js/Notify";

const Race20 = () => {
    const [roundId, setRoundId] = useState('')
    const [cardsPoint, setCardsPoint] = useState(0)

    const ruleImage = '/img/rules/race20.jpg'
    const defaultStatusAmount = {status: "suspended-box", amounts: ""};
    const defaultValuesWithBackAndLay = {odds: {back: 0, lay: 0, bs: 10000, ls: 10000}, ...defaultStatusAmount}
    const defaultValuesWithBack = {odds: {back: 0}, ...defaultStatusAmount}

    const [totalPlayers, setTotalPlayers] = useState({
        'K of spade': {...defaultValuesWithBackAndLay, "img": process.env.REACT_APP_CARD_PATH + "KSS.png"},
        'K of heart': {...defaultValuesWithBackAndLay, "img": process.env.REACT_APP_CARD_PATH + "KHH.png"},
        'K of club': {...defaultValuesWithBackAndLay, "img": process.env.REACT_APP_CARD_PATH + "KCC.png"},
        'K of diamond': {...defaultValuesWithBackAndLay, "img": process.env.REACT_APP_CARD_PATH + "KDD.png"},
        'Total points': defaultValuesWithBackAndLay,
        'Total cards': defaultValuesWithBackAndLay,
        'Win with 5': defaultValuesWithBack,
        'Win with 6': defaultValuesWithBack,
        'Win with 7': defaultValuesWithBack,
        'Win with 15': defaultValuesWithBack,
        'Win with 16': defaultValuesWithBack,
        'Win with 17': defaultValuesWithBack,

    })


    const roundIdSaved = useRef(null);

    const [submitButtonDisable, setSubmitButtonDisable] = useState(false)

    const [cards, setCards] = useState({});

    const [cardLength, setCardLength] = useState(0)
    const stakeValue = useRef(0);
    const [odds, setOdds] = useState(0)

    const [backOrLay, setbackOrLay] = useState('back')
    const [sportList, setSportList] = useState({})
    const {match_id} = useParams();
    const {
        setBetType,
        setPopupDisplayForDesktop,

    } = useContext(SportsContext)
    const [hideLoading, setHideLoading] = useState(true)
    const chunkArray = (arr, size) => {
        const chunks = [];
        for (let i = 0; i < arr.length; i += size) {
            chunks.push(arr.slice(i, i + size)); // Take a slice of the array of the given size
        }
        return chunks;
    }


    const winChunk = chunkArray(Object.entries(totalPlayers).slice(6, 12), 2)


    const teamNames = useRef(["Player A", "Player B"])

    const [data, setData] = useState([]);

    const remark = useRef('Welcome');
    const [lastResult, setLastResult] = useState({});
    const teamname = useRef('');
    const loss = useRef(0);
    const profit = useRef(0);
    const profitData = useRef(0);


    const updateAmounts = async () => {


        const promises = Object.entries(totalPlayers).slice(0, 4).map(([index, value]) => {
            return getExByTeamNameForCasino(sportList.id, roundId, index, match_id, 'ODDS')
        })
        const all_promises = await Promise.all(promises)

        setTotalPlayers((prevState) => {

            const updatedPlayers = JSON.parse(JSON.stringify(prevState))

            Object.entries(updatedPlayers).slice(0, 4).forEach(([index, value], i) => {

                updatedPlayers[index].amounts = all_promises[i].data || ''

            })


            return updatedPlayers

        })
    }


    const updatePlayers = () => {

        setTotalPlayers((prevState) => {

            const updatedPlayers = JSON.parse(JSON.stringify(prevState))


            Object.entries(updatedPlayers).forEach(([index, value]) => {

                const fdata = data.sub.find(item => item.nat === index)


                if (fdata) {

                    updatedPlayers[index].odds.back = fdata.b
                    updatedPlayers[index].odds.lay = fdata.l
                    updatedPlayers[index].status = fdata.gstatus === 'OPEN' ? "" : 'suspended-box'
                    updatedPlayers[index].odds.bs = ['Total points', 'Total cards'].includes(index) ? 100 : 10000
                    updatedPlayers[index].odds.ls = ['Total points', 'Total cards'].includes(index) ? 100 : 10000

                }


            })

            return updatedPlayers

        })


    }


    useEffect(() => {


        if (data?.sub) {

            updatePlayers();
        }

        if (data.card) {
            const cardArray = data.card.split(",");


            setCards({
                spade: (function () {
                    const spadeArray = cardArray.filter(item => item.includes("SS"));
                    return spadeArray.length > 0 ? [...spadeArray, "KSS"] : spadeArray;
                })(),
                heart: (function () {
                    const spadeArray = cardArray.filter(item => item.includes("HH"));
                    return spadeArray.length > 0 ? [...spadeArray, "KHH"] : spadeArray;
                })(),
                club: (function () {
                    const spadeArray = cardArray.filter(item => item.includes("CC"));
                    return spadeArray.length > 0 ? [...spadeArray, "KCC"] : spadeArray;
                })(),
                diamond: (function () {
                    const spadeArray = cardArray.filter(item => item.includes("DD"));
                    return spadeArray.length > 0 ? [...spadeArray, "KDD"] : spadeArray;
                })()
            });

            remark.current = data.remark || 'Welcome';
        }
    }, [data]);

    useEffect(() => {


        setCardLength(
            Math.max(
                parseInt(cards?.spade?.length || 0, 10) - 1,
                0
            ) +
            Math.max(
                parseInt(cards?.club?.length || 0, 10) - 1,
                0
            ) +
            Math.max(
                parseInt(cards?.heart?.length || 0, 10) - 1,
                0
            ) +
            Math.max(
                parseInt(cards?.diamond?.length || 0, 10) - 1,
                0
            )
        );


        setCardsPoint(cardPointsCount("K", cards?.spade) + cardPointsCount("K", cards?.club) + cardPointsCount("K", cards?.heart) + cardPointsCount("K", cards?.diamond))


    }, [cards]);

    const exposure = localStorage.getItem('exposure');
    const sportLength = Object.keys(data).length;


    useEffect(() => {

        if (data?.sub && sportList?.id) {
            updateAmounts()
        }
    }, [exposure, sportLength, roundId]);


    const openPopup = (isBakOrLay, teamnam, oddvalue) => {
        if (parseFloat(oddvalue) > 0) {
            roundIdSaved.current = roundId
            setbackOrLay(isBakOrLay)
            setPopupDisplayForDesktop(true);
            teamname.current = teamnam
            setOdds(oddvalue)
        } else {
            Notify("Odds Value Change Bet Not Confirm", null, null, 'danger')
        }
    }
    const casinoBetDataNew = (new_odds) => {
        stakeValue.current = new_odds
        if (backOrLay === 'back') {


            loss.current = stakeValue.current;


            profit.current = profitData.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)

        } else {

            profit.current = profitData.current = stakeValue.current;


            loss.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)
        }


    }

    const renderCards = (cards, player) => (
        <div className="mb-1" key={player}>
            {cards && cards.length > 0  && (
                <span><img src={process.env.REACT_APP_CARD_PATH + player + ".png"}/></span>
            )}
            {cards && Array.isArray(cards) && cards.length > 0 && cards.map((value, index) => (
                <span className="result-image" key={index}><img src={process.env.REACT_APP_CARD_PATH + value + ".png"}/></span>
            ))}
        </div>
    );

    const placeBet = () => {

        setHideLoading(false)
        if (totalPlayers[teamname.current].status !== '') {
            Notify(`Bet Not Confirmed. Reason: Game Suspended`, null, null, 'danger');
            setPopupDisplayForDesktop(false)
            setHideLoading(true);

            resetBetFields(profitData, stakeValue)

            return;
        }

        if (roundIdSaved.current !== roundId) {
            Notify("Bet Not Confirm Reason Game Change", null, null, 'danger')
            setPopupDisplayForDesktop(false)
            setHideLoading(true);

            resetBetFields(profitData, stakeValue)

            return;
        }


        setSubmitButtonDisable(true)

        const postdata = {

            "sportId": sportList.id,
            "matchId": roundId,
            "isback": backOrLay === 'back' ? 1 : 0,
            "placeName": teamname.current,
            "placeName2": null,
            "odds": odds,
            "oddsk": 0,
            "profit": parseFloat(profit.current).toFixed(2),
            "loss": parseFloat(loss.current).toFixed(2),
            "betType": "ODDS",
            "bet_side": backOrLay.toUpperCase(),
            "betAmount": parseFloat(stakeValue.current),
            "type": match_id.toUpperCase(),
            "matchType": match_id.toLowerCase(),

        }

        axiosFetch('casino/store', 'post', null, postdata)
            .then((res) => {
                if (res.data.status === true) {
                    updateAmounts()

                    resetBetFields(profitData, stakeValue)
                    Notify("Bet Placed Successfully", null, null, 'success')
                } else {

                    resetBetFields(profitData, stakeValue)
                    Notify(res.data.msg, null, null, 'danger')
                }
                setHideLoading(true)
                setSubmitButtonDisable(false)
                setPopupDisplayForDesktop(false)
            })


    }

    return (
        <CasinoLayout ruleImage={ruleImage} raceClass="new-casino race" hideLoading={hideLoading} isBack={backOrLay} teamname={teamname} handleStakeChange={casinoBetDataNew} odds={odds}
                      stakeValue={stakeValue} setOdds={setOdds} placeBet={placeBet}
                      submitButtonDisable={submitButtonDisable} data={data} roundId={roundId} setRoundId={setRoundId}
                      sportList={sportList}
                      setSportList={setSportList} setData={setData} setLastResult={setLastResult}>


            <div className="video-container" style={{minHeight: '0px'}}>
                <div className="video-overlay" style={{top: '-300px'}}>
                    <div className="video-box-container">
                        {cardLength > 0 && (
                            <div className="race-total-point" style={{marginLeft : "-128px"}}>
                                <div className="text-white">
                                    <div>Cards</div>
                                    <div>{cardLength}</div>
                                </div>
                                <div className="text-white">
                                    <div>Points</div>
                                    <div>{cardsPoint}</div>
                                </div>
                            </div>
                        )}
                    </div>
                    {['spade', 'heart', 'club', 'diamond'].map((value, index) => (
                        renderCards(cards[value], value)
                    ))}
                </div>
            </div>

            <div className="casino-detail">
                <div className="card-content m-t-10">
                    <div className="casino-odds-box-wrapper">
                        {Object.entries(totalPlayers).slice(0, 4).map(([index, value], i) => (
                            <div className="casino-odds-box-container casino-odds-box-container-double" key={i}>
                                <div className="casino-odds-box-bhav">
                                    <img src={value.img} style={{width: '35px'}} alt={index}/>

                                </div>
                                <div className="casino-odds-box null">
                                    <div className={`back-border ${value.status}`}
                                         onClick={() => openPopup('back', index, value.odds.back)}>
                                        <span className="casino-odds-box-odd">{value.odds.back}</span>
                                        <span>{value.odds.bs}</span>
                                    </div>
                                    <div className={`lay-border ${value.status}`}
                                         onClick={() => openPopup('lay', index, value.odds.lay)}>
                                        <span className="casino-odds-box-odd">{value.odds.lay}</span>
                                        <span>{value.odds.ls}</span>
                                    </div>
                                </div>
                                <div className="casino-odds-book" style={{color: 'black'}}>
                                    {getExByColor(value.amounts)}
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
                <div className="row row5 mt-2">
                    <div className="col-12">
                        <div className="card-content">
                            {Object.entries(totalPlayers).slice(4, 6).map(([index, value], i) => (
                                <div className="casino-odds-box-container casino-odds-box-container-extra" key={i}>
                                    <div className="casino-yn">
                                        <div></div>
                                        <div className="casino-odds-box-bhav"><b>No</b></div>
                                        <div className="casino-odds-box-bhav"><b>Yes</b></div>
                                    </div>
                                    <div className="casino-odds-box casino-yn">
                                        <div className="casino-odds-box-bhav">
                                            <b>{index}</b>

                                        </div>
                                        <div className={`lay-border ${value.status}`}><span
                                            className="casino-odds-box-odd">{value.odds.back}</span>
                                            <span>{value.odds.bs}</span></div>
                                        <div className={`back-border ${value.status}`}><span
                                            className="casino-odds-box-odd">{value.odds.lay}</span>
                                            <span>{value.odds.ls}</span></div>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                    <div className="col-12">
                        <div className="card-content">
                            <div className="row row5">
                                {winChunk.map((value, index) => {


                                    return (
                                        <div className="col-4" key={index}>
                                            {value.map((value1, index1) => {

                                                return (
                                                    <div
                                                        className="casino-odds-box-container casino-odds-box-container-extra"
                                                        key={index1}>
                                                        <div className="casino-odds-box-bhav">
                                                            <b>{value1[0]}</b>

                                                        </div>
                                                        <div
                                                            className={`casino-odds-box back-border ${value1[1].status}`}><span
                                                            className="casino-odds-box-odd">{value1[1].odds.back}</span>
                                                        </div>
                                                    </div>
                                                )
                                            })}

                                        </div>
                                    )
                                })}


                            </div>
                        </div>
                    </div>
                </div>


                <marquee scrollamount="3" className="casino-remark m-b-10">{remark.current}</marquee>
                <div className="casino-last-result-title">
                    <span>Last Result</span>
                </div>
                <div className="last-result-container text-right mt-1">
                    <CasinoLastResult sportList={sportList} lastResults={lastResult} data={data}/>
                </div>
            </div>
            

        </CasinoLayout>
    );

};

const PlayerTable = ({playerName, playerValue, playerBack, openPopup, playerLay, playerStatus}) => (
    <div className="casino-table-left-box">
        <div className="casino-table-header">
            <div className="casino-nation-detail">{playerName}</div>
            <div className="casino-odds-box back">Back</div>
            <div className="casino-odds-box lay">Lay</div>
        </div>
        <div className={`casino-table-body`}>
            <div className={`casino-table-row ${playerStatus}`}>
                <div className="casino-nation-detail">
                    <div className="casino-nation-name">Main</div>
                    <p className="m-b-0">
                        <span className={`font-weight-bold ${playerValue >= 0 ? 'text-success' : 'text-danger'}`}>
                            {playerValue}
                        </span>
                    </p>
                </div>
                <div className="casino-odds-box back">
                    <span className="casino-odds"
                          onClick={() => openPopup('back', playerName, playerBack)}>{playerBack}</span>
                </div>
                <div className="casino-odds-box lay">
                    <span className="casino-odds"
                          onClick={() => openPopup('lay', playerName, playerLay)}>{playerLay}</span>
                </div>
            </div>
        </div>
    </div>
);

export default Race20;

