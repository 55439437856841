import CasinoLayout from "../Section/Casino/CasinoLayout";
import {useContext, useEffect, useRef, useState} from "react";

import {CasinoLastResult} from "../Section/Casino/CasinoLastResult";

import axiosFetch, {
    getExByColor, getExBySingleTeamNameCasino,
 resetBetFields
} from "../../Common_Functions/Constants";
import {useParams} from "react-router-dom";
import {SportsContext} from "../../Components/Context/SportsContext";


import Notify from "../../js/Notify";

const Teen120 = () => {
    const [roundId, setRoundId] = useState('')
    const defaultValues  ={odds : '', amounts :"", status : ''}

    const [totalPlayers, setTotalPlayers] = useState({

        "Player" : defaultValues,

        "Tie" : defaultValues,
        "Dealer" : defaultValues,
        "Pair" : defaultValues,



    })

    const ruleDescription = `                            <div class="rules-section">
                                <ul class="pl-4 pr-4 list-style">
                                    <li>1 CARD 20-20 is a very easy and fast paced game.</li>
                                    <li>This game is played with 8 decks of regular 52 cards between the player and
                                        dealer.
                                    </li>
                                    <li>Both, the player and dealer will be dealt one card each.</li>
                                    <li>The objective of the game is to guess whether the player or dealer will draw a
                                        card of the higher value and will therefore win.
                                    </li>
                                    <li>You can place your bets on the player as well as dealer.</li>
                                    <li><b>Ranking of cards :</b> from lowest to highest</li>
                                    <li>2 , 3 , 4 , 5 , 6 , 7 , 8 , 9 , 10 , J , Q , K , A</li>
                                    <li>If the player and dealer both have the same hands with the same ranking cards
                                        but of different suits then the winner will be decided according to the order of
                                        the suits
                                    </li>
                                    <li><b>Order of suits :</b> from highest to lowest</li>
                                    <li>Spades , Hearts , Clubs , Diamonds</li>
                                    <li>eg Clubs ACE Diamonds ACE</li>
                                    <li>Here ACE of Clubs wins.</li>
                                    <li>If both,the player and dealer hands have the same ranking cards which are of the
                                        same suit, then it will be a TIE.
                                    </li>
                                    <li>eg Spades ACE Spades ACE</li>
                                    <li>In case of a TIE ,bets placed on both the player and dealer will lose the bet
                                        amount.
                                    </li>
                                </ul>
                            </div>
                             <div>
                            <div class="rules-section">
                                <h6 class="rules-highlight">Side Bets</h6>
                                <ul class="pl-4 pr-4 list-style">
                                    <li><b>Pair :</b> Here you can bet that both, the player and dealer will have the
                                        same ranking cards irrespective of suits
                                    </li>
                                    <li><b>Tie :</b> Here you can bet that the game will be a Tie.</li>
                                    <li><b>Note :</b> In case of a Tie between the player and dealer, bets placed on
                                        Side bets will be considered valid.
                                    </li>
                                </ul>
                            </div>
                        </div>
`

    const roundIdSaved = useRef(null);

    const [submitButtonDisable, setSubmitButtonDisable] = useState(false)

    const [cards, setCards] = useState({});

    const stakeValue = useRef(0);
    const [odds, setOdds] = useState(0)

    const [backOrLay, setbackOrLay] = useState('back')
    const [sportList, setSportList] = useState({})
    const {match_id} = useParams();
    const {
        betType,
        setBetType,
        setPopupDisplayForDesktop,

    } = useContext(SportsContext)
    const [hideLoading, setHideLoading] = useState(true)

    
    const teamNames = useRef(["Player A", "Player B"])

    const [data, setData] = useState([]);

    const remark = useRef('Welcome');
    const [lastResult, setLastResult] = useState({});
    const teamname = useRef('');
    const loss = useRef(0);
    const profit = useRef(0);
    const profitData = useRef(0);


    useEffect(() => {


        if (data?.sub) {
            setTotalPlayers((prevState) => {
                return data.sub.reduce((prev, value) => {
                    if (prev.hasOwnProperty(value.nat)) {

                        return {
                            ...prev,
                            [value.nat]: {
                                ...prev[value.nat],
                                status: value.gstatus === 'OPEN' ? '' : "suspended-box",
                                odds: value.b
                            }
                        };
                    }
                    return prev;
                }, {...prevState});
            });
        }

        if (data.card) {
            const cardArray = data.card.split(",").map(item => item.trim());
            setCards({
                playerA: cardArray.slice(0, 1),
                playerB: cardArray.slice(1, 2),
            });
            remark.current = data.remark || 'Welcome';
        }
    }, [data]);

    const exposure = localStorage.getItem('exposure');
    const sportLength = Object.keys(data).length;

    const updateAmounts = async () => {

        const res = await getExBySingleTeamNameCasino(sportList.id, data.mid, '', match_id)
        setTotalPlayers((prevState) => {
            // Ensure each existing state entry is updated with new data
            const updatedPrev = { ...prevState };

            // Update amounts for each player based on the current 'team_name'
            Object.entries(prevState).forEach(([teamName, values]) => {
                const dataFound = res.data.find(item => item.team_name === teamName);
                updatedPrev[teamName] = { ...values, amounts: dataFound ? dataFound.total_amount || '' : '' };
            });

            return updatedPrev;
        });


    }

    useEffect(() => {

        if (data?.sub && sportList?.id) {

            updateAmounts()


        }
    }, [exposure, sportLength, roundId]);


    const openPopup = (isBakOrLay, teamnam, oddvalue, betType) => {

        setBetType(betType)

        if (parseFloat(oddvalue) > 0) {
            roundIdSaved.current = roundId
            setbackOrLay(isBakOrLay)
            setPopupDisplayForDesktop(true);
            teamname.current = teamnam
            setOdds(oddvalue)
        } else {
            Notify("Odds Value Change Bet Not Confirm", null, null, 'danger')

        }


    }
    const casinoBetDataNew = (new_odds) => {
        stakeValue.current = new_odds
        if (backOrLay === 'back') {


            loss.current = stakeValue.current;


            profit.current = profitData.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)

        } else {

            profit.current = profitData.current = stakeValue.current;


            loss.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)
        }

        

    }

    const renderCards = (cards, player) => (
        <div className="flip-card-container">
            {cards?.map((card, index) => {
                const imgSrc = card ? `/img/casino/cards/${card}.png` : '/img/casino/cards/1.png';
                return (
                    <div className="flip-card" key={index}>
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={imgSrc} alt={`${player} card ${index + 1}`}/>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );

    const placeBet = () => {

        setHideLoading(false)
            if (totalPlayers[teamname.current].status !== '') {
                Notify(`Bet Not Confirmed. Reason: Game Suspended`, null, null, 'danger');
                setPopupDisplayForDesktop(false);
                setHideLoading(true);
                resetBetFields(profitData, stakeValue)
                return;


            }

        if (roundIdSaved.current !== roundId) {
            Notify("Bet Not Confirm Reason Game Change", null, null, 'danger')
            setPopupDisplayForDesktop(false)
            resetBetFields(profitData, stakeValue)
            setHideLoading(true);
            return;
        }


        setSubmitButtonDisable(true)

        const postdata = {

            "sportId": sportList.id,
            "matchId": roundId,
            "isback": backOrLay === 'back' ? 1 : 0,
            "placeName": teamname.current,
            "placeName2": null,
            "odds": odds,
            "oddsk": 0,
            "profit": parseFloat(profit.current).toFixed(2),
            "loss": parseFloat(loss.current).toFixed(2),
            "betType": betType,
            "bet_side": backOrLay.toUpperCase(),
            "betAmount": parseFloat(stakeValue.current),
            "type": match_id.toUpperCase(),
            "matchType":  match_id.toLowerCase(),
            
        }

        axiosFetch('casino/store', 'post', null, postdata)
            .then((res) => {
                if (res.data.status === true) {
                    updateAmounts()

                    resetBetFields(profitData, stakeValue)
                    Notify("Bet Placed Successfully", null, null, 'success')
                }
                else{

                    resetBetFields(profitData, stakeValue)
                    Notify(res.data.msg, null, null, 'danger')
                }
                setHideLoading(true)
                setSubmitButtonDisable(false)
                setPopupDisplayForDesktop(false)
            })


    }

    return (
        <CasinoLayout raceClass="onecard20" ruleDescription={ruleDescription} hideLoading={hideLoading} isBack={backOrLay}
                      teamname={teamname} handleStakeChange={casinoBetDataNew} odds={odds}
                      stakeValue={stakeValue} setOdds={setOdds} placeBet={placeBet}
                      submitButtonDisable={submitButtonDisable} data={data} roundId={roundId} setRoundId={setRoundId}
                      sportList={sportList}
                      setSportList={setSportList} setData={setData} setLastResult={setLastResult}>

            <div className="video-container" style={{minHeight: '0px'}}>
                <div className="video-overlay" style={{top: '-300px'}}>
                    <div className="casino-video-cards">
                        <div>
                            <h5>PLAYER</h5>
                            {renderCards(cards.playerA, "Player A")}
                        </div>
                        <div className="mt-1">
                            <h5>DEALER</h5>
                            {renderCards(cards.playerB, "Player B")}
                        </div>
                    </div>
                </div>
            </div>

            <div className="onecard20">
            <div className="casino-detail">
                <div className="casino-table">
                    <div className="casino-table-full-box">
                        {Object.entries(totalPlayers).map(([index,value]) => (
                        <div className={`onecard20oddbox onecard20${index.toLowerCase()}`}>
                            <div className="casino-odds text-center">{value.odds}</div>
                            <div className={`casino-odds-box back casino-odds-box-theme ${value.status}`}
                                 onClick={() => openPopup('back', index, value.odds, index === 'Tie' ? 'TIE' : (index === 'Pair' ? "PAIR" : "ODDS"))}><span className="casino-odds">{index}</span></div>
                            <div
                                className="casino-nation-book text-center">{getExByColor(value.amounts)}</div>
                        </div>
                        ))}
                    </div>
                </div>

                <marquee scrollamount="3" className="casino-remark m-b-10">{remark.current}</marquee>
                <div className="casino-last-result-title">
                    <span>Last Result</span>
                </div>
                <div className="casino-last-results">
                    <CasinoLastResult sportList={sportList} lastResults={lastResult} data={data}/>
                </div>
            </div>
            </div>
            

        </CasinoLayout>
    );

};
export default Teen120;
