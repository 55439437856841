import Header from "./Section/Header";
import {useContext, useEffect, useState} from "react";
import axios from "axios";
import {AuthContext} from "../Components/Context/AuthContext";
import {io} from 'socket.io-client'
import {CommonContext} from "../Components/Context/CommonContext";

import {Link, useNavigate, useParams} from "react-router-dom";
import {SportsContext} from "../Components/Context/SportsContext";
import {Buffer} from "buffer";
import SidebarLayout from "./Section/SidebarLayout";
import Footer from "./Section/Footer";

function HomeTabPages() {

    const navigate = useNavigate();
    const {setShowLoader} = useContext(SportsContext)

    let Back1 = [];
    let Lay1 = [];
    let Back2 = [];
    let Lay2 = [];
    let BackX = [];
    let LayX = [];



    const BackAndLayForSports = (apiData, model) => {


        if (apiData && Object.keys(apiData).length > 0) {

            let detailArray = apiData.detailData || apiData.game_detail;

            if (!Array.isArray(detailArray)) {
                detailArray = [detailArray];
            }


            if (detailArray && detailArray[0] && detailArray[0].mname.toLowerCase() === 'match_odds') {
                const gameData = detailArray[0];
                const firstGame = gameData.section[0];
                const secondGame = gameData.section[1];
                const thirdGame = gameData.section[2] || null;


                const firstGameOddsBack = firstGame.odds.findIndex(o => o.oname === 'back1');
                const firstGameOddsLay = firstGame.odds.findIndex(o => o.oname === 'lay1');
                const secondGameOddsBack = secondGame.odds.findIndex(o => o.oname === 'back2');
                const secondGameOddsLay = secondGame.odds.findIndex(o => o.oname === 'lay1');

                Back1[model.match_id] = firstGame.odds[firstGameOddsBack]?.odds || 0;

                Lay1[model.match_id] = firstGame.odds[firstGameOddsLay]?.odds || 0;
                Back2[model.match_id] = secondGame.odds[secondGameOddsBack]?.odds || 0;
                Lay2[model.match_id] = secondGame.odds[secondGameOddsLay]?.odds || 0;


                if (thirdGame) {
                    const thirdGameOddsBack = thirdGame.odds.findIndex(o => o.oname === 'back2');
                    const thirdGameOddsLay = thirdGame.odds.findIndex(o => o.oname === 'lay1');
                    BackX[model.match_id] = thirdGame.odds[thirdGameOddsBack]?.odds || '-';
                    LayX[model.match_id] = (thirdGame.odds[thirdGameOddsLay]?.odds || '-');
                }
            }

            if (model.game_id === 1) {
                if (apiData.listData && apiData.game_detail) {
                    let listData = apiData.listData.ename ? apiData.listData : apiData.game_detail;


                }
            }
        }


    };
    const {match} =useParams()

    const [matchesData, setMatchesData] = useState([])

    const [listData, setListData] = useState([]);

    const token = localStorage.getItem('token');
    useEffect(() => {
        let isMounted = true; // Flag to track if component is still mounted

        // Define the async function
        const fetchData = async () => {
            try {
                let response = await axios.get(process.env.REACT_APP_API_URL + 'getMatchesDataForSocket', {
                    headers: {Authorization: `Bearer ${token}`}
                });

                setMatchesData(response.data); // Update state only if the component is still mounted
                setShowLoader(false); // Hide loader after data is fetched

            } catch (error) {
                if (isMounted) {
                    console.error("Error fetching data: ", error);
                    setShowLoader(false); // Hide loader if there's an error
                }
            }
        };

        // Execute the async function
        if (isMounted) {
            fetchData();
        }

        // Cleanup function to run when the component unmounts
        return () => {
            isMounted = false; // Set the flag to false to prevent state updates on unmounted component
        };
    }, [token]); // Add token as a dependency if needed

    function formatBasedOnHyphen(str) {



        // Split the string by hyphen, capitalize the first letter of each word, and join them back with a space
        return str.split('-')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    }

    useEffect(() => {


        if (matchesData !== null && matchesData.length > 0) {
            const list_socket = io(process.env.REACT_APP_LIST_URL)
            list_socket.emit('setPurposeFor', 'list', 1, '', '', matchesData)

            list_socket.on('getListData', userDatas => {
                if (userDatas !== null) {
                    userDatas = JSON.parse(Buffer.from(userDatas).toString('utf8'))

                    setListData(userDatas)

                }

            });
            return () => {
                list_socket.off('getListData'); // remove getListData event listener
                list_socket.disconnect(); // disconnect the socket
            };
        }
    }, [matchesData]);




    const [tennisList, setTennisList] = useState([]);
    const [soccerList, setSoccerList] = useState([]);
    const {cricketList, unAuthorizeHandle, casinoList} = useContext(AuthContext);

    const lists = {
        cricket: cricketList,
        tennis: tennisList,
        soccer: soccerList
    };

    const {formatDateTime} = useContext(CommonContext);
    useEffect(() => {

        if (typeof token !== 'undefined') {
            loadCricketList()

        }



    }, []);


    useEffect(() => {

        localStorage.setItem('casinoList', JSON.stringify(casinoList))


    }, [casinoList])


    function loadCricketList() {


        const soccerRequest = axios.get(process.env.REACT_APP_API_URL + "soccer_list", {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            if (error.code === 'ERR_NETWORK') {

                //console.clear();
                unAuthorizeHandle()

                return navigate('/login');
            }

        });

        const tennisRequest = axios.get(process.env.REACT_APP_API_URL + "tennis_list", {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).catch((e) => {

        });

// Now use axios.all to call all the APIs at the same time
        axios.all([soccerRequest, tennisRequest])
            .then(axios.spread((...responses) => {
                // The responses come back in an array

                const soccerResponse = responses[0];
                const tennisResponse = responses[1];

                // Check soccer data
                if (typeof soccerResponse.data !== 'undefined') {
                    if (Object.entries(soccerResponse.data).length > 0) {
                        setSoccerList(prevList => [...prevList, ...soccerResponse.data]);
                    }

                }
                // Check tennis data
                if (typeof tennisResponse.data !== 'undefined') {
                    if (Object.entries(tennisResponse.data).length > 0) {
                        setTennisList(prevList => [...prevList, ...tennisResponse.data]);
                    }
                }

            }))
            .catch(errors => {
                // react on errors.
                console.error(errors);
            });
    }

    return (
        <div>
            <Header/>


            <div className="main">
                <div className="container-fluid container-fluid-5">

                    <div className="row row5">
                        <SidebarLayout/>
                        <div className="col-md-10 featured-box home-page">
                            <div className="latest-event d-xl-flex">


                            </div>

                            <div className="d-block w-100">


                                <div>
                                    <ul role="tablist" id="home-events" className="nav nav-pills">
                                        <li className="nav-item"><span data-toggle="tab"
                                                                    data-option="cricketData"
                                                                    className="sport-nav nav-link active">{formatBasedOnHyphen(match)}</span>
                                        </li>

                                    </ul>

                                    <div className="tab-content">
                                        <div className="tab-pane active">
                                            <div className="coupon-card coupon-card-first">


                                                <div className="nowDataFound sportData">
                                                    <table className="table coupon-table">
                                                        <thead>
                                                        <tr>
                                                            <th style={{width: '63%'}}>Game</th>
                                                            <th colSpan="2">1</th>
                                                            <th colSpan="2">X</th>
                                                            <th colSpan="2">2</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {lists[match]?.map((cricket, index) => {
                                                            if (Object.keys(listData).length > 0) {
                                                                BackAndLayForSports(listData[cricket.match_id], cricket)

                                                            } else if (Object.keys(JSON.parse(cricket.sport_new_data)).length > 0) {

                                                                BackAndLayForSports(JSON.parse(cricket.sport_new_data), cricket)
                                                            }

                                                            return (<tr>
                                                                    <td>
                                                                        <div className="game-name">
                                                                            <Link className="event-name"
                                                                               to={`/${match}/` + cricket.match_id}>{cricket.match_name} {formatDateTime(new Date(cricket.match_date_time))}</Link>

                                                                        </div>
                                                                        <div className="game-icons">
                                                                            {cricket && cricket.isPlay !== 0 && (

                                                                                <span className="game-icon"><span
                                                                                    className="active"></span></span>
                                                                            )}

                                                                            <span className="game-icon"><i
                                                                                className="fas fa-tv icon-tv"></i></span>

                                                                            <span className="game-icon"><img
                                                                                src="../img/icons/ic_fancy.png"
                                                                                className="fancy-icon"/></span>
                                                                            {cricket && cricket.isBookmaker !== 0 && (

                                                                                <span className="game-icon"><img
                                                                                    src="../img/icons/ic_bm.png"
                                                                                    className="fancy-icon"/></span>

                                                                            )}
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <button
                                                                            className={`back ${Back1?.[cricket.match_id] > 0 ? '' : 'suspended-box'}`}>
                                                                                <span
                                                                                    className="odd">{Back1?.[cricket.match_id] ?? '-'}</span>
                                                                        </button>
                                                                    </td>
                                                                    <td>
                                                                        <button
                                                                            className={`lay ${Lay1?.[cricket.match_id] > 0 ? '' : 'suspended-box'}`}>
                                                                                <span
                                                                                    className="odd">{Lay1?.[cricket.match_id] ?? '-'}</span>
                                                                        </button>
                                                                    </td>

                                                                    <td>
                                                                        <button
                                                                            className={`back ${BackX?.[cricket.match_id] > 0 ? '' : 'suspended-box'}`}>

                                                                                <span
                                                                                    className="odd">{BackX?.[cricket.match_id] ?? '-'}</span>
                                                                        </button>
                                                                    </td>
                                                                    <td>
                                                                        <button
                                                                            className={`lay ${LayX?.[cricket.match_id] > 0 ? '' : 'suspended-box'}`}>
                                                                                <span
                                                                                    className="odd">{LayX?.[cricket.match_id] ?? '-'}</span>
                                                                        </button>
                                                                    </td>

                                                                    <td>
                                                                        <button
                                                                            className={`back ${Back2?.[cricket.match_id] > 0 ? '' : 'suspended-box'}`}>
                                                                                <span
                                                                                    className="odd">{Back2?.[cricket.match_id] ?? '-'}</span>
                                                                        </button>
                                                                    </td>
                                                                    <td>
                                                                        <button
                                                                            className={`lay ${Lay2?.[cricket.match_id] > 0 ? '' : 'suspended-box'}`}>
                                                                                <span
                                                                                    className="odd">{Lay2?.[cricket.match_id] ?? '-'}</span>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                        </tbody>
                                                    </table>
                                                    {/*<div className="norecords">No real-time records found</div>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

            <Footer/>
        </div>
    )
}


export default HomeTabPages;