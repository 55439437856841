import {SportsContext} from "../Context/SportsContext";
import {useRef, useState} from "react";
import {io} from "socket.io-client";
import {checkBetPlace} from "../../Common_Functions/Constants";

export const SportsProvider = (props) => {

    const [popupDisplay, setPopupDisplay] = useState(false)
    const [popupDisplayForDesktop, setPopupDisplayForDesktop] = useState(false)
    const runnerRowDefault = useRef('');
    const rootClassDefault = useRef('');
    const oddsk = useRef(null);
    const [betType, setBetType] = useState('ODDS')
    const [triggerBetPlace, setTriggerBetPlace] = useState(false)

    const [betTypeFromArray, setBetTypeFromArray] = useState('match_odds')

    const [showLoader, setShowLoader] = useState(true)

    const betPlaceStatus = useRef({}); // State for checkBetPlace results


    const defaultTeamDatasCalculation = (sportList, setOddsTeamData, setBookmakerTeamData, setTiedMatchData = null) => {
        const bettt = [
            'ODDS',
            'BOOKMAKER',
            'TIED_MATCH'
        ]


        bettt.forEach((value, key) => {
            const storageKey = `exByTeamName_${value}_${sportList.id}`;
            const storedData = localStorage.getItem(storageKey);
            const arrayjson = JSON.parse(storedData)

            switch (value) {

                case 'ODDS' :
                    setOddsTeamData(arrayjson)

                    break;

                case 'BOOKMAKER' :
                    setBookmakerTeamData(arrayjson)



                    break;
                default :
                    if (setTiedMatchData !== null) {
                        setTiedMatchData(arrayjson)
                    }
                    break;
            }


        })

    }
    const defaultNavProps = {'active': false, 'list': {}};
    const [navLinks, setNavLinks] = useState({

        "Cricket": {...defaultNavProps, canonical_name : 'cricket'},
        "Football": {...defaultNavProps, canonical_name : 'soccer'},
        "Tennis": {...defaultNavProps, canonical_name : 'tennis'},
        "Table Tennis": defaultNavProps,
        "Basketball": defaultNavProps,
        "Volleyball": defaultNavProps,
        "Snooker": defaultNavProps,
        "Handball": defaultNavProps,
        "Ice Hockey": defaultNavProps,
        "E Games": defaultNavProps,
        "Futsal": defaultNavProps,
        "Kabaddi": defaultNavProps,
        "Golf": defaultNavProps,
        "Rugby League": defaultNavProps,
        "Boxing": defaultNavProps,
        "Beach Volleyball": defaultNavProps,
        "Mixed Martial Arts": defaultNavProps,
        "MotoGP": defaultNavProps,
        "Chess": defaultNavProps,
        "Badminton": defaultNavProps,
        "Cycling": defaultNavProps,
        "Motorbikes": defaultNavProps,
        "Athletics": defaultNavProps,
        "Basketball 3X3": defaultNavProps,
        "Sumo": defaultNavProps,
        "Virtual sports": defaultNavProps,
        "Motor Sports": defaultNavProps,
        "Baseball": defaultNavProps,
        "Rugby Union": defaultNavProps,
        "Darts": defaultNavProps,
        "American Football": defaultNavProps,
        "Soccer": defaultNavProps,
        "Esports": defaultNavProps,


    })

    const activeLink = (match_name) => {
        setNavLinks(prevState => {
            return {
                ...prevState,
                [match_name]: {...prevState[match_name],active: !prevState[match_name].active}
            };
        });
    }

    const betPlaceStatusCheck = (sportList, data, teamname = null) => {

        const fetchAllBetPlaceStatus = async () => {

            if (data && Array.isArray(data) && teamname === null) {
                const sections = [];

                if (data['normal'] !== undefined && data['normal'].section) {
                    sections.push(...data['normal'].section);
                }

                if (data['over by over'] !== undefined && data['over by over'].section) {
                    sections.push(...data['over by over'].section);
                }

                if (sections.length > 0) {
                    const betStatuses = await Promise.all(sections.map(async (oddsArr) => {
                        const betPlace = await checkBetPlace(oddsArr.nat, sportList.id);
                        return {teamName: oddsArr.nat, status: betPlace.data};
                    }));

                    betPlaceStatus.current =  betStatuses.reduce((acc, item) => {
                        acc[item.teamName] = item.status;
                        return acc;
                    }, {});


                }
            } else if (data && Array.isArray(data) && teamname !== null) {
                const betPlace = await checkBetPlace(teamname, sportList.id);


                betPlaceStatus.current[teamname] = betPlace.data;


            }
        };

        fetchAllBetPlaceStatus();

        return betPlaceStatus;
    }


    const sports_socket = io(process.env.REACT_APP_CRICKET_WEBSOCKET_URL)
    const [stakeValues, setStakeValues] = useState({});

    const getSportOldData = (type, matchID, newData) => {
        let apiDataOld;

        if (localStorage.hasOwnProperty(matchID)) {
            apiDataOld = JSON.parse(localStorage.getItem(matchID));
        } else {
            apiDataOld = newData;
            localStorage.setItem(matchID, JSON.stringify(newData));
        }

        return apiDataOld;
    };


    return (
        <SportsContext.Provider value={{
            betPlaceStatusCheck,
            popupDisplayForDesktop,
            setPopupDisplayForDesktop,
            showLoader,
            setShowLoader,
            betTypeFromArray,
            setBetTypeFromArray,
            betPlaceStatus,
            defaultTeamDatasCalculation,
            getSportOldData,
            sports_socket,
            betType,
            triggerBetPlace,
            setTriggerBetPlace,
            setBetType,
            popupDisplay,
            setPopupDisplay,
            runnerRowDefault,
            rootClassDefault,
            oddsk,
            activeLink,
            navLinks,
            setNavLinks,
            stakeValues,
            setStakeValues

        }}>
            {props.children}
        </SportsContext.Provider>
    );
};