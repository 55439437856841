import React from 'react';

const BetTable = ({ myBetModel }) => {
    return (
        <div className="tbodyScroll">
            <table className="table coupon-table table table-borderedless">
                <thead>
                <tr>
                    <th>Runner</th>
                    <th>Type</th>
                    <th>Bet Type</th>
                    <th>Odds</th>
                    <th>Stack</th>
                </tr>
                </thead>
                <tbody>
                {myBetModel.length > 0 ? (
                    myBetModel.map((data, index) => {
                        const className = data.bet_side === "LAY" ? "lay" : "back";
                        return (
                            <tr key={index} className={className}>
                                <td>{data.team_name}</td>
                                <td>{data.bet_type}</td>
                                {data.type === data.bet_type && data.bet_type === "SESSION" ? (
                                    <>
                                        <td>{data.bet_side === "LAY" ? "NO" : "YES"}</td>
                                        <td>{data.bet_odds} / {data.bet_oddsk}</td>
                                    </>
                                ) : (
                                    <>
                                        <td>{data.bet_side}</td>
                                        <td>{data.bet_odds}</td>
                                    </>
                                )}
                                <td>{data.bet_amount}</td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <th colSpan="5">No Records Found</th>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    );
};

export default BetTable;
