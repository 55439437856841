export const Race20 = ({lastResults, openPopup}) => {
    const results = {1 : process.env.REACT_APP_CARD_PATH + "spade.png", 2 : process.env.REACT_APP_CARD_PATH + "heart.png",
         3 : process.env.REACT_APP_CARD_PATH + "club.png",
        4 : process.env.REACT_APP_CARD_PATH + "diamond.png",


    };
    return (


        <p id="last-result" className="text-right">
            {Object.entries(lastResults).map(([key, result]) => {
                const mid = result.mid;


                return (

                        <span className="last-result ball-runs playersuit" key={mid} onClick={() => openPopup(mid)}>
                                    <img src={results[result.win]} alt={'1'} />
                            </span>

                );
            })}
        </p>
    );
}
