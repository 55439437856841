import CasinoLayout from "../Section/Casino/CasinoLayout";
import {useContext, useEffect, useRef, useState} from "react";

import {CasinoLastResult} from "../Section/Casino/CasinoLastResult";

import axiosFetch, {
    getExByColor,
    resetBetFields,
    getExByTeamNameForCasino, getPlayerCardAccordingToNumberOfPlayers, updatePlayerStats,

} from "../../Common_Functions/Constants";
import {useParams} from "react-router-dom";
import {SportsContext} from "../../Components/Context/SportsContext";


import Notify from "../../js/Notify";

const Poker = () => {
    const [roundId, setRoundId] = useState('')

    const ruleImage = '/img/rules/poker.jpeg'
    const ruleDescription = '';
    const roundIdSaved = useRef(null);

    const [submitButtonDisable, setSubmitButtonDisable] = useState(false)


    const stakeValue = useRef(0);
    const [odds, setOdds] = useState(0)

    const [backOrLay, setbackOrLay] = useState('back')
    const [sportList, setSportList] = useState({})
    const {match_id} = useParams();
    const {
        setBetType,
        setPopupDisplayForDesktop,

    } = useContext(SportsContext)
    const [hideLoading, setHideLoading] = useState(true)


    const teamNames = useRef(["Player A", "Player B"])

    const [data, setData] = useState([]);
    const [playerA, setPlayerA] = useState(0); // Example player A value
    const [playerStatuses, setPlayerStatuses] = useState({"Player A": 'suspended-box', "Player B": 'suspended-box'});
    const [playerA_Back, setPlayerA_Back] = useState(0);
    const [playerB_Back, setPlayerB_Back] = useState(0);
    const [playerA_Lay, setPlayerA_Lay] = useState(0);
    const [playerB, setPlayerB] = useState(0); // Example player B value

    const [playerB_Lay, setPlayerB_Lay] = useState(0);
    const remark = useRef('Welcome');
    const [lastResult, setLastResult] = useState({});
    const teamname = useRef('');
    const loss = useRef(0);
    const profit = useRef(0);
    const profitData = useRef(0);


    useEffect(() => {
        setBetType('ODDS')

        if (data?.sub) {
            updatePlayerStats(data.sub[0], setPlayerA_Back, setPlayerA_Lay, "Player A", setPlayerStatuses, 'suspended-box');
            updatePlayerStats(data.sub[1], setPlayerB_Back, setPlayerB_Lay, "Player B", setPlayerStatuses, 'suspended-box');

        }

    }, [data]);

    const exposure = localStorage.getItem('exposure');
    const sportLength = Object.keys(data).length;


    useEffect(() => {

        if (data?.sub && sportList?.id) {
            getExByTeamNameForCasino(sportList.id, data.mid, 'Player A', match_id, 'ODDS').then(res => setPlayerA(res.data))

            getExByTeamNameForCasino(sportList.id, data.mid, 'Player B', match_id, 'ODDS').then(res => setPlayerB(res.data))
        }
    }, [exposure, sportLength, roundId]);


    const openPopup = (isBakOrLay, teamnam, oddvalue) => {


        if (parseFloat(oddvalue) > 0) {
            roundIdSaved.current = roundId
            setbackOrLay(isBakOrLay)
            setPopupDisplayForDesktop(true);
            teamname.current = teamnam
            setOdds(oddvalue)
        } else {
            Notify("Odds Should Not Be Zero", null, null, 'danger')

        }


    }
    const casinoBetDataNew = (new_odds) => {
        stakeValue.current = new_odds
        if (backOrLay === 'back') {


            loss.current = stakeValue.current;


            profit.current = profitData.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)

        } else {

            profit.current = profitData.current = stakeValue.current;


            loss.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)
        }


    }


    const placeBet = () => {

        setHideLoading(false)
        if (playerStatuses[teamname.current] === 'suspended-box') {
            Notify(`Bet Not Confirmed. Reason: Game Suspended`, null, null, 'danger');
            setPopupDisplayForDesktop(false);
            setHideLoading(true)
            return;
        }

        if (roundIdSaved.current !== roundId) {
            Notify("Bet Not Confirm Reason Game Change", null, null, 'danger')
            setPopupDisplayForDesktop(false)
            setHideLoading(true)
            return;
        }


        setSubmitButtonDisable(true)

        const postdata = {

            "sportId": sportList.id,
            "matchId": roundId,
            "isback": backOrLay === 'back' ? 1 : 0,
            "placeName": teamname.current,
            "placeName2": null,
            "odds": odds,
            "oddsk": 0,
            "profit": parseFloat(profit.current).toFixed(2),
            "loss": parseFloat(loss.current).toFixed(2),
            "betType": "ODDS",
            "bet_side": backOrLay.toUpperCase(),
            "betAmount": parseFloat(stakeValue.current),
            "type": "POKER",
            "matchType": "poker",

        }

        axiosFetch('casino/store', 'post', null, postdata)
            .then((res) => {
                if (res.data.status === true) {

                    resetBetFields(profitData, stakeValue)
                    Notify("Bet Placed Successfully", null, null, 'success')
                } else {

                    resetBetFields(profitData, stakeValue)
                    Notify(res.data.msg, null, null, 'danger')
                }
                setHideLoading(true)
                setSubmitButtonDisable(false)
                setPopupDisplayForDesktop(false)
            })


    }

    return (
        <CasinoLayout hideLoading={hideLoading} isBack={backOrLay} teamname={teamname}
                      handleStakeChange={casinoBetDataNew} odds={odds}
                      stakeValue={stakeValue} setOdds={setOdds} placeBet={placeBet}
                      submitButtonDisable={submitButtonDisable} data={data} roundId={roundId} setRoundId={setRoundId}
                      sportList={sportList}
                      setSportList={setSportList} setData={setData} setLastResult={setLastResult}>
            <div className="video-container" style={{minHeight: '0px'}}>
                <div className="video-overlay" style={{top: '-300px'}}>

                        <div id="game-cards">
                            <div className="card-inner">
                                <div className="row">
                                    <div className="col-6">
                                        <h3 className="text-white" style={{fontSize: '11px'}}>Player A</h3>
                                        <div>
                                            {getPlayerCardAccordingToNumberOfPlayers(data, 1, 9, [0, 1]).map((card, index) => (
                                                <img key={index} className="borde-img-card" src={card}
                                                     alt={`Player A card ${index}`}/>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-6 text-right">
                                        <h3 className="text-white" style={{fontSize: '11px'}}>Player B</h3>
                                        {getPlayerCardAccordingToNumberOfPlayers(data, 1, 9, [2, 3]).map((card, index) => (
                                            <img key={index} className="borde-img-card" src={card}
                                                 alt={`Player B card ${index}`}/>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="card-inner">
                                <h3 className="text-white">Board</h3>
                                <div>
                                    {getPlayerCardAccordingToNumberOfPlayers(data, 1, 9, [4, 5, 6, 7, 8]).map((card, index) => (
                                        <img key={index} className="borde-img-card" src={card}
                                             alt={`Board card ${index}`}/>
                                    ))}
                                </div>
                            </div>
                        </div>


                </div>
            </div>

            <div className="card-content m-t-0">
                <div className="row row5">
                    <div className="col-md-5 main-market p-r-5">
                        <div className="live-poker">
                            <table className="table coupon-table table-bordered">
                                <thead>
                                <tr>
                                    <th className="box-4"></th>
                                    <th className="box-3 back">BACK</th>
                                    <th className="box-3 lay-color">LAY</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr data-title="SUSPENDED" className="bet-info">
                                    <td className={`box-4`}>
                                        <b>Player A</b>

                                        <span className={`d-block`}>{getExByColor(playerA)}</span>
                                    </td>
                                    <td className={`box-3 back text-section ${playerStatuses['Player A']}`}>
                                        <button className="back"
                                                onClick={() => openPopup('back', 'Player A', playerA_Back)}>
                                            <span className="odd">{playerA_Back}</span>
                                        </button>
                                    </td>
                                    <td className={`box-3 lay text-section ${playerStatuses['Player A']}`}>
                                        <button className="lay"
                                                onClick={() => openPopup('lay', 'Player A', playerA_Lay)}>
                                            <span className="odd">{playerA_Lay}</span>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={`box-4`}>
                                        <b>Player B</b>
                                        <p className={`mb-0`}>{getExByColor(playerB)}</p>
                                    </td>
                                    <td className={`box-3 back text-center ${playerStatuses['Player B']}`}
                                        onClick={() => openPopup('back', 'Player B', playerB_Back)}>
                                        <span className="odds d-block"><b>{playerB_Back}</b></span>
                                        <span className="p-2"></span>
                                    </td>
                                    <td className={`box-3 lay text-center ${playerStatuses['Player B']}`}
                                        onClick={() => openPopup('lay', 'Player B', playerB_Lay)}>
                                        <span className="odds d-block"><b>{playerB_Lay}</b></span>
                                        <span className="p-2"></span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-md-7 main-market p-l-5">
                        <div className="live-poker">
                            <table className="table coupon-table table-bordered">
                                <thead>
                                <tr>
                                    <th className="box-4"></th>
                                    <th className="box-3 back-color">BACK</th>
                                    <th className="box-3 back-color">BACK</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr data-title="SUSPENDED" className="bet-info">
                                    <td className="box-4">Player A</td>
                                    <td className={`box-3 back teen-section  ${playerStatuses['Player A']}`}>
                                        <button className="back">

                                            <span className="d-block"
                                                  style={{color: 'black'}}>2 Card Bonus</span>
                                        </button>
                                    </td>
                                    <td className={`box-3 back teen-section  ${playerStatuses['Player A']}`}>
                                        <button className="back">
                                            <span className="d-block"
                                                  style={{color: 'black'}}>7 Card Bonus</span>
                                        </button>
                                    </td>
                                </tr>
                                <tr data-title="SUSPENDED" className="bet-info">
                                    <td className="box-4">Player B</td>
                                    <td className={`box-3 back teen-section ${playerStatuses['Player B']}`}>
                                        <button className="back">

                                            <span className="d-block"
                                                  style={{color: 'black'}}>2 Card Bonus</span>
                                        </button>
                                    </td>
                                    <td className={`box-3 back teen-section ${playerStatuses['Player B']}`}>
                                        <button className="back">

                                            <span className="d-block"
                                                  style={{color: 'black'}}>7 Card Bonus</span>
                                        </button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <marquee scrollamount="3" className="casino-remark m-b-10">{remark.current}</marquee>
                <div className="casino-last-result-title">
                    <span>Last Result</span>
                </div>
                <div className="casino-last-results">
                    <CasinoLastResult sportList={sportList} lastResults={lastResult} data={data}/>
                </div>
            </div>


        </CasinoLayout>
    );

};


export default Poker;
