import CasinoLayout from "../Section/Casino/CasinoLayout";
import {useContext, useEffect, useRef, useState} from "react";

import {CasinoLastResult} from "../Section/Casino/CasinoLastResult";
import axiosFetch, {
    cardMap,  changeCardIndex, getExByColor,
    resetBetFields
} from "../../Common_Functions/Constants";
import {SportsContext} from "../../Components/Context/SportsContext";
import {BetPlacPopupSection} from "../../Components/BetPlacPopupSection";
import Notify from "../../js/Notify";
import {CasinoContext} from "../../Components/Context/CasinoContext";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AndarBaharVideoCards from "../../Components/AndarBaharVideoCards";

const Ab3 = () => {
    let astatus = '', bstatus;
    const [roundId, setRoundId] = useState('')

    const [showCardCount, setShowCardCount] = useState(true)
    const {getAndarBaharCalculation} = useContext(CasinoContext)
    const [cardCount, setCardCount] = useState('')
    const [cardCountName, setCardCountName] = useState('Bahar')

    const ruleDescription = '<div class="rules-section">\n' +
        '\n' +
        '                                <ul class="pl-4 pr-4 list-style">\n' +
        '                                    <li>1. Andar Bahar is a fast paced Indian origin game.</li>\n' +
        '                                    <li>2. It is played with a regular deck of 52 cards.</li>\n' +
        '                                    <li>3. This game is played between two sides Andar and Bahar.</li>\n' +
        '                                    <li>4. The objective of the game is to place bet on cards of your choice whether they will be on the Andar side or the Bahar side and therefor win.</li>\n' +
        '                                    <li>5. The odds will be available on every card to place your bets upto 46th card.</li>\n' +
        '                                    <li>6. At the start of the game first card will be drawn on the Bahar side and the next card will be drawn on the Andar side and so on upto the 50th card.</li>\n' +
        '                                    <li>7. When the card is to be open on the Bahar side odds will be available for both the Andar side and the Bahar side.\n' +
        '                                        <ul class="pl-4 pr-4 list-style">\n' +
        '                                            <li>* If you place bets on the Bahar side and you win on that particular first card the payout will be 50% of your bet amount from 1st card to 31st card and from the 33rd card to 45th card the payout\n' +
        '                                                will be 40% of your bet amount.</li>\n' +
        '\n' +
        '                                            <li>* Winning on all cards other than that particular first card payout will be 100%.</li>\n' +
        '                                        </ul>\n' +
        '                                    </li>\n' +
        '                                    <li>8. When the card is to be open on the Andar side the odds will be available only for the Bahar side. The payout will be 100% of your bet amount on all the cards.</li>\n' +
        '                                    <li>9. The game will be considered over after the 50th card is drawn. The pending bets on remaining 2 cards will be cancelled (Pushed).</li>\n' +
        '                                </ul>\n' +
        '\n' +
        '\n' +
        '                            </div>';
    const roundIdSaved = useRef(null);

    const [submitButtonDisable, setSubmitButtonDisable] = useState(false)


    const stakeValue = useRef(0);
    const [odds, setOdds] = useState(0)

    const [backOrLay, setbackOrLay] = useState('back')
    const [sportList, setSportList] = useState({})

    const defaultCards = Array.from({length: 13}, (_, array) => {
        array = 0;
        return "/img/card/" + array + ".jpg";

    })

    const andarBaharCards = defaultCards

    const [enableOnclick, setEnableOnclick] = useState(false)

    const [totalPlayers, setTotalPlayers] = useState([
        {
            Andar: {
                cards: andarBaharCards, status: '', amounts: Object.fromEntries(Array.from({length: 13}, (_, index) => {
                    return ["Andar " + cardMap(index), ''];
                })),
                card_count: Object.fromEntries(Array.from({length: 13}, (_, index) => {
                    return ["Andar " + cardMap(index), 0];
                }))

            }
        },
        {
            Bahar: {
                cards: andarBaharCards, status: '', amounts: Object.fromEntries(Array.from({length: 13}, (_, index) => {
                    return ["Bahar " + cardMap(index), ''];
                })),
                card_count: Object.fromEntries(Array.from({length: 13}, (_, index) => {
                    return ["Bahar " + cardMap(index), 0];
                }))

            }
        }

    ])


    const {
        setBetType,
        betType,
        setPopupDisplay,

    } = useContext(SportsContext)
    const [hideLoading, setHideLoading] = useState(true)


    const [data, setData] = useState([]);

    const remark = useRef('Welcome');
    const [lastResult, setLastResult] = useState({});
    const teamname = useRef('');
    const loss = useRef(0);
    const profit = useRef(0);
    const profitData = useRef(0);


    useEffect(() => {


        if (data?.sub) {


        }

        if (data.card) {

            remark.current = data.remark || 'Welcome';
            processData(data)

            let arr = data.card.split(',');
            let ar_all = arr.filter((value, key_array) => key_array % 2 !== 0 && value !== '1');
            let ar_ball = arr.filter((value, key_array) => key_array % 2 === 0 && value !== '1');
            let c = ar_all.length + ar_ball.length + 1;
            setCardCount(c)

            setCardCountName(c % 2 === 0 ? 'Bahar' : 'Andar');

        }


    }, [data]);

    const exposure = localStorage.getItem('exposure');
    const sportLength = Object.keys(data).length;
    const processData = (d_data) => {

        let card_change = false;
        const defaultCardsChange = Array.from({length: 13}, (_, array) => {
            array += 1;
            return "/img/card/" + array + ".jpg";

        })


        const cardSet1 = d_data?.card
        const cardsArray = cardSet1.split(','); // Explode the string into an array

        const allAreOnes = cardsArray.every(value => value === '1' || value === 1); // Check if all values are '1'


        if (allAreOnes) {

            card_change = false;
            if (enableOnclick === false)
                setEnableOnclick(true)


        } else {
            card_change = true;

            if (enableOnclick === true) {

                setEnableOnclick(false)

                setTotalPlayers((prevState) => {

                    const changeState = [...prevState];

                    changeState.forEach((value, index) => {
                        const akeys = Object.keys(value)[0]
                        changeState[index][akeys] = {
                            ...changeState[index][akeys],
                            status: ''

                        }

                    })


                    return changeState;
                })
            }
        }

        const img_ar = card_change === true ? defaultCards.slice() : defaultCardsChange.slice(), post_ar = {},
            post_br = {}, card_ar = {}, card_br = {};
        const img_br = card_change === true ? defaultCards.slice() : defaultCardsChange.slice()



        const setCardImagesAndCardCount = (img_obj, post_obj, keyPrefix, cardSet, offset = 0) => {

            const cardAndar = cardSet.slice(0, 13);
            const cardBahar = cardSet.slice(13, 26);

            cardAndar.forEach((value, key) => {

                post_obj[value.nat] = value.l

                if (value.l > 0) {
                    img_obj[key] = "/img/card/" + changeCardIndex(value.nat.split("Andar ")[1]) + ".jpg"

                }


            })

            cardBahar.forEach((value, key) => {

                post_obj[value.nat] = value.l
                if (value.l > 0) {
                    img_obj[key] = "/img/card/" + changeCardIndex(value.nat.split("Bahar ")[1]) + ".jpg"
                }


            })


            if (data?.lt <= 3 && cardCountName === 'Bahar') {
                astatus = 'suspended-box'
                bstatus = ''
            } else if (data?.lt <= 3 && cardCountName === 'Andar') {
                astatus = ''
                bstatus = ''
            }

            if (data?.lt <= 3 && (astatus === 'suspended-box' && bstatus === '')) {
                astatus = 'suspended-box'
                bstatus = 'suspended-box'
            }


        }

        if (d_data?.child) {
            [...Array(13)].forEach((_, i) => {
                img_ar[i] = img_br[i] = process.env.REACT_APP_CARD_PATH + '1.png';
            });

            if (d_data?.child) setCardImagesAndCardCount(img_ar, card_ar, 'Andar', d_data.child);
            if (d_data?.child) setCardImagesAndCardCount(img_br, card_br, 'Bahar', d_data.child, 20);
        }

        setTotalPlayers((prevState) => {
            const updatedPlayers = [...prevState];
            updatedPlayers[0]['Andar'].cards = img_ar;
            updatedPlayers[1]['Bahar'].cards = img_br;
            updatedPlayers[0]['Andar'].status = astatus;
            updatedPlayers[1]['Bahar'].status = bstatus;
            updatedPlayers[0]['Andar'].card_count = card_ar;
            updatedPlayers[1]['Bahar'].card_count = card_br;
            return updatedPlayers;
        });

    };


// Example usage of asset function to simulate PHP asset path


    useEffect(() => {

        if (data?.sub && sportList?.id) {

            getAndarBaharCalculation(sportList.id, roundId, 'ab3', setTotalPlayers);

        }
    }, [exposure, sportLength, roundId]);


    const openPopup = (isBakOrLay, teamnam, oddvalue, bet) => {
        setBetType(bet)


        if (parseFloat(oddvalue) > 0) {
            roundIdSaved.current = roundId
            setbackOrLay(isBakOrLay)
            setPopupDisplay(true);
            teamname.current = teamnam
            setOdds(oddvalue)
        } else {
            Notify("Odds Value Change Bet Not Confirm", null, null, 'danger')

        }


    }
    const casinoBetDataNew = (event, new_odds) => {
        stakeValue.current = event.target.value
        if (backOrLay === 'back') {


            loss.current = stakeValue.current;


            profit.current = profitData.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)

        } else {

            profit.current = profitData.current = stakeValue.current;


            loss.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)
        }


    }

    const placeBet = () => {

        setHideLoading(false)


        if (betType === 'ANDAR' && totalPlayers[0]['Andar'].status === 'suspended-box') {
            Notify(`Bet Not Confirmed. Reason: Game Suspended`, null, null, 'danger');
            setPopupDisplay(false);
            setHideLoading(true);

            return;
        }
        else if (betType === 'BAHAR' && totalPlayers[1]['Bahar'].status === 'suspended-box') {
            Notify(`Bet Not Confirmed. Reason: Game Suspended`, null, null, 'danger');
            setPopupDisplay(false);
            setHideLoading(true);

            return;
        }

        if (roundIdSaved.current !== roundId) {
            Notify("Bet Not Confirm Reason Game Change", null, null, 'danger')
            setPopupDisplay(false)
            return;
        }


        setSubmitButtonDisable(true)

        const postdata = {

            "sportId": sportList.id,
            "matchId": roundId,
            "isback": backOrLay === 'back' ? 1 : 0,
            "placeName": teamname.current,
            "placeName2": null,
            "odds": odds,
            "oddsk": 0,
            "profit": parseFloat(profit.current).toFixed(2),
            "loss": parseFloat(loss.current).toFixed(2),
            "betType": betType,
            "bet_side": backOrLay.toUpperCase(),
            "betAmount": parseFloat(stakeValue.current),
            "type": "AB3",
            "matchType": "ab3",

        }

        axiosFetch('casino/store', 'post', null, postdata)
            .then((res) => {
                if (res.data.status === true) {

                    resetBetFields(profitData, stakeValue)
                    getAndarBaharCalculation(sportList.id, roundId, 'ab3', setTotalPlayers);
                    Notify("Bet Placed Successfully", null, null, 'success')
                } else {

                    resetBetFields(profitData, stakeValue)
                    Notify(res.data.msg, null, null, 'danger')
                }
                setHideLoading(true)
                setSubmitButtonDisable(false)
                setPopupDisplay(false)
            })


    }

    return (
        <CasinoLayout  ruleDescription={ruleDescription} data={data} roundId={roundId} setRoundId={setRoundId} sportList={sportList}
                      setSportList={setSportList} setData={setData} setLastResult={setLastResult}>
            <div className="video-container" style={{minHeight: '0px', marginLeft: "20px"}}>
                <div className="video-overlay" style={{top: '230px'}}>
                    <div id="game-cards" style={{width: '155px'}}>
                        {showCardCount && (
                            <div className="row">
                                <div className="col d-flex">
                                    <div className="text-white">
                                        Next Card Count :
                                    </div>
                                    <div className="text-warning fw-bolder mx-2">
                                        {cardCount} / {cardCountName}
                                    </div>
                                </div>
                            </div>
                        )}
                        <div>
                            {data?.card && (
                                <AndarBaharVideoCards setShowCardCount={setShowCardCount} showCardCount={showCardCount} cards={data?.card}
                                                      player="Andar"/>
                            )}
                            {data?.card && (
                                <AndarBaharVideoCards setShowCardCount={setShowCardCount} cards={data?.card}
                                                      player="Bahar"/>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="casino-detail ab ab3">
                <div className="casino-table">
                    <div className="casino-table-box">


                        {data?.card && totalPlayers.map((value, key) => {
                            const title = Object.keys(value)[0];
                            const status = value[title].status
                            const amounts = value[title].amounts
                            const cardcount = value[title].card_count
                            return (
                                <div className={`${title.toLowerCase()}-box ${status}`} key={key}>
                                    <div className="ab-title">{title.toUpperCase()}</div>
                                    <div className="ab-cards">


                                        {Object.entries(value[title]['cards']).map(([key, value]) => {


                                            return (
                                                <div key={key} className="card-odd-box">
                                                    <div className="casino-odds text-center"
                                                         onClick={() => openPopup('back', title + " " + cardMap(parseInt(key)) + "/"+cardCount, 2, title.toUpperCase())}>
                                                        {cardcount[title + " " + cardMap(parseInt(key))]}
                                                        <div className="mt-2">
                                                            <img src={value} alt={title}
                                                                 className="andar-bahar-image"/>
                                                        </div>
                                                        {getExByColor(amounts[title + " " + cardMap(parseInt(key))])}
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </div>
                                </div>

                            )
                        })}
                    </div>
                </div>
                <marquee scrollamount="3" className="casino-remark m-b-10">{remark.current}</marquee>
                <div className="casino-last-result-title">
                    <span>Last Result</span>
                </div>
                <div className="last-result-container text-right mt-1">
                    <CasinoLastResult sportList={sportList} lastResults={lastResult} data={data}/>
                </div>
            </div>

            <BetPlacPopupSection placeBet={placeBet} profitValue={profitData.current} profit={profit} loss={loss}
                                 handleStakeChange={casinoBetDataNew} hideLoading={hideLoading}
                                 teamname={teamname} backOrLay={backOrLay} stakeValue={stakeValue} odds={odds}
                                 profitData={profitData}
                                 setOdds={setOdds} submitButtonDisable={submitButtonDisable}/>

        </CasinoLayout>
    );

};


export default Ab3;
