import {useState} from "react";

import {CasinoContext} from "../Context/CasinoContext";
import {io} from "socket.io-client";
import {
    cardMap,
    cardMapInteger,
    getExBySingleTeamNameCasino,
    getExByTeamNameForCasino
} from "../../Common_Functions/Constants";

export const CasinoProvider = (props) => {
    const casino_socket = io(process.env.REACT_APP_CASINO_URL)
    const [betType, setBetType] = useState('ODDS')
    const [popupDisplay, setPopupDisplay] = useState(false)

    const fetchDataDragonTiger = async (data, sportList, match_id, roundId, TOTALPLAYERS, setTotalPlayers) => {
        if (data?.sub && sportList?.id) {
            try {


                // Fetch data once for all teams
                const result = await getExBySingleTeamNameCasino(sportList.id, roundId, '', match_id, '');

                const updatedPlayers = TOTALPLAYERS.map((section, index) => {
                    if (index === 0) {
                        // Update the Dragon, Tiger, Tie, Pair odds and amounts
                        return {
                            ...section,
                            Dragon: {
                                ...section.Dragon,

                                amounts: result?.data.find(item => item.team_name === 'Dragon')?.total_amount || ''
                            },
                            Tie: {
                                ...section.Tie,

                                amounts: result?.data.find(item => item.team_name === 'Tie')?.total_amount || ''
                            },
                            Tiger: {
                                ...section.Tiger,

                                amounts: result?.data.find(item => item.team_name === 'Tiger')?.total_amount || ''
                            },
                            Pair: {
                                ...section.Pair,

                                amounts: result?.data.find(item => item.team_name === 'Pair')?.total_amount || ''
                            }
                        };
                    }

                    if (index === 1) {
                        // Update Dragon's nested data
                        return {
                            ...section,
                            Dragon: {
                                ...section.Dragon,
                                Even: {
                                    ...section.Dragon.Even,
                                    amounts: result?.data.find(item => item.team_name === 'Dragon Even')?.total_amount || ''
                                },
                                Odd: {
                                    ...section.Dragon.Odd,
                                    amounts: result?.data.find(item => item.team_name === 'Dragon Odd')?.total_amount || ''
                                },
                                Black: {
                                    ...section.Dragon.Black,
                                    amounts: result?.data.find(item => item.team_name === 'Dragon Black')?.total_amount || ''
                                },
                                Red: {
                                    ...section.Dragon.Red,
                                    amounts: result?.data.find(item => item.team_name === 'Dragon Red')?.total_amount || ''
                                },
                                Card: {
                                    ...section.Dragon.Card,
                                    amounts: Array.from({length: 13}, (_, i) => {
                                        const cardData = result?.data.find(item => item.team_name === `Dragon Card ${cardMap(i)}`);
                                        return cardData ? cardData.total_amount : ''; // Set value at the respective index or empty string
                                    })
                                }
                            }
                        };
                    }

                    if (index === 2) {
                        // Update Tiger's nested data
                        return {
                            ...section,
                            Tiger: {
                                ...section.Tiger,
                                Even: {
                                    ...section.Tiger.Even,
                                    amounts: result?.data.find(item => item.team_name === 'Tiger Even')?.total_amount || ''
                                },
                                Odd: {
                                    ...section.Tiger.Odd,
                                    amounts: result?.data.find(item => item.team_name === 'Tiger Odd')?.total_amount || ''
                                },
                                Black: {
                                    ...section.Tiger.Black,
                                    amounts: result?.data.find(item => item.team_name === 'Tiger Black')?.total_amount || ''
                                },
                                Red: {
                                    ...section.Tiger.Red,
                                    amounts: result?.data.find(item => item.team_name === 'Tiger Red')?.total_amount || ''
                                },
                                Card: {
                                    ...section.Tiger.Card,
                                    amounts: Array.from({length: 13}, (_, i) => {
                                        const cardData = result?.data.find(item => item.team_name === `Tiger Card ${cardMap(i)}`);
                                        return cardData ? cardData.total_amount : ''; // Set value at the respective index or empty string
                                    })
                                }
                            }
                        };
                    }


                    return section;
                });


                setTotalPlayers(updatedPlayers);

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    };

    const fetchDataDragonTigerDt6 = async (data, sportList, match_id, roundId, TOTALPLAYERS, setTotalPlayers, betType, what_to_fetch = 'all') => {
        if (data?.sub && sportList?.id) {

            try {

                const promises = [];
                const entities = ['Dragon', 'Tiger']; // Include Pair in the entities

                // Fetch odds for Dragon, Tiger, and Pair
                entities.forEach(entity => {
                    promises.push(getExByTeamNameForCasino(sportList.id, roundId, entity, match_id, 'ODDS'));
                });

                // Fetch results for all entities including Pair
                promises.push(getExBySingleTeamNameCasino(sportList.id, roundId, '', match_id, ''));

                const results = await Promise.all(promises);
                const updatedPlayers = {...TOTALPLAYERS};

                // Calculate starting indices for Dragon, Tiger, and Pair
                let oddsIndex = 0;
                const startIndex = results.length - 1; // Start index for the combined results


                entities.forEach((entity, index) => {
                    updatedPlayers[entity] = {
                        ...TOTALPLAYERS[entity],
                        amounts: results[oddsIndex]?.data || '',

                        Even: {
                            ...TOTALPLAYERS[entity].Even,
                            amounts: (results[startIndex]?.data.filter(item => item.type === `${entity.charAt(0)}_EVEN_ODD` && item.team_name === `${entity} Even`)[0]?.total_amount || ''),
                        },
                        Odd: {
                            ...TOTALPLAYERS[entity].Odd,
                            amounts: (results[startIndex]?.data.filter(item => item.type === `${entity.charAt(0)}_EVEN_ODD` && item.team_name === `${entity} Odd`)[0]?.total_amount || ''),
                        },
                        Black: {
                            ...TOTALPLAYERS[entity].Black,
                            amounts: (results[startIndex]?.data.filter(item => item.type === `${entity.charAt(0)}_RED_BLACK` && item.team_name === `${entity} Black`)[0]?.total_amount || ''),
                        },
                        Red: {
                            ...TOTALPLAYERS[entity].Red,
                            amounts: (results[startIndex]?.data.filter(item => item.type === `${entity.charAt(0)}_RED_BLACK` && item.team_name === `${entity} Red`)[0]?.total_amount || ''),
                        },
                        Spade: {
                            ...TOTALPLAYERS[entity].Spade,
                            amounts: (results[startIndex]?.data.filter(item => item.type === `${entity.charAt(0)}_COLOR` && item.team_name === `${entity} Spade`)[0]?.total_amount || ''),
                        },
                        Heart: {
                            ...TOTALPLAYERS[entity].Heart,
                            amounts: (results[startIndex]?.data.filter(item => item.type === `${entity.charAt(0)}_COLOR` && item.team_name === `${entity} Heart`)[0]?.total_amount || ''),
                        },
                        Club: {
                            ...TOTALPLAYERS[entity].Club,
                            amounts: (results[startIndex]?.data.filter(item => item.type === `${entity.charAt(0)}_COLOR` && item.team_name === `${entity} Club`)[0]?.total_amount || ''),
                        },
                        Diamond: {
                            ...TOTALPLAYERS[entity].Diamond,
                            amounts: (results[startIndex]?.data.filter(item => item.type === `${entity.charAt(0)}_COLOR` && item.team_name === `${entity} Diamond`)[0]?.total_amount || ''),
                        }

                    };
                    oddsIndex++;
                });
                updatedPlayers['Pair'] = {
                    ...TOTALPLAYERS.Pair,
                    amounts: (results[2]?.data.filter(item => item.type === 'PAIR' && item.team_name === 'Pair')[0]?.total_amount || ''),

                }


                setTotalPlayers(updatedPlayers);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    };

    const getCardTotalCard32 = (cards, playerNumber) => {
        return cards && cards.length > 0 ? cards.reduce((acc, value, index) => {
            return acc + (index === 0 ? parseInt(playerNumber) : 0) + parseInt(cardMapInteger(value));
        }, 0) : null

    }


    const updateCardsForCard32Casino = (data, totalPlayers, setTotalPlayers, index = null, type = null) => {
        let cardArray;

        if (data.card) {

            cardArray = data?.card?.split(",").map(item => item.trim());
            let j = 0;

            if (index === null) {

                Object.keys(totalPlayers).forEach((key) => {
                    const playerNumber = key.split(" ")[1].trim();

                    let player8cards = [];
                    for (var i = j; i <= 35; i += 4) {
                        if (cardArray[i] !== '1') {
                            if (!player8cards.includes(cardArray[i])) {
                                player8cards.push(cardArray[i]);
                            }


                            setTotalPlayers((prevState) => {
                                const playerCardUpdates = {...prevState};
                                playerCardUpdates[key] = {
                                    ...playerCardUpdates[key],
                                    cards: player8cards.map(value => process.env.REACT_APP_CARD_PATH + value + ".png"),
                                    card_number: getCardTotalCard32(player8cards, playerNumber)

                                };


                                return playerCardUpdates;


                            })
                        }
                    }


                    if (player8cards.length === 0) {

                        setTimeout(function () {
                            setTotalPlayers((prevState) => {
                                const playerCardUpdates = {...prevState};
                                playerCardUpdates[key] = {
                                    ...playerCardUpdates[key],
                                    cards: [],
                                    card_number: null


                                };


                                return playerCardUpdates;


                            })
                        }, 700)
                    }

                    j++;
                })
            } else {


                Object.keys(totalPlayers[index][type]).forEach((key) => {
                    const playerNumber = key.split(" ")[1].trim();

                    let player8cards = [];
                    for (var i = j; i <= 35; i += 4) {
                        if (cardArray[i] !== '1') {
                            if (!player8cards.includes(cardArray[i])) {
                                player8cards.push(cardArray[i]);
                            }


                            setTotalPlayers((prevState) => {
                                const playerCardUpdates = [...prevState];  // copy array
                                const playerInfo = playerCardUpdates[index][type];
                                playerInfo[key] = {
                                    ...playerInfo[key],
                                    cards: player8cards.map(value => process.env.REACT_APP_CARD_PATH + value + ".png"),
                                    card_number: getCardTotalCard32(player8cards, playerNumber)
                                };


                                return playerCardUpdates;  // return updated state
                            });
                        }
                    }


                    if (player8cards.length === 0) {

                        setTimeout(function () {

                            setTotalPlayers((prevState) => {
                                const playerCardUpdates = [...prevState];  // copy array
                                const playerInfo = playerCardUpdates[index][type];
                                playerInfo[key] = {
                                    ...playerInfo[key],
                                    cards: [],
                                    card_number: null
                                };


                                return playerCardUpdates;  // return updated state
                            });
                        }, 700)
                    }

                    j++;
                })
            }
        }
    }
    const getAndarBaharCalculation = async (id, roundId, match_id, setTotalPlayers) => {

        const promises = [getExBySingleTeamNameCasino(id, roundId, '', match_id.toUpperCase(), 'ANDAR'),
            getExBySingleTeamNameCasino(id, roundId, '', match_id.toUpperCase(), 'BAHAR')]
        const results = await Promise.all(promises)


        setTotalPlayers((prevState) => {
            const defaultPlayers = [...prevState];

            defaultPlayers.forEach((value, key) => {
                const playerKey = Object.keys(value)[0]; // Get the key ("Andar" or "Bahar")
                const amounts = {...defaultPlayers[key][playerKey].amounts}; // Clone amounts


                if (results?.[key] && results?.[key]?.data) {
                    results[key].data.forEach(item => {
                        let teamName = item.team_name.trim(); // Normalize the team name (remove any extra spaces)
                        if (teamName.includes("/")) {
                            teamName = teamName.split("/")[0]

                        }
                        if (amounts.hasOwnProperty(teamName)) {


                            amounts[teamName] = item.total_amount; // Update the amount
                        }
                    });
                }

                if (results[key].data.length === 0) {
                    // Reset all amounts to 0
                    Object.keys(amounts).forEach((teamName) => {
                        amounts[teamName] = '';
                    });
                }


                defaultPlayers[key][playerKey] = {
                    ...defaultPlayers[key][playerKey],
                    amounts: amounts // Assign the updated amounts
                };


            });


            return defaultPlayers;
        });


    }


    return (
        <CasinoContext.Provider value={{
            casino_socket,
            getAndarBaharCalculation,
            getCardTotalCard32,
            updateCardsForCard32Casino,
            fetchDataDragonTigerDt6,
            fetchDataDragonTiger,
            betType,
            setBetType,
            popupDisplay,
            setPopupDisplay
        }}>
            {props.children}
        </CasinoContext.Provider>
    );
};